<script lang="ts" setup>
import { ScratchPadItemTypeEnum } from '@/enums'
</script>

<template>
  <Overlay :id="id" size="2xl">
    <CloseButton class="absolute top-3 right-3 z-[99]" @click="setOverlay(null)" />
    <div class="flex flex-col gap-4">
      <Image :src="coverImage" class="w-full bg-slate-200 text-blue-violet aspect-[2/1] object-cover rounded-2xl" />
      <div class="flex flex-col gap-4 py-4">
        <h3 class="text-3xl text-blue-violet font-serif leading-none text-balance">{{ attraction.name }}</h3>
        <div class="flex items-center gap-4 divide-x child:pl-4">
          <PinButton
            :item="{
              type: ScratchPadItemTypeEnum.ATTRACTION,
              id: attraction.id,
              data: { title: attraction.name, image: coverImage, url: attraction.url, original: attraction },
            }"
            class="!pl-0" />
          <StarRating v-if="attraction.reviews" :rating="attraction.reviews.combinedAverageRating" :reviews="attraction.reviews.totalReviews" />
        </div>
        <p class="text-slate-500" v-html="attraction.description" />
      </div>
    </div>
  </Overlay>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import viatorMixins from '@/mixins/viator'

export default defineComponent({
  mixins: [viatorMixins],
  props: {
    attraction: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    coverImage() {
      const image = this.attraction.images.find((image) => image.isCover) || this.attraction.images[0]
      return this.getBestImage(image?.variants) ?? ''
    },
  },
})
</script>
