<script lang="ts" setup>
import { ScratchPadItemTypeEnum } from '@/enums'
</script>

<template>
  <div class="group/card bg-slate-200 relative rounded-3xl aspect-[4/5] overflow-hidden p-3 flex flex-col justify-end transition hover:shadow-xl">
    <PinButton
      class="absolute top-2 right-2 z-10"
      :item="{
        type: ScratchPadItemTypeEnum.ATTRACTION,
        id: attraction.id,
        data: { title: attraction.name, image: coverImage, url: attraction.url, original: attraction },
      }" />
    <Image :src="coverImage" class="cursor-pointer text-blue-violet !absolute inset-0" @click="setOverlay(id)" />
    <div
      class="cursor-pointer flex flex-col gap-3 bg-white group-hover/card:-translate-y-1 group-hover/card:shadow-xl transition-all duration-[400ms] rounded-2xl relative z-10 p-4"
      @click="setOverlay(id)">
      <h3 class="text-2xl text-blue-violet font-serif leading-none text-balance">{{ attraction.name }}</h3>
      <StarRating v-if="attraction.reviews" :rating="attraction.reviews.combinedAverageRating" :reviews="attraction.reviews.totalReviews" />
    </div>
  </div>
  <AttractionOverlay :id="id" :attraction="attraction" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import viatorMixins from '@/mixins/viator'
import uniqid from 'uniqid'

export default defineComponent({
  mixins: [viatorMixins],
  props: {
    attraction: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      uniqid: uniqid(),
    }
  },
  computed: {
    id() {
      return `attraction-${this.uniqid}-${this.attraction.id}`
    },
    coverImage() {
      const image = this.attraction.images.find((image) => image.isCover) || this.attraction.images[0]
      return this.getBestImage(image?.variants) ?? ''
    },
  },
})
</script>
