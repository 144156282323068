import { Message, Size } from '@/types'
import { ModuleTypeEnum } from '@/enums'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      isDevelopment: import.meta.env.VITE_ENV === 'development',
      isProduction: import.meta.env.VITE_ENV === 'production',
    }
  },
  computed: {
    ...mapState([
      'activeModules',
      'numModulesDisplayed',
      'portfolioModules',
      'portfolioSummary',
      'customPortfolioSummary',
      'conversation',
      'isSidebarOpen',
      'firstItemAddedToSidebar',
      'overlay',
      'overlayData',
    ]),
    ...mapGetters(['portfolio', 'isDarkBackground', 'activePanelModules', 'activeModalModules', 'activeFullScreenModules']),
    ...mapGetters('user', ['tripInfo', 'userInfo', 'userInfoList']),
    ...mapGetters('assistant', ['isCallActive', 'isCallLoading', 'isAssistantBusy', 'isAssistantReady']),
  },
  methods: {
    ...mapActions([
      'addActiveModule',
      'removeActiveModule',
      'setActiveModules',
      'setPortfolioModules',
      'addToPortfolioModules',
      'removeFromPortfolioModules',
      'setPortfolioSummary',
      'setCustomPortfolioSummary',
      'addMessage',
      'showSidebar',
      'hideSidebar',
      'toggleSidebar',
      'setOverlay',
      'setSidebarLocked',
      'setSidebarTab',
      'setFirstItemAddedToSidebar',
    ]),
    ...mapActions('user', ['handleCaptureTravelDetails', 'removeTripPreference', 'removeUserPreference']),
  },
}

declare module 'vue' {
  interface ComponentCustomProperties {
    // active modules

    addActiveModule: ({ name, params }: { name: string; type?: ModuleTypeEnum; size?: Size; title?: string; params: object }) => void
    removeActiveModule: (module) => void
    setActiveModules: () => void

    // portfolio modules

    setPortfolioModules: (portfolio: { name: string; params: object }[]) => void
    addToPortfolioModules: ({ name, params }: { name: string; params: object }) => void
    removeFromPortfolioModules: (name: string) => void

    setPortfolioSummary: (summary: string) => void
    addMessage: (message: Message) => void
    showSidebar: ({ tab, panel }?: { tab?: string; panel?: string }) => void
    hideSidebar: () => void
    toggleSidebar: () => void
    setOverlay: (overlay: string | null) => void
    setCustomPortfolioSummary: (summary: string) => void
    captureTravelDetails: (payload: any) => void
    handleCaptureTravelDetails: (payload: any) => void
    setSidebarLocked: (locked: boolean) => void
    setSidebarTab: (tab: string) => void
    setFirstItemAddedToSidebar: (value: boolean) => void

    activeModules: { name: string; size: Size; title?: string; params: object }[]
    numModulesDisplayed: number
    portfolioModules: { name: string; params: object }[]
    portfolioSummary: string
    isSidebarOpen: boolean
    overlay: string | null
    overlayData: any
    customPortfolioSummary: string
    conversation: Message[]

    portfolio: {
      departure_date: string
      return_date: string
      location: string
      lat_long: number[]
      funfacts: string[]
      travelers: { traveler_type: string; age: number }[]
    }
    isDarkBackground: boolean
    activePanelModules: { name: string; params: object }[]
    activeModalModules: { name: string; params: object }[]
    activeFullScreenModules: { name: string; params: object }[]

    tripInfo: { [key: string]: string[] }
    userInfo: { [key: string]: string[] }
    userInfoList: { category: string; value: string }[]

    isCallActive: boolean
    isCallLoading: boolean
    isAssistantBusy: boolean
    isAssistantReady: boolean

    isDevelopment: boolean
    isProduction: boolean
  }
}
