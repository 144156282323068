import { Events } from '@/types'
import { ModuleTypeEnum, SidebarWidthEnum } from '@/enums'
import { createStore } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import auth from './modules/auth'
import user from './modules/user'
import assistant from './modules/assistant'
import loader from './modules/loader'
import queuePlugin from './plugins/queue'

export interface State {
  modules: { name: string; type: ModuleTypeEnum; params: object; loaded: boolean; accessed: boolean }[]
  activeModules: { name: string; type: ModuleTypeEnum; params: object; loaded: boolean; accessed: boolean }[]
  numModulesDisplayed: number
  portfolioModules: { name: string; params: object }[]
  informationModules: any[]

  functionQueue: { name: keyof Events; params: object }[]
  abortControllers: { controllerId: string; abortController: AbortController }[]
  apiError: any

  onboardingStepsCompleted: number

  portfolioSummary: string
  customPortfolioSummary: string

  overlay: string | null
  overlayData: any
  nestedOverlays: string[]
  toastMessage: string | null

  isSidebarOpen: boolean
  isSidebarLocked: boolean
  sidebarTab: string | null
  sidebarDetailsPanel: string | null
  sidebarDetailsPanelShown: {
    tripDetails: boolean
    tripInfo: boolean
    userInfo: boolean
  }
  firstItemAddedToSidebar: boolean
  sidebarWidth: SidebarWidthEnum

  takeoverBackground: string | null
  isFullScreenModule: boolean
  isFtuxModule: boolean

  weatherForecast: { time: string[]; weather_code: number[]; temperature_2m_max: number[]; temperature_2m_min: number[] } | null
}

export default createStore<State>({
  modules: {
    auth,
    user,
    loader,
    assistant,
  },
  state: {
    modules: [],
    activeModules: [],
    numModulesDisplayed: 0,
    portfolioModules: [],
    informationModules: [],

    functionQueue: [],
    abortControllers: [],
    apiError: null,

    onboardingStepsCompleted: 0,

    portfolioSummary: '',
    customPortfolioSummary: '',

    overlay: null,
    overlayData: null,
    nestedOverlays: [],
    toastMessage: null,

    isSidebarOpen: false,
    isSidebarLocked: false,
    sidebarTab: null,
    sidebarDetailsPanel: null,
    sidebarDetailsPanelShown: {
      tripDetails: false,
      tripInfo: false,
      userInfo: false,
    },
    firstItemAddedToSidebar: false,
    sidebarWidth: SidebarWidthEnum.SM,

    takeoverBackground: null,
    isFullScreenModule: false,
    isFtuxModule: false,

    weatherForecast: null,
  },
  actions,
  mutations,
  getters,
  plugins: [queuePlugin],
})
