<template>
  <div v-if="portfolioSummary || customPortfolioSummary" class="flex flex-col gap-1">
    <div class="relative flex">
      <div v-if="!editingSummary" class="group text-slate-500 font-light text-xl flex items-center gap-2" @click="editSummary">
        <div>{{ customPortfolioSummary || portfolioSummary }}</div>
        <IconPencil class="w-6 h-6 text-blue-violet-200 transition cursor-pointer group-hover:text-blue-violet shrink-0" />
      </div>
      <div v-if="editingSummary" class="relative flex w-full pr-8">
        <div ref="summary" class="group text-slate-500 font-light text-xl absolute left-0 right-8 opacity-0 pointer-events-none">
          {{ internalSummary }}
        </div>
        <textarea
          ref="summaryInput"
          v-model="internalSummary"
          class="w-full text-slate-500 font-light text-xl border-none border-transparent p-0 focus:outline-none focus:border-transparent focus:ring-0 overflow-hidden resize-none min-h-7"
          placeholder="Add a summary..."
          @input="onEditSummary"
          @blur="saveCustomSummary"
          @keydown.enter="saveCustomSummary" />
      </div>
    </div>
    <div class="text-blue-violet text-xs cursor-pointer hover:text-violet-400" @click="setOverlay('userProfile')">Manage my trips</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { IconPencil } from '@tabler/icons-vue'
import config from '@/config/index.json'
import { ModuleEventEnum } from '@/enums'

export default defineComponent({
  components: {
    IconPencil,
  },
  data() {
    return {
      config,
      editingSummary: false,
      internalSummary: '',
    }
  },
  mounted() {
    this.$emitter.on(ModuleEventEnum.TRIP_TITLE, this.setTripTitle)
  },
  unmounted() {
    this.$emitter.off(ModuleEventEnum.TRIP_TITLE, this.setTripTitle)
  },
  methods: {
    setTripTitle({ title }: { title: string }) {
      this.setPortfolioSummary(title)
    },
    editSummary() {
      this.editingSummary = true
      if (this.editingSummary) {
        this.internalSummary = this.customPortfolioSummary || this.portfolioSummary
        this.$nextTick(() => {
          ;(this.$refs.summaryInput as HTMLElement).focus()
          this.onEditSummary()
        })
      }
    },
    saveCustomSummary() {
      this.editingSummary = false
      this.setCustomPortfolioSummary(this.internalSummary?.trim())
    },
    onEditSummary() {
      ;(this.$refs.summaryInput as HTMLElement).style.height = `${(this.$refs.summary as HTMLElement).offsetHeight}px`
    },
  },
})
</script>
