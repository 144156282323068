<template>
  <div class="panel" :class="{ open, closed }">
    <slot v-bind="$props" />
    <transition name="fade">
      <figure v-if="hasContent" class="overlay" />
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    open: Boolean,
    closed: Boolean,
    hasContent: {
      type: Boolean,
      default: true,
    },
  },
})
</script>

<style scoped>
.panel {
  @apply flex-1 flex-grow relative overflow-hidden transition-flex duration-[500ms] flex flex-col gap-4;
  transition-timing-function: var(--ease-in-out-circ);

  .overlay {
    @apply absolute block h-16 w-full left-0 flex-none bottom-0 bg-gradient-to-b from-transparent via-white/80 to-white/100 transition-all duration-500 ease-out opacity-100 pointer-events-none z-[99];
  }

  &.open {
    @apply flex-grow;

    .overlay {
      @apply h-8 ease-in;
    }
  }

  &.closed {
    @apply flex-shrink-0 flex-grow-0 basis-[4.5rem];

    .overlay {
      pointer-events: none;
      @apply opacity-0 ease-in h-[70px];
    }
  }
}
</style>
