<script lang="ts" setup>
import { InformationLayoutEnum, InformationTypeEnum } from '@/enums'
</script>

<template>
  <ModuleFullScreen v-slot="{ breakpoint }" class="!z-10" :padding-top="false" :padding-bottom="false" :container-class="disabled ? 'pointer-events-none' : ''">
    <transition name="fade">
      <Spinner v-if="loading" absolute class="z-[999] text-white" />
    </transition>
    <transition name="blob">
      <div v-if="blobVisible" class="absolute right-10 bottom-10 w-72 h-72 z-[99] pointer-events-none">
        <Blob :mode="'idle'" />
      </div>
    </transition>
    <div class="absolute w-12 h-full">
      <Menu
        v-if="layout !== InformationLayoutEnum.RECOMMENDATION"
        class="left-0"
        :class="{ 'opacity-50 hover:opacity-100': narrowLayout && !showMenu }"
        v-bind="{ open: showMenu, breakpoint }"
        @open="showMenu = true"
        @close="showMenu = false">
        <RecommendationsMenu
          v-if="layout === InformationLayoutEnum.RECOMMENDATIONS"
          v-bind="{ categories, selectedCategories, filters, selectedFilters, breakpoint }"
          @input:categories="onChangeRecommendationCategories"
          @input:filters="onChangeRecommendationFilters" />
        <RecommendationMenu v-else-if="layout === InformationLayoutEnum.RECOMMENDATION" v-bind="{ recommendation, breakpoint }" />
        <ExploringMenu v-else v-bind="{ title, subtitle, category, questions: suggestedQuestions, breakpoint }" />
      </Menu>
      <transition name="fade">
        <SpeechBubble v-if="showMenuHint" position="right" class="w-36 z-10">Not sure what to ask next?</SpeechBubble>
      </transition>
    </div>
    <div class="dark-background-bottom-gradient" />
    <div
      ref="content"
      class="absolute inset-0 flex flex-col overflow-auto dark-background transition-all duration-[700ms]"
      :class="{ 'sidebar-open-padding-right-full': isSidebarOpen, 'pl-[20rem]': showMenu }"
      @scroll="scrollListener">
      <!-- big title -->
      <Header v-if="title && layout === InformationLayoutEnum.DEFAULT" v-bind="{ breakpoint, title, subtitle, photo: photos?.[0]?.url }" @close="close" />
      <!-- simple title -->
      <Title v-else-if="title" v-bind="{ title, subtitle }" @close="close" />
      <!-- recommendation title -->
      <Header
        v-if="layout === InformationLayoutEnum.RECOMMENDATION"
        v-bind="{ breakpoint, title: recommendation.title, subtitle: recommendation.cover.description, photo: recommendation.cover.photo.url }"
        @close="close" />
      <!-- content -->
      <div class="flex-auto pb-48 px-4" :class="{ 'narrow-layout': narrowLayout }">
        <!-- photos layout -->
        <PhotosLayout v-if="layout === InformationLayoutEnum.PHOTOS" ref="layout" v-bind="{ breakpoint, functionCall, photos, description }" />
        <!-- map layout -->
        <MapLayout
          v-if="layout === InformationLayoutEnum.MAP"
          ref="layout"
          v-bind="{ breakpoint, functionCall, mapLatLong, mapPois, mapZoomLevel, photos, video, description }" />
        <!-- video layout -->
        <VideoLayout v-if="layout === InformationLayoutEnum.VIDEO" ref="layout" v-bind="{ breakpoint, functionCall, video }" />
        <!-- default layout -->
        <DefaultLayout
          v-if="layout === InformationLayoutEnum.DEFAULT"
          ref="layout"
          v-bind="{ breakpoint, functionCall, photos, description, video, mapLatLong, mapPois, mapZoomLevel }" />
        <!-- recommendations layout -->
        <RecommendationsLayout
          v-if="layout === InformationLayoutEnum.RECOMMENDATIONS"
          ref="layout"
          v-bind="{ breakpoint, functionCall, recommendations, selectedCategories, selectedFilters }"
          @update:categories="updateRecommendationCategories"
          @update:filters="updateRecommendationFilters"
          @close="close" />
        <!-- recommendation layout -->
        <RecommendationLayout v-if="layout === InformationLayoutEnum.RECOMMENDATION" v-bind="{ breakpoint, functionCall, recommendation }" />
        <!-- activities and attractions -->
        <div class="relative max-w-5xl mx-auto py-8 grid grid-cols-5 gap-8">
          <div class="col-span-5">
            <Attractions v-bind="{ attractions }" />
          </div>
          <div class="col-span-5">
            <Products v-bind="{ products }" />
          </div>
        </div>
      </div>
    </div>
  </ModuleFullScreen>
</template>

<script lang="ts">
import { MessageRoleEnum, ModuleEventEnum, FunctionEnum } from '@/enums'
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import debounce from 'debounce'
import dataMixins from '@/information/mixins/data'

import PhotosLayout from '@/information/layouts/PhotosLayout.vue'
import MapLayout from '@/information/layouts/MapLayout.vue'
import VideoLayout from '@/information/layouts/VideoLayout.vue'
import DefaultLayout from '@/information/layouts/DefaultLayout.vue'
import RecommendationsLayout from '@/information/layouts/RecommendationsLayout.vue'
import RecommendationLayout from '@/information/layouts/RecommendationLayout.vue'

import Menu from '@/information/components/Menu.vue'
import RecommendationsMenu from '@/information/components/RecommendationsMenu.vue'
import RecommendationMenu from '@/information/components/RecommendationMenu.vue'
import ExploringMenu from '@/information/components/ExploringMenu.vue'

import Header from '@/information/components/Header.vue'
import Title from '@/information/components/Title.vue'
import Attractions from '@/information/components/Attractions.vue'
import Products from '@/information/components/Products.vue'

const DEFAULT_MAP_ZOOM_LEVEL = 10

export default defineComponent({
  components: {
    PhotosLayout,
    MapLayout,
    VideoLayout,
    DefaultLayout,
    RecommendationsLayout,
    RecommendationLayout,
    Menu,
    RecommendationsMenu,
    RecommendationMenu,
    ExploringMenu,
    Header,
    Title,
    Attractions,
    Products,
  },
  mixins: [dataMixins],
  props: {
    id: {
      type: String,
      required: true,
    },
    first: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // information data
      priority: [] as InformationTypeEnum[],
      title: '',
      subtitle: '',
      description: '',
      photos: [] as Array<{ url: string; description: string }>,
      video: '',
      mapLatLong: [],
      mapPois: [] as Array<[number, number]>,
      mapZoomLevel: DEFAULT_MAP_ZOOM_LEVEL,
      attractions: [] as object[],
      products: [] as object[],
      suggestedQuestions: [] as string[],
      suggestedQuestionsPrompt: false,
      categories: [] as object[],
      filters: [] as string[],
      selectedCategories: [] as string[],
      selectedFilters: [] as string[],
      recommendation: {} as object,
      recommendations: [] as object[],

      // ui data
      loading: false,
      showMenu: false,
      menuShown: false,
      showMenuHint: false,
      scrolled: false,
      scrollListener: debounce(
        function (event) {
          ;(this as any).$emit('scrolled', event.target.scrollTop > 100)
        }.bind(this),
        200
      ),
      functionCall: {} as object,
      blobVisible: false,
    }
  },
  computed: {
    layout(): InformationLayoutEnum {
      if (this.hasRecommendations || this.prioritizeRecommendations) {
        return InformationLayoutEnum.RECOMMENDATIONS
      }
      if (this.hasRecommendation) {
        return InformationLayoutEnum.RECOMMENDATION
      }
      if (this.hasPhotos && this.prioritizePhotos) {
        return InformationLayoutEnum.PHOTOS
      }
      if (this.hasVideo && this.prioritizeVideo) {
        return InformationLayoutEnum.VIDEO
      }
      if (this.hasMap && this.prioritizeMap) {
        return InformationLayoutEnum.MAP
      }
      return InformationLayoutEnum.DEFAULT
    },
  },
  watch: {
    layout: {
      immediate: true,
      handler() {
        this.updateInformationModule({ id: this.id, darkBackground: true })
      },
    },
    showMenu() {
      if (this.showMenu) {
        this.showMenuHint = false
      }
    },
    // temporary
    prioritizeRecommendations() {
      setTimeout(() => {
        this.blobVisible = true
      }, 2000)
      setTimeout(() => {
        this.spotlight(this.$refs.layout.$refs.recommendationCards[1].$el)
      }, 3000)
    },
  },
  async mounted() {
    this.$emitter.on(FunctionEnum.GO_BACK, this.goBack)
    this.$emitter.on(ModuleEventEnum.CALL_END, this.close)

    this.functionCall = (await this.getFunctionCall(this.$props.data.toolCallId)) || {}

    // populate basic info

    console.log('[InformationModule] populate from $props', this.$props.data)
    this.populate(this.$props.data)

    // populate from api (if it's not recommendations)

    if (!this.prioritizeRecommendations && !this.hasRecommendation) {
      this.loading = true
      const results = await this.apiFetchWithTripId(`/information`, {
        ...this.functionCall,
      })
      this.loading = false
      console.log('[InformationModule] populate from api', results)
      this.populate(results)
    }

    this.possiblyShowMenu()
  },
  unmounted() {
    this.$emitter.off(ModuleEventEnum.CALL_END, this.close)
    this.$emitter.off(FunctionEnum.GO_BACK, this.goBack)
  },
  methods: {
    ...mapActions('assistant', ['sendMessage', 'getFunctionCall']),
    ...mapActions(['removeFromInformationModules', 'updateInformationModule']),
    populate({
      priority = [],
      title,
      subtitle,
      description,
      photo,
      map,
      video,
      attraction,
      questions,
      categories,
      filters,
      recommendations,
      recommendation,
    }: {
      priority?: InformationTypeEnum[]
      title?: string
      subtitle?: string
      description?: string
      photo?: Array<{ id: string; urls: any; description: string }>
      map?: { zoom_level: number; pois: { lat_long: []; name: string }[] }
      video?: string
      attraction?: {
        attractions?: { results: [] }
        products?: { results: [] }
      }
      products?: { results: [] }
      questions?: string[]
      categories?: object[]
      filters?: string[]
      recommendations?: object[]
      recommendation?: object
    }) {
      if (priority) {
        this.priority = priority
      }

      if (title) {
        this.title = title
      }

      if (subtitle) {
        this.subtitle = subtitle
      }

      if (description) {
        this.description = description
      }

      // video
      if (video && Array.isArray(video) && video?.[0]?.link) {
        this.video = video[0].link
      }

      // photos
      if (photo && Array.isArray(photo) && photo.length > 0) {
        this.photos = photo.map((p) => ({ url: p.urls.regular, ...p }))
      }

      // map
      if (map && map.pois && map.pois.length > 0) {
        this.mapLatLong = map?.pois?.[0]?.lat_long || [0, 0]
        this.mapPois = map?.pois || []
        this.mapZoomLevel = map?.zoom_level || DEFAULT_MAP_ZOOM_LEVEL
      }

      // attractions
      if (attraction?.attractions?.results && Array.isArray(attraction.attractions.results) && attraction.attractions.results.length > 0) {
        this.attractions = attraction.attractions.results
      }

      // products
      if (attraction?.products?.results && Array.isArray(attraction.products.results) && attraction.products.results.length > 0) {
        this.products = attraction.products.results
      }

      // questions
      if (questions && Array.isArray(questions) && questions.length > 0) {
        this.suggestedQuestions = questions
      }

      // categories
      if (categories && Array.isArray(categories) && categories.length > 0) {
        this.categories = categories
        this.selectedCategories = categories.map((c: any) => c.id)
      }

      // filters
      if (filters && Array.isArray(filters) && filters.length > 0) {
        this.filters = filters
        this.selectedFilters = filters
      }

      // recommendations
      if (recommendations && Array.isArray(recommendations) && recommendations.length > 0) {
        this.recommendations = recommendations
      }

      // recommendation
      if (recommendation && Object.keys(recommendation).length) {
        this.recommendation = recommendation
      }

      // TODO: handle video w/ system message
      /*
      If a video:
      "The user is watching a video about toolCall.function.arguments.title. While the user is watching the video, they should not 
      be interrupted. It is mandatory that you not link to or mention the URL of the video. For example, do not add a "watch video" link."

      If no video:
      "The user has been presented more information about toolCall.function.arguments.title. You should provide a 1-2 sentence 
      summary of the description that has been presented."
      */
    },
    possiblyShowMenu() {
      // for first module, show menu after a short delay
      window.setTimeout(() => {
        if (
          (this.layout === InformationLayoutEnum.RECOMMENDATIONS && this.categories.length > 0) ||
          this.filters.length > 0 ||
          this.suggestedQuestions.length > 0
        ) {
          if (this.first && !this.menuShown) {
            // show menu
            this.showMenu = true
            this.menuShown = true
          } else {
            // show tooltip
            this.showMenuHint = true
            window.setTimeout(() => {
              this.showMenuHint = false
            }, 3000)
          }
        }
      }, 1000)
    },
    onChangeRecommendationCategories(categories) {
      this.selectedCategories = categories
    },
    onChangeRecommendationFilters(filters) {
      this.selectedFilters = filters
    },
    updateRecommendationCategories(categories) {
      // update available categories and ensure unselected categories stay unselected
      const unselectedCategories = this.categories.filter((c: any) => !this.selectedCategories.includes(c.id)).map((c: any) => c.id)
      this.categories = categories
      this.selectedCategories = categories.map((c: any) => c.id).filter((id: string) => !unselectedCategories.includes(id))
    },
    updateRecommendationFilters(filters) {
      // update available filters and ensure unselected filters stay unselected
      const unselectedFilters = this.filters.filter((f) => !this.selectedFilters.includes(f))
      this.filters = filters
      this.selectedFilters = filters.filter((f) => !unselectedFilters.includes(f))
    },
    goBack() {
      // only close if this module is focused
      if (this.focused) {
        this.close()
      }
    },
    async close(e) {
      await this.sendMessage({
        role: MessageRoleEnum.SYSTEM,
        content: `The user has finished viewing the results of the ${FunctionEnum.INFORMATION} tool call with id ${this.data.toolCallId}`,
      })
      this.removeFromInformationModules(this.id)
    },
  },
})
</script>

<style scoped>
.blob-enter-active,
.blob-leave-active {
  transition: all 1s var(--ease-in-out-circ);
}

.blob-enter-from,
.blob-leave-to {
  @apply opacity-0 scale-50 translate-y-1/3;
}
</style>
