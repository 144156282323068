<template>
  <div class="flex items-center gap-2 text-sm py-1 pl-2 pr-3 rounded-full text-white" :class="[bgColor]">
    <RecommendationIcon :type="recommendation.type" class="w-5 h-5 shrink-0 opacity-50 blend-mode-opacity" />
    <span>{{ name }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { RecommendationType } from '@/types'
import { RecommendationTypeEnum } from '@/enums'

export default defineComponent({
  props: {
    recommendation: {
      type: Object as PropType<RecommendationType>,
      required: true,
    },
  },
  computed: {
    bgColor() {
      switch (this.recommendation.type) {
        case RecommendationTypeEnum.DESTINATION:
          return 'bg-purple-600/90'
        case RecommendationTypeEnum.ATTRACTION:
          return 'bg-blue-600/90'
        case RecommendationTypeEnum.ACTIVITY:
        case RecommendationTypeEnum.PRODUCT:
          return 'bg-emerald-500/90'
        case RecommendationTypeEnum.TRIP:
          return 'bg-orange-500/90'
        case RecommendationTypeEnum.RESTAURANT:
          return 'bg-fuchsia-600/90'
        case RecommendationTypeEnum.ACCOMMODATION:
          return 'bg-rose-600/90'
      }
      return null
    },
    name() {
      if (this.recommendation.subcategory) {
        return this.recommendation.subcategory
      }
      switch (this.recommendation.type) {
        case RecommendationTypeEnum.DESTINATION:
          return 'Destination'
        case RecommendationTypeEnum.ATTRACTION:
          return 'Attraction'
        case RecommendationTypeEnum.ACTIVITY:
        case RecommendationTypeEnum.PRODUCT:
          return 'Activity'
        case RecommendationTypeEnum.TRIP:
          return 'Trip'
        case RecommendationTypeEnum.RESTAURANT:
          return 'Restaurant'
        case RecommendationTypeEnum.ACCOMMODATION:
          return 'Accommodation'
      }
      return null
    },
  },
})
</script>
