import { App, defineAsyncComponent } from 'vue'
import { Store } from 'vuex'
import { Router } from 'vue-router'
import Module from '@/modules/module'
import { FunctionEnum } from '@/enums'

const size = 'lg'

export default ({ name, store, app }: { name: string; router?: Router; store: Store; app: App }) => {
  // create module
  const module = new Module({ name, size, store, app })

  // register event handlers
  module.registerEventHandlers([
    {
      event_name: FunctionEnum.TRIP_SUMMARY,
      confirmHandler: (params) => {
        return `The user has confirmed that they will ${params.foo} and ${params.bar}`
      },
      inferredHandler: (params) => {
        return `Can we confirm ${params.foo} and ${params.bar} with the user?`
      },
      removeHandler: () => {
        return `The user is removing this from the trip`
      },
      requestHandler: () => {
        return `The user would like to talk about foo and bar`
      },
      portfolioComponent: defineAsyncComponent(() => import('./components/Portfolio.vue')),
    },
  ])

  // add module store
  module.addModuleStore({
    state: {
      // ...
    },
    actions: {
      // ...
    },
    mutations: {
      // ...
    },
  })
}
