<template>
  <button
    class="font-medium rounded-full transition inline-flex items-center cursor-pointer leading-tight"
    :class="[
      `type-${type}`,
      {
        'text-base px-8 py-4 gap-1.5 size-xl': size === 'xl',
        'text-base px-6 py-2 gap-1.5 size-lg': size === 'lg',
        'text-base px-4 py-2 gap-1.5 size-md': size === 'md',
        'text-sm px-3 py-2 gap-1 size-sm': size === 'sm',
        'text-xs px-2 py-1 gap-1 size-xs': size === 'xs',
      },
    ]">
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'md',
    },
  },
})
</script>

<style scoped>
.type-default {
  @apply bg-slate-400 text-white hover:bg-slate-300;
}

.type-tab {
  @apply bg-blue-500 text-white hover:bg-blue-400;
}

.type-light {
  @apply bg-sky-100 text-blue-violet hover:bg-sky-200;
}

.type-bold {
  @apply bg-purple-400 text-white hover:bg-purple-300;
}

.type-alert {
  @apply bg-amber-300 text-amber-800 hover:bg-amber-400;
}

.type-delete {
  @apply bg-red-400 text-white hover:bg-red-500;
}

.type-pill {
  @apply bg-sky-100 text-slate-700 hover:bg-red-400 hover:text-white;
}

.type-pill :deep(svg) {
  @apply text-blue-500;
}

.type-pill:hover :deep(svg) {
  @apply text-white;
}

.size-xl :deep(svg) {
  @apply w-12 h-12 opacity-60 inline-flex;
}

.size-lg :deep(svg) {
  @apply w-8 h-8 opacity-60 inline-flex;
}

.size-md :deep(svg) {
  @apply w-5 h-5 opacity-60 inline-flex;
}

.size-sm :deep(svg) {
  @apply w-4 h-4 opacity-60 inline-flex;
}

.size-xs :deep(svg) {
  @apply w-4 h-4 opacity-60 inline-flex;
}
</style>
