<template>
  <div class="absolute inset-0 transition duration-[700ms]" :class="{ 'sidebar-open-translate-1/2': isSidebarOpen }">
    <component
      :is="module.name"
      v-for="module in activeFullScreenModules"
      :key="module.name"
      :data-module="module.name"
      v-bind="{ ...(module?.params ?? {}), name: module.name }"
      @close="removeActiveModule(module.name)"
      @update="(props) => update(module, props)" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

export default defineComponent({
  methods: {
    ...mapActions(['updateActiveModule']),
    update(module, props) {
      this.updateActiveModule({ name: module.name, ...props })
    },
  },
})
</script>
