import Rollbar, { LogArgument } from 'rollbar'
import { App, Plugin } from 'vue'

export default {
  install(app: App) {
    if (import.meta.env.MODE == 'production') {
      const rollbar = new Rollbar({
        accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: import.meta.env.MODE,
        },
      })
      app.config.errorHandler = (error, vm, info) => {
        rollbar.error(error as LogArgument)
        console.log('Caught error', error)
      }
      app.provide('rollbar', rollbar)
    }
  },
} as Plugin
