<template>
  <Gallery v-slot="{ gallery }" class="relative max-w-5xl mx-auto py-8 grid grid-cols-3 auto-rows-min grid-flow-dense gap-8">
    <div class="col-span-3 flex flex-col gap-8">
      <Markdown v-if="hasDescription" :source="description" class="prose text-slate-200" />
    </div>
    <Image
      v-for="(photo, index) in photos"
      :key="photo.id"
      :can-save="true"
      :src="photo.url"
      :caption="photo.description"
      :gallery="gallery"
      :class="{ 'col-span-1 aspect-ratio': index % 4, 'col-span-2': !(index % 4) }"
      class="rounded-xl text-white" />
  </Gallery>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Markdown from 'vue-markdown-render'
import dataMixins from '../mixins/data'
import propsMixins from '../mixins/props'

export default defineComponent({
  components: { Markdown },
  mixins: [dataMixins, propsMixins],
})
</script>
