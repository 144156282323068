import { FunctionEnum } from '@/enums'
import Module from '@/modules/module'
import { App, defineAsyncComponent } from 'vue'
import { Router } from 'vue-router'
import { Store } from 'vuex'

const title = 'Accommodations'

export default ({ name, store, app }: { name: string; router?: Router; store: Store; app: App }) => {
  // create module
  const module = new Module({ name, title, store, app })

  // register event handlers
  module.registerEventHandlers([
    {
      event_name: FunctionEnum.ACCOMMODATIONS,
      shownHandler(params) {
        console.log('[accommodations] shownHandler', params)
        return `The user has been shown that ${params.foo} and ${params.bar}`
      },
      confirmHandler: (params) => {
        console.log('[accommodations] confirmHandler', params)
        return `The user has confirmed that they will ${params.foo} and ${params.bar}`
      },
      inferredHandler: (params) => {
        console.log('[accommodations] inferredHandler', params)
        return `Can we confirm ${params.foo} and ${params.bar} with the user?`
      },
      removeHandler: () => {
        console.log('[accommodations] removeHandler')
        return `The user is removing this from the trip`
      },
      requestHandler: () => {
        console.log('[accommodations] requestHandler')
        return `The user would like to talk about foo and bar`
      },
      portfolioComponent: defineAsyncComponent(() => import('./components/Portfolio.vue')),
    },
  ])

  // add module store
  module.addModuleStore({
    state: {
      // ...
      results: [],
      loading: false,
      error: null,
      numResultsToShow: 5,
    },
    actions: {
      // gets called when the module is loaded
      async onModuleLoaded({ commit }) {
        commit('loader/pending', null, { root: true })

        // get api stuff

        commit('loader/done', null, { root: true })
      },
      // gets called when the module is first accessed
      async onModuleAccessed({ commit }) {
        commit('loader/pending', null, { root: true })

        // get api stuff
        commit('loader/done', null, { root: true })
      },
      async onServerStreamEvent({ dispatch, state }, { event, id, property, value }) {
        // update module in the background
      },
    },
    mutations: {
      // ...
    },
  })
}
