<template>
  <Overlay :id="id" size="xl" padding="thin" @show="currentTab = 'myTrips'">
    <div class="flex flex-col gap-3 min-h-[50vh]">
      <nav class="flex items-center justify-center gap-2 py-3">
        <MiniButton :type="currentTab === 'myTrips' ? 'tab' : 'light'" @click="currentTab = 'myTrips'">
          <IconPlane />
          My Trips
        </MiniButton>
        <MiniButton :type="currentTab === 'userInfo' ? 'tab' : 'light'" @click="currentTab = 'userInfo'">
          <IconUser />
          About me
        </MiniButton>
      </nav>

      <transition name="fade" mode="out-in">
        <MyTrips v-if="currentTab === 'myTrips'" />
        <AboutMe v-else-if="currentTab === 'userInfo'" />
      </transition>

      <UserProfile class="p-4 rounded-4xl border border-slate-200">
        <MiniButton type="bold" @click="logout">Logout</MiniButton>
      </UserProfile>
    </div>
  </Overlay>
</template>

<script lang="ts">
import { IconPlane, IconUser } from '@tabler/icons-vue'
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

export default defineComponent({
  components: {
    IconPlane,
    IconUser,
  },
  data() {
    return {
      currentTab: 'myTrips',
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
  },
})
</script>
