<template>
  <div class="relative max-w-5xl mx-auto py-8 grid grid-cols-4 narrow:grid-cols-2 auto-rows-min grid-flow-dense gap-8">
    <div class="col-span-2 narrow:order-2 flex flex-col gap-4">
      <div class="flex items-center flex-wrap gap-4 divide-x divide-white/20 child:pl-4">
        <div class="!pl-0">
          <RecommendationTag :recommendation="recommendation" />
        </div>
        <PinButton :item="scratchPadItem" />
        <RecommendationStarRating :recommendation="recommendation" />
      </div>
      <div v-if="recommendation.details.description" class="prose text-slate-200" v-html="recommendation.details.description" />
    </div>
    <Image :src="recommendation.cover.photo.url" class="col-span-2 narrow:order-1 aspect-video text-white rounded-xl" />
    <RecommendationMeta class="col-span-4 narrow:col-span-2" :recommendation="recommendation" />
    <Gallery v-if="recommendation?.details?.images?.length > 0" v-slot="{ gallery }" class="col-span-4 grid grid-cols-3 gap-4">
      <Image
        v-for="(image, index) in recommendation.details.images"
        :key="index"
        :src="image.photo"
        :caption="image.title"
        :can-save="true"
        :gallery="gallery"
        class="aspect-video text-white rounded-xl" />
    </Gallery>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import dataMixins from '../mixins/data'
import propsMixins from '../mixins/props'
import recommendationMixins from '@/mixins/recommendation'

export default defineComponent({
  mixins: [dataMixins, propsMixins, recommendationMixins],
})
</script>
