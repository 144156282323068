<template>
  <Wordmark />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Wordmark from '@/assets/wordmark-ai.svg'

export default defineComponent({
  components: { Wordmark: Wordmark as any },
})
</script>

<style scoped>
:deep(.suffix) {
  @apply fill-[#8179c5];
}
</style>
