<template>
  <button
    class="w-10 h-10 p-2 relative text-white bg-blue-violet rounded-full flex items-center justify-center cursor-pointer transition hover:bg-violet-400 hover:text-white hover:scale-110">
    <IconUser stroke-width="2" class="w-full h-full" />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { IconUser } from '@tabler/icons-vue'

export default defineComponent({
  components: { IconUser },
})
</script>
