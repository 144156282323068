<template>
  <div class="fixed inset-0 z-0 pointer-events-none">
    <transition name="fade">
      <ImageFallback v-if="showImage" :src="takeoverBackground" class="background w-full h-full opacity-80" />
    </transition>
  </div>
</template>

<script lang="ts">
import { mapState } from 'vuex'
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    ...mapState(['takeoverBackground']),
    showImage() {
      if (typeof this.takeoverBackground === 'string' && this.takeoverBackground.length > 0) {
        return true
      }
      if (this.takeoverBackground && Array.isArray(this.takeoverBackground) && this.takeoverBackground.length > 0) {
        return true
      }
      return false
    },
  },
})
</script>

<style scoped>
.background {
  animation: kenburns 20s infinite alternate ease-in-out;
}

@keyframes kenburns {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
</style>
