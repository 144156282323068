<template>
  <div class="h-full flex flex-col justify-center">
    <transition name="fade">
      <WaitingProcess v-if="internalRecommendations.length === 0" class="!fixed inset-0 z-[1000]" :can-skip="true" @skip="skip" />
      <div v-else class="text-white w-full px-6 max-w-7xl mx-auto flex flex-col gap-12">
        <div v-if="filteredRecommendations.length > 0" class="grid gap-8 narrow:grid-cols-2" :class="[`grid-cols-${cols}`]">
          <TransitionGroupGrid>
            <RecommendationCard
              v-for="(recommendation, index) in filteredRecommendations"
              ref="recommendationCards"
              :key="recommendation.id"
              :style="`--index: ${index}`"
              v-bind="{ recommendation, isShortBreakpoint: breakpoint.short }"
              @click="viewRecommendation(recommendation)" />
          </TransitionGroupGrid>
        </div>
        <div v-else class="text-white w-full flex flex-col items-center justify-center px-6 py-24 bg-blue-violet/20 rounded-3xl">
          <IconBeach class="w-12 h-12 text-violet-400" stroke-width="1.5" />
          <div class="text-slate-200 font-light text-xl">Nothing here</div>
        </div>
        <Spinner v-if="loading" class="self-center" />
        <div v-else class="flex flex-col items-center gap-4 justify-center">
          <Button v-if="filteredRecommendations.length" light @click="getRecommendations">Show me more</Button>
          <div class="text-violet-300 cursor-pointer hover:text-white transition" @click="$emit('close')">Go back</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { FunctionEnum, MessageRoleEnum } from '@/enums'
import { ToolCallParamsType, RecommendationType } from '@/types'
import dataMixins from '../mixins/data'
import propsMixins from '../mixins/props'
import { IconBeach } from '@tabler/icons-vue'

const NUM_FEATURED_RECOMMENDATIONS = 3
const MAX_COLUMNS = 4

export default defineComponent({
  components: { IconBeach },
  mixins: [dataMixins, propsMixins],
  emits: ['update:categories', 'update:filters', 'close'],
  data() {
    return {
      internalRecommendations: this.recommendations as unknown as RecommendationType[],
      page: 1,
      max: NUM_FEATURED_RECOMMENDATIONS,
      loading: false,
    }
  },
  computed: {
    cols() {
      return Math.min(this.max, MAX_COLUMNS)
    },
    filteredRecommendations() {
      return this.internalRecommendations
        .filter((r) => {
          return this.selectedCategories.includes(r.type) && this.selectedFilters.filter((f) => r.tags.includes(f)).length > 0
        })
        .slice(0, this.max)
    },
  },
  watch: {
    recommendations() {
      this.internalRecommendations = this.recommendations as unknown as RecommendationType[]
    },
  },
  mounted() {
    this.$emitter.on(FunctionEnum.TRIP_RECOMMENDATION_DETAIL, this.selectRecommendation)
    this.$emitter.on(FunctionEnum.SHOW_MORE, this.getRecommendations)

    if (this.internalRecommendations.length === 0) {
      this.getRecommendations()
      this.$store.dispatch('assistant/sendMessage', {
        role: MessageRoleEnum.SYSTEM,
        content: `The user is waiting to view their trip recommendations. Don't talk about the recommendations until the user is viewing them.`,
      })
    }
  },
  unmounted() {
    this.$emitter.off(FunctionEnum.TRIP_RECOMMENDATION_DETAIL, this.selectRecommendation)
    this.$emitter.off(FunctionEnum.SHOW_MORE, this.getRecommendations)
  },
  methods: {
    viewRecommendation(recommendation) {
      this.$emitter.emit(FunctionEnum.INFORMATION, { toolCallId: null, recommendation })
    },
    selectRecommendation({ id }: ToolCallParamsType) {
      this.viewRecommendation(this.internalRecommendations.find((r) => r.id === id))
    },
    async getRecommendations() {
      try {
        // if some already loaded recommendations are being hidden, show them first before going to the API
        if (this.max < this.internalRecommendations.length) {
          this.max = this.internalRecommendations.length
          return
        }

        // get the recommendations from the API
        this.loading = true
        console.log('Getting recommendations', this.functionCall)
        const { recommendations, filters, categories } = await this.apiFetchWithTripId(`/recommendations?page=${this.page}`, {
          ...this.functionCall,
        })
        this.loading = false

        this.$store.dispatch('assistant/sendMessage', {
          role: MessageRoleEnum.SYSTEM,
          content: `The user is now viewing the following recommendations for their trip:\n\n${recommendations.map((r) => `Title: ${r.title}\nId: ${r.id}\nType: ${r.type}\nTags: ${r.tags.join(', ')}\nDescription: ${r.cover.description}`).join('\n\n')}`,
        })

        if (this.page >= 2) {
          this.max = 9999
        }

        this.page++

        if (recommendations && Array.isArray(recommendations) && recommendations.length) {
          this.internalRecommendations = [...this.internalRecommendations, ...recommendations]
        }

        // update categories in filters in parent

        this.$emit('update:categories', categories)
        this.$emit('update:filters', filters)
      } catch (error) {
        console.error('Error getting results', error)
        this.loading = false
      }
    },
    async skip() {
      await this.$store.dispatch('assistant/sendMessage', {
        role: MessageRoleEnum.SYSTEM,
        content: `The user doesn't want to see trip recommendations. Continue helping them plan their trip.`,
      })
      this.$emit('close')
    },
  },
})
</script>
