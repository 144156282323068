<script setup lang="ts">
import { MessageRoleEnum } from '@/enums'
</script>

<template>
  <Responsive
    v-slot="{ breakpoint }"
    :breakpoints="{ short: (el) => el.height <= 150 }"
    class="flex flex-col items-center justify-center mx-auto relative transition-all duration-500 pointer-events-none"
    :class="{ 'pb-12 pt-4': !showingSuggestedResponses, 'pb-16': showingSuggestedResponses }">
    <div
      class="relative flex flex-col items-center self-stretch transition-all duration-500"
      :class="{ 'opacity-80 pt-12 compact': isFullScreenModule, 'opacity-0': !isCallActive }">
      <transition-group name="message" appear>
        <div v-for="message in conversation as TranscriptMessage[]" :key="message.id" class="absolute flex duration-500 message">
          <div
            v-if="message.role === MessageRoleEnum.ASSISTANT"
            :id="message.id"
            class="px-4 py-2 font-serif text-center text-balance transition-all duration-500"
            :data-role="message.role"
            :class="{
              'text-3xl': !isFullScreenModule && !breakpoint.short,
              'text-2xl': isFullScreenModule || breakpoint.short,
              'w-screen max-w-3xl': isFullScreenModule || !isLongMessage(message.transcript),
              'w-screen max-w-5xl': !isFullScreenModule && isLongMessage(message.transcript),
              'text-white': isDarkBackground,
            }">
            {{ message.transcript }}
          </div>
          <div
            v-else
            :id="message.id"
            class="px-4 py-2 text-center text-balance transition-all duration-500"
            :data-role="message.role"
            :class="{
              'text-3xl': !isFullScreenModule && !breakpoint.short,
              'text-2xl': isFullScreenModule || breakpoint.short,
              'w-screen max-w-3xl': isFullScreenModule || !isLongMessage(message.transcript),
              'w-screen max-w-5xl': !isFullScreenModule && isLongMessage(message.transcript),
              'text-blue-violet-600': !isDarkBackground,
              'text-violet-200': isDarkBackground,
            }">
            {{ message.transcript }}
          </div>
        </div>
      </transition-group>
    </div>
    <div
      class="absolute bottom-0 left-1/2 -translate-x-1/2 w-screen px-4 flex flex-col items-center gap-2 transition-opacity duration-300 ease-out pointer-events-auto">
      <ChatResponseSuggestions :responses="suggestedResponses" @choose="chooseSuggestedResponse" @showing="(val) => (showingSuggestedResponses = val)" />
      <ChatTextInput class="w-full" />
    </div>
  </Responsive>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import { Message, TranscriptMessage } from '@/types'
import { MessageTypeEnum, FunctionEnum } from '@/enums'
import config from '@/config/index.json'
import SplitType from 'split-type'
import gsap from 'gsap'

const LONG_MESSAGE_NUM_WORDS = 15

export default defineComponent({
  data() {
    return {
      config,
      suggestedResponses: [] as string[],
      showingSuggestedResponses: false,
    }
  },
  computed: {
    ...mapState('assistant', ['inputMode', 'activeMessage', 'modelOutput']),
    ...mapGetters('assistant', { messages: 'recentMessages' }),
    ...mapState(['isFullScreenModule']),
    conversation() {
      return [...this.messages.filter((m) => m.type === MessageTypeEnum.TRANSCRIPT)]
    },
    lastMessage(): Message {
      return this.messages[this.messages.length - 1]
    },
  },
  watch: {
    activeMessage() {
      // animate partial message
      if (this.activeMessage) {
        this.$nextTick(() => {
          const split = new SplitType('#partial', {
            types: 'words',
          })
          gsap.from(split.words, {
            duration: (split?.words ?? []).length * 0.05,
            opacity: 0,
            stagger: 0.05,
          })
        })
      }
    },
    lastMessage() {
      // animate last message
      if (this.lastMessage) {
        this.$nextTick(() => {
          const split = new SplitType(`#${(this.lastMessage as TranscriptMessage).id}`, {
            types: 'words',
          })
          gsap.from(split.words, {
            duration: (split?.words ?? []).length * 0.05,
            opacity: 0,
            stagger: 0.05,
          })
        })
      }
    },
    messages: {
      deep: true,
      handler() {
        this.setSuggestedResponses({ ideas: [] })
      },
    },
  },
  mounted() {
    this.$emitter.on(FunctionEnum.NEXT_MESSAGE_IDEAS, this.setSuggestedResponses)
  },
  unmounted() {
    this.$emitter.off(FunctionEnum.NEXT_MESSAGE_IDEAS, this.setSuggestedResponses)
  },
  methods: {
    ...mapActions('assistant', ['sendMessage']),
    setSuggestedResponses({ options = [] }: { options: string[] }) {
      this.suggestedResponses = options
    },
    chooseSuggestedResponse(response: string) {
      // send message
      this.sendMessage({
        role: MessageRoleEnum.USER,
        content: response,
      })
    },
    isLongMessage(message: string) {
      return message.split(' ').length > LONG_MESSAGE_NUM_WORDS
    },
  },
})
</script>

<style scoped>
.message {
  @apply bottom-0 translate-y-1/2;
}
.message:not(.message-enter-active):nth-last-child(2) {
  @apply bottom-16 opacity-0 scale-[0.7];
}
.message:not(.message-enter-active):nth-last-child(n + 3) {
  @apply bottom-24 opacity-0 scale-[0.6];
}

.compact .message:not(.message-enter-active):nth-last-child(2) {
  @apply bottom-12 opacity-0 scale-[0.7];
}
.compact .message:not(.message-enter-active):nth-last-child(n + 3) {
  @apply bottom-16 scale-[0.6];
}

.message-enter-active,
.message-leave-active {
  transition: all 0.5s ease;
}
.message-enter-from,
.message-leave-to {
  opacity: 0;
  transform: translateY(100%) scale(0.95);
}
</style>
