<script lang="ts" setup>
import { ScratchPadItemTypeEnum } from '@/enums'
</script>

<template>
  <Overlay :id="id" size="2xl">
    <CloseButton class="absolute top-3 right-3 z-[99]" @click="setOverlay(null)" />
    <div class="flex flex-col gap-4">
      <Image :src="coverImage" class="w-full bg-slate-200 text-blue-violet aspect-[2/1] object-cover rounded-2xl" />
      <div class="flex flex-col gap-4 py-4">
        <h3 class="text-3xl text-blue-violet font-serif leading-none text-balance">{{ trip.title }}</h3>
        <div class="flex items-center gap-4 divide-x child:pl-4">
          <PinButton
            :item="{
              type: ScratchPadItemTypeEnum.TRIP,
              id: trip.url,
              data: { title: trip.title, image: coverImage, url: trip.url, original: trip },
            }"
            class="!pl-0" />
          <a v-if="trip.url" :href="trip.url" target="_blank" class="link">{{ cleanUrl(trip.url) }}</a>
        </div>
        <div v-if="trip.details.description" class="prose text-slate-500" v-html="trip.details.description" />
        <Gallery v-if="trip?.details?.images?.length > 0" v-slot="{ gallery }" class="col-span-4 grid grid-cols-2 gap-4 mt-6">
          <Image
            v-for="(image, index) in trip.details.images"
            :key="index"
            :src="image.photo"
            :caption="image.title"
            :can-save="true"
            :gallery="gallery"
            class="aspect-video text-white rounded-xl" />
        </Gallery>
      </div>
    </div>
  </Overlay>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import viatorMixins from '@/mixins/viator'

export default defineComponent({
  mixins: [viatorMixins],
  props: {
    trip: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    coverImage() {
      return this.trip.cover.photo.url
    },
  },
})
</script>
