import { Trip, ScratchPadItem } from '@/types'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export interface State {
  currentTripId: number | null
  trips: Trip[]
  preferences: any[]
  scratchPad: Map<string, ScratchPadItem>
  itinerary: ScratchPadItem[][]
}

export default {
  namespaced: true,
  state: {
    currentTripId: null,
    trips: [],
    preferences: [],
    scratchPad: new Map<string, ScratchPadItem>(),
    itinerary: [],
  } as State,
  actions,
  mutations,
  getters,
}
