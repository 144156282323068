<template>
  <div
    class="bg-emerald-100 transition hover:bg-emerald-200 p-3 flex items-center justify-center text-sm text-emerald-600 leading-none gap-2 cursor-pointer rounded-3xl">
    <IconLayoutColumns class="w-5 h-5 opacity-50" />
    Switch to split mode to build your itinerary
  </div>
</template>

<script lang="ts">
import { IconLayoutColumns } from '@tabler/icons-vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { IconLayoutColumns },
})
</script>
