<template>
  <div class="fixed bottom-4 left-40 flex gap-2 z-[1000] scale-[0.75] origin-bottom-left">
    <div class="group relative">
      <MiniButton>Events</MiniButton>
      <div class="absolute bottom-0 left-0 group-hover:flex flex-col hidden bg-white shadow divide-y rounded-lg z-10 overflow-hidden">
        <div v-for="e in Object.keys(events)" :key="e" class="p-2 cursor-pointer hover:bg-slate-100" @click="() => (event = e as keyof Events)">
          {{ e }}
        </div>
      </div>
    </div>
    <div class="group relative" :class="{ 'opacity-50 pointer-events-none': !isCallActive }">
      <MiniButton>Messages</MiniButton>
      <div class="absolute bottom-0 left-0 group-hover:flex flex-col hidden bg-white shadow divide-y rounded-lg z-10 w-96 h-screen max-h-[90vh] overflow-auto">
        <div v-for="category in messageCategories" :key="category.category" class="divide-y">
          <div class="p-3 font-medium bg-violet-100">{{ category.category }}</div>
          <div v-for="message in category.messages" :key="message" class="p-3 cursor-pointer hover:bg-slate-100 leading-tight" @click="send(message)">
            {{ message }}
          </div>
        </div>
      </div>
    </div>
    <MiniButton @click="simulateFirstEvents">First answer</MiniButton>
    <MiniButton @click="fillPortfolio">Fill portfolio</MiniButton>
    <MiniButton @click="triggerAccommodations">Accommodations</MiniButton>
    <MiniButton @click="clearLocalStorage">Clear storage</MiniButton>
  </div>
</template>

<script lang="ts">
import recommendationsData from '@/data/recommendations.json'
import viatorData from '@/data/viator.json'
import { FunctionEnum, MessageRoleEnum, ModuleEventEnum } from '@/enums'
import { Events } from '@/types'
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

export default defineComponent({
  data() {
    return {
      messageCategories: [
        {
          category: 'Trip planning',
          messages: [
            `I'd like to take my wife and 3 kids to Ottawa from ${this.$dayjs().add(1, 'week').format('MMMM D')} to ${this.$dayjs().add(2, 'week').format('MMMM D')}`,
            "I'd like to go to Ottawa",
            "Ask me who's coming with me",
            'My wife and 3 kids',
            'Ages 9, 12 and 14',
            'In the winter',
            'Can you show me some recommendations for a relaxing trip in Ottawa with my wife and son in September?',
          ],
        },
        {
          category: 'Navigation',
          messages: ['Show me the first one', 'Can we go back?', 'Yes', 'No', "Thanks for the help, I think we're done here."],
        },
        {
          category: 'Omni-module',
          messages: [
            'Can I see some photos of Ottawa?',
            "I'm thinking of going to Ottawa, can I see a video showing me what it's like there?",
            'Can you show me where Ottawa is on a map?',
            'Can you show me a map of eastern Ottawa?',
            'Can I see some ideas of trips to Ottawa?',
          ],
        },
        {
          category: 'Onboarding',
          messages: ['I need some ideas', `I'd like a relaxing trip in Ottawa with my wife`, `I'd like an active trip with a guide, staying in a hotel`],
        },
      ],
      events: {
        captureTravelDetails: {
          details: [
            {
              detail_type: 'user_info',
              category: 'activities',
              detail: 'relaxing',
              confidence: 1,
            },
            {
              detail_type: 'user_info',
              category: 'transportation',
              detail: 'flying',
              confidence: 1,
            },
            {
              detail_type: 'trip_info',
              category: 'activities',
              detail: 'guided tours',
              confidence: 1,
            },
            {
              detail_type: 'trip_info',
              category: 'accommodation',
              detail: 'hotel accommodations',
              confidence: 1,
            },
          ],
        },
        userOnboarding: {
          // steps: [
          //   {
          //     type: 'priorities',
          //     question: 'What is important to you on this trip?',
          //     instructions: 'Choose as many as you like',
          //     options: [
          //       { label: 'Destination', icon: 'IconGlobe', value: 'destination' },
          //       { label: 'Family-friendly activities', icon: 'IconBuildingCarousel', value: 'family' },
          //       { label: 'Outdoor adventures', icon: 'IconMountain', value: 'outdoor' },
          //       { label: 'Budget', icon: 'IconCreditCard', value: 'budget' },
          //       { label: 'Cultural experiences', icon: 'IconWorld', value: 'cultural' },
          //       { label: 'Relaxation and leisure', icon: 'IconBeach', value: 'relaxation' },
          //     ],
          //   },
          //   {
          //     type: 'question',
          //     question: `Is there a destination you've always wanted to visit?`,
          //     suggested_answers: ['Ottawa, Canada', 'Paris, France', 'Tokyo, Japan', 'Sydney, Australia', 'New York City, USA'],
          //   },
          //   {
          //     type: 'images',
          //     question: 'Which of these activities do you enjoy?',
          //     images: [
          //       { url: 'https://images.unsplash.com/photo-1498576260462-eefc9d0ce9f7?w=800', label: 'Skiing' },
          //       { url: 'https://images.unsplash.com/photo-1509233725247-49e657c54213?w=800', label: 'Beach' },
          //       { url: 'https://images.unsplash.com/photo-1502126324834-38f8e02d7160?w=800', label: 'Hiking' },
          //       { url: 'https://images.unsplash.com/photo-1453614512568-c4024d13c247?w=800', label: 'Sipping coffee' },
          //       { url: 'https://images.unsplash.com/photo-1509023464722-18d996393ca8?w=800', label: 'City sightseeing' },
          //     ],
          //   },
          // ],
          // step: {
          //   type: 'priorities',
          //   question: 'What is important to you on this trip?',
          //   instructions: 'Choose as many as you like',
          //   options: [
          //     { label: 'Destination', icon: 'IconGlobe', value: 'destination' },
          //     { label: 'Family-friendly activities', icon: 'IconBuildingCarousel', value: 'family' },
          //     { label: 'Outdoor adventures', icon: 'IconMountain', value: 'outdoor' },
          //     { label: 'Budget', icon: 'IconCreditCard', value: 'budget' },
          //     { label: 'Cultural experiences', icon: 'IconWorld', value: 'cultural' },
          //     { label: 'Relaxation and leisure', icon: 'IconBeach', value: 'relaxation' },
          //   ],
          // },
          step: {
            type: 'question',
            category: 'destination',
            question: `Is there a destination you've always wanted to visit?`,
            suggested_answers: ['Ottawa, Canada', 'Paris, France', 'Tokyo, Japan', 'Sydney, Australia', 'New York City, USA'],
          },
        },
        tripDestination: {
          /*
          location: 'Ottawa, Canada',
          lat_long: [45.4201, -75.7003],
          funfacts: [
            'Ottawa is the capital city of Canada.',
            'The city is known for its beautiful Parliament Hill.',
            'Ottawa hosts the largest tulip festival in the world.',
            "The Rideau Canal in Ottawa becomes the world's largest skating rink in winter.",
            'Ottawa is home to seven national museums.',
          ],
          photos: [
            {
              urls: { regular: 'https://images.unsplash.com/photo-1720050238968-0f591061438d?q=80&w=3132' },
              description: 'Parliament Hill and the Ottawa River',
            },
            {
              urls: { regular: 'https://images.unsplash.com/photo-1582483769245-79c83ad5540a?q=80&w=3056' },
              description: 'Rideau Canal in winter',
            },
            {
              urls: { regular: 'https://images.unsplash.com/photo-1683917306480-694451a64d4f?q=80&w=3097' },
              description: 'ByWard Market',
            },
          ],
          */
        },
        travelDates: {
          departure_date: this.$dayjs().add(1, 'week').format('YYYY-MM-DD'),
          return_date: this.$dayjs().add(2, 'week').format('YYYY-MM-DD'),
          show_calendar: true,
        },
        travelers: {
          travelers: [
            { traveler_type: 'adult' },
            { traveler_type: 'adult' },
            { traveler_type: 'child', age: 8 },
            { traveler_type: 'child', age: 10 },
            { traveler_type: 'child', age: 12 },
          ],
        },
        getTripInspiration: {},
        'toolMessage (getTripInspiration)': {
          event: 'toolMessage',
          content: JSON.stringify({
            json: {
              background: {
                photo: 'https://images.unsplash.com/photo-1691072459141-a838c9205d4c?q=80&w=2971',
              },
              photos: [
                {
                  urls: { regular: 'https://images.unsplash.com/photo-1720050238968-0f591061438d?q=80&w=3132' },
                  description: 'Parliament Hill and the Ottawa River',
                },
                { urls: { regular: 'https://images.unsplash.com/photo-1582483769245-79c83ad5540a?q=80&w=3056' }, description: 'Rideau Canal in winter' },
                { urls: { regular: 'https://images.unsplash.com/photo-1683917306480-694451a64d4f?q=80&w=3097' }, description: 'ByWard Market' },
              ],
              ideas: [
                {
                  title: 'August weekend fun in Ottawa: a family itinerary',
                  photos: [
                    {
                      urls: { regular: 'https://images.unsplash.com/photo-1720050238968-0f591061438d?q=80&w=3132' },
                      description: 'Parliament Hill and the Ottawa River',
                    },
                    { urls: { regular: 'https://images.unsplash.com/photo-1582483769245-79c83ad5540a?q=80&w=3056' }, description: 'Rideau Canal in winter' },
                    { urls: { regular: 'https://images.unsplash.com/photo-1683917306480-694451a64d4f?q=80&w=3097' }, description: 'ByWard Market' },
                  ],
                  photo:
                    'https://ottawatourism.ca/sites/default/files/styles/header_image_320x80/public/images/2022/05/1920x640-View-of-Parliament-from-Canadian-Museum-of-History-RT4_9691-Credit-Ottawa-Tourism-expire-March-2024.jpg?itok=8HcL0tY_',
                  cover: {
                    description: 'View of Parliament from Canadian Museum of History, family',
                  },
                  details: {
                    description:
                      "With the warm weather, outdoor activities, guided tours and fun attractions, Ottawa is the perfect summer destination. Use the itinerary ideas below to plan your own fun getaway or staycation with the whole family in the Ottawa region this August long weekend!\n\nThe itinerary covers 3 days of activities including cycling, walking tours, ziplining, paddling, hiking, museum visits, and animal encounters. Highlights include riding the world's first interprovincial zipline between Ontario and Quebec, exploring Gatineau Park, visiting national museums like the Canadian Museum of History, and seeing wildlife at Parc Omega.",
                    meta: [
                      {
                        type: 'duration',
                        title: 'Duration',
                        description: '3 day itinerary for a weekend getaway',
                      },
                      {
                        type: 'travelers',
                        title: "Who it's for",
                        description: 'Families with children looking for a mix of outdoor activities and cultural attractions',
                      },
                      {
                        type: 'dates',
                        title: 'When to go',
                        description: 'August long weekend, taking advantage of warm summer weather',
                      },
                    ],
                    images: [
                      {
                        photo:
                          'https://ottawatourism.ca/sites/default/files/styles/header_image_320x80/public/images/2022/05/1920x640-View-of-Parliament-from-Canadian-Museum-of-History-RT4_9691-Credit-Ottawa-Tourism-expire-March-2024.jpg?itok=8HcL0tY_',
                        title: 'View of Parliament from Canadian Museum of History',
                      },
                    ],
                  },
                  trip_id: 1,
                  url: 'https://ottawatourism.ca/en/itineraries/august-weekend-fun-ottawa-family-itinerary',
                },
                {
                  title: 'Visiting Ottawa in July-August with family - please suggest places to see/hotels to stay',
                  photo: null,
                  cover: {
                    description:
                      "News, events, discussions, and what not from Ottawa, Ontario. / Nouvelles, événements, discussions et varia concernant la ville d'Ottawa, Ontario.",
                  },
                  details: {
                    description:
                      "We are going to do a trip from Winnipeg to Ottawa - Montreal - Quebec City this summer (July 29 - August 9). Family of two adults and two kids (boys 2 and 7 years old). Original plan is to stay in each city for 3-4 days (we have 11 days overall), but it's flexible. Could you be so kind to suggest activities/places to visit in Ottawa. We are going to fly from Winnipeg to Toronto and rent a car there(but don't have any plans to stay in Toronto), so if you can advise some places to visit along the route it would be greatly appreciated.",
                    meta: [
                      {
                        type: 'dates',
                        title: 'When to go',
                        description: 'July 29 - August 9',
                      },
                      {
                        type: 'travelers',
                        title: "Who it's for",
                        description: 'Family of two adults and two kids (boys 2 and 7 years old)',
                      },
                      {
                        type: 'duration',
                        title: 'How long',
                        description: '11 days overall, 3-4 days in each city (Ottawa, Montreal, Quebec City)',
                      },
                    ],
                    images: [],
                  },
                  trip_id: 2,
                  url: 'https://www.reddit.com/r/ottawa/comments/7xaqra/visiting_ottawa_in_julyaugust_with_family_please/',
                },
                {
                  title: '3 Days In Ottawa With Kids (In Summer)',
                  photo: 'https://travelwithbender.com/files/3714/4031/1447/ottawa_IMG_0642-2.jpg',
                  cover: {
                    description:
                      "Ottawa quickly became one of my favourite destinations in Eastern Canada. It could have been because of the way we started it or the way we ended it. Or perhaps both. Whatever it was, if you've only got 3 days to discover this gorgeous city like we did, then here's your ultimate guide to squeeze delight out of every minute.",
                    source: {
                      name: 'Erin Holmes & Josh Bender',
                      description: 'From a top-rated travel blog',
                      image: 'https://travelwithbender.com/themes/twb/img/logo.svg',
                    },
                  },
                  details: {
                    description:
                      'Ottawa is the capital of Canada, located in the most eastern point of Southern Ontario on the south bank of the Ottawa River. The oldest part of the city is known as Lower Town and is situated between the Rideau Canal, Rideau River and Ottawa River. This 3-day itinerary covers top attractions like Parliament Hill, ByWard Market, museums, and family-friendly activities like Calypso waterpark and pirate adventures. The trip includes stays at the historic Fairmont Château Laurier hotel.',
                    meta: [
                      {
                        type: 'travelers',
                        title: "Who it's for",
                        description: "Families with kids looking for a mix of history, culture and fun activities in Canada's capital city",
                      },
                      {
                        type: 'duration',
                        title: 'How long',
                        description: '3 days itinerary covering major attractions and activities in Ottawa',
                      },
                      {
                        type: 'dates',
                        title: 'When to go',
                        description: 'Summer is ideal for outdoor activities and events like the Sound & Light show at Parliament Hill',
                      },
                    ],
                    images: [
                      {
                        photo: 'https://travelwithbender.com/files/1814/4031/1442/ottawa_IMG_0638-2.jpg',
                        title: 'Fairmont Château Laurier hotel',
                      },
                      {
                        photo: 'https://travelwithbender.com/files/9914/4031/1208/ottawa_IMG_0097-2.jpg',
                        title: 'Calypso waterpark',
                      },
                      {
                        photo: 'https://travelwithbender.com/files/4314/4031/1266/ottawa_IMG_0234-2.jpg',
                        title: 'Parliament Hill',
                      },
                    ],
                  },
                  trip_id: 3,
                  url: 'https://travelwithbender.com/travel-blog/eastern-canada/3-days-ottawa-kids-summer',
                },
              ],
              /*
              ideas: [
                {
                  title: 'The Best Beaches near Ottawa for Families',
                  photo: 'https://www.savvymom.ca/wp-content/uploads/2023/07/Beaches-in-Ottawa-Mooneys-Bay-SavvyMom-iStock-462240907-1024x673.jpg',
                  cover: {
                    description: "Sunset at Mooney's Bay Beach",
                    source: {
                      name: 'Amanda DeGrace',
                      description: 'SavvyMom Ottawa Contributor',
                      image: 'https://www.savvymom.ca/wp-content/uploads/2022/10/Amanda-DeGrace-SavvyMom.jpg',
                    },
                  },
                  details: {
                    description:
                      'The best beaches in Ottawa to visit are perfect for families seeking sun-soaked adventures and quality time together. With a wide range of options available, you can explore a new beach each week, creating memories to last a lifetime. Whether you prefer tranquil and secluded spots or bustling beaches with abundant amenities, the Ottawa area offers something for everyone. Keep your beach bag in the car because you are guaranteed to always be within close distance of a beach in the Ottawa area.',
                    meta: [
                      {
                        type: 'dates',
                        title: 'When to visit',
                        description: 'Summer months are the best time to explore the beaches.',
                      },
                      {
                        type: 'travelers',
                        title: "Who it's for",
                        description: 'Ideal for families looking for beach activities.',
                      },
                      {
                        type: 'duration',
                        title: 'Duration of Visit',
                        description: 'Spend a day or the entire summer exploring.',
                      },
                    ],
                    images: [
                      {
                        photo: 'https://www.savvymom.ca/wp-content/uploads/2023/07/Beaches-in-Ottawa-Mooneys-Bay-SavvyMom-iStock-462240907-1024x673.jpg',
                        title: "Mooney's Bay Beach",
                      },
                      {
                        photo: 'https://www.savvymom.ca/wp-content/uploads/2023/07/Lac-Philippe.jpg',
                        title: 'Lac Philippe Beach',
                      },
                      {
                        photo: 'https://www.savvymom.ca/wp-content/uploads/2023/07/Fitzroy-Provincial-Park.jpg',
                        title: 'Fitzroy Provincial Park',
                      },
                      {
                        photo: 'https://www.savvymom.ca/wp-content/uploads/2023/07/Britannia-Beach.jpg',
                        title: 'Britannia Beach',
                      },
                      {
                        photo: 'https://www.savvymom.ca/wp-content/uploads/2023/07/Meech-Lake.jpg',
                        title: 'Meech Lake',
                      },
                      {
                        photo: 'https://www.savvymom.ca/wp-content/uploads/2023/07/Petrie-Island.jpg',
                        title: 'Petrie Island Beach',
                      },
                      {
                        photo: 'https://www.savvymom.ca/wp-content/uploads/2023/07/Westboro-Beach.jpg',
                        title: 'Westboro Beach',
                      },
                      {
                        photo: 'https://www.savvymom.ca/wp-content/uploads/2023/07/Baxter-Conservation.jpg',
                        title: 'Baxter Conservation',
                      },
                      {
                        photo: 'https://www.savvymom.ca/wp-content/uploads/2023/07/Constance-Bay-Beach.jpg',
                        title: 'Constance Bay Beach',
                      },
                      {
                        photo: 'https://www.savvymom.ca/wp-content/uploads/2023/07/Lac-Leamy.jpg',
                        title: 'Lac Leamy',
                      },
                    ],
                  },
                  trip_id: 1,
                  url: 'https://www.savvymom.ca/article/the-best-beaches-near-ottawa-for-families/',
                },
              ],
              */
            },
          }),
        },
        flights: {
          departure_flight: {
            airline: 'Delta',
            flight_number: 'DL123',
            departure_date: this.$dayjs().add(1, 'week').format('YYYY-MM-DD'),
            departure_time: '08:00',
            arrival_date: this.$dayjs().add(1, 'week').format('YYYY-MM-DD'),
            arrival_time: '10:00',
            departure_airport: 'JFK',
            arrival_airport: 'LAX',
          },
          return_flight: {
            airline: 'Delta',
            flight_number: 'DL456',
            departure_date: this.$dayjs().add(2, 'week').format('YYYY-MM-DD'),
            departure_time: '08:00',
            arrival_date: this.$dayjs().add(2, 'week').format('YYYY-MM-DD'),
            arrival_time: '10:00',
            departure_airport: 'LAX',
            arrival_airport: 'JFK',
          },
        },
        /*
        accommodations: [
          {
            type: 'hotel',
            name: 'Hilton New York',
            address: '1335 Avenue of the Americas, New York, NY 10019',
            check_in_date: this.$dayjs().add(1, 'week').format('YYYY-MM-DD'),
            check_out_date: this.$dayjs().add(2, 'week').format('YYYY-MM-DD'),
          },
        ],
        */
        tripSummary: {
          departure_date: this.$dayjs().add(4, 'week').format('YYYY-MM-DD'),
          return_date: this.$dayjs().add(5, 'week').format('YYYY-MM-DD'),
          travelers: [
            { traveler_type: 'adult' },
            { traveler_type: 'adult' },
            { traveler_type: 'child', age: 8 },
            { traveler_type: 'child', age: 10 },
            { traveler_type: 'child', age: 12 },
          ],
          location: 'Ottawa, Canada',
          lat_long: [45.4201, -75.7003],
        },
        nextMessageIdeas: {
          ideas: ['Yes', 'No', "I'm not sure"],
        },
        'information (text)': {
          event: 'information',
          content_type: 'text',
          title: 'About Ottawa',
          subtitle: 'Discover the capital city of Canada',
          description: `# Ottawa, Canada

## Overview
Ottawa is the capital city of Canada, located in the eastern part of the province of Ontario. It is the fourth largest city in Canada and is known for its political significance, cultural heritage, and beautiful landscapes.

## History
- **Early History**: Originally inhabited by the Algonquin people.
- **19th Century**: Founded as Bytown in 1826, named after Colonel John By, who supervised the construction of the Rideau Canal. Renamed Ottawa in 1855.
- **Capital City**: Designated as the capital of the Province of Canada by Queen Victoria in 1857.

## Geography
- **Location**: Situated on the south bank of the Ottawa River, opposite Gatineau, Quebec.
- **Climate**: Humid continental climate with four distinct seasons, including cold winters and warm summers.
- **Major Features**: Ottawa River, Rideau Canal, and numerous parks and green spaces.

## Government
- **Political Significance**: As the capital of Canada, Ottawa is the center of the federal government.
- **Key Institutions**: Houses Parliament Hill, the official residences of the Prime Minister and the Governor General, and many federal departments and agencies.

## Demographics
- **Population**: Approximately 1 million residents.
- **Diversity**: Multicultural city with a diverse population, including a significant number of immigrants.
- **Languages**: English and French are the primary languages spoken.

## Economy
- **Key Industries**: Government and public administration, technology, health care, education, and tourism.
- **Major Employers**: Federal government, tech companies like Shopify, and various educational institutions.

## Culture and Attractions
- **Museums and Galleries**: Canadian Museum of History, National Gallery of Canada, and Canadian War Museum.
- **Festivals**: Winterlude, Canada Day celebrations, and the Ottawa International Jazz Festival.
- **Landmarks**: Parliament Hill, Rideau Canal (a UNESCO World Heritage Site), and ByWard Market.

## Education
- **Major Universities**: University of Ottawa and Carleton University.
- **Research and Innovation**: Strong emphasis on research and innovation, particularly in technology and health sciences.

## Transportation
- **Public Transit**: OC Transpo provides bus and light rail services.
- **Major Highways**: Highway 417 (Queensway) and Highway 416.
- **Air Travel**: Served by Ottawa Macdonald-Cartier International Airport.

## Sports
- **Professional Teams**: Ottawa Senators (NHL), Ottawa Redblacks (CFL).
- **Recreational Activities**: Skating on the Rideau Canal, cycling along extensive bike paths, and various winter sports.

## Sister Cities
- **Key Partnerships**: Sister city relationships with cities such as Beijing, China; Catania, Italy; and Helsinki, Finland.

## Notable Facts
- **Bilingualism**: Ottawa is officially bilingual, with services offered in both English and French.
- **Green City**: Known for its extensive green spaces and commitment to environmental sustainability.
- **Tech Hub**: Referred to as "Silicon Valley North" due to its thriving tech industry.

## References
- [City of Ottawa](https://ottawa.ca/)
- [Tourism Ottawa](https://www.ottawatourism.ca/)
- [Wikipedia: Ottawa](https://en.wikipedia.org/wiki/Ottawa)

---

Feel free to explore more about Ottawa through the provided references for detailed information on specific aspects of the city.`,
          attraction: {
            ...viatorData,
          },
        },
        'information (photo)': {
          event: 'information',
          content_type: 'photo',
          priority: ['photo'],
          title: 'About Ottawa',
          photo: [
            {
              id: '1',
              urls: { regular: 'https://images.unsplash.com/photo-1720050238968-0f591061438d?q=80&w=3132' },
              description: 'Parliament Hill and the Ottawa River',
            },
            {
              id: '2',
              urls: { regular: 'https://images.unsplash.com/photo-1582483769245-79c83ad5540a?q=80&w=3056' },
              description: 'Rideau Canal in winter',
            },
            {
              id: '3',
              urls: { regular: 'https://images.unsplash.com/photo-1683917306480-694451a64d4f?q=80&w=3097' },
              description: 'ByWard Market',
            },
          ],
          attraction: {
            ...viatorData,
          },
        },
        'information (video)': {
          event: 'information',
          content_type: 'video',
          priority: ['video'],
          title: 'Winterlude Festival',
          subtitle: "Explore the Best of Canada's Capital",
          video: [
            {
              link: 'https://www.youtube.com/watch?v=kn-4tNO8FdQ',
            },
          ],
          attraction: {
            ...viatorData,
          },
        },
        'information (map)': {
          event: 'information',
          content_type: 'map',
          priority: ['map'],
          title: 'Winterlude Locations',
          subtitle: "Explore the Best of Canada's Capital",
          description:
            "Here is a map of Ottawa, the capital city of Canada, showing key locations and attractions. From Parliament Hill to the Rideau Canal, Ottawa offers a variety of experiences for visitors to enjoy. Use this map to plan your trip and discover the best of Canada's capital.",
          /*
          map: {
            zoom_level: 12,
            pois: [
              { lat_long: [45.4215, -75.6981], name: 'Ottawa' },
              { lat_long: [45.5019, -73.5674], name: 'Montreal' },
            ],
          },
          */
          map: {
            zoom_level: 13,
            pois: [
              {
                lat_long: [45.4215, -75.6972],
                name: 'ByWard Market',
                photo: {
                  id: 1,
                  description: 'ByWard Market District Authority | BMDA ...',
                  urls: {
                    regular:
                      'https://images.squarespace-cdn.com/content/v1/60bed59745a00c322d46a862/77346695-8b4b-40c3-be2f-ba8eddeaf0e1/BYWARD+STOCK-resized.jpg',
                  },
                  width: 2160,
                  height: 1440,
                },
                photos: [
                  {
                    id: 1,
                    description: 'ByWard Market District Authority | BMDA ...',
                    urls: {
                      regular:
                        'https://images.squarespace-cdn.com/content/v1/60bed59745a00c322d46a862/77346695-8b4b-40c3-be2f-ba8eddeaf0e1/BYWARD+STOCK-resized.jpg',
                    },
                    width: 2160,
                    height: 1440,
                  },
                  {
                    id: 2,
                    description: 'ByWard Market - Wikipedia',
                    urls: {
                      regular: 'https://upload.wikimedia.org/wikipedia/commons/9/95/ByWard_Market_Hall.jpg',
                    },
                    width: 2990,
                    height: 4309,
                  },
                  {
                    id: 3,
                    description: '15 Captivating Facts About ByWard ...',
                    urls: {
                      regular: 'https://facts.net/wp-content/uploads/2023/09/15-captivating-facts-about-byward-market-ottawa-1694536818.jpg',
                    },
                    width: 2000,
                    height: 1332,
                  },
                ],
              },
              {
                lat_long: [45.4293, -75.6844],
                name: 'Rideau Canal',
                photo: {
                  id: 1,
                  description: 'Rideau Canal National Historic Site ...',
                  urls: {
                    regular: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/08/80/d5/f5/rideau-canal.jpg?w=1200&h=-1&s=1',
                  },
                  width: 1024,
                  height: 736,
                },
                photos: [
                  {
                    id: 1,
                    description: 'Rideau Canal National Historic Site ...',
                    urls: {
                      regular: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/08/80/d5/f5/rideau-canal.jpg?w=1200&h=-1&s=1',
                    },
                    width: 1024,
                    height: 736,
                  },
                  {
                    id: 2,
                    description: 'The Rideau Canal ...',
                    urls: {
                      regular: 'https://weexplorecanada.com/wp-content/uploads/2022/04/How-to-experience-the-Rideau-Canal-Feature.jpg',
                    },
                    width: 1125,
                    height: 633,
                  },
                  {
                    id: 3,
                    description: 'Historic Rideau Canal in Ottawa ...',
                    urls: {
                      regular: 'https://www.planetware.com/photos-large/CDN/canada-ottawa-rideau-canal.jpg',
                    },
                    width: 730,
                    height: 496,
                  },
                ],
              },
              {
                lat_long: [45.4318, -75.6435],
                name: 'St. Laurent Shopping Centre',
                photo: {
                  id: 1,
                  description: 'St. Laurent Shopping Centre walking ...',
                  urls: {
                    regular: 'https://i.ytimg.com/vi/8tMuCDY9I6U/maxresdefault.jpg',
                  },
                  width: 1280,
                  height: 720,
                },
                photos: [
                  {
                    id: 1,
                    description: 'St. Laurent Shopping Centre walking ...',
                    urls: {
                      regular: 'https://i.ytimg.com/vi/8tMuCDY9I6U/maxresdefault.jpg',
                    },
                    width: 1280,
                    height: 720,
                  },
                  {
                    id: 2,
                    description: 'St. Laurent Shopping Centre walking ...',
                    urls: {
                      regular:
                        'https://i.ytimg.com/vi/WYDvD9cQs4A/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLDjQ5ez_s2oFqAuKYuiGc2zsl7yKw',
                    },
                    width: 686,
                    height: 386,
                  },
                  {
                    id: 3,
                    description: 'St Laurent - All You Need to Know ...',
                    urls: {
                      regular: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/11/08/45/d8/sign.jpg?w=1200&h=1200&s=1',
                    },
                    width: 1200,
                    height: 1086,
                  },
                ],
              },
            ],
          },
          video: [
            {
              link: 'https://www.youtube.com/watch?v=kn-4tNO8FdQ',
            },
          ],
          attraction: {
            ...viatorData,
          },
        },
        'information (kitchen sink)': {
          event: 'information',
          content_type: 'text',
          title: 'Winterlude Locations',
          subtitle: "Explore the Best of Canada's Capital",
          description: `Ottawa, the capital city of Canada, is known for its stunning architecture, vibrant cultural scene, and rich history. Located in Ontario, it sits on the south bank of the Ottawa River. The city is home to many national institutions, including Parliament Hill, the official seat of Canada's federal government. Visitors can explore world-class museums like the National Gallery of Canada and the Canadian Museum of History. Ottawa also boasts beautiful parks and outdoor spaces, such as Gatineau Park and the Rideau Canal, which becomes the world's largest skating rink in winter. The city hosts numerous festivals throughout the year, including Winterlude and the Canadian Tulip Festival. With a blend of English and French cultures, Ottawa offers a unique and diverse experience for travelers.`,
          priority: ['photo', 'video', 'map'],
          map: {
            zoom_level: 12,
            pois: [{ lat_long: [45.4215, -75.6981], name: 'Ottawa' }],
          },
          photo: [
            {
              id: '1',
              urls: { regular: 'https://images.unsplash.com/photo-1720050238968-0f591061438d?q=80&w=3132' },
              description: 'Parliament Hill and the Ottawa River',
            },
            {
              id: '2',
              urls: { regular: 'https://images.unsplash.com/photo-1582483769245-79c83ad5540a?q=80&w=3056' },
              description: 'Rideau Canal in winter',
            },
            {
              id: '3',
              urls: { regular: 'https://images.unsplash.com/photo-1683917306480-694451a64d4f?q=80&w=3097' },
              description: 'ByWard Market',
            },
          ],
          video: [
            {
              link: 'https://www.youtube.com/watch?v=kn-4tNO8FdQ',
              // link: 'https://www.tiktok.com/@nickipoststravelstuff/video/7361152767357979909',
            },
          ],
          attraction: {
            ...viatorData,
          },
        },
        tripRecommendations: {
          //...recommendationsData,
        },
        'toolMessage (recommendations)': {
          event: 'toolMessage',
          content: JSON.stringify({
            json: {
              ...recommendationsData,
            },
          }),
        },
        tripTitle: {
          title: 'Family trip to Ottawa',
        },
        userResponseSuggestions: {
          suggestions: ['I want to go to [location] with [travelers] to see the [attraction] in [when]', "I don't know what I want to do, show me some ideas"],
        },
      },
      event: '' as keyof Events | '',
    }
  },
  watch: {
    event() {
      if (this.event) {
        if (this.events?.[this.event].event) {
          this.$emitter.emit(this.events?.[this.event].event, this.events?.[this.event] ?? {})
        } else {
          this.$emitter.emit(this.event, this.events?.[this.event] ?? {})
        }
        this.event = ''
      }
    },
  },
  methods: {
    ...mapActions(['addActiveModule']),
    ...mapActions('assistant', ['sendMessage']),
    simulateFirstEvents() {
      const events: Array<keyof Events> = [FunctionEnum.TRIP_DESTINATION, FunctionEnum.TRAVEL_DATES, FunctionEnum.TRAVELERS]
      const stagger = 20
      events.forEach((event, index) => {
        setTimeout(() => {
          this.$emitter.emit(event, this.events[event])
        }, index * stagger)
      })
    },
    fillPortfolio() {
      this.$emitter.emit(ModuleEventEnum.ADD_TO_PORTFOLIO, {
        name: FunctionEnum.TRIP_DESTINATION,
        params: {
          location: 'Ottawa, Canada',
          lat_long: [45.4201, -75.7003],
        },
      })
      this.$emitter.emit(ModuleEventEnum.ADD_TO_PORTFOLIO, {
        name: FunctionEnum.TRAVEL_DATES,
        params: {
          departure_date: this.$dayjs().add(1, 'week').format('YYYY-MM-DD'),
          return_date: this.$dayjs().add(2, 'week').format('YYYY-MM-DD'),
        },
      })
      this.$emitter.emit(ModuleEventEnum.ADD_TO_PORTFOLIO, {
        name: FunctionEnum.TRAVELERS,
        params: {
          travelers: [
            { traveler_type: 'adult' },
            { traveler_type: 'adult' },
            { traveler_type: 'child', age: 8 },
            { traveler_type: 'child', age: 10 },
            { traveler_type: 'child', age: 12 },
          ],
        },
      })
    },
    clearLocalStorage() {
      localStorage.clear()
      location.reload()
    },
    async send(message) {
      await this.sendMessage({ role: MessageRoleEnum.USER, content: message })
    },
    triggerAccommodations() {
      this.$emitter.emit(FunctionEnum.ACCOMMODATIONS, {
        results: [
          {
            rooms: 1,
            accommodations: {
              results: [
                {
                  rooms: 1,
                  id: 'ssr_0000ASVBuJVLdmqtZDJ4ca',
                  check_out_date: '2024-01-08',
                  check_in_date: '2024-01-01',
                  cheapest_rate_total_amount: '799.00',
                  cheapest_rate_currency: 'GBP',
                  accommodation: {
                    supported_loyalty_programme: 'duffel_hotel_group_rewards',
                    rooms: [
                      {
                        rates: [
                          {
                            total_currency: 'GBP',
                            total_amount: '799.00',
                            tax_currency: 'GBP',
                            tax_amount: '82.23',
                            supported_loyalty_programme: 'duffel_hotel_group_rewards',
                            quantity_available: 12,
                            payment_type: 'pay_now',
                            id: 'rat_0000BTVRuKZTavzrZDJ4cb',
                            fee_currency: 'GBP',
                            fee_amount: '50.94',
                            due_at_accommodation_currency: 'GBP',
                            due_at_accommodation_amount: '39.95',
                            conditions: [{ title: 'Parking', description: 'Public parking is available nearby for £15 per day' }],
                            cancellation_timeline: [{ refund_amount: '799.00', currency: 'GBP', before: '2023-05-23T13:00:00Z' }],
                            board_type: 'room_only',
                            base_currency: 'GBP',
                            base_amount: '665.83',
                            available_payment_methods: [['balance', 'card']],
                          },
                        ],
                        photos: [{ url: 'https://assets.duffel.com/img/stays/image.jpg' }],
                        name: 'Double Suite',
                        beds: [{ type: 'king', count: 2 }],
                      },
                    ],
                    review_score: 8.8,
                    rating: 3,
                    photos: [{ url: 'https://assets.duffel.com/img/stays/image.jpg' }],
                    phone_number: '+442074938181',
                    name: 'Duffel Test Hotel',
                    location: {
                      geographic_coordinates: { longitude: -75.6972, latitude: 45.4215 },
                      address: { region: 'England', postal_code: 'EC2A 4TP', line_one: '100 Clifton Street', country_code: 'GB', city_name: 'London' },
                    },
                    key_collection: { instructions: "Please collect the keys from accommodation's reception." },
                    id: 'acc_0000AWr2VsUNIF1Vl91xg0',
                    email: 'reservations@duffel-hotel-group.com',
                    description: 'Ornate quarters, some with grand pianos, in a luxurious hotel offering acclaimed dining & a spa.',
                    check_in_information: { check_out_before_time: '11:30', check_in_after_time: '14:30' },
                    chain: { name: 'Duffel Hotel Group' },
                    amenities: [{ type: 'parking', description: 'Parking' }],
                  },
                },
              ],
              created_at: '2024-09-15T15:25:02.672Z',
            },
          },
        ],
      })
    },
  },
})
</script>
