<template>
  <div class="flex flex-col gap-2">
    <IconSparkles class="w-8 h-8 text-sky-300" stroke-width="1.5" />
    <div class="font-serif leading-none text-white text-2xl">Travel ideas</div>
  </div>
  <div v-if="categories.length" class="flex flex-col gap-4">
    <div class="text-blue-violet-400 font-medium text-lg">Categories</div>
    <div class="flex flex-col gap-1 items-start text-sm text-slate-400">
      <label
        v-for="category in categories"
        :key="category.id"
        class="flex items-center gap-1 transition duration-[400ms] cursor-pointer"
        :class="{
          'text-violet-300': internalSelectedCategories.includes(category.id),
          'group text-blue-violet-400': !internalSelectedCategories.includes(category.id),
        }">
        <input v-model="internalSelectedCategories" type="checkbox" :value="category.id" checked class="absolute opacity-0" />
        <RecommendationIcon :type="category.id" class="w-6 h-6 shrink-0" />
        <span class="py-1 px-2 leading-tight rounded-full transition duration-[400ms] group-hover:bg-white group-hover:text-blue-violet">
          {{ category.label }}
        </span>
      </label>
    </div>
  </div>
  <div v-if="filters.length" class="flex flex-col gap-4">
    <div class="flex items-center justify-between">
      <div class="text-blue-violet-400 font-medium text-lg">Filters</div>
      <transition name="fade">
        <IconRotateClockwise
          v-if="internalSelectedFilters.length < filters.length"
          v-tooltip="'Reset filters'"
          class="w-5 h-5 text-slate-400 hover:text-violet-500 hover:rotate-180 transition duration-500 cursor-pointer"
          @click="resetFilters" />
      </transition>
    </div>
    <div class="flex flex-wrap gap-2 text-blue-violet">
      <FilterItem v-for="filter in internalSelectedFilters" :key="filter" :value="filter" @remove="removeFilter" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { FunctionEnum } from '@/enums'
import { IconRotateClockwise, IconSparkles } from '@tabler/icons-vue'

export default defineComponent({
  components: {
    IconRotateClockwise,
    IconSparkles,
  },
  props: {
    categories: {
      type: Array as PropType<{ id: string; label: string }[]>,
      required: true,
    },
    selectedCategories: {
      type: Array as PropType<string[]>,
      required: true,
    },
    filters: {
      type: Array as PropType<string[]>,
      required: true,
    },
    selectedFilters: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  emits: ['input:categories', 'input:filters'],
  data() {
    return {
      internalSelectedCategories: this.selectedCategories,
      internalSelectedFilters: this.selectedFilters,
    }
  },
  watch: {
    selectedCategories() {
      this.internalSelectedCategories = this.selectedCategories
    },
    selectedFilters() {
      this.internalSelectedFilters = this.selectedFilters
    },
    internalSelectedCategories: {
      deep: true,
      handler(value) {
        this.$emit('input:categories', value)
      },
    },
    internalSelectedFilters: {
      deep: true,
      handler(value) {
        this.$emit('input:filters', value)
      },
    },
  },
  mounted() {
    this.$emitter.on(FunctionEnum.FILTER_RESULTS, this.updateFilters)
  },
  unmounted() {
    this.$emitter.off(FunctionEnum.FILTER_RESULTS, this.updateFilters)
  },
  methods: {
    removeFilter({ category, value }: { category: string; value: string }) {
      this.internalSelectedFilters = this.internalSelectedFilters.filter((f) => f !== value)
    },
    resetFilters() {
      this.internalSelectedFilters = this.filters
    },
    updateFilters({ filter }: { filter: string[] }) {
      this.internalSelectedCategories = filter
    },
  },
})
</script>
