<template>
  <h3 class="relative font-medium text-lg flex items-center gap-2 cursor-pointer z-10 pt-4">
    <component :is="icon" class="w-9 h-9 p-2 rounded-full shrink-0" :class="iconClass" />
    {{ title }}
    <div class="ml-auto flex items-center gap-2">
      <slot />
      <div v-if="!disabled" class="flex items-center gap-2">
        <transition name="button" mode="out-in">
          <button v-if="!open" class="relative group">
            <IconSelector class="w-8 h-10 py-2 px-1 text-blue-violet-300 transition group-hover:opacity-0" :class="{ 'rotate-90': columns }" />
            <IconArrowsVertical
              class="absolute top-0 left-0 w-8 h-10 py-2 px-1 text-blue-violet-300 transition opacity-0 scale-y-[0.5] group-hover:opacity-100 group-hover:scale-y-[1]"
              :class="{ 'rotate-90': columns }" />
            <span class="sr-only">Open section</span>
          </button>
          <button v-else>
            <IconLayoutRows class="w-8 h-10 py-2 px-1 text-blue-violet transition" :class="{ 'rotate-90': columns }" />
            <span class="sr-only">Show split view</span>
          </button>
        </transition>
      </div>
    </div>
  </h3>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { IconUser, IconGlobe, IconChevronDown, IconHeart, IconPin, IconClipboard, IconLayoutRows, IconSelector, IconArrowsVertical } from '@tabler/icons-vue'

export default defineComponent({
  components: {
    IconChevronDown,
    IconLayoutRows,
    IconUser,
    IconHeart,
    IconGlobe,
    IconPin,
    IconClipboard,
    IconSelector,
    IconArrowsVertical,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    closed: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      default: 'bg-slate-200 text-slate-500',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style scoped>
.button-enter-active {
  transition: all 0.25s var(--ease-out-circ);
}

.button-leave-active {
  transition: all 0.2s ease-in-out;
}

.button-enter-from {
  @apply opacity-0 transform -rotate-90;
}

.button-leave-to {
  @apply opacity-0 transform rotate-90;
}
</style>
