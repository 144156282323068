import { SidebarTabEnum, TravelDetailEnum } from '@/enums'
import { Trip, ScratchPadItem, TravelDetail } from '@/types'
import store from '@/store'
import { apiFetch } from '@/libs/api'
import uniqid from 'uniqid'
import { State } from './index'
import preferencesHelper from '@/libs/preferences'

export default {
  // trips

  setTrips(state: State, value: Trip[]) {
    state.trips = value
  },
  setCurrentTripId(state: State, value: number) {
    state.currentTripId = value
    preferencesHelper.savePreference('currentTripId', value)
  },

  // preferences

  setPreferences(state: State, value: any[]) {
    state.preferences = value
  },

  // travel details

  async handleCaptureTravelDetails(state: State, { details }: { details: TravelDetail[] }) {
    const userInfoDetails = details.filter((d) => d.detail_type === TravelDetailEnum.USER_INFO)
    const tripInfoDetails = details.filter((d) => d.detail_type === TravelDetailEnum.TRIP_INFO)

    // save user info and save returned (de-duped) values

    if (userInfoDetails.length) {
      console.log('[handleCaptureTravelDetails] Combine user preferences', state.preferences, 'with these details', userInfoDetails)

      const { preferences: userPreferences } = await apiFetch('/users', {
        id: uniqid(),
        function: {
          arguments: {
            details: {
              preferences: [...state.preferences, ...userInfoDetails],
            },
          },
        },
      })

      state.preferences = userPreferences
    }

    // save trip info and save returned (de-duped) values

    if (tripInfoDetails.length) {
      const currentTrip = state.trips.find((t) => t.id === state.currentTripId)

      console.log('[handleCaptureTravelDetails] Combine trip preferences', currentTrip?.preferences, 'with these details', tripInfoDetails)

      const trip = await apiFetch('/trips', {
        id: uniqid(),
        function: {
          arguments: {
            details: {
              preferences: [...(currentTrip?.preferences ?? []), ...tripInfoDetails],
            },
            tripId: state.currentTripId,
          },
        },
      })

      if (currentTrip) {
        // update the current trip
        currentTrip.preferences = trip.preferences
        state.trips = state.trips.map((t) => (t.id === currentTrip.id ? currentTrip : t))
      } else {
        // new trip, so add it to state and set it as current
        state.trips.unshift(trip)
        state.currentTripId = trip.id
      }
    }
  },

  // scratchPad

  setScratchPad(state: State, value: ScratchPadItem[]) {
    state.scratchPad = new Map(value.map((item) => [item.id, item]))
  },
  addToScratchPad(state: State, value: ScratchPadItem) {
    const shouldOpen = Array.from(state.scratchPad.values()).length === 0
    state.scratchPad.set(value.id, value)
    if (shouldOpen) {
      store.dispatch('showSidebar', { tab: SidebarTabEnum.SCRATCHPAD })
    }
  },
  removeFromScratchPad(state: State, value: string | ScratchPadItem) {
    state.scratchPad.delete(typeof value === 'string' ? value : value.id)
  },

  // itinerary

  setItinerary(state: State, value: ScratchPadItem[][]) {
    state.itinerary = value
  },
}
