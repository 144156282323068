<template>
  <div class="relative pb-6">
    <img class="float w-full aspect-square relative z-10" src="@/assets/earth.png" />
    <img class="pulse absolute bottom-0" src="@/assets/earth-shadow.png" />
  </div>
</template>

<style scoped>
.float {
  animation: float 2s infinite var(--ease-in-out-cubic);
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: rotate(0);
  }
}

.pulse {
  animation: pulse 2s infinite var(--ease-in-out-cubic);
}

@keyframes pulse {
  0% {
    transform: scale(0.6);
    opacity: 0.35;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.15;
  }
  100% {
    transform: scale(0.6);
    opacity: 0.35;
  }
}
</style>
