<template>
  <nav
    class="flex items-center justify-center gap-4"
    :class="{
      'absolute bottom-0 left-0 right-0 z-[999] opacity-0 translate-y-2 group-hover:opacity-100 group-hover:translate-y-0 transition duration-500': autoHide,
    }">
    <slot />
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    autoHide: {
      type: Boolean,
      default: true,
    },
  },
})
</script>
