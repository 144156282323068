import config from '@/config'
import { FIRST_MODULE_NAME, SOLO_MODULES, QUEUEABLE_FUNCTIONS } from '@/constants'
import { preferenceFactory } from '@/libs/preferences'
import { Events, Size } from '@/types'
import { BooleanEnum, FunctionEnum, MessageRoleEnum, ModuleEventEnum, ModuleTypeEnum } from '@/enums'
import { App, Component, defineAsyncComponent } from 'vue'
import { Store } from 'vuex'
import { CustomEmitter } from '@/plugins/events'
import { AxiosInstance } from 'axios'

export const shouldFunctionBeQueued = ({ event_name, params, module, store }: { event_name: keyof Events; params: object; module?: string; store: Store }) => {
  if (
    // is this a queueable function?
    QUEUEABLE_FUNCTIONS.includes(event_name as FunctionEnum) &&
    // are there any active modules (that aren't the same as this one) or information modules?
    ((store.state.activeModules.length > 0 && store.state.activeModules.filter((m) => m.name === module).length === 0) ||
      (store.state.informationModules.length > 0 && event_name !== FunctionEnum.INFORMATION))
  ) {
    store.dispatch('queueFunction', { name: event_name, params })
    return true
  }
  return false
}

export default class Module {
  name: string
  title: string | undefined
  store: Store
  app: App
  size: Size | undefined = 'sm'
  type: ModuleTypeEnum = ModuleTypeEnum.PANEL
  component: Component | undefined
  savePreference: Function = () => {}
  loadPreference: Function = () => {}
  loadPreferences: Function = () => {}
  $emitter: CustomEmitter<Events>
  $http: AxiosInstance

  constructor({ name, type, title, size, store, app }: { name: string; type?: ModuleTypeEnum; title?: string; size?: Size; store: Store; app: App }) {
    this.name = name
    if (type) this.type = type
    if (title) this.title = title
    if (size) this.size = size
    this.store = store
    this.app = app

    // shortcuts
    this.$emitter = this.app.config.globalProperties.$emitter
    this.$http = this.app.config.globalProperties.$http

    this.registerComponent()
    this.addToStore()
    this.initPreferences()
  }

  // register module component

  registerComponent() {
    this.component = this.app.component(
      this.name,
      defineAsyncComponent(() => import(`@/modules/${this.name}/index.vue`))
    )
  }

  // register portfolio component

  registerPortfolioComponent(event: keyof Events, component: Component) {
    this.app.component(`Portfolio/${event}`, component)
  }

  // add module to global store

  addToStore() {
    this.store.dispatch('createModule', {
      name: this.name,
      type: this.type,
    })
  }

  // initialize module preferences

  initPreferences() {
    const { savePreference, loadPreference, loadPreferences } = preferenceFactory(`module/${this.name}/preferences`)

    this.savePreference = savePreference
    this.loadPreference = loadPreference
    this.loadPreferences = loadPreferences

    // preference mixins
    this.app.mixin({
      methods: {
        [`save${this.name}Preference`]: savePreference,
        [`load${this.name}Preference`]: loadPreference,
        [`load${this.name}Preferences`]: loadPreferences,
      },
    })
  }

  // register event handlers

  registerEventHandlers(
    eventHandlers: {
      event_name: keyof Events
      wordSequences?: Array<Array<string>>
      defaultParams?: object
      confirmHandler?: Function
      shownHandler?: Function
      removeHandler?: Function
      requestHandler?: Function
      inferredHandler?: Function
      preferencesHandler?: Function
      portfolioComponent?: Component
      bootstrapHandler?: Function
    }[]
  ) {
    eventHandlers.forEach(
      ({
        event_name,
        wordSequences = [],
        defaultParams = {},
        shownHandler,
        confirmHandler,
        removeHandler,
        requestHandler,
        inferredHandler,
        preferencesHandler,
        portfolioComponent,
        bootstrapHandler,
      }) => {
        // module event handler (function)

        const eventHandler = (params: any) => {
          // convert 'true' and 'false' strings to booleans

          params.details_confirmed = params.details_confirmed === BooleanEnum.TRUE
          params.details_inferred = params.details_inferred === BooleanEnum.TRUE

          // queue event if necessary

          if (shouldFunctionBeQueued({ event_name, params, module: this.name, store: this.store })) {
            return
          }

          // add function arguments to store

          this.store.dispatch('assistant/addFunctionCall', params)

          // handler to announce that UI is being shown

          if (shownHandler) {
            Promise.resolve(shownHandler(params)).then((message) => {
              if (message) {
                this.store.dispatch('assistant/sendMessage', {
                  role: MessageRoleEnum.SYSTEM,
                  content: message,
                })
              }
            })
          }

          // if details are confirmed and we are waiting for confirmation, emit the confirmed event

          if (params.details_confirmed && config.waitForAssistantToConfirmDetails) {
            // send confirm event
            this.$emitter.emit(ModuleEventEnum.CONFIRMED, { name: event_name, params })
            // remove module if details are confirmed
            if (this.store.getters.isFirstModuleActive && config.useCombinedFirstModule) {
              // first response is showing, remove first response
              this.store.dispatch('removeActiveModule', FIRST_MODULE_NAME)
            } else {
              this.store.dispatch('removeActiveModule', this.name)
            }
          } else {
            // if module includes any preferences, notify the AI
            if (preferencesHandler) {
              const message = preferencesHandler()
              if (message) {
                if (this.store.state.assistant.isAssistantTalking) {
                  this.$emitter.once(ModuleEventEnum.SPEECH_END, () => {
                    this.store.dispatch('assistant/sendMessage', {
                      role: MessageRoleEnum.SYSTEM,
                      content: message,
                    })
                  })
                } else {
                  this.store.dispatch('assistant/sendMessage', {
                    role: MessageRoleEnum.SYSTEM,
                    content: message,
                  })
                }
              }
            }

            // add module to UI
            if (this.store.getters.isFirstModule && config.useCombinedFirstModule) {
              // use special module for first response
              this.store.dispatch('addActiveModule', { name: FIRST_MODULE_NAME, type: this.type, size: 'lg', params: { [event_name]: params } })
            } else if (
              this.store.getters.isFirstModuleActive &&
              config.useCombinedFirstModule &&
              !params.from_context &&
              this.store.state.activeModules.length === 1
            ) {
              // first response is showing, add params (unless it was triggered contextually in conversation)
              const existingParams = this.store.state.activeModules.find((module) => module.name === FIRST_MODULE_NAME).params
              this.store.dispatch('updateActiveModule', { name: FIRST_MODULE_NAME, params: { ...existingParams, [event_name]: params } })
            } else {
              // use this module normally
              this.store.dispatch('addActiveModule', { name: this.name, type: this.type, title: this.title, size: this.size, params })
            }
            // if details are inferred, prompt AI for confirmation
            if (params.details_inferred && inferredHandler) {
              const message = inferredHandler(params)
              if (message) {
                this.store.dispatch('assistant/sendMessage', {
                  role: MessageRoleEnum.SYSTEM,
                  content: message,
                })
              }
            }
          }
        }

        console.log(`[module: ${this.name}] registering event handler:`, event_name)

        this.$emitter.on(event_name, eventHandler)

        // close module (via assistant)

        this.$emitter.on(FunctionEnum.CLOSE_MODULE, ({ module }) => {
          if (module === event_name) {
            console.log('close module:', event_name)
            this.store.dispatch('removeActiveModule', this.name)
          }
        })

        // word sequences (listening for specific words in assistant messages to trigger modules)
        if (config.triggerModulesUsingWordSequences) {
          this.$emitter.on(ModuleEventEnum.ADD_ASSISTANT_MESSAGE, (message: string) => {
            wordSequences.forEach((wordSequence) => {
              // check that all words in wordSequence are in the message in the same order
              const regex = new RegExp(String.raw`${wordSequence.join('\\s(.*\\s*)')}`, 'gi')
              if (
                message.match(regex) && // word sequence detected
                !this.store.state.activeModules.find((module) => module.name === this.name) && // module not already active
                !this.store.state.portfolioModules.find((item) => item.name === event_name) && // module not already in portfolio
                !this.store.state.activeModules.find((module) => SOLO_MODULES.includes(module.name)) // solo module not already active
              ) {
                console.log(`[module: ${this.name}] word sequence detected:`, wordSequence, 'trigger:', event_name)
                eventHandler({ from_context: true, ...defaultParams })
              }
            })
          })
        }

        // portfolio event handler (confirming via UI)

        if (confirmHandler) {
          this.$emitter.on(ModuleEventEnum.ADD_TO_PORTFOLIO, async ({ name, params, confirm = true }) => {
            if (name === event_name && confirm) {
              const message = await confirmHandler(params)
              if (message) {
                this.store.dispatch('assistant/sendMessage', {
                  role: MessageRoleEnum.SYSTEM,
                  content: message,
                })
              }
            }
          })
        }

        this.$emitter.on(ModuleEventEnum.CONFIRMED, async ({ name, params }) => {
          if (name === event_name && confirmHandler) {
            await confirmHandler(params)
          }
        })

        // portfolio event handler (removing via UI)
        if (removeHandler) {
          this.$emitter.on(ModuleEventEnum.REMOVE_FROM_PORTFOLIO, (name) => {
            if (name === event_name) {
              const message = removeHandler()
              if (message) {
                console.log('removeFromPortfolio system message from user:', message)
                this.store.dispatch('assistant/sendMessage', {
                  role: MessageRoleEnum.SYSTEM,
                  content: message,
                })
              }
            }
          })
        }

        // event handler (requesting via UI)

        if (requestHandler) {
          this.$emitter.on(ModuleEventEnum.USER_REQUEST, (name) => {
            if (name === event_name) {
              const message = requestHandler()
              if (message) {
                console.log('userRequest system message from user:', message)
                this.store.dispatch('assistant/sendMessage', {
                  role: MessageRoleEnum.SYSTEM,
                  content: message,
                })
              }
            }
          })
        }

        // portfolio component
        if (portfolioComponent) {
          // register portfolio component
          this.registerPortfolioComponent(event_name, portfolioComponent)
        } else {
          // use default blank component
          this.registerPortfolioComponent(
            event_name,
            defineAsyncComponent(() => import(`@/components/Blank.vue`))
          )
        }

        // if bootstrapHandler is defined, call it
        if (bootstrapHandler) {
          bootstrapHandler()
        }
      }
    )
  }

  // add module store
  addModuleStore({ state, actions, mutations }) {
    this.store.registerModule(`module/${this.name}`, {
      namespaced: true,
      state,
      actions: {
        async onModuleAccessed() {
          /* placeholder */
        },
        ...actions,
      },
      mutations,
    })
    this.onModuleLoaded()
  }

  // module lifecycle hooks

  async onModuleLoaded() {
    try {
      if (this.store._actions[`module/${this.name}/onModuleLoaded`]) {
        this.store.dispatch(`module/${this.name}/onModuleLoaded`)
        this.store.dispatch('updateModule', {
          name: this.name,
          loaded: true,
        })
      }
    } catch (error) {
      console.warn(error)
    }
  }
}
