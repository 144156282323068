<template>
  <div
    class="absolute"
    :class="{
      'bottom-full left-1/2 mb-4': position === 'top',
      'left-full top-1/2 -translate-y-full ml-4': position === 'right',
      'right-full top-1/2 -translate-y-full mr-4': position === 'left',
      'top-full left-1/2 mt-4': position === 'bottom',
      'bottom-full left-full ml-4': position === 'top-right',
      'bottom-full right-full mr-4': position === 'top-left',
      'top-full left-full ml-4': position === 'bottom-right',
      'top-full right-full mr-4': position === 'bottom-left',
    }">
    <div
      class="flex"
      :class="{
        '-translate-x-full flex-col items-end': vertical,
        'items-end justify-end': horizontal && !position.includes('bottom'),
        'justify-end': horizontal && position.includes('bottom'),
        'flex-col-reverse': vertical && reverse,
        'flex-row-reverse': horizontal && reverse,
      }">
      <div
        class="text-sm text-slate-700 bg-white px-3 py-2 inline-block"
        :class="{
          'rounded-t-lg rounded-bl-lg': position === 'top',
          'rounded-r-lg rounded-tl-lg': position === 'right' || position === 'top-right',
          'rounded-l-lg rounded-tr-lg': position === 'left' || position === 'top-left',
          'rounded-b-lg rounded-tl-lg': position === 'bottom' || position === 'bottom-left',
          'rounded-b-lg rounded-tr-lg': position === 'bottom-right',
        }">
        <slot />
      </div>
      <div class="w-4 h-4 inline-flex overflow-hidden relative z-10 shrink-0">
        <div
          class="absolute h-full w-[150%] bg-white transform"
          :class="{
            'rotate-45 bottom-0 right-0 origin-bottom-right': position === 'top' || position === 'bottom-right',
            'rotate-45 top-0 left-0 origin-top-left': position === 'left' || position === 'top-left',
            '-rotate-45 bottom-0 right-0 origin-top-right': position === 'top-right' || position === 'right',
            '-rotate-45 top-0 right-0 origin-top-right': position === 'bottom',
            '-rotate-45 bottom-0 left-0 origin-bottom-left': position === 'bottom-left',
          }" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    position: {
      type: String,
      default: 'top',
    },
  },
  computed: {
    vertical() {
      return this.position === 'top' || this.position === 'bottom'
    },
    horizontal() {
      return !this.vertical
    },
    reverse() {
      return (this.position.includes('bottom') && !this.position.includes('left')) || this.position.includes('right')
    },
  },
})
</script>
