<template>
  <div class="relative bg-gradient-to-br from-white to-sky-100 rounded-4xl overflow-hidden">
    <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-2">
      <AnimatedGlobe class="w-40" />
      <p class="flex flex-wrap text-center justify-center text-xl gap-x-1 leading-tight">
        I'm finding
        <VueWriter
          :array="['the best', 'some magical', 'the coolest', 'the most amazing', 'some unforgettable']"
          :erase-speed="25"
          :type-speed="50"
          class="-mr-1 font-medium text-blue-violet inline-flex" />
        trips for you!
      </p>
      <Spinner size="5" class="text-blue-violet" />
    </div>
    <div v-if="showFacts">
      <div
        v-for="(fact, index) in facts"
        :key="index"
        :style="`--index: ${index}`"
        class="bubble fact absolute max-w-xs text-balance text-center z-10 bg-white flex rounded-full p-4 shadow-xl leading-tight"
        @animationstart="(e) => onAnimationStart(e, index)"
        @animationiteration="(e) => onAnimationStart(e, index)">
        {{ fact }}
      </div>
    </div>
    <div v-else>
      <SpeechBubble
        v-for="(feature, index) in features"
        :key="index"
        position="right"
        :style="`--index: ${index}`"
        class="bubble feature cursor-pointer absolute z-10"
        @animationstart="(e) => onAnimationStart(e, index)"
        @animationiteration="(e) => onAnimationStart(e, index)"
        @click="sendMessage(feature)">
        {{ feature }}
      </SpeechBubble>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { MessageRoleEnum } from '@/enums'
import { VueWriter } from 'vue-writer'
import messages from '@/config/messages.json'

export default defineComponent({
  components: {
    VueWriter,
  },
  props: {
    showFacts: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    facts() {
      return ['Did you know?', ...this.portfolio.funfacts, ...(messages?.waiting?.facts ?? [])]
    },
    features() {
      return [...(messages?.waiting?.features ?? [])]
        .filter((m) => (m.indexOf('%location%') && this.portfolio.location) || !m.indexOf('%location%'))
        .map((feature) => feature.replace(/%location%/g, this.portfolio.location))
    },
  },
  methods: {
    onAnimationStart(event: AnimationEvent, index: number) {
      const items = this.showFacts ? this.facts : this.features
      ;(event.target as HTMLElement).style.left = `${50 + 35 * (index % 2 ? 1 : -1) * (index / items.length)}%`
    },
    sendMessage(message: string) {
      this.$store.dispatch('assistant/sendMessage', {
        role: MessageRoleEnum.USER,
        content: message,
      })
    },
  },
})
</script>

<style scoped>
.bubble {
  animation: bubble 20s infinite ease-in-out both;
  animation-delay: calc(var(--index) * 7.5s);
}
.bubble.fact:first-child {
  @apply text-2xl font-serif bg-blue-violet text-white px-6;
}
.bubble.fact:not(:first-child):nth-child(odd) {
  @apply text-blue-violet;
}
.bubble.fact:not(:first-child):nth-child(even) {
  @apply text-slate-500;
}
@keyframes bubble {
  0% {
    top: 100%;
    transform: translateX(-50%);
    opacity: 0;
  }
  30% {
    transform: translateX(-55%) translateY(-30%);
    opacity: 1;
  }
  70% {
    transform: translateX(-45%) translateY(-70%);
    opacity: 1;
  }
  100% {
    top: 0%;
    transform: translateX(-50%) translateY(-100%);
    opacity: 0;
  }
}
</style>
