<template>
  <div class="relative max-w-5xl mx-auto py-8 grid grid-cols-5 narrow:grid-cols-3 gap-8">
    <div class="col-span-5 md:col-span-3 flex flex-col gap-8">
      <Gallery
        v-if="hasPhotos"
        v-slot="{ gallery }"
        class="flex gap-6 text-slate-200 col-span-3 grid"
        :class="[`grid-cols-${NUM_FEATURED_PHOTOS}`, { '-ml-10': wideLayout }]">
        <Image
          v-for="(photo, index) in photos.slice(0, numPhotos)"
          :key="photo.id"
          :src="photo.url"
          :caption="photo.description"
          :gallery="gallery"
          :can-save="true"
          class="rounded-xl col-span-1 aspect-square" />
      </Gallery>
      <Markdown v-if="hasDescription" :source="description" class="prose prose-invert text-slate-200" />
    </div>
    <div class="col-span-5 md:col-span-2 narrow:col-span-3 flex flex-col gap-8 row-start-1 md:row-start-auto">
      <div v-if="hasVideo">
        <Video :src="videoEmbedUrl" :can-save="true" class="rounded-xl" />
      </div>
      <div v-if="hasMap" class="relative z-1" :class="{ 'w-full lg:w-2/3': hasVideo }">
        <Map
          :center="mapLatLong"
          :pois="mapPois"
          :legend="true"
          :zoom="mapZoomLevel"
          class="text-slate-200"
          map-class="rounded-xl w-full shadow-xl"
          :class="{ 'aspect-square': hasVideo, 'aspect-video': !hasVideo }" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Markdown from 'vue-markdown-render'
import dataMixins from '../mixins/data'
import propsMixins from '../mixins/props'

export default defineComponent({
  components: { Markdown },
  mixins: [dataMixins, propsMixins],
  data() {
    return {
      numPhotos: 3,
    }
  },
})
</script>
