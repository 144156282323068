import mitt, { Emitter, EventType } from 'mitt'
import { App, Plugin } from 'vue'
import { Events } from '@/types'
import { Store } from 'vuex'

export interface CustomEmitter<Events extends Record<EventType, unknown>> extends Emitter<Events> {
  once: (type: keyof Events, handler: (args: any) => void) => void
}

export default {
  install(app: App, store: Store) {
    const emitter = mitt() as CustomEmitter<Events>

    emitter.once = (type, handler) => {
      const fn = (args) => {
        emitter.off(type, fn)
        handler(args)
      }
      emitter.on(type, fn)
    }

    app.config.globalProperties.$emitter = emitter
    store.$emitter = emitter
  },
} as Plugin

declare module 'vue' {
  interface ComponentCustomProperties {
    $emitter: CustomEmitter<Events>
  }
}
