import { PropType } from 'vue'
import { ScratchPadItemTypeEnum, RecommendationTypeEnum } from '@/enums'
import { RecommendationType } from '@/types'

export default {
  props: {
    recommendation: {
      type: Object as PropType<RecommendationType>,
      required: true,
    },
  },
  computed: {
    scratchPadItem() {
      switch (this.recommendation.type) {
        case RecommendationTypeEnum.ATTRACTION:
          return {
            type: ScratchPadItemTypeEnum.ATTRACTION,
            id: this.recommendation.attraction?.id ?? `recommendation-${this.recommendation.type}-${this.recommendation.id}`,
            data: {
              title: this.recommendation.title,
              image: this.recommendation.cover.photo.url,
              original: this.recommendation.attraction,
            },
          }
        case RecommendationTypeEnum.ACTIVITY:
        case RecommendationTypeEnum.PRODUCT:
          return {
            type: ScratchPadItemTypeEnum.ACTIVITY,
            id: this.recommendation.activity?.id ?? this.recommendation.product?.id ?? `recommendation-${this.recommendation.type}-${this.recommendation.id}`,
            data: {
              title: this.recommendation.title,
              image: this.recommendation.cover.photo.url,
              original: this.recommendation.activity || this.recommendation.product,
            },
          }
        case RecommendationTypeEnum.TRIP:
          return {
            type: ScratchPadItemTypeEnum.TRIP,
            id: this.recommendation?.url ?? `recommendation-${this.recommendation.type}-${this.recommendation.id}`,
            data: {
              url: this.recommendation.url,
              title: this.recommendation.title,
              image: this.recommendation.cover.photo.url,
              original: this.recommendation,
            },
          }
        default:
          return {
            type: ScratchPadItemTypeEnum.UNKNOWN,
            id: `recommendation-${this.recommendation.type}-${this.recommendation.id}`,
            data: {
              title: this.recommendation.title,
              image: this.recommendation.cover.photo.url,
              original: this.recommendation,
            },
          }
      }
    },
  },
}
