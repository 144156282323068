<template>
  <div :class="{ 'pointer-events-none opacity-50': disabled, '!opacity-100': itemIsInScratchPad }">
    <div class="relative flex shrink-0">
      <div
        v-if="added"
        ref="added"
        class="absolute top-0 left-0 w-8 h-8 flex p-1 rounded-xl cursor-pointer transition text-emerald-600 bg-emerald-200/90"
        @animationend="added = false">
        <IconCircleCheck class="flex w-full h-full" />
      </div>
      <div
        v-if="itemIsInScratchPad"
        class="group/button w-8 h-8 flex p-1 rounded-xl cursor-pointer transition text-emerald-600 bg-emerald-200/90 hover:text-red-600 hover:bg-red-300 hover:shadow-lg hover:bg-opacity-100"
        @click.stop="removeFromScratchPad(item)">
        <IconCircleCheck class="flex group-hover/button:hidden w-full h-full" />
        <IconPinnedOff class="group-hover/button:flex hidden w-full h-full" />
      </div>
      <div
        v-else
        v-tooltip="{ content: 'Add to scratchpad', distance: 10 }"
        class="w-8 h-8 flex p-1 rounded-xl text-fuchsia-200 bg-fuchsia-400/90 cursor-pointer transition hover:shadow-lg hover:text-white hover:bg-opacity-100"
        @click.stop="addToScratchPad(item)">
        <IconPin class="w-full h-full" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ScratchPadItem } from '@/types'
import { IconCircleCheck, IconPin, IconPinnedOff } from '@tabler/icons-vue'
import { defineComponent, PropType } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { heartBeat } from '@asika32764/vue-animate'

export default defineComponent({
  components: {
    IconPin,
    IconPinnedOff,
    IconCircleCheck,
  },
  props: {
    item: {
      type: Object as PropType<ScratchPadItem>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      added: false,
    }
  },
  computed: {
    ...mapGetters('user', ['scratchPadItems']),
    itemIsInScratchPad() {
      return this.item && this.scratchPadItems.filter((i) => i.type === this.item.type && i.id === this.item.id).length > 0
    },
  },
  watch: {
    itemIsInScratchPad(n, o) {
      if (n && !o) {
        this.added = true
        this.$nextTick(() => {
          heartBeat(this.$refs.added as HTMLElement, { duration: '1000ms' })
        })
      }
    },
  },
  methods: {
    ...mapActions('user', ['addToScratchPad', 'removeFromScratchPad']),
  },
})
</script>
