<template>
  <Gallery v-slot="{ gallery }" class="relative max-w-5xl mx-auto py-8 grid grid-cols-3 gap-8">
    <Map
      :center="mapLatLong"
      :pois="mapPois"
      :legend="true"
      :zoom="mapZoomLevel"
      class="col-span-3 text-slate-200"
      map-class="rounded-xl aspect-video shadow-xl" />

    <div v-for="(poi, index) in mapPois" :key="poi.id" class="col-span-1 text-slate-200">
      <ImageFallback :src="getPoiPhotos(poi)" :can-save="true" :caption="poi.name" :gallery="gallery" class="aspect-square rounded-xl" />
    </div>

    <Gallery
      v-if="hasPhotos && mapPois.length === 0"
      v-slot="{ gallery }"
      class="flex gap-6 text-slate-200 grid"
      :class="[`grid-cols-${NUM_FEATURED_PHOTOS}`, { 'col-span-2': hasVideo, 'col-span-3': !hasVideo }]">
      <Image
        v-for="(photo, index) in photos.slice(0, NUM_FEATURED_PHOTOS)"
        :key="photo.id"
        :can-save="true"
        :src="photo.url"
        :caption="photo.description"
        :gallery="gallery"
        class="rounded-xl col-span-1 aspect-square" />
    </Gallery>
    <Video v-if="hasVideo" :src="videoEmbedUrl" :can-save="true" class="rounded-xl col-span-2" />
    <Markdown v-if="hasDescription" :source="description" class="text-slate-200" :class="{ 'col-span-1': hasVideo, 'col-span-2': !hasVideo }" />
  </Gallery>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Markdown from 'vue-markdown-render'
import dataMixins from '../mixins/data'
import propsMixins from '../mixins/props'

export default defineComponent({
  components: { Markdown },
  mixins: [dataMixins, propsMixins],
  methods: {
    getPoiPhotos(poi) {
      return (poi?.photos ?? []).map((p) => p?.urls?.regular).filter(Boolean)
    },
  },
})
</script>
