<template>
  <div
    class="rounded-t-2xl pl-2 pr-3 pt-2 pb-1.5 flex items-center text-sm font-medium gap-1 cursor-pointer transition"
    :class="{ 'bg-white text-blue-violet': selected, 'text-blue-violet-300 hover:text-blue-violet-400': !selected }">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
