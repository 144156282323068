<template>
  <Button icon light :disabled="isCallLoading" @click="toggle">
    <IconPlayerPauseFilled v-if="isCallActive" />
    <IconPlayerPlayFilled v-else />
  </Button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { IconPlayerPauseFilled, IconPlayerPlayFilled } from '@tabler/icons-vue'

export default defineComponent({
  components: {
    IconPlayerPauseFilled,
    IconPlayerPlayFilled,
  },
  methods: {
    ...mapActions('assistant', ['resume', 'pause']),
    toggle() {
      if (!this.isCallActive) {
        this.resume()
      } else {
        this.pause()
      }
    },
  },
})
</script>
