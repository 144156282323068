import { State } from './index'
import { ScratchPadItem } from '@/types'

export default {
  // trip info

  tripInfo(state: State) {
    const categorized = {}
    const currentTrip = state.trips.find((t) => t.id === state.currentTripId)
    if (!currentTrip) {
      return categorized
    }
    const categories = new Set((currentTrip?.preferences ?? []).map((p) => p.category))
    categories.forEach((category) => {
      categorized[category] = currentTrip.preferences.filter((p) => p.category === category).map((p) => p.detail)
    })
    return categorized
  },

  // user info

  userInfo(state: State) {
    const categorized = {}
    const categories = new Set(state.preferences.map((p) => p.category))
    categories.forEach((category) => {
      categorized[category] = state.preferences.filter((p) => p.category === category).map((p) => p.detail)
    })
    return categorized
  },

  userInfoList(state: State, getters: any) {
    const list: { category: string; value: string }[] = []
    Object.keys(getters.userInfo).forEach((category) => {
      getters.userInfo[category].forEach((value) => {
        list.push({ category, value })
      })
    })
    return list
  },

  // scratchpad

  scratchPadItems(state: State): ScratchPadItem[] {
    // The 'unknown' type is likely due to TypeScript not inferring the correct type for this.scratchPad
    // We can fix this by explicitly typing the scratchPad state
    const result = Array.from((state.scratchPad as Map<string, ScratchPadItem>).values()) as ScratchPadItem[]
    return result
  },
}
