<script setup lang="ts">
import { VMap, VMapOsmTileLayer, VMapPinMarker } from 'vue-map-ui'
import { LatLngExpression } from 'leaflet'
</script>

<template>
  <div class="w-full flex flex-col">
    <div class="flex-auto">
      <VMap
        v-bind="$props"
        :bounds="bounds"
        :scroll-wheel-zoom="false"
        :class="[mapClass]"
        :style="{ position: 'relative', zIndex: 1 }"
        @view-changed="onViewChanged">
        <!-- @vue-ignore -->
        <VMapOsmTileLayer />
        <VMapPinMarker v-if="pois.length === 0" :latlng="center" />
        <VMapPinMarker v-for="(poi, index) in pois" :key="index" :latlng="poi.lat_long" :icon-color="colors[index % colors.length]">
          <span class="font-sans font-bold">{{ index + 1 }}</span>
        </VMapPinMarker>
      </VMap>
    </div>
    <div v-if="pois.length" class="flex items-center justify-center flex-wrap gap-x-4 gap-y-2 mt-4">
      <div v-for="(poi, index) in pois" :key="index" class="flex items-center gap-2">
        <div
          class="w-6 h-6 rounded-full text-xs font-bold text-white flex items-center justify-center leading-none"
          :style="{ backgroundColor: colors[index % colors.length] }">
          {{ index + 1 }}
        </div>
        {{ poi.name }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    mapClass: {
      type: String,
      default: '',
    },
    center: {
      type: Object as PropType<LatLngExpression>,
      required: true,
    },
    pois: {
      type: Array as PropType<Array<{ lat_long: LatLngExpression; name?: string }>>,
      default: () => [],
    },
    zoom: {
      type: Number,
      required: true,
    },
    zoomControl: {
      type: Boolean,
      default: true,
    },
    legend: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['move-map'],
  data() {
    return {
      colors: [
        // a list of nice hex colors:
        '#E74C3C',
        '#884EA0',
        '#2471A3',
        '#17A589',
        '#E67E22',
      ],
    }
  },
  computed: {
    bounds() {
      if (this.pois.length < 2) {
        return false
      }
      const latitudes = this.pois.map((poi) => poi.lat_long[0])
      const longitudes = this.pois.map((poi) => poi.lat_long[1])
      const minLat = Math.min(...latitudes)
      const maxLat = Math.max(...latitudes)
      const minLng = Math.min(...longitudes)
      const maxLng = Math.max(...longitudes)
      const latPadding = (maxLat - minLat) * 0.1
      const lngPadding = (maxLng - minLng) * 0.1
      const padding = Math.max(latPadding, lngPadding)
      return [
        [minLat - padding, minLng - padding],
        [maxLat + padding, maxLng + padding],
      ]
    },
  },
  methods: {
    onViewChanged(view) {
      this.$emit('move-map', [view.center.lat, view.center.lng])
    },
  },
})
</script>
