<template>
  <div class="flex-auto p-4 pt-0 w-full min-h-[40vh] max-h-[60vh] bg-sky-50 overflow-auto flex flex-col rounded-4xl divide-y divide-sky-100">
    <div class="sticky top-0 z-10 pt-5 pb-4 bg-gradient-to-b from-sky-50 via-sky-50 to-transparent flex items-center justify-between">
      <div class="font-serif text-blue-violet text-2xl">About me</div>
    </div>
    <div class="flex items-center justify-center gap-4 pt-4">
      <div class="flex flex-wrap gap-2">
        <div
          v-for="{ category, value } in userInfoList"
          :key="`${category}-${value}`"
          class="bg-white border border-2 border-sky-100 hover:border-sky-200 text-blue-violet text-sm leading-none py-1 pr-1 pl-2 rounded-xl flex items-center gap-1">
          {{ value }}
          <IconX class="w-6 h-6 p-1 text-slate-300 hover:text-red-400 cursor-pointer" stroke-width="4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { IconX } from '@tabler/icons-vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    IconX,
  },
})
</script>
