<script setup lang="ts">
import { MessageRoleEnum, InputTypeEnum } from '@/enums'
</script>

<template>
  <div class="flex justify-center">
    <div class="w-full max-w-xl relative flex items-center justify-center gap-4">
      <div
        class="shrink-0 w-3 h-3 rounded-full z-[999]"
        :class="{ 'bg-red-500': isAssistantBusy || isInputDisabled, 'bg-green-400': !isAssistantBusy && !isInputDisabled }" />
      <div class="w-full relative transition" :class="{ 'flex items-center': size === 'sm', 'opacity-20 pointer-events-none': isInputDisabled }">
        <input
          ref="textInput"
          v-model="textMessage"
          type="text"
          :class="{ 'text-2xl border-transparent': size === 'lg', 'text-sm border-slate-300': size === 'sm' }"
          class="w-full py-2 pl-4 pr-16 text-left transition text-blue-violet-600 bg-white border-2 focus:border-blue-violet focus:ring-0 rounded-full focus:shadow-xl"
          @keydown.enter="sendTextMessage" />
        <IconArrowRight
          class="absolute transition bg-blue-violet text-white rounded-full right-2 top-1/2 transform -translate-y-1/2 cursor-pointer hover:bg-green-500"
          :class="{ 'opacity-50 pointer-events-none': !textMessage, 'w-10 h-10 p-1.5': size === 'lg', 'w-7 h-7 p-1': size === 'sm' }"
          @click="sendTextMessage" />
      </div>
      <InputModeToggleSwitch />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import config from '@/config/index.json'
import { IconArrowRight } from '@tabler/icons-vue'

export default defineComponent({
  components: {
    IconArrowRight,
  },
  props: {
    size: {
      type: String,
      required: false,
      default: 'lg',
    },
  },
  data() {
    return {
      config,
      textMessage: '',
    }
  },
  computed: {
    ...mapState('assistant', ['inputMode']),
    isInputDisabled() {
      return !this.isCallActive || this.inputMode === InputTypeEnum.VOICE || !this.isAssistantReady
    },
  },
  watch: {
    inputMode() {
      this.textMessage = ''
      if (this.inputMode === InputTypeEnum.TEXT) {
        // wait for transition
        setTimeout(() => {
          ;(this.$refs?.textInput as HTMLElement)?.focus()
        }, 500)
      }
    },
  },
  methods: {
    ...mapActions('assistant', ['sendMessage']),
    sendTextMessage() {
      if (this.textMessage) {
        this.sendMessage({
          role: MessageRoleEnum.USER,
          content: this.textMessage,
        })
        this.textMessage = ''
      }
    },
  },
})
</script>
