<template>
  <div class="relative max-w-5xl mx-auto py-8 grid grid-cols-3 gap-8">
    <Video :src="videoEmbedUrl" :can-save="true" class="rounded-xl col-span-3" />
    <Markdown v-if="hasDescription" :source="description" class="prose prose-invert text-slate-200 col-span-3" />
    <Gallery v-if="hasPhotos" v-slot="{ gallery }" class="flex gap-6 text-slate-200 col-span-3 grid" :class="[`grid-cols-${NUM_FEATURED_PHOTOS}`]">
      <Image
        v-for="(photo, index) in photos.slice(0, NUM_FEATURED_PHOTOS)"
        :key="photo.id"
        :can-save="true"
        :src="photo.url"
        :caption="photo.description"
        :gallery="gallery"
        class="rounded-xl col-span-1 aspect-square" />
    </Gallery>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Markdown from 'vue-markdown-render'
import dataMixins from '../mixins/data'
import propsMixins from '../mixins/props'

export default defineComponent({
  components: { Markdown },
  mixins: [dataMixins, propsMixins],
})
</script>
