import { FunctionEnum, MessageRoleEnum, ModuleEventEnum } from '@/enums'
import { Trip } from '@/types'
import { apiFetch } from '@/libs/api'
import uniqid from 'uniqid'
import dayjs from 'dayjs'
import messages from '@/config/messages.json'
import preferencesHelper from '@/libs/preferences'

const sortTrips = (a: Trip, b: Trip) => (dayjs(a.created_at).isAfter(b.created_at) ? -1 : 1)

export default {
  // when user logs in

  async onLoggedIn({ dispatch }) {
    await dispatch('fetchTrips')
    await dispatch('fetchPreferences')
    await dispatch('assistant/createAssistant', { resuming: false }, { root: true })
  },

  // trips

  async fetchTrips({ commit, dispatch }) {
    // get user's trips
    const trips = await apiFetch(`/trips`, {
      id: uniqid(),
      function: {
        name: FunctionEnum.TRIPS,
      },
    })

    if (trips.length === 0) {
      // if no trips exist, create one
      await dispatch('createTrip')
    } else {
      trips.sort(sortTrips)
      commit('setTrips', trips)

      const lastTripId = preferencesHelper.loadPreference('currentTripId')
      if (lastTripId && trips.find((t) => t.id === lastTripId)) {
        // load trip user was last editing
        await dispatch('loadTrip', lastTripId)
      } else {
        // load most recent
        await dispatch('loadTrip', trips[0].id)
      }
    }
  },
  async fetchTripDetails({ commit, state }, tripId: number) {
    // get trip details

    const trip = await apiFetch(`/trips`, {
      id: uniqid(),
      function: {
        name: FunctionEnum.TRIPS,
        arguments: {
          tripId,
        },
      },
    })

    // update store

    const trips = [...state.trips]
    const index = trips.findIndex((t) => t.id === tripId)
    if (index !== -1) {
      trips.splice(index, 1, trip)
    } else {
      trips.push(trip)
    }
    commit('setTrips', trips)
  },
  async loadTrip({ dispatch, state }, tripId: number) {
    await dispatch('setCurrentTripId', tripId)
    const trip = state.trips.find((t) => t.id === tripId)
    if (trip) {
      dispatch('loadItinerary', trip.itinerary || [])
      dispatch('loadScratchPad', trip.scratchpad || [])
    }
  },
  async createTrip({ dispatch }) {
    await dispatch('reset', null, { root: true })
    const trip = await apiFetch(`/trips`, {
      id: uniqid(),
      function: {
        name: FunctionEnum.TRIPS,
        arguments: {},
      },
    })
    dispatch('addTrip', trip)
  },
  async updateTrip({ commit, state }, data: object, tripId?: number) {
    const trip = await apiFetch(`/trips`, {
      id: uniqid(),
      function: {
        name: FunctionEnum.TRIPS,
        arguments: {
          tripId: tripId || state.currentTripId,
          details: {
            ...data,
          },
        },
      },
    })
    const trips = state.trips.map((t) => (t.id === trip.id ? trip : t))
    commit('setTrips', trips)
  },
  async deleteTrip({ commit, dispatch, state }, tripId: string) {
    await apiFetch(`/trips`, {
      id: uniqid(),
      function: {
        arguments: {
          action: 'delete',
          tripId,
        },
      },
    })
    const trips = state.trips.filter((trip) => trip.id !== tripId)
    commit('setTrips', trips)
    if (tripId === state.currentTripId) {
      dispatch('createTrip')
    }
  },
  async removeTripPreference({ state }, { category, value }) {
    if (state.currentTripId === null) return

    const currentTrip = state.trips.find((t) => t.id === state.currentTripId)

    if (!currentTrip) return

    const { preferences } = await apiFetch('/trips', {
      id: uniqid(),
      function: {
        arguments: {
          details: {
            preferences: (currentTrip?.preferences ?? []).filter((p) => p.category !== category || p.detail !== value),
          },
          tripId: state.currentTripId,
        },
      },
    })

    currentTrip.preferences = preferences
    state.trips = state.trips.map((t) => (t.id === currentTrip.id ? currentTrip : t))
  },
  addTrip({ commit, dispatch, state }, trip: Trip) {
    const trips = [...state.trips]
    const index = trips.findIndex((t) => t.id === trip.id)
    if (index !== -1) {
      trips.splice(index, 1, trip)
    } else {
      trips.unshift(trip)
    }
    commit('setTrips', trips)
    dispatch('setCurrentTripId', trip.id)
  },
  async setCurrentTripId({ commit, state, dispatch }, tripId: number) {
    const previousTripId = state.currentTripId
    commit('setCurrentTripId', tripId)

    if (previousTripId === tripId) return

    // user has changed to a new trip, fetch new one
    await dispatch('fetchTripDetails', tripId)

    const trip = state.trips.find((t) => t.id === tripId)

    if (!trip) return

    // update the UI
    const { title, location, lat_long, departure_date, return_date, travelers } = trip

    // make a copy of the trip without scratchpad and itinerary
    const basicTrip = { ...trip }
    delete basicTrip.scratchpad
    delete basicTrip.itinerary

    // update store
    state.trips = state.trips.map((t) => (t.id === state.currentTripId ? trip : t))

    // skip the welcome message (if call hasn't started)
    if (location || departure_date || return_date || travelers?.length) {
      await dispatch('assistant/setSkipWelcomeMessage', true, { root: true })
    }

    if (previousTripId !== null) {
      dispatch('reset', null, { root: true })

      // notify AI if the user has switched trips

      await dispatch(
        'assistant/sendMessage',
        {
          role: MessageRoleEnum.SYSTEM,
          content: `${messages.tripSwitch.replace('$json', JSON.stringify(basicTrip, null, 2))}`,
        },
        { root: true }
      )
    } else {
      // notify the AI to start planning the new trip

      await dispatch(
        'assistant/sendMessage',
        {
          role: MessageRoleEnum.SYSTEM,
          content: `${messages.tripResume.replace('$json', JSON.stringify(basicTrip, null, 2))}`,
        },
        { root: true }
      )
    }

    // put new trip data into portfolio

    // destination

    if (location) {
      this.$emitter.emit(ModuleEventEnum.ADD_TO_PORTFOLIO, {
        name: FunctionEnum.TRIP_DESTINATION,
        params: {
          location,
          lat_long,
        },
        confirm: false,
      })
    }

    // travel dates

    if (departure_date && return_date) {
      this.$emitter.emit(ModuleEventEnum.ADD_TO_PORTFOLIO, {
        name: FunctionEnum.TRAVEL_DATES,
        params: {
          departure_date,
          return_date,
        },
        confirm: false,
      })
    }

    // travelers

    if (travelers && travelers.length) {
      this.$emitter.emit(ModuleEventEnum.ADD_TO_PORTFOLIO, {
        name: FunctionEnum.TRAVELERS,
        params: {
          travelers,
        },
        confirm: false,
      })
    }

    if (title && title !== 'null') {
      await dispatch('setPortfolioSummary', trip.title, { root: true })
    }
  },

  // preferences

  async fetchPreferences({ commit }) {
    const preferences = await apiFetch(`/users`, {
      id: uniqid(),
      function: {},
    })
    commit('setPreferences', Array.isArray(preferences) ? preferences : [])
  },
  async removeUserPreference({ commit, state }, { category, value }) {
    const { preferences } = await apiFetch('/users', {
      id: uniqid(),
      function: {
        arguments: {
          details: {
            preferences: state.preferences.filter((p) => p.category !== category || p.detail !== value),
          },
        },
      },
    })
    commit('setPreferences', Array.isArray(preferences) ? preferences : [])
  },
  setPreferences({ commit }, value) {
    commit('setPreferences', value)
  },

  // travel details

  handleCaptureTravelDetails({ commit }, value) {
    commit('handleCaptureTravelDetails', value)
  },

  // scratchPad

  addToScratchPad({ commit, dispatch }, value) {
    commit('addToScratchPad', value)
    dispatch('saveScratchPad')
  },
  removeFromScratchPad({ commit, dispatch }, value) {
    commit('removeFromScratchPad', value)
    dispatch('saveScratchPad')
  },
  saveScratchPad({ state, dispatch }) {
    dispatch('updateTrip', { scratchpad: Array.from(state.scratchPad.values()) }, state.currentTripId)
  },
  loadScratchPad({ commit }, value) {
    commit('setScratchPad', value)
  },

  // itinerary

  setItinerary({ commit, dispatch }, value) {
    commit('setItinerary', value)
    dispatch('saveItinerary')
  },
  saveItinerary({ state, dispatch }) {
    dispatch('updateTrip', { itinerary: state.itinerary }, state.currentTripId)
  },
  loadItinerary({ commit }, value) {
    commit('setItinerary', value)
  },
}
