import * as modules from '@/modules'
import { App, Plugin } from 'vue'

// instantiate each app module

export default {
  install(app: App) {
    const moduleList = Object.keys(modules)
    moduleList.forEach((module) => {
      modules[module]({
        name: module,
        router: app.config.globalProperties.$router,
        store: app.config.globalProperties.$store,
        app,
      })
    })
  },
} as Plugin
