import { App, Plugin } from 'vue'
import { Store } from 'vuex'
import { FunctionEnum } from '@/enums'
import axios from 'axios'

export default {
  install(app: App, store: Store) {
    const watcher = (state, getters) => {
      // can't get it to work with the getters.portfolio.lat_long value
      return (state.portfolioModules?.find((module) => module.name === FunctionEnum.TRIP_DESTINATION)?.params as any)?.lat_long ?? []
    }
    const callback = async (lat_long) => {
      if (lat_long && Array.isArray(lat_long) && lat_long.length === 2) {
        const { data } = await axios.get(
          `https://api.open-meteo.com/v1/forecast?latitude=${lat_long[0]}&longitude=${lat_long[1]}&daily=weather_code,temperature_2m_max,temperature_2m_min&forecast_days=16`
        )
        if (data.daily) {
          store.dispatch('setWeatherForecast', data.daily)
        }
      }
    }
    const unwatch = store.watch(watcher, callback)
  },
} as Plugin
