import { FunctionEnum } from '@/enums'

export const NUM_RECENT_MESSAGES = 5
export const MAX_NUM_INFO_MODULES = 10
export const FIRST_MODULE_NAME = 'FirstModule'
export const SOLO_MODULES = ['SuggestedTrips', 'UserOnboarding']
export const QUEUEABLE_FUNCTIONS = [
  FunctionEnum.GET_TRIP_INSPIRATION,
  FunctionEnum.TRIP_RECOMMENDATIONS,
  FunctionEnum.USER_ONBOARDING,
  FunctionEnum.INFORMATION,
]
export const DEFAULT_BUTTON_COUNTDOWN = 15 // seconds
export const DEFAULT_CURRENCY = 'USD'
export const LOCAL_CURRENCY = 'CAD'

// sidebar
export const SIDEBAR_WIDTH = { sm: '26rem', lg: '50vw' }

// animation durations
export const SIDEBAR_TRANSITION_DURATION = 700
export const ORB_SHORT_DELAY = 1000
export const ORB_STAGGER_DELAY = 500

// make an array of words to flag as important about the user
// if they show up in a message, we should send a system message to the AI
export const IMPORTANT_USER_KEYWORDS = [
  'solo',
  'spouse',
  'boyfriend',
  'girlfriend',
  'companion',
  'partner',
  'wife',
  'husband',
  'child',
  'children',
  'kid',
  'kids',
  'family',
  'vegetarian',
  'vegan',
  'gluten-free',
  'gluten',
  'allergies',
  'allergic',
  'allergy',
  'lactose',
  'travel',
  'trip',
  'food',
  'foodie',
  'meat',
  'carnivore',
  'restaurant',
  'married',
  'wedding',
]
