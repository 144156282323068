<template>
  <div class="flex flex-col h-full duration-700 transition-flex">
    <PortfolioTripTitle class="p-4" />
    <transition name="fade" mode="out-in">
      <div
        v-if="portfolioModules.length > 0 || Object.keys(tripInfo).length > 0 || Object.keys(userInfo).length > 0"
        class="flex flex-col p-4 divide-y flex-auto pt-0 h-full">
        <SidebarPanel
          :ref="`Portfolio/tripDetails`"
          title="Trip details"
          type="tripDetails"
          icon-class="bg-green-200 text-green-500"
          empty-message="I'll add details about your trip here"
          :open="openSection === 'tripDetails'"
          :closed="closedSections.includes('tripDetails')"
          :populated="portfolioModules.length > 0"
          @toggle="toggleSection">
          <div class="flex flex-col gap-2">
            <div v-for="(item, index) in portfolioModules" :key="index">
              <component
                :is="`Portfolio/${item.name}`"
                :ref="`Portfolio/${item.name}`"
                v-bind="{ event: item.name, ...item.params }"
                @modify="modify"
                @forget="forget" />
            </div>
          </div>
        </SidebarPanel>
        <SidebarPanel
          :ref="`Portfolio/tripInfo`"
          title="Trip filters"
          type="tripInfo"
          icon-class="bg-orange-200 text-orange-500"
          container-class="flex flex-col gap-4"
          empty-message="I'll keep track of what kind of trip you'd like here"
          :populated="Object.keys(tripInfo).length > 0"
          :open="openSection === 'tripInfo'"
          :closed="closedSections.includes('tripInfo')"
          @toggle="toggleSection">
          <div v-for="(category, index) in Object.keys(tripInfo)" :key="index" class="flex flex-col gap-1.5">
            <div class="text-sm font-medium flex items-center gap-2">
              <div class="w-3 h-3 bg-orange-200 rounded-full" />
              <span class="sentence">{{ unslugify(category) }}</span>
            </div>
            <div class="w-full flex flex-col gap-1 divide-y">
              <TransitionGroupList>
                <PortfolioItem
                  v-for="value in tripInfo[category]"
                  :key="getTravelDetailSlug(category, value)"
                  :ref="`Portfolio/tripInfo/${getTravelDetailSlug(category, value)}`"
                  :event="`tripInfo-${getTravelDetailSlug(category, value)}`"
                  highlight="false"
                  button="true">
                  <FilterItem type="block" v-bind="{ category, value }" @remove="removeTripInfo" />
                </PortfolioItem>
              </TransitionGroupList>
            </div>
          </div>
        </SidebarPanel>
        <SidebarPanel
          :ref="`Portfolio/userInfo`"
          title="About me"
          type="userInfo"
          icon-class="bg-fuchsia-200 text-fuchsia-500"
          container-class="flex flex-wrap gap-2 items-center"
          empty-message="As I learn about you, I'll add things here"
          :populated="Object.keys(userInfo).length > 0"
          :open="openSection === 'userInfo'"
          :closed="closedSections.includes('userInfo')"
          @toggle="toggleSection">
          <div class="w-full flex flex-col gap-2 divide-y">
            <TransitionGroupList>
              <PortfolioItem
                v-for="item in userInfoList"
                :key="getTravelDetailSlug(item.category, item.value)"
                :ref="`Portfolio/userInfo/${getTravelDetailSlug(item.category, item.value)}`"
                class="pt-2 first:pt-0"
                :event="`userInfo-${getTravelDetailSlug(item.category, item.value)}`"
                highlight="false">
                <FilterItem type="block" v-bind="{ ...item }" @remove="removeUserInfo" />
              </PortfolioItem>
            </TransitionGroupList>
          </div>
        </SidebarPanel>
      </div>
      <div v-else class="flex-auto flex flex-col gap-2 items-center justify-center bg-gradient-to-br from-white to-sky-50 rounded-3xl">
        <IconBeach class="w-12 h-12 text-sky-300" stroke-width="1.5" />
        <div class="text-slate-500 font-light text-xl">Your next trip awaits</div>
        <div class="text-blue-violet text-sm cursor-pointer hover:text-violet-400" @click="setOverlay('userProfile')">Manage my trips</div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import config from '@/config/index.json'
import { SIDEBAR_TRANSITION_DURATION } from '@/constants'
import { getTravelDetailSlug, unslugify } from '@/libs/utils'
import { Events } from '@/types'
import { ModuleEventEnum } from '@/enums'
import { IconBeach, IconCheck } from '@tabler/icons-vue'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
  components: {
    IconBeach,
    IconCheck,
  },
  data() {
    return {
      SIDEBAR_TRANSITION_DURATION,
      config,
      sections: ['tripDetails', 'tripInfo', 'userInfo'],
      openSection: null as string | null,
      closedSections: [] as string[],
      getTravelDetailSlug,
      unslugify,
    }
  },
  computed: {
    ...mapState(['portfolioModules', 'isSidebarLocked', 'firstItemAddedToSidebar']),
  },
  methods: {
    ...mapActions(['setSidebarDetailsPanel', 'setSidebarLocked', 'setFirstItemAddedToSidebar', 'setSidebarDetailsPanelShown', 'setIsScratchPadShowing']),
    toggleSection(section: string, ignoreLock: boolean = false) {
      if (!ignoreLock && this.isSidebarLocked) return
      this.openSection = this.openSection === section ? null : section
      this.closedSections = this.openSection ? this.sections.filter((s) => s !== section) : []
      this.setSidebarDetailsPanel(this.openSection)
      this.setSidebarDetailsPanelShown(this.openSection)
    },
    modify({ event, params }: { event: keyof Events; params: object }) {
      this.$emitter.emit(event, params)
      this.hideSidebar()
    },
    forget(event: keyof Events) {
      this.$emitter.emit(ModuleEventEnum.REMOVE_FROM_PORTFOLIO, event)
    },
    removeTripInfo({ category, value }: { category: string; value: string }) {
      this.$emitter.emit(ModuleEventEnum.REMOVE_TRIP_INFO, { category, value })
    },
    removeUserInfo({ category, value }: { category: string; value: string }) {
      this.$emitter.emit(ModuleEventEnum.REMOVE_USER_INFO, { category, value })
    },
  },
})
</script>
