<template>
  <div
    v-if="type === 'pill'"
    class="group bg-sky-100 text-xs font-medium leading-none flex items-center gap-1 py-1.5 px-2 rounded-full transition"
    :class="{ 'pointer-events-none opacity-50': removing }">
    <slot />
    <span v-if="value" class="sentence">{{ unslugify(value) }}</span>
    <IconX
      v-if="!removing"
      stroke-width="3"
      class="w-3 h-3 text-sky-400 hover:text-red-400 hover:rotate-90 transition duration-[300ms] cursor-pointer"
      @click="remove" />
    <Spinner v-else size="3" class="text-violet-400" />
  </div>
  <div v-else-if="type === 'block'" class="w-full flex items-center gap-2 group" :class="{ 'pointer-events-none opacity-50': removing }">
    <slot />
    <span v-if="value" class="sentence text-sm text-slate-500 leading-tight">{{ unslugify(value) }}</span>
    <IconX
      v-if="!removing"
      stroke-width="3"
      class="shrink-0 w-6 h-6 p-1 cursor-pointer transition opacity-0 group-hover:opacity-100 text-slate-300 hover:text-red-400 ml-auto"
      @click="remove" />
    <Spinner v-else size="4" class="m-1 text-violet-400" />
  </div>
</template>

<script lang="ts">
import { Events } from '@/types'
import { IconX } from '@tabler/icons-vue'
import { defineComponent, PropType } from 'vue'
import { unslugify } from '@/libs/utils'

export default defineComponent({
  components: {
    IconX,
  },
  props: {
    type: {
      type: String,
      required: true,
      default: 'pill',
    },
    value: {
      type: String,
      required: true,
      default: '',
    },
    category: {
      type: String,
      required: true,
      default: '',
    },
    event: {
      type: String as PropType<keyof Events>,
      required: true,
    },
  },
  emits: ['remove'],
  data() {
    return {
      removing: false,
      unslugify,
    }
  },
  methods: {
    remove() {
      this.removing = true
      this.$emit('remove', { category: this.category, value: this.value })
    },
  },
})
</script>
