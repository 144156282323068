<template>
  <StyleProvider class="relative flex flex-col w-screen h-screen overflow-hidden">
    <transition name="view">
      <RouterView />
    </transition>
    <ToastMessage />
  </StyleProvider>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { RouterView } from 'vue-router'
import eventMixins from '@/mixins/events'

export default defineComponent({
  components: {
    RouterView,
  },
  mixins: [eventMixins],
})
</script>

<style scoped>
.view-enter-active,
.view-leave-active {
  transition: all 0.5s var(--ease-in-out-cubic);
}
.view-enter-active {
  transition-delay: 0.25s;
}

.view-enter-from,
.view-leave-to {
  opacity: 0;
  transform: scale(0.98);
}
</style>
