<template>
  <div class="relative w-full h-full">
    <div v-if="showGradient" class="absolute bottom-0 w-full h-12 bg-gradient-to-t from-white to-transparent z-10 pointer-events-none" />
    <div class="absolute top-0 left-0 w-full h-full overflow-auto" :class="[containerClass, { 'pb-12': showGradient }]">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InlineScroller',
  props: {
    containerClass: {
      type: String,
      default: '',
    },
    showGradient: {
      type: Boolean,
      default: true,
    },
  },
})
</script>
