<template>
  <div class="h-full p-4 pt-0 flex" :class="{ 'flex-row gap-x-8': columns, 'flex-col': !columns }">
    <CollapsiblePanel
      v-if="columns || selected === 'scratchPad'"
      v-slot="{ open, closed }"
      :open="openSection === 'scratchPad'"
      :closed="closedSections.includes('scratchPad')"
      :has-content="false">
      <SidebarSectionTitle
        title="Scratchpad"
        icon="IconPin"
        icon-class="bg-fuchsia-200 text-fuchsia-500"
        :open="open"
        :closed="closed"
        :columns="columns"
        :disabled="!columns"
        @click="toggleSection('scratchPad')">
        <span v-if="numScratchPadItems" class="text-sm text-slate-400">{{ numScratchPadItems }} item{{ numScratchPadItems > 1 ? 's' : '' }}</span>
      </SidebarSectionTitle>
      <div class="flex-auto overflow-auto bg-gradient-to-br from-white to-sky-50 p-4 rounded-2xl" @click.stop="scratchPadClickHandler">
        <transition name="fade">
          <ScratchPadModule v-if="(columns && (open || !closed)) || !columns" :draggable="itineraryVisible" />
        </transition>
      </div>
      <SidebarSplitModeHint v-if="!columns" @click.stop="$emit('fullscreen')" />
    </CollapsiblePanel>
    <CollapsiblePanel
      v-if="columns || selected === 'itinerary'"
      v-slot="{ open, closed }"
      :open="openSection === 'itinerary'"
      :closed="closedSections.includes('itinerary')"
      :has-content="false">
      <SidebarSectionTitle
        title="Itinerary"
        icon="IconClipboard"
        icon-class="bg-emerald-200 text-emerald-500"
        :open="open"
        :closed="closed"
        :columns="columns"
        :disabled="!columns"
        @click="toggleSection('itinerary')">
        <div class="flex items-center gap-2">
          <MiniButton size="sm" :type="itineraryLayout === 'list' ? 'bold' : 'light'" @click.stop="itineraryLayout = 'list'">
            <IconLayoutList />
            List
          </MiniButton>
          <MiniButton size="sm" :type="itineraryLayout === 'calendar' ? 'bold' : 'light'" @click.stop="itineraryLayout = 'calendar'">
            <IconCalendarWeek />
            Calendar
          </MiniButton>
        </div>
      </SidebarSectionTitle>
      <div class="flex-auto overflow-auto bg-gradient-to-br from-white to-sky-50 p-4 rounded-2xl" @click.stop="itineraryClickHandler">
        <transition name="fade">
          <ItineraryModule v-if="(columns && (open || !closed)) || !columns" :layout="itineraryLayout" />
        </transition>
      </div>
      <SidebarSplitModeHint v-if="!columns" @click.stop="$emit('fullscreen')" />
    </CollapsiblePanel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import { IconLayoutList, IconCalendarWeek } from '@tabler/icons-vue'

export default defineComponent({
  components: { IconLayoutList, IconCalendarWeek },
  props: {
    selected: {
      type: String,
      default: 'scratchPad',
    },
    columns: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['fullscreen'],
  data() {
    return {
      openSection: null as string | null,
      closedSections: [] as string[],
      sections: ['scratchPad', 'itinerary'],
      itineraryLayout: 'calendar' as 'list' | 'calendar',
    }
  },
  computed: {
    ...mapState('user', ['itinerary']),
    ...mapGetters('user', ['scratchPadItems']),
    numScratchPadItems() {
      return this.scratchPadItems.length
    },
    hasDates() {
      return this.portfolio.departure_date.length > 0 && this.portfolio.return_date.length > 0
    },
    numItineraryDays() {
      return this.itinerary.length
    },
    numItineraryItems() {
      return this.itinerary.reduce((acc, day) => acc + day.length, 0)
    },
    scratchPadVisible() {
      return !this.closedSections.includes('scratchPad')
    },
    itineraryVisible() {
      return !this.closedSections.includes('itinerary')
    },
  },
  watch: {
    selected: {
      immediate: true,
      handler(value) {
        this.$nextTick(() => {
          this.toggleSection(value)
        })
      },
    },
    columns: {
      immediate: true,
      handler(value) {
        if (value) {
          this.closedSections = []
          this.openSection = null
        }
      },
    },
  },
  methods: {
    toggleSection(section: string) {
      this.openSection = this.openSection === section ? null : section
      this.closedSections = this.openSection ? this.sections.filter((s) => s !== section) : []
    },
    scratchPadClickHandler() {
      if (this.columns && this.closedSections.includes('scratchPad')) {
        this.toggleSection('itinerary')
      }
    },
    itineraryClickHandler() {
      if (this.columns && this.closedSections.includes('itinerary')) {
        this.toggleSection('scratchPad')
      }
    },
  },
})
</script>
