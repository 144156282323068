<template>
  <CollapsiblePanel :id="panelId" :open="open" :closed="closed" :has-content="populated">
    <SidebarSectionTitle :icon="icon" :icon-class="iconClass" :title="title" :open="open" @click="toggle" />
    <transition name="fade" mode="out-in">
      <div v-if="showContent" :class="containerClass">
        <slot />
      </div>
      <div v-else-if="showEmptyMessage" class="flex-auto flex items-center justify-center flex-col gap-2">
        <IconHelpCircle class="w-8 h-8 text-slate-300" stroke-width="1.5" />
        <p class="text-center text-balance text-sm px-6 text-slate-400">{{ emptyMessage }}</p>
      </div>
    </transition>
  </CollapsiblePanel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { IconHelpCircle } from '@tabler/icons-vue'
import { SidebarDetailsPanelType } from '@/types'

export default defineComponent({
  components: {
    IconHelpCircle,
  },
  props: {
    populated: Boolean,
    type: {
      type: String as () => SidebarDetailsPanelType,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    containerClass: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: 'bg-slate-200 text-slate-500',
    },
    emptyMessage: {
      type: String,
      default: 'No content provided for this panel',
    },
    open: {
      type: Boolean,
      default: false,
    },
    closed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle'],

  computed: {
    showContent() {
      return this.populated && !this.closed
    },
    showEmptyMessage() {
      return !this.populated && !this.closed
    },
    panelId() {
      return `sidebar-panel-${this.type}`
    },
    icon() {
      switch (this.type) {
        case 'tripDetails':
          return 'IconGlobe'
        case 'tripInfo':
          return 'IconHeart'
        case 'userInfo':
        default:
          return 'IconUser'
      }
    },
  },
  methods: {
    toggle() {
      this.$emit('toggle', this.type)
    },
  },
})
</script>
