import { App } from 'vue'
import { Store } from 'vuex'
import { Router } from 'vue-router'
import Module from '@/modules/module'
import { FunctionEnum } from '@/enums'

const size = 'md'

export default ({ name, store, app }: { name: string; router?: Router; store: Store; app: App }) => {
  // create module
  const module = new Module({ name, size, store, app })

  // register event handlers
  module.registerEventHandlers([
    {
      event_name: FunctionEnum.FLIGHTS,
      confirmHandler: ({ departure_flight, return_flight }) => {
        return `The user has confirmed that they will be flying on ${departure_flight} and returning on ${return_flight}`
      },
      removeHandler: () => {
        return `Please forget about the flights the user mentioned earlier`
      },
      requestHandler: () => {
        return `The user would like to talk about their flights`
      },
    },
  ])

  // add module store
  module.addModuleStore({
    state: {
      // ...
    },
    actions: {
      // ...
    },
    mutations: {
      // ...
    },
  })
}
