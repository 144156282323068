<script setup lang="ts">
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { CallStatusEnum } from '@/enums'

const store = useStore()
const blobPosition = ref<'top' | 'middle'>('middle')
const callStatus = ref(CallStatusEnum.LOADING)
const isWaiting = ref(false)
const waitingForResponseTimeout = ref()
const WAITING_DELAY = 10000

// Watch for changes in the call status
watch(
  () => store.state.assistant.callStatus,
  (newValue) => {
    callStatus.value = newValue
  }
)

// adjust blob position when any module is active
watch(
  () => store.getters.isAnyModuleActive,
  (newValue) => {
    blobPosition.value = newValue ? 'top' : 'middle'
  }
)

// watch for waiting for response
watch(
  () => store.state.assistant.waitingForResponse,
  (newValue) => {
    isWaiting.value = false
    if (waitingForResponseTimeout.value) {
      clearTimeout(waitingForResponseTimeout.value)
    }
    if (newValue) {
      waitingForResponseTimeout.value = setTimeout(() => {
        isWaiting.value = true
      }, WAITING_DELAY)
    }
  }
)
</script>

<template>
  <div
    id="entity"
    class="relative self-center w-full h-full pointer-events-none transition-all"
    :class="{
      'opacity-0 scale-50': callStatus === CallStatusEnum.LOADING || callStatus === CallStatusEnum.INACTIVE,
      'duration-[700ms] -translate-y-[10%] scale-[0.8] opacity-100': callStatus === CallStatusEnum.ACTIVE && blobPosition === 'middle',
      'duration-[700ms] -translate-y-[40%] scale-[0.4]': callStatus === CallStatusEnum.ACTIVE && blobPosition === 'top',
    }">
    <transition name="fade">
      <Spinner v-if="isWaiting" absolute class="waiting-spinner z-10 text-white/60" size="32" stroke="4" duration="1.2s" easing="ease-in-out-circ" />
    </transition>
    <Blob :mode="'idle'" />
    <figure
      id="entity-center"
      aria-hidden="true"
      class="absolute block top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[100px] h-[100px] rounded-full origin-center" />
    <div class="absolute inset-0 gradient z-[-1]" />
  </div>
</template>

<style scoped>
.gradient {
  background-image: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.8) 15%, rgba(255, 255, 255, 0) 30%);
  animation: pulse 5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.waiting-spinner:after,
.waiting-spinner:before {
  @apply absolute inset-0 border border-white rounded-full;
  animation: ring 3s var(--ease-out-circ) infinite;
  content: '';
}
.waiting-spinner:after {
  animation-delay: 1.5s;
}
@keyframes ring {
  0% {
    transform: scale(0.75);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
</style>
