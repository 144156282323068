<template>
  <VTooltip v-if="callDetails || appVersion" :triggers="['click']" :distance="10">
    <template #popper>
      <div class="flex flex-col gap-1 p-2 text-sm text-slate-500">
        <div v-if="callDetails?.id">
          <span class="opacity-50 mr-2">Call ID</span>
          {{ callDetails.id }}
        </div>
        <div v-if="appVersion">
          <span class="opacity-50 mr-2">Version</span>
          v{{ appVersion }}
          <span v-if="env" class="text-sm">{{ env }}</span>
        </div>
        <div v-if="buildTimestamp">
          <span class="opacity-50 mr-2">Build</span>
          {{ buildTimestamp }}
        </div>
      </div>
    </template>
    <IconInfoSmall class="w-7 h-7 bg-orange-400 rounded-full text-white cursor-pointer hover:bg-orange-500 transition" />
  </VTooltip>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { IconInfoSmall } from '@tabler/icons-vue'

// @ts-ignore
const appVersion = APP_VERSION // from package.json
// @ts-ignore
const buildTimestamp = BUILD_TIMESTAMP // from vite.config.ts
const env = import.meta.env.VITE_ENV

export default defineComponent({
  components: {
    IconInfoSmall,
  },
  data() {
    return {
      appVersion,
      buildTimestamp,
      env: env !== 'production' ? env : null,
    }
  },
  computed: {
    ...mapState('assistant', ['callDetails']),
  },
})
</script>
