import { googleLogout, decodeCredential } from 'vue3-google-login'
import { FunctionEnum } from '@/enums'
import api from '@/services/api'
import router from '@/router'
import preferencesHelper from '@/libs/preferences'

export interface State {
  identity: any
  isAuthenticated: boolean
  token: string | null
}

export default {
  namespaced: true,
  state: {
    identity: {},
    isAuthenticated: false,
    token: null,
  } as State,
  actions: {
    async login({ commit, dispatch }, response) {
      try {
        commit('setIdentity', decodeCredential(response.credential))
        commit('setToken', response.credential)
        const {
          data: { result, token, refreshToken },
        } = await api.post(
          `/login`,
          {
            message: {
              toolCallList: [
                {
                  function: {
                    name: FunctionEnum.LOGIN,
                  },
                },
              ],
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        if (result) {
          commit('setIsAuthenticated', true)
          commit('setToken', token)
          preferencesHelper.savePreference('refreshToken', refreshToken)

          // init user

          dispatch('user/onLoggedIn', null, { root: true })

          // redirect to agent page

          router.push('/agent')
        }
      } catch (err) {
        dispatch('logout')
        console.log(err)
      }
    },
    async logout({ commit }) {
      googleLogout()
      commit('setIsAuthenticated', false)
      commit('setToken', null)
      router.push('/')
    },
    setIdentity({ commit }, value) {
      commit('setIdentity', value)
    },
    setIsAuthenticated({ commit }, value) {
      commit('setIsAuthenticated', value)
    },
    setToken({ commit }, value) {
      commit('setToken', value)
    },
  },
  mutations: {
    setIdentity(state, value) {
      state.identity = value
    },
    setIsAuthenticated(state, value) {
      state.isAuthenticated = value
    },
    setToken(state, value) {
      state.token = value
    },
  },
}
