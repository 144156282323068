<script setup lang="ts">
import { InputTypeEnum, ModuleEventEnum } from '@/enums'
</script>

<template>
  <div class="flex items-center gap-2 transition" :class="{ 'opacity-25 pointer-events-none': !isCallActive, '!opacity-0': isSidebarOpen }">
    <button
      v-tooltip="{ content: inputMode === InputTypeEnum.VOICE ? 'Mute' : 'Unmute', distance: 10 }"
      class="w-8 h-8"
      :class="{ 'text-violet-300': isDarkBackground || overlay, 'text-blue-violet': !isDarkBackground && !overlay }"
      :disabled="isCallLoading"
      @click="toggle">
      <IconVolume v-if="inputMode === InputTypeEnum.VOICE" stroke-width="1.5" />
      <IconVolumeOff v-else stroke-width="1.5" />
    </button>
    <input
      v-model="volume"
      type="range"
      min="0"
      max="1"
      step="0.01"
      :disabled="isMuted"
      class="w-20"
      :class="{
        'bg-slate-200/20 accent-violet-300': isDarkBackground,
        'bg-slate-800/10 accent-blue-violet': !isDarkBackground,
      }" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import { IconVolumeOff, IconVolume } from '@tabler/icons-vue'

export default defineComponent({
  components: {
    IconVolumeOff,
    IconVolume,
  },
  data() {
    return {
      volume: 0.5,
    }
  },
  computed: {
    ...mapState('assistant', ['inputMode', 'volumeLevel']),
    ...mapGetters('assistant', ['isMuted']),
  },
  watch: {
    volumeLevel(value) {
      this.volume = value
    },
    volume(value) {
      if (!this.isMuted) {
        this.setVolumeLevel(value)
      }
    },
    isMuted(value) {
      if (value) {
        this.volume = 0
      } else {
        this.volume = this.volumeLevel
      }
    },
  },
  methods: {
    ...mapActions('assistant', ['setVolumeLevel']),
    toggle() {
      this.$emitter.emit(ModuleEventEnum.SWITCH_TO_TEXT_INPUT, { enable: this.inputMode === InputTypeEnum.VOICE, quietly: true })
    },
  },
})
</script>
