<script lang="ts" setup>
import { ScratchPadItemTypeEnum } from '@/enums'
</script>

<template>
  <Overlay :id="id" size="2xl">
    <CloseButton class="absolute top-3 right-3 z-[99]" @click="setOverlay(null)" />
    <div class="flex flex-col gap-4">
      <Image :src="coverImage" class="w-full bg-slate-200 text-blue-violet aspect-[2/1] object-cover rounded-2xl">
        <div v-if="activity.pricing" class="absolute z-10 bottom-4 right-4 bg-white py-2 px-4 rounded-2xl flex flex-col leading-none">
          <span class="text-sm opacity-50">from</span>
          <span v-if="activity.pricing.currency === DEFAULT_CURRENCY" class="text-xl font-medium">
            {{ convertToCurrency(activity.pricing.summary.fromPrice, LOCAL_CURRENCY).format() }} {{ LOCAL_CURRENCY }}
          </span>
          <span v-else class="text-xl font-medium">{{ $currency(activity.pricing.summary.fromPrice).format() }} {{ activity.pricing.currency }}</span>
        </div>
      </Image>
      <div class="flex flex-col gap-4 py-4">
        <h3 class="text-3xl text-blue-violet font-serif leading-none text-balance">{{ activity.title }}</h3>
        <div class="flex items-center gap-4 divide-x child:pl-4">
          <PinButton
            :item="{
              type: ScratchPadItemTypeEnum.ACTIVITY,
              id: activity.productCode,
              data: { title: activity.title, image: coverImage, url: activity.productUrl, original: activity },
            }"
            class="!pl-0" />
          <StarRating v-if="activity.reviews" :rating="activity.reviews.combinedAverageRating" :reviews="activity.reviews.totalReviews" />
          <div v-if="activity.duration">
            {{ $dayjs.duration(activity.duration.fixedDurationInMinutes, 'minutes').format('H [hr] mm [min]') }}
          </div>
        </div>

        <p class="text-slate-500" v-html="activity.description" />
      </div>
    </div>
  </Overlay>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import viatorMixins from '@/mixins/viator'
import { DEFAULT_CURRENCY, LOCAL_CURRENCY } from '@/constants'

export default defineComponent({
  mixins: [viatorMixins],
  props: {
    activity: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      LOCAL_CURRENCY,
      DEFAULT_CURRENCY,
    }
  },
  computed: {
    coverImage() {
      const image = this.activity.images.find((image) => image.isCover) || this.activity.images[0]
      return this.getBestImage(image?.variants) ?? ''
    },
  },
})
</script>
