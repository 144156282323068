import { Ref, onMounted, ref } from 'vue'

import { useStore } from 'vuex'

export function useMicrophone() {
  const store = useStore()
  const audioContext = ref<AudioContext | null>(null)
  const analyser = ref<AnalyserNode | null>(null)
  const dataArray = ref<Uint8Array | null>(null)
  const bufferLength = ref(0)
  const isUserTalking: Ref<boolean> = ref(false)
  const isReady: Ref<boolean> = ref(false)
  const micLevel: Ref<number> = ref(0)

  const THRESHOLD = 35 // Adjust this value based on your needs
  const FRAME_COUNT_NOISE = 35 // Number of consecutive frames of noise to register talking
  const FRAME_COUNT_SILENCE = 90 // Number of consecutive frames of silence to end talking (assuming 60 fps)

  let noiseCounter = 0
  let silenceCounter = 0

  const ensureMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      stream.getTracks().forEach((track) => track.stop())
      return true
    } catch (err) {
      console.error('Error getting microphone permission:', err)
      return false
    }
  }

  const initializeAudio = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      if (!audioContext.value) {
        const audio = new Audio()
        audio.src = 'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA'
        await audio.play()
        audioContext.value = new AudioContext()
        const source = audioContext.value.createMediaStreamSource(stream)
        analyser.value = audioContext.value.createAnalyser()
        source.connect(analyser.value)
        analyser.value.fftSize = 256
        bufferLength.value = analyser.value.frequencyBinCount
        dataArray.value = new Uint8Array(bufferLength.value)
        isReady.value = true
      }

      // Handle suspended state
      if (audioContext.value.state === 'suspended') {
        await audioContext.value.resume()
      }
    } catch (err) {
      console.error('Error accessing microphone:', err)
      // Implement retry mechanism
      // setTimeout(initializeAudio, 1000);
    }
  }

  const initializeAudioFromClickHandler = async () => {
    document.removeEventListener('click', initializeAudioFromClickHandler)
    await initializeAudio()
  }

  const getFrequencyData = () => {
    if (analyser.value) {
      analyser.value.getByteFrequencyData(dataArray.value!)
      return dataArray.value
    }
    return null
  }

  const getLevel = () => {
    if (dataArray.value) {
      micLevel.value = dataArray.value.reduce((sum, value) => sum + value, 0) / dataArray.value.length
      return
    }
    micLevel.value = 0
  }

  const resetMic = () => {
    noiseCounter = 0
    silenceCounter = 0
    isUserTalking.value = false
    store.commit('assistant/setUserTalking', false)
  }

  const checkAudioLevel = () => {
    getFrequencyData()
    if (dataArray.value) {
      getLevel()
      if (micLevel.value > THRESHOLD) {
        noiseCounter++
        silenceCounter = 0
        if (noiseCounter >= FRAME_COUNT_NOISE) {
          isUserTalking.value = true
          store.commit('assistant/setUserTalking', true)
        }
      } else {
        if (isUserTalking.value) {
          silenceCounter++
          noiseCounter = 0
          if (silenceCounter >= FRAME_COUNT_SILENCE) {
            isUserTalking.value = false
            store.commit('assistant/setUserTalking', false)
          }
        }
      }
    }
  }

  const manualInit = async () => {
    const hasPermission = await ensureMicrophonePermission()
    if (hasPermission) {
      await initializeAudio()
    } else {
      console.error('Microphone permission denied')
      // Handle permission denial
    }
  }

  onMounted(async () => {
    const hasPermission = await ensureMicrophonePermission()
    if (hasPermission) {
      try {
        await initializeAudio()
      } catch (error) {
        console.error('Failed to initialize audio:', error)
        document.addEventListener('click', initializeAudioFromClickHandler)
      }
    } else {
      console.error('Microphone permission denied')
      // Handle permission denial (e.g., show a message to the user)
    }
    // Wait for user interaction
    //document.addEventListener('click', initializeAudio)
  })

  return { getFrequencyData, manualInit, checkAudioLevel, isUserTalking, isReady, micLevel, resetMic }
}
