<template>
  <div class="flex flex-col gap-2">
    <IconSparkles class="w-8 h-8 text-sky-300" stroke-width="1.5" />
    <div class="font-serif leading-none text-blue-violet text-2xl">Travel idea</div>
  </div>
  <PinButton :item="scratchPadItem" />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { RecommendationType } from '@/types'
import { IconSparkles } from '@tabler/icons-vue'
import recommendationMixins from '@/mixins/recommendation'

export default defineComponent({
  components: {
    IconSparkles,
  },
  mixins: [recommendationMixins],
  props: {
    recommendation: {
      type: Object as PropType<RecommendationType>,
      required: true,
    },
  },
})
</script>
