const defaultPreferences = {}
const defaultNamespace = 'userPreferences'

// create namespaced preference methods

export const preferenceFactory = (namespace) => {
  return {
    savePreference: (setting, value) => {
      const preferences = JSON.parse(localStorage.getItem(namespace) || '{}') || defaultPreferences
      localStorage.setItem(namespace, JSON.stringify({ ...preferences, [setting]: value }))
    },
    loadPreference: (setting) => {
      return JSON.parse(localStorage.getItem(namespace) || '{}')?.[setting]
    },
    loadPreferences: () => {
      return JSON.parse(localStorage.getItem(namespace) || '{}')
    },
  }
}

// default namespaced preference methods

export default preferenceFactory(defaultNamespace)
