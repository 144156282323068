<template>
  <input
    :value="modelValue"
    :type="type"
    class="border-2 rounded-full border-slate-300 transition"
    :class="{ 'opacity-25 pointer-events-none': disabled }"
    :disabled="disabled"
    @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
})
</script>
