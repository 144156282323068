<template>
  <transition name="fade" appear>
    <div v-if="informationModules.length" class="fixed inset-0 w-screen h-screen">
      <div class="absolute top-10 left-1/2 z-[9999] transition duration-[700ms]" :class="{ 'sidebar-open-translate-1/2': isSidebarOpen }">
        <div
          v-if="informationModules.length > 1"
          class="nav -translate-x-1/2 flex items-center gap-1 p-1 rounded-full bg-blue-violet-700/60 border-2 border-transparent transition duration-500 cursor-pointer"
          :class="{ 'hover:border-violet-500 hover:border-opacity-50': !showAll }"
          @click="onClickNav(selectedIndex)">
          <div
            v-for="(module, index) in informationModules"
            :key="index"
            class="cursor-pointer flex align-center justify-center bg-white transition-all duration-[350ms] border-2 border-transparent"
            :class="{
              'w-2 h-2 rounded-full m-1': index !== selectedIndex,
              'w-6 h-4 rounded-full': index === selectedIndex,
              'opacity-25 child:opacity-0': !showAll,
              'opacity-40 hover:opacity-100 hover:scale-[1.1] child:opacity-0 peer': showAll,
              '!opacity-100': index === selectedIndex && (!showAll || collapsing),
            }"
            @click.stop="onClickNav(index)" />
        </div>
      </div>
      <div
        ref="container"
        v-dragscroll.x
        class="fixed inset-0 bg-gradient-to-r from-emerald-400 to-blue-600"
        :class="{ 'overflow-auto': showAll, 'overflow-hidden': !showAll }"
        @dragscrollstart="startDragging"
        @dragscrollend="endDragging"
        @click.capture="clickDragging">
        <transition-group name="fullscreen">
          <div
            v-for="(module, index) in informationModules"
            :key="module.id"
            :style="infoModuleStyle[index]"
            :class="{ 'z-10': index === selectedIndex, 'z-0': index !== selectedIndex }"
            class="w-full h-full absolute left-0 top-0 pointer-events-none">
            <InformationModule
              v-bind="{ id: module.id, data: module, first: index === 0, focused: index === selectedIndex }"
              data-module="InformationModule"
              class="screen w-full h-full absolute pointer-events-auto transition duration-500"
              :class="{
                'scale-[0.5]': showAll && !collapsing,
                'child:pointer-events-none rounded-2xl overflow-hidden cursor-pointer shadow-[0_40px_60px_0_rgba(0,0,0,0.35)]': showAll,
                '-translate-x-20 opacity-0': !showAll && !collapsing && index !== selectedIndex,
              }"
              :disabled="showAll"
              @transitionend="onTransitionEnd"
              @click="onClickModule(index)" />
          </div>
        </transition-group>
        <div class="h-full" :style="infoBgStyle" @click="showAll = false" />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import { FunctionEnum, MessageRoleEnum, InformationTypeEnum } from '@/enums'
import uniqid from 'uniqid'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
import config from '@/config/index.json'
import { shouldFunctionBeQueued } from '@/modules/module'

gsap.registerPlugin(ScrollToPlugin)

const SMALL_SCALE = 0.5
const SMALL_GAP = 0.1

export default defineComponent({
  data() {
    return {
      showAll: false,
      collapsing: false,
      replacing: false,
      selectedIndex: null,
      dragging: false,
      draggingTimer: null,
      config,
    }
  },
  computed: {
    ...mapState(['informationModules']),
    infoModuleStyle() {
      return this.informationModules.map((_, index) => {
        return this.showAll
          ? {
              transform: `translateX(${index * 100 * SMALL_SCALE * (1 + SMALL_GAP)}%)`,
              zIndex: this.collapsing && index === this.selectedIndex ? 2000 : 1000 + index,
              backfaceVisibility: 'hidden',
            }
          : {}
      })
    },
    infoBgStyle() {
      return {
        width: this.showAll
          ? `${(this.informationModules.length * SMALL_SCALE + (1 - SMALL_SCALE) + (this.informationModules.length - 1) * (SMALL_SCALE * SMALL_GAP)) * 100}%`
          : '100%',
      }
    },
    scrollPosition() {
      return (this.selectedIndex / (this.informationModules.length - 1)) * (this.$refs.container.scrollWidth - this.$refs.container.clientWidth)
    },
  },
  watch: {
    showAll(value) {
      this.$nextTick(() => {
        if (value && this.$refs.container) {
          this.$refs.container.scrollLeft = this.scrollPosition
        }
      })
    },
    'informationModules.length'(n, o) {
      if (n === 0) {
        this.showAll = false
      } else {
        this.selectedIndex = n - 1
      }
      // if this is first info module, hide other active modules
      if (n === 1 && o === 0) {
        this.setActiveModules([])
      }
    },
  },
  mounted() {
    this.$emitter.on(FunctionEnum.INFORMATION, this.informationHandler)
    this.$emitter.on(FunctionEnum.TRIP_RECOMMENDATIONS, this.recommendationsHandler)
  },
  unmounted() {
    this.$emitter.off(FunctionEnum.INFORMATION, this.informationHandler)
    this.$emitter.off(FunctionEnum.TRIP_RECOMMENDATIONS, this.recommendationsHandler)
  },
  methods: {
    ...mapActions(['addToInformationModules', 'setInformationModules', 'setActiveModules']),
    informationHandler({ toolCallId, ...params }) {
      // determine if function should be queued
      if (shouldFunctionBeQueued({ event_name: FunctionEnum.INFORMATION, params: { toolCallId, ...params }, store: this.$store })) {
        return
      }

      // announce that UI is being shown (if it's not recommendations)
      if (!(params?.priority ?? []).includes(InformationTypeEnum.RECOMMENDATION)) {
        this.$store.dispatch('assistant/sendMessage', {
          role: MessageRoleEnum.SYSTEM,
          content: `The user has been shown the results of the ${FunctionEnum.INFORMATION} tool call with id ${toolCallId}, so you may now reference that content.`,
        })
      }

      // add module to store
      this.addToInformationModules({ id: uniqid(), toolCallId, ...params })
    },
    recommendationsHandler({ toolCallId, ...params }) {
      // add new information module with priority 'recommendations'
      this.informationHandler({ toolCallId, ...params, priority: [InformationTypeEnum.RECOMMENDATION] })
    },
    onClickNav(index) {
      if (this.showAll) {
        this.toggle(index)
      } else {
        this.toggle(this.selectedIndex)
      }
    },
    onClickModule(index) {
      if (!this.showAll) return
      this.toggle(index)
    },
    toggle(index) {
      if (this.showAll) {
        this.selectedIndex = index
        gsap.to(this.$refs.container, {
          scrollTo: { x: this.scrollPosition },
          duration: 0.5,
          ease: 'circ.inOut',
          onComplete: () => {
            this.collapsing = true
          },
        })
      } else if (this.informationModules.length > 1) {
        this.selectedIndex = index
        this.showAll = true
      }
    },
    onTransitionEnd() {
      if (this.collapsing) {
        this.showAll = false
        this.$nextTick(() => {
          this.collapsing = false
        })
      }
    },
    startDragging() {
      this.draggingTimer = window.setTimeout(() => (this.dragging = true), 100)
    },
    endDragging() {
      if (this.draggingTimer) {
        clearTimeout(this.draggingTimer)
      }
      window.setTimeout(() => (this.dragging = false))
    },
    clickDragging(event: MouseEvent) {
      if (!this.dragging) return
      event.stopPropagation()
    },
  },
})
</script>

<style scoped>
.screen {
  transition-timing-function: var(--ease-in-out-circ);
}

.fullscreen-enter-active {
  transition: all 0.5s var(--ease-in-out-circ);
}

.fullscreen-leave-active {
  transition: all 0.5s var(--ease-in-out-circ);
}

.fullscreen-enter-from,
.fullscreen-leave-to {
  opacity: 0;
  transform: scale(0.97);
}
</style>
