<template>
  <div class="flex flex-col items-center justify-center flex-auto gap-6">
    <Logo class="w-40 text-fox-orange mb-4" />
    <h1 class="font-serif text-7xl tracking-tight">Planning trips is hard.</h1>
    <h2 class="text-3xl font-medium text-blue-violet">But it doesn’t have to be!</h2>
    <AnimatedGlobe class="w-40 pb-4 -mb-6" />
    <h3 class="max-w-sm text-2xl text-center text-balance leading-tight tracking-tight">
      <span class="font-semibold">TravelFox.</span>
      The easiest way to plan a trip.
    </h3>
    <transition name="fade" mode="out-in">
      <div v-if="isAuthenticated">
        <Button @click="start">Get Started</Button>
      </div>
      <div v-else>
        <GoogleLogin :callback="login" prompt auto-login />
        <!--
      <GoogleLogin :callback="login" prompt auto-login>
        <Button light>
          <GoogleG />
          Sign in with Google
        </Button>
      </GoogleLogin>
      --></div>
    </transition>
    <div v-if="appVersion" class="absolute opacity-50 bottom-6 text-center">
      <div>
        v{{ appVersion }}
        <span v-if="env" class="text-sm">{{ env }}</span>
      </div>
      <div class="text-xs">Patent pending</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import GoogleG from '@/assets/google-g.svg'

// @ts-ignore
const appVersion = APP_VERSION // from package.json
const env = import.meta.env.VITE_ENV

export default defineComponent({
  components: { GoogleG: GoogleG as any },
  data() {
    return {
      appVersion,
      env: env !== 'production' ? env : null,
    }
  },
  computed: {
    ...mapState('auth', ['isAuthenticated']),
  },
  methods: {
    ...mapActions('auth', ['login']),
  },
})
</script>
