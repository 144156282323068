<template>
  <div v-if="title || subtitle" class="flex flex-col gap-2">
    <IconSparkles class="w-8 h-8 text-sky-300" stroke-width="1.5" />
    <div
      v-if="title"
      class="font-serif leading-none text-white"
      :class="{ 'text-4xl': titleWords <= 2, 'text-3xl': titleWords > 2 && titleWords <= 4, 'text-2xl': titleWords > 4 }">
      {{ title }}
    </div>
    <div v-if="subtitle" class="text-lg font-medium slate-500 leading-tight">{{ subtitle }}</div>
  </div>
  <div v-if="questions.length" class="flex flex-col gap-4">
    <div class="text-violet-300 font-medium text-lg">Travelers also ask</div>
    <div v-disabled="isAssistantBusy" class="flex flex-col gap-3 text-blue-violet">
      <div
        v-for="question in questions"
        :key="question"
        class="text-sm leading-tight cursor-pointer text-white hover:text-violet-300 transition"
        @click="sendRelatedQuestion(question)">
        &ldquo;{{ question }}&rdquo;
      </div>
    </div>
  </div>
  <div v-if="options.length" class="flex flex-col gap-4">
    <div class="text-violet-300 font-medium text-lg">Explore</div>
    <div v-disabled="isAssistantBusy" class="grid grid-cols-2 gap-2 text-slate-600">
      <div
        v-for="option in options"
        :key="option.title"
        :class="[option.color]"
        class="col-span-1 py-4 px-2 flex flex-col items-center justify-center rounded-xl text-sm cursor-pointer transition text-center leading-none gap-2"
        @click="sendOptionMessage(option.message)">
        <component :is="option.icon" class="w-6 h-6 mix-blend-hard-light" stroke-width="1.5" />
        {{ option.title }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { InformationCategoryEnum, MessageRoleEnum } from '@/enums'
import { IconSparkles, IconVideo, IconMap, IconX, IconBuildingCarousel, IconPlane } from '@tabler/icons-vue'

export default defineComponent({
  components: {
    IconSparkles,
    IconX,
    IconVideo,
    IconMap,
    IconBuildingCarousel,
    IconPlane,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    category: {
      type: String,
      default: InformationCategoryEnum.DESTINATION,
    },
    questions: {
      type: Array,
      default: () => [],
    },
    breakpoint: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    titleWords() {
      return this.title.split(' ').length
    },
    options() {
      switch (this.category) {
        case InformationCategoryEnum.DESTINATION:
          return [
            { title: 'See a video', icon: 'IconVideo', color: 'bg-emerald-200', message: `The user would like to see a video about ${this.title}` },
            { title: 'Look at a map', icon: 'IconMap', color: 'bg-fuchsia-200', message: `The user would like to see a map of ${this.title}` },
            {
              title: 'See attractions',
              icon: 'IconBuildingCarousel',
              color: 'bg-blue-200',
              message: `The user would like to see attractions in ${this.title}`,
            },
            { title: 'Find trips', icon: 'IconPlane', color: 'bg-amber-300', message: `The user would like to find trips in ${this.title}` },
          ]
        case InformationCategoryEnum.MAP:
          return [
            {
              title: 'See attractions',
              icon: 'IconBuildingCarousel',
              color: 'bg-blue-200',
              message: `The user would like to see attractions in ${this.title}`,
            },
          ]
      }
      return []
    },
  },
  methods: {
    ...mapActions('assistant', ['sendMessage']),
    sendRelatedQuestion(question: string) {
      this.sendMessage({
        role: MessageRoleEnum.USER,
        content: question,
      })
      this.toggle()
    },
    sendOptionMessage(message: string) {
      this.sendMessage({
        role: MessageRoleEnum.SYSTEM,
        content: message,
      })
      this.toggle()
    },
  },
})
</script>
