import { App, Plugin } from 'vue'

export default {
  install(app: App) {
    app.config.globalProperties.$filters = {
      number(value: number): string {
        return Number(value).toLocaleString()
      },
      percentage(value: number): string {
        return `${Math.round(value * 100)}%`
      },
      time(value: number): string {
        return (
          Math.floor(value / 60) +
          'm ' +
          Math.round(value % 60)
            .toString()
            .padStart(2, '0') +
          's'
        )
      },
      capitalize(value: string): string {
        return (value && value[0].toUpperCase() + value.slice(1)) || ''
      },
      camelToWords(value: string): string {
        return value.replace(/([a-z])([A-Z])/g, '$1 $2')
      },
    }
  },
} as Plugin
