import { App, defineAsyncComponent } from 'vue'
import { Store } from 'vuex'
import { Router } from 'vue-router'
import Module from '@/modules/module'
import dayjs from 'dayjs'
import { FunctionEnum } from '@/enums'
import uniqid from 'uniqid'
import { apiFetchWithTripId } from '@/libs/api'

const title = 'Dates'

export default ({ name, store, app }: { name: string; router?: Router; store: Store; app: App }) => {
  // create module
  const module = new Module({ name, title, store, app })

  // register event handlers
  module.registerEventHandlers([
    {
      event_name: FunctionEnum.TRAVEL_DATES,
      wordSequences: [
        ['when', 'planning', 'travel'],
        ['when', 'planning', 'go'],
        ['travel', 'dates'],
        ['specific', 'dates', 'mind'],
        ['when', 'thinking', 'traveling'],
      ],
      defaultParams: {
        departure_date: dayjs().add(1, 'week').format('YYYY-MM-DD'),
        return_date: dayjs().add(2, 'week').format('YYYY-MM-DD'),
        show_calendar: false,
      },
      confirmHandler: async ({ departure_date, return_date }) => {
        // send to server
        const trip = await apiFetchWithTripId('/trips', {
          id: uniqid(),
          function: {
            name: FunctionEnum.TRAVEL_DATES,
            arguments: {
              departure_date,
              return_date,
            },
          },
        })
        store.dispatch('user/addTrip', trip)

        return `The user has confirmed that they will be traveling from ${dayjs(departure_date).format('dddd MMMM D, YYYY')} to ${dayjs(return_date).format('dddd MMMM D, YYYY')}`
      },
      inferredHandler: ({ departure_date, return_date }) => {
        return `Can we confirm the travel dates of ${dayjs(departure_date).format('dddd MMMM D, YYYY')} to ${dayjs(return_date).format('dddd MMMM D, YYYY')} with the user?`
      },
      removeHandler: () => {
        return `Please forget about the travel dates the user mentioned earlier`
      },
      requestHandler: () => {
        return `The user would like to talk about their travel dates`
      },
      portfolioComponent: defineAsyncComponent(() => import('./components/Portfolio.vue')),
    },
  ])

  // add module store
  module.addModuleStore({
    state: {
      // ...
    },
    actions: {
      // gets called when the module is first accessed
      async onModuleAccessed() {
        if (store.getters.location) {
          // show some message ideas
          app.config.globalProperties.$emitter.emit(FunctionEnum.NEXT_MESSAGE_IDEAS, {
            ideas: [`📆 Tell me the best time of year to go`],
          })
        }
      },
    },
    mutations: {
      // ...
    },
  })
}
