import api from '@/services/api'
import { App, Plugin } from 'vue'
import { AxiosInstance } from 'axios'

// create global shortcut to axios instance

export default {
  install(app: App) {
    app.config.globalProperties.$http = api
  },
} as Plugin

declare module 'vue' {
  interface ComponentCustomProperties {
    $http: AxiosInstance
  }
}
