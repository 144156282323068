<script setup lang="ts">
import { ScratchPadItemTypeEnum } from '@/enums'
</script>

<template>
  <div v-if="item.type === ScratchPadItemTypeEnum.LINK" class="group flex items-center justify-between gap-4">
    <div>
      <a :href="item.data.url" target="_blank" class="block text-blue-violet hover:underline leading-tight">{{ item.data.title }}</a>
      <span class="text-sm text-slate-400">{{ cleanUrl(item.data.url) }}</span>
    </div>
    <PinButton :item="item" class="transition opacity-0 group-hover:opacity-100" />
  </div>
  <Gallery v-else-if="item.type === ScratchPadItemTypeEnum.IMAGE" v-slot="{ gallery }">
    <Image :src="item.data.src" :caption="item.data.description" class="aspect-square rounded-2xl" :gallery="gallery" />
  </Gallery>
  <Video v-else-if="item.type === ScratchPadItemTypeEnum.VIDEO" :can-save="true" :src="item.data.src" class="rounded-xl" />
  <div
    v-else-if="item.type === ScratchPadItemTypeEnum.ATTRACTION"
    class="relative z-10 aspect-video flex flex-col justify-end p-4 cursor-pointer"
    @click="setOverlay(attractionId)">
    <PinButton :item="item" class="absolute top-2 right-2 z-10" />
    <div v-if="item.data.title" class="relative z-10 bg-white px-2 py-2 text-sm rounded-2xl self-start text-balance leading-none">{{ item.data.title }}</div>
    <Image :src="item.data.image" class="!absolute inset-0 rounded-2xl text-blue-violet" />
    <AttractionOverlay :id="attractionId" :attraction="item.data.original" />
  </div>
  <div
    v-else-if="item.type === ScratchPadItemTypeEnum.ACTIVITY"
    class="relative aspect-video flex flex-col justify-end p-4 cursor-pointer"
    @click="setOverlay(activityId)">
    <PinButton :item="item" class="absolute top-2 right-2 z-10" />
    <div v-if="item.data.title" class="relative z-10 bg-white px-2 py-2 text-sm rounded-2xl self-start text-balance leading-none">{{ item.data.title }}</div>
    <Image :src="item.data.image" class="!absolute inset-0 rounded-2xl text-blue-violet" />
    <ActivityOverlay :id="activityId" :activity="item.data.original" />
  </div>
  <div
    v-else-if="item.type === ScratchPadItemTypeEnum.TRIP"
    class="relative aspect-video flex flex-col justify-end p-4 cursor-pointer"
    @click="setOverlay(tripId)">
    <PinButton :item="item" class="absolute top-2 right-2 z-10" />
    <div v-if="item.data.title" class="relative z-10 bg-white px-2 py-2 text-sm rounded-2xl self-start text-balance leading-none">{{ item.data.title }}</div>
    <Image :src="item.data.image" class="!absolute inset-0 rounded-2xl text-blue-violet" />
    <TripOverlay :id="tripId" :trip="item.data.original" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import uniqid from 'uniqid'

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      uniqid: uniqid(),
    }
  },
  computed: {
    attractionId() {
      return `attraction-${this.uniqid}-${this.item.id}`
    },
    activityId() {
      return `activity-${this.uniqid}-${this.item.id}`
    },
    tripId() {
      return `trip-${this.uniqid}-${this.item.id}`
    },
  },
})
</script>
