<template>
  <div v-if="trips.length === 0" class="flex-auto flex flex-col gap-2 items-center justify-center bg-gradient-to-br from-white to-sky-50 rounded-4xl">
    <IconBeach class="w-12 h-12 text-sky-300" stroke-width="1.5" />
    <div class="text-slate-500 font-light text-xl">Your next trip awaits</div>
  </div>
  <div v-else class="relative p-4 pt-0 w-full min-h-[40vh] max-h-[60vh] bg-sky-50 overflow-auto flex flex-col rounded-4xl divide-y divide-sky-100">
    <div class="sticky top-0 z-10 pt-5 pb-4 bg-gradient-to-b from-sky-50 via-sky-50 to-transparent flex items-center justify-between">
      <div class="font-serif text-blue-violet text-2xl">My Trips</div>
      <MiniButton type="bold" @click="create">Start new trip</MiniButton>
    </div>
    <TransitionGroupList>
      <div v-for="trip in trips" :key="trip.id">
        <div class="group/trip relative flex items-center gap-4 py-3">
          <IconMapPinFilled
            class="w-16 h-16 p-4 rounded-full transition-all duration-500"
            :class="{ 'text-white bg-emerald-400': trip.id === currentTripId, 'bg-white text-blue-500': trip.id !== currentTripId }" />
          <div class="flex-1 flex flex-col items-start gap-0.5">
            <div class="font-medium text-blue-violet cursor-pointer flex items-center flex-wrap gap-4" @click="load(trip.id)">
              {{ getTripTitle(trip) }}
            </div>
            <div
              v-if="(trip.departure_date && trip.return_date) || trip.travelers || trip.id === currentTripId"
              class="text-sm text-slate-400 flex items-center flex-wrap gap-4">
              <div v-if="trip.id === currentTripId" class="text-xs bg-emerald-400 text-white leading-none py-1 px-2 whitespace-nowrap rounded-full">
                Planning
              </div>
              <div v-if="trip.departure_date && trip.return_date" class="flex items-center gap-1.5">
                <IconCalendarMonth class="w-4 h-4" />
                {{ formatTripDates(trip.departure_date, trip.return_date, 'MMM') }}
              </div>
              <div v-if="trip.travelers" class="flex items-center gap-1.5">
                <IconManFilled class="w-4 h-4" />
                {{ getTravelersLabel(trip.travelers) }}
              </div>
            </div>
          </div>
          <div
            class="absolute z-10 right-2 top-1/2 -translate-y-1/2 flex items-center gap-2 opacity-0 pointer-events-none transition group-hover/trip:opacity-100 group-hover/trip:pointer-events-auto">
            <MiniButton type="delete" @click="del(trip.id)">
              <IconX />
            </MiniButton>
            <MiniButton v-if="trip.id !== currentTripId" type="alert" @click="load(trip.id)">
              <IconSwitchHorizontal />
            </MiniButton>
          </div>
        </div>
      </div>
    </TransitionGroupList>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Trip } from '@/types'
import { mapState, mapActions } from 'vuex'
import { IconMapPinFilled, IconX, IconSwitchHorizontal, IconBeach, IconCalendarMonth, IconManFilled } from '@tabler/icons-vue'
import { getTravelersLabel } from '@/modules/ChooseTravelers'

export default defineComponent({
  components: {
    IconMapPinFilled,
    IconX,
    IconSwitchHorizontal,
    IconBeach,
    IconCalendarMonth,
    IconManFilled,
  },
  data() {
    return {
      imageError: false,
      getTravelersLabel,
    }
  },
  computed: {
    ...mapState('user', ['trips', 'currentTripId']),
  },
  methods: {
    ...mapActions('user', ['deleteTrip', 'loadTrip', 'createTrip']),
    async del(tripId: number) {
      if (tripId === this.currentTripId) {
        this.showToastMessage('Ready to plan', 1200)
        await this.deleteTrip(tripId)
        this.setOverlay(null)
      } else {
        await this.deleteTrip(tripId)
      }
    },
    async load(tripId: number) {
      this.loadTrip(tripId)
      this.showToastMessage('Trip loaded', 1200)
      this.setOverlay(null)
    },
    async create() {
      this.showToastMessage('Ready to plan', 1200)
      await this.createTrip()
      this.setOverlay(null)
    },
    getTripTitle(trip: Trip) {
      if (trip.title && trip.title !== 'null') {
        return trip.title
      } else if (trip.location) {
        return `Trip to ${trip.location}`
      }
      return 'Untitled trip'
    },
  },
})
</script>
