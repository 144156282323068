<template>
  <div class="bg-emerald-200/90 inline-flex items-center justify-center gap-2 py-2 px-5 rounded-full">
    <IconSun class="w-6 h-6 inline-flex shrink-0 -ml-2 text-amber-500" />
    <span class="text-sm text-emerald-700 inline-flex flex-auto">Expect sunny weather around 25&deg;C</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { IconSun } from '@tabler/icons-vue'
//https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/Ottawa/2024-07-16/2024-07-19?unitGroup=metric&include=day&key=C84CGQR4A2VGQKSUZE8M8ZXJ8&contentType=json

export default defineComponent({
  components: {
    IconSun,
  },
  props: {
    location: {
      type: String,
      required: true,
    },
    departure_date: {
      type: String,
    },
    return_date: {
      type: String,
    },
  },
})
</script>
