export default {
  namespaced: true,
  state: {
    loading: false,
    message: '',
    requestsPending: 0,
  },
  actions: {
    show({ commit }, value: string) {
      commit('show', value)
    },
    hide({ commit }) {
      commit('hide')
    },
    pending({ commit }, value: string) {
      commit('pending', value)
    },
    done({ commit }) {
      commit('done')
    },
  },
  mutations: {
    show(state, value) {
      if (value) state.message = value
      state.loading = true
    },
    hide(state) {
      state.message = ''
      state.loading = false
    },
    pending(state, value) {
      if (state.requestsPending === 0) {
        // @ts-ignore
        this.commit('loader/show', value)
      }
      state.requestsPending++
    },
    done(state) {
      if (state.requestsPending >= 1) {
        state.requestsPending--
      }
      if (state.requestsPending <= 0) {
        // @ts-ignore
        this.commit('loader/hide')
      }
    },
  },
}
