import { App, defineAsyncComponent } from 'vue'
import { Store } from 'vuex'
import { Router } from 'vue-router'
import Module from '@/modules/module'
import { FunctionEnum } from '@/enums'
import uniqid from 'uniqid'
import { apiFetchWithTripId } from '@/libs/api'

const title = 'Destination'

export default ({ name, store, app }: { name: string; router?: Router; store: Store; app: App }) => {
  // create module
  const module = new Module({ name, title, store, app })

  // register event handlers
  module.registerEventHandlers([
    {
      event_name: FunctionEnum.TRIP_DESTINATION,
      wordSequences: [
        ['where', 'like', 'go'],
        ['where', 'planning', 'go'],
        ['where', 'planning', 'travel'],
      ],
      shownHandler({ location, lat_long }) {
        if (location) {
          return `The user has been shown their trip destination of ${location}. Wait for the user to confirm their destination before proceeding.`
        }
        return `The user has been asked for their destination. Wait for the user to confirm their destination before proceeding.`
      },
      confirmHandler: async ({ location, lat_long }) => {
        // send to server
        const trip = await apiFetchWithTripId('/trips', {
          id: uniqid(),
          function: {
            name: FunctionEnum.TRIP_DESTINATION,
            arguments: {
              lat_long,
              location,
            },
          },
        })
        store.dispatch('user/addTrip', trip)

        // system message
        return `The user has confirmed that they will be traveling to ${location}`
      },
      inferredHandler: ({ location, lat_long }) => {
        return `Can we confirm ${location} as the user's destination?`
      },
      removeHandler: () => {
        return `Please forget about the trip destination the user mentioned earlier`
      },
      requestHandler: () => {
        return `The user would like to talk about their trip destination`
      },
      portfolioComponent: defineAsyncComponent(() => import('./components/Portfolio.vue')),
    },
  ])

  // add module store
  module.addModuleStore({
    state: {
      // ...
    },
    actions: {
      // gets called when the module is first accessed
      async onModuleAccessed({ commit }) {
        // show some message ideas
        app.config.globalProperties.$emitter.emit(FunctionEnum.NEXT_MESSAGE_IDEAS, {
          ideas: ['📸 Show me some pictures', '🍿 Can I see a video?', `🤔 Show me what's it like there`],
        })
      },
    },
    mutations: {
      // ...
    },
  })
}
