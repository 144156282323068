<template>
  <transition-group name="responses" tag="nav" class="flex items-center gap-2" @after-leave="switchResponses">
    <MiniButton v-for="response in internalResponses" :key="`${response} ${uniqid()}`" type="light" @click="choose(response)">
      {{ response }}
    </MiniButton>
  </transition-group>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import uniqid from 'uniqid'

export default defineComponent({
  props: {
    responses: {
      type: Array as () => string[],
      required: true,
    },
    expiry: {
      type: Number,
      default: 5000,
    },
  },
  emits: ['choose', 'showing'],
  data() {
    return {
      uniqid,
      responsesCanBeReplaced: true,
      internalResponses: [] as string[],
      nextResponses: [] as string[],
      responsesTimeout: 0,
    }
  },
  computed: {
    ...mapGetters(['showSuggestedResponses']),
  },
  watch: {
    responses: {
      immediate: true,
      handler(responses: string[]) {
        if (!this.showSuggestedResponses) return

        if (this.responsesCanBeReplaced) {
          if (responses.length > 0) {
            // responses are not empty
            this.responsesCanBeReplaced = false
          }
          if (responses.length > 0 && this.internalResponses.length > 0) {
            // remove current responses, queue up next responses
            this.internalResponses = []
            this.nextResponses = responses
          } else {
            // switch to new responses
            this.internalResponses = responses
          }
          this.responsesTimeout = window.setTimeout(() => {
            this.responsesCanBeReplaced = true
          }, this.expiry)
        }
      },
    },
    internalResponses() {
      this.$emit('showing', this.internalResponses.length > 0)
    },
    showSuggestedResponses() {
      if (!this.showSuggestedResponses) {
        this.internalResponses = []
      }
    },
    isCallActive() {
      if (!this.isCallActive) {
        this.internalResponses = []
      }
    },
  },
  methods: {
    switchResponses() {
      if (this.nextResponses.length > 0) {
        this.internalResponses = this.nextResponses
        this.nextResponses = []
        clearTimeout(this.responsesTimeout)
      }
    },
    choose(response: string) {
      this.$emit('choose', response)
      this.internalResponses = []
      this.nextResponses = []
      this.responsesCanBeReplaced = true
      clearTimeout(this.responsesTimeout)
    },
  },
})
</script>

<style scoped>
.responses-enter-active {
  transition: all 0.35s var(--ease-out-cubic);
}

.responses-leave-active {
  transition: all 0.25s var(--ease-in-cubic);
}

.responses-enter-from,
.responses-leave-to {
  opacity: 0;
  transform: translateY(25%);
}
</style>
