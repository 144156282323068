import { MAX_NUM_INFO_MODULES } from '@/constants'
import { SidebarWidthEnum } from '@/enums'
import set from 'lodash/set'
import { State } from './index'

export default {
  // modules

  createModule(state: State, value) {
    state.modules.push(value)
  },
  updateModule(state: State, value) {
    const index = state.modules.findIndex((m) => m.name === value.name)
    if (index > -1) {
      set(state.modules, index, { ...state.modules[index], ...value })
    }
  },

  // active modules

  setActiveModules(state: State, value) {
    state.activeModules = value
    state.numModulesDisplayed += value.length
  },
  addActiveModule(state: State, value) {
    // only allow one instance of each module to be active
    if (!state.activeModules.map((m) => m.name).includes(value.name)) {
      state.activeModules.push(value)
      state.numModulesDisplayed++
    } else {
      // update current active module
      const index = state.activeModules.findIndex((m) => m.name === value.name)
      if (index > -1) {
        set(state.activeModules, index, { ...state.activeModules[index], ...value })
      }
    }
  },
  updateActiveModule(state: State, value) {
    if (value?.name) {
      const index = state.activeModules.findIndex((m) => m.name === value.name)
      if (index > -1) {
        set(state.activeModules, index, { ...state.activeModules[index], ...value })
      }
    }
  },
  removeActiveModule(state: State, value) {
    const index = state.activeModules.findIndex((m) => m.name === value)
    if (index > -1) {
      state.activeModules.splice(index, 1)
    }
  },
  removeAllOtherActiveModules(state: State, value) {
    state.activeModules = state.activeModules.filter((m) => m.name === value)
  },

  // function queue

  addToFunctionQueue(state: State, value) {
    state.functionQueue.push(value)
  },
  runNextQueuedFunction(state: State) {
    console.log('runNextQueuedFunction', JSON.parse(JSON.stringify(state.functionQueue)))
    if (state.functionQueue.length > 0) {
      // first function in queue
      let nextFunction = state.functionQueue.shift()
      // check if there are newer functions of the same name in the queue
      const newerNextFunctions = state.functionQueue.filter((f) => f.name === nextFunction?.name)
      if (newerNextFunctions.length > 0) {
        nextFunction = newerNextFunctions.pop()
        state.functionQueue = state.functionQueue.filter((f) => f.name !== nextFunction?.name)
      }
      console.log('nextFunction', JSON.parse(JSON.stringify(nextFunction)))
      this.$emitter.emit(nextFunction?.name, nextFunction?.params)
    }
  },
  clearFunctionQueue(state: State) {
    state.functionQueue = []
  },

  // abort controllers

  addAbortController(state: State, value) {
    state.abortControllers.push(value)
  },
  clearAbortController(state: State, value) {
    state.abortControllers = state.abortControllers.filter((s) => s.controllerId !== value)
  },
  abortAllAbortControllers(state: State) {
    state.abortControllers.forEach((s) => s.abortController.abort())
    state.abortControllers = []
  },

  // api error

  setApiError(state: State, value) {
    state.apiError = value
  },

  // portfolio

  setPortfolioSummary(state: State, value) {
    state.portfolioSummary = value
  },
  setCustomPortfolioSummary(state: State, value) {
    state.customPortfolioSummary = value
  },
  setPortfolioModules(state: State, value) {
    state.portfolioModules = value
  },
  addToPortfolioModules(state: State, value) {
    // only allow one of each module in the portfolio
    if (state.portfolioModules.find((m) => m.name === value.name)) {
      const index = state.portfolioModules.findIndex((m) => m.name === value.name)
      set(state.portfolioModules, index, { ...state.portfolioModules[index], ...value })
    } else {
      state.portfolioModules.push(value)
    }
  },
  updateInformationModule(state: State, value) {
    const index = state.informationModules.findIndex((m) => m.id === value.id)
    if (index > -1) {
      set(state.informationModules, index, { ...state.informationModules[index], ...value })
    }
  },
  removeFromPortfolioModules(state: State, value) {
    const index = state.portfolioModules.findIndex((m) => m.name === value)
    if (index > -1) {
      state.portfolioModules.splice(index, 1)
    }
  },

  // completed onboarding steps

  incrementOnboardingStepsCompleted(state: State) {
    state.onboardingStepsCompleted++
  },

  // ui

  setSidebar(state: State, value: boolean) {
    state.isSidebarOpen = value
  },
  setSidebarLocked(state: State, value: boolean) {
    state.isSidebarLocked = value
  },
  setSidebarTab(state: State, value: string) {
    state.sidebarTab = value
  },
  setFirstItemAddedToSidebar(state: State, value: boolean) {
    state.firstItemAddedToSidebar = value
  },
  setSidebarDetailsPanelShown(state: State, panel: string) {
    state.sidebarDetailsPanelShown[panel] = true
  },
  setSidebarWidth(state: State, value: SidebarWidthEnum) {
    state.sidebarWidth = value
  },
  setOverlay(state, value) {
    if (value) {
      state.nestedOverlays.push(state.overlay)
      state.overlay = value
    } else {
      if (state.nestedOverlays.length) {
        state.overlay = state.nestedOverlays.pop()
      } else {
        state.overlay = value
      }
    }
  },
  setOverlayData(state, value) {
    state.overlayData = value
  },
  setToastMessage(state: State, value) {
    state.toastMessage = value
  },
  setTakeoverBackground(state: State, value) {
    state.takeoverBackground = value
  },
  setFullScreenModule(state: State, value) {
    if (!value && state.informationModules.length > 0) return // can't set to false if info modules exist
    state.isFullScreenModule = value
  },
  setFtuxModule(state: State, value) {
    state.isFtuxModule = value
  },
  setInformationModules(state: State, value) {
    state.informationModules = value
  },
  addToInformationModules(state: State, value) {
    if (state.informationModules.length >= MAX_NUM_INFO_MODULES) {
      state.informationModules.shift()
    }
    state.informationModules.push(value)
  },
  removeFromInformationModules(state: State, value) {
    const index = state.informationModules.findIndex((m) => m.id === value)
    if (index > -1) {
      state.informationModules.splice(index, 1)
    }
  },

  setSidebarDetailsPanel(state: State, value) {
    state.sidebarDetailsPanel = value
  },

  // weather forecast

  setWeatherForecast(state: State, value) {
    state.weatherForecast = value
  },
}
