import { Message, TranscriptMessage } from '@/types'
import {
  AssistantEnum,
  CallStatusEnum,
  ConversationRoleEnum,
  FunctionEnum,
  InputTypeEnum,
  MessageRoleEnum,
  MessageTypeEnum,
  ModuleEventEnum,
  TranscriptMessageTypeEnum,
} from '@/enums'
import { travelAssistant } from '@/assistants/travel.assistant'
import config from '@/config/index.json'
import messagesJson from '@/config/messages.json'
import { NUM_RECENT_MESSAGES } from '@/constants'
import Vapi from '@vapi-ai/web'
import { Store } from 'vuex'
import { State as RootState } from '@/store'
import uniqid from 'uniqid'

export interface State {
  instance: Vapi | null
  addMessageEnabled: boolean
  messages: Message[]
  activeMessage: TranscriptMessage | null
  messageQueue: Message[]
  skipWelcomeMessage: boolean
  resolvedToolCallIds: string[]
  functionCalls: object[]
  modelOutput: string[]
  callStatus: CallStatusEnum
  callDetails: { id: string }
  audioLevel: number
  volumeLevel: number
  isAssistantTalking: boolean
  isUserTalking: boolean
  waitingForAssistant: boolean // when assistant can't be interrupted
  waitingForResponse: boolean // when assistant is thinking
  isResuming: boolean
  callPaused: boolean
  callTimedOut: boolean
  callEnded: boolean
  inputMode: InputTypeEnum
}

declare module 'vue' {
  interface ComponentCustomProperties {
    messages: Message[]
    activeMessage: TranscriptMessage | null
  }
}

// helper functions

const getAssistantSession = (assistant: any, messages: Message[]) => {
  const session = { ...assistant }

  // fill in history if continuing a session

  if (session.model && session.model.messages) {
    for (const message of messages) {
      if (message.type !== MessageTypeEnum.TRANSCRIPT) continue
      session.model.messages.push({
        role: message.role,
        content: message.transcript,
      })
    }
  }

  return session
}

const setAssistantVolume = (volume) => {
  const audioEls = document.querySelectorAll('audio[data-participant-id]') as NodeListOf<HTMLAudioElement>
  audioEls.forEach((audioEl) => {
    audioEl.volume = volume
  })
}

const cleanUpTranscript = (transcript: string) => {
  const replacements = messagesJson?.transcript?.replacements ?? {}
  return Object.keys(replacements).reduce((f, s) => `${f}`.replace(new RegExp(s, 'ig'), replacements[s]), transcript)
}

const splitIntoFragments = (text: string) => {
  const words = text.split(' ')

  if (words.length > MAX_WORDS_PER_ASSISTANT_MESSAGE) {
    const sentences = text.match(/[^.!?]+[.!?]+/g) || [text]
    if (sentences.length > 1) {
      // multiple sentences
      return sentences
    } else {
      // only one sentence, split into fragments
      const fragments = []
      let currentFragment = ''
      for (const word of words) {
        if (currentFragment.split(' ').length >= MAX_WORDS_PER_ASSISTANT_MESSAGE) {
          fragments.push(currentFragment)
          currentFragment = ''
        }
        currentFragment += `${word} `
      }
      fragments.push(currentFragment)
      return fragments
    }
  }

  return [text]
}

// constants

const DELAY_PER_WORD = 350 // ms
const MAX_WORDS_PER_ASSISTANT_MESSAGE = 20
let fragmentTimeouts: number[] = []

// assistant store module

export default {
  namespaced: true,
  state: {
    instance: null,
    addMessageEnabled: true,
    messages: [],
    activeMessage: null,
    messageQueue: [],
    skipWelcomeMessage: false,
    resolvedToolCallIds: [],
    functionCalls: [],
    modelOutput: [],
    callStatus: CallStatusEnum.INACTIVE,
    callDetails: { id: '' },
    audioLevel: 0,
    volumeLevel: 1,
    isAssistantTalking: false,
    isUserTalking: false,
    waitingForResponse: false,
    waitingForAssistant: false,
    isResuming: false,
    callPaused: false,
    callTimedOut: false,
    callEnded: false,
    inputMode: InputTypeEnum.VOICE,
  } as State,
  actions: {
    async createAssistant(this: Store<RootState>, { commit, dispatch, state: { messages, instance, volumeLevel }, state }, { resuming } = { resuming: false }) {
      if (instance) {
        return // don't create a new assistant if one already exists
      }

      // reset everything (in case we're resuming from a paused state)

      commit('setCallStatus', CallStatusEnum.LOADING)
      commit('setResuming', resuming)

      // create assistant

      const vapi = new Vapi(import.meta.env.VITE_VAPI_WEB_TOKEN)

      // event listeners

      vapi.on('speech-start', () => {
        console.log('[vapi] speech-start')
        this.$emitter.emit(ModuleEventEnum.SPEECH_START)
        commit('setAssistantTalking', true)
        commit('setWaitingForResponse', false)
        commit('pushModelOutput')
      })

      vapi.on('speech-end', () => {
        console.log('[vapi] speech-end')
        this.$emitter.emit(ModuleEventEnum.SPEECH_END)
        commit('setAssistantTalking', false)
      })

      vapi.on('call-start', async () => {
        console.log('[vapi] call-start')

        // set call status and assistant volume

        commit('setCallStatus', CallStatusEnum.ACTIVE)

        this.$emitter.emit(ModuleEventEnum.CALL_START)
        setAssistantVolume(state.inputMode === InputTypeEnum.TEXT ? 0 : volumeLevel) // don't destructure inputMode here, won't work

        // queued messages (if any)
        if (!config.includeTripInAssistantConfig) {
          await dispatch('sendQueuedMessages')
        }

        // send messages based on whether we're resuming or not

        if (state.isResuming) {
          // resuming message
          dispatch('sendMessage', {
            role: MessageRoleEnum.SYSTEM,
            content: messagesJson.resuming.system,
          })

          // shown to user
          vapi.say(messagesJson.resuming.assistant)
          commit('addMessage', {
            role: MessageRoleEnum.ASSISTANT,
            transcript: messagesJson.resuming.assistant,
            type: MessageTypeEnum.TRANSCRIPT,
            transcriptType: TranscriptMessageTypeEnum.FINAL,
          })
        } else {
          if (!state.skipWelcomeMessage) {
            // welcome message
            commit('setSkipWelcomeMessage', false)
            commit('setWaitingForAssistant', true)

            // trigger FTUX suggestions after assistant finishes speaking
            this.$emitter.once(ModuleEventEnum.SPEECH_END, () => {
              commit('setWaitingForAssistant', false)
              this.$emitter.emit(ModuleEventEnum.FTUX, {
                ...messagesJson?.ftux,
              })
            })

            // use FTUX first message
            vapi.say(messagesJson.ftux.firstMessage)
            commit('addMessage', {
              role: MessageRoleEnum.ASSISTANT,
              transcript: messagesJson.ftux.firstMessage,
              type: MessageTypeEnum.TRANSCRIPT,
              transcriptType: TranscriptMessageTypeEnum.FINAL,
            })
          }
        }
      })

      vapi.on('call-end', () => {
        console.log('[vapi] call-end')
        commit('setCallStatus', CallStatusEnum.INACTIVE)
      })

      vapi.on('volume-level', (volume: number) => {
        commit('setAudioLevel', volume)
      })

      vapi.on('message', (message: Message) => {
        // transcript messages

        if (message.type === MessageTypeEnum.TRANSCRIPT) {
          if (message.role === MessageRoleEnum.USER) {
            commit('addMessage', message) // adds voice messages from user to the conversation
          }
        }

        // function calls (not being used anymore, but keeping for reference)

        if (message.type === MessageTypeEnum.FUNCTION_CALL) {
          console.log('[message: function]', message.functionCall)
          this.$emitter.emit(message.functionCall.name, { ...message.functionCall.parameters })
        }

        // tool calls

        if (message.type === MessageTypeEnum.TOOL_CALLS) {
          console.log('[message: tool-calls]', message.toolCallList)
          for (const toolCall of message.toolCallList) {
            if (toolCall.type === 'function' && toolCall.function) {
              // add onboarding step if not present
              if (toolCall.function.name === FunctionEnum.USER_ONBOARDING && !toolCall.function.arguments?.step) {
                console.error('No step param found in onboarding tool call!')
              }
              this.$emitter.emit(toolCall.function.name, { toolCallId: toolCall.id, ...toolCall.function.arguments })
              commit('addFunctionCall', toolCall)
            }
          }
        }

        // conversation updates

        if (message.type === MessageTypeEnum.CONVERSATION_UPDATE) {
          const toolEntries = message.conversation.filter(
            (entry) => entry?.role === ConversationRoleEnum.TOOL && entry?.tool_call_id && !state.resolvedToolCallIds.includes(entry.tool_call_id)
          )
          for (const entry of toolEntries) {
            if (entry.content && typeof entry.content === 'string' && entry.content.startsWith('{')) {
              // if content is json, emit event and mark resolved
              console.log('[message: conversation-update/json]', entry)
              try {
                this.$emitter.emit(AssistantEnum.TOOL_MESSAGE, entry)
                commit('addResolvedToolCallId', entry.tool_call_id)
              } catch (e) {
                console.error(e)
              }
            }
          }
        }

        // model output

        if (message.type === MessageTypeEnum.MODEL_OUTPUT && typeof message.output === 'string') {
          commit('addModelOutput', message.output)
        }
      })

      vapi.on('error', (e: any) => {
        console.error(e)
        if (e?.error?.type === 'no-room') {
          commit('setCallTimedOut', true)
        }
      })

      commit('setInstance', vapi)

      let travelAssistantSession = getAssistantSession(travelAssistant, [...messages])

      if (config.includeTripInAssistantConfig) {
        console.log('Include messages in assistant config', [...state.messageQueue])
        travelAssistantSession = getAssistantSession(travelAssistant, [...messages, ...state.messageQueue])
        travelAssistantSession.firstMessageMode = 'assistant-speaks-first-with-model-generated-message'
        commit('clearMessageQueue')
      }

      if (resuming) {
        delete travelAssistantSession.firstMessage // no first message if we're resuming
      }

      const response = await vapi.start(travelAssistantSession)
      commit('setCallDetails', response)

      this.$emitter.emit(ModuleEventEnum.ASSISTANT_STARTED, { resuming })
    },
    async sendMessage({ commit, state: { callStatus, instance, addMessageEnabled } }, message) {
      if (callStatus !== CallStatusEnum.ACTIVE) {
        console.log(`[queueMessage: ${message.role}]`, message.content)
        commit('addToMessageQueue', {
          role: message.role,
          transcript: message.content,
          type: MessageTypeEnum.TRANSCRIPT,
          transcriptType: TranscriptMessageTypeEnum.FINAL,
        })
        return
      }

      console.log(`[sendMessage: ${message.role}]`, message.content)

      if (message.role === MessageRoleEnum.USER) {
        commit('addMessage', {
          role: message.role,
          transcript: message.content || '',
          type: MessageTypeEnum.TRANSCRIPT,
          transcriptType: TranscriptMessageTypeEnum.FINAL,
        })
        this.$emitter.emit(ModuleEventEnum.ADD_USER_MESSAGE, message.content)
      }
      commit('setWaitingForResponse', true)

      // send message if adding user messages is enabled or it's a system message
      if (addMessageEnabled || message.role === MessageRoleEnum.SYSTEM) {
        await instance?.send({
          type: MessageTypeEnum.ADD_MESSAGE,
          message,
        })
      }
    },
    setInstance({ commit }, instance) {
      commit('setInstance', instance)
    },
    setAddMessageEnabled({ commit }, value) {
      commit('setAddMessageEnabled', value)
    },
    setAssistantTalking({ commit }, value) {
      commit('setAssistantTalking', value)
    },
    setUserTalking({ commit }, value) {
      commit('setUserTalking', value)
    },
    setWaitingForResponse({ commit }, value) {
      commit('setWaitingForResponse', value)
    },
    setWaitingForAssistant({ commit }, value) {
      commit('setWaitingForAssistant', value)
    },
    setCallStatus({ commit }, value) {
      commit('setCallStatus', value)
    },
    setAudioLevel({ commit }, value) {
      commit('setAudioLevel', value)
    },
    setVolumeLevel({ commit }, value) {
      commit('setVolumeLevel', value)
    },
    addMessage({ commit }, message) {
      commit('addMessage', message)
    },
    clearMessages({ commit }) {
      commit('clearMessages')
    },
    setModelOutput({ commit }, output) {
      commit('setModelOutput', output)
    },
    addModelOutput({ commit }, output) {
      commit('addModelOutput', output)
    },
    pushModelOutput({ commit }) {
      commit('pushModelOutput')
    },
    clearQueuedModelOutput({ commit }) {
      commit('clearQueuedModelOutput')
    },
    async endCall({ commit, state: { instance } }) {
      commit('setCallStatus', CallStatusEnum.INACTIVE)
      await instance?.stop()
      commit('setPaused', true)
      commit('setCallEnded', true)
    },
    async pause({ commit, state: { instance } }) {
      commit('setCallStatus', CallStatusEnum.LOADING)
      await instance?.stop()
      commit('setPaused', true)
    },
    async resume({ commit, dispatch }) {
      commit('setCallTimedOut', false)
      commit('setPaused', false)
      commit('setCallEnded', false)
      commit('destroyAssistant')
      await dispatch('createAssistant', { resuming: true })
    },
    async restart({ commit, state: { instance }, dispatch }) {
      commit('setCallStatus', CallStatusEnum.LOADING)
      await instance?.stop()
      commit('setCallTimedOut', false)
      commit('setCallEnded', false)
      commit('destroyAssistant')
      await dispatch('createAssistant')
    },
    setInputMode({ commit, state: { instance, volumeLevel } }, mode) {
      // mute mic if switching to text input
      if (instance?.started) {
        instance?.setMuted(mode === InputTypeEnum.TEXT)
      }
      // set assistant volume to 0 if switching to text input
      setAssistantVolume(mode === InputTypeEnum.TEXT ? 0 : volumeLevel)
      commit('setInputMode', mode)
    },
    addFunctionCall({ commit }, value) {
      commit('addFunctionCall', value)
    },
    getFunctionCall({ state }, toolCallId) {
      return state.functionCalls.find((f) => f.id === toolCallId)
    },

    // message queue

    queueMessage({ commit, state, dispatch }, message) {
      if (state.callStatus === CallStatusEnum.ACTIVE) {
        dispatch('sendMessage', message)
      } else {
        commit('addToMessageQueue', message)
      }
    },
    async sendQueuedMessages({ state, dispatch }) {
      if (state.messageQueue.length > 0) {
        const combinedMessagesByRole = state.messageQueue.reduce((acc, message) => {
          if (!acc[message.role]) {
            acc[message.role] = ''
          }
          acc[message.role] += `${message.transcript}\n\n`
          return acc
        }, {})
        for (const role of Object.keys(combinedMessagesByRole)) {
          await dispatch('sendMessage', {
            role,
            content: combinedMessagesByRole[role],
          })
        }
        dispatch('clearMessageQueue')
      }
    },
    clearMessageQueue({ commit }) {
      commit('clearMessageQueue')
    },

    setSkipWelcomeMessage({ commit }, value) {
      commit('setSkipWelcomeMessage', value)
    },
  },
  mutations: {
    setInstance(state, instance) {
      state.instance = instance
    },
    setAddMessageEnabled(state, value) {
      state.addMessageEnabled = value
    },
    setAssistantTalking(state, value) {
      state.isAssistantTalking = value
    },
    setUserTalking(state, value) {
      state.isUserTalking = value
    },
    setWaitingForResponse(state, value) {
      state.waitingForResponse = value
    },
    setWaitingForAssistant(state, value) {
      state.waitingForAssistant = value
    },
    setResuming(state, value) {
      state.isResuming = value
    },
    setCallStatus(state, value) {
      state.callStatus = value
    },
    setCallDetails(state, details) {
      state.callDetails = details
    },
    setAudioLevel(state, value) {
      state.audioLevel = value
    },
    setVolumeLevel(state, value) {
      state.volumeLevel = value
      setAssistantVolume(value)
    },
    addMessage(state, message) {
      if (
        message.transcriptType === TranscriptMessageTypeEnum.PARTIAL &&
        (state.messages.length === 0 || (state.messages.length && message.transcript !== state.messages[state.messages.length - 1].transcript)) &&
        message.transcript.split(' ').length >= config.minPartialMessageWords // must be min number of words to avoid transcript showing gibberish
      ) {
        state.activeMessage = message
      }
      if (message.transcriptType === TranscriptMessageTypeEnum.FINAL) {
        state.messages.push({ id: uniqid(), ...message, transcript: cleanUpTranscript(message.transcript) })
        state.activeMessage = null
      }
    },
    clearMessages(state) {
      state.messages = []
    },
    setModelOutput(state, output) {
      state.modelOutput = output
    },
    addModelOutput(state, output) {
      if (state.modelOutput.length === 0) {
        state.modelOutput.push('')
      }
      state.modelOutput[state.modelOutput.length - 1] += output
    },
    pushModelOutput(state) {
      const lastEntry = state.modelOutput[state.modelOutput.length - 1]
      if (!lastEntry || lastEntry === '') return

      state.modelOutput.push('')

      // clear existing timeouts (queued up fragments)
      fragmentTimeouts.forEach((timeout) => clearTimeout(timeout))
      fragmentTimeouts = []

      // add output to conversation as messages
      const fragments = splitIntoFragments(lastEntry)

      fragments.forEach((fragment, index) => {
        // delay each fragment based on number of words that preceded it
        const delay = fragments.slice(0, index).join(' ').split(' ').length * DELAY_PER_WORD
        fragmentTimeouts.push(
          window.setTimeout(() => {
            state.messages.push({
              id: uniqid(),
              role: MessageRoleEnum.ASSISTANT,
              transcript: cleanUpTranscript(fragment),
              type: MessageTypeEnum.TRANSCRIPT,
              transcriptType: TranscriptMessageTypeEnum.FINAL,
            })
            this.$emitter.emit(ModuleEventEnum.ADD_ASSISTANT_MESSAGE, fragment)
          }, delay)
        )
      })
    },
    clearQueuedModelOutput() {
      fragmentTimeouts.forEach((timeout) => clearTimeout(timeout))
      fragmentTimeouts = []
    },
    setPaused(state, value) {
      state.callPaused = value
    },
    setCallTimedOut(state, value) {
      state.callTimedOut = value
    },
    setCallEnded(state, value) {
      state.callEnded = value
    },
    setInputMode(state, mode) {
      state.inputMode = mode
    },
    destroyAssistant(state) {
      state.instance?.removeAllListeners()
      state.instance = null
    },
    addResolvedToolCallId(state, value) {
      state.resolvedToolCallIds.push(value)
    },
    addFunctionCall(state, value) {
      state.functionCalls.push(value)
    },

    // message queue

    addToMessageQueue(state: State, value) {
      state.messageQueue.push(value)
    },
    clearMessageQueue(state: State) {
      state.messageQueue = []
    },

    setSkipWelcomeMessage(state: State, value) {
      state.skipWelcomeMessage = value
    },
  },
  getters: {
    recentMessages(state: State) {
      return state.messages.slice(-NUM_RECENT_MESSAGES)
    },
    isMuted(state: State) {
      return state.inputMode === InputTypeEnum.TEXT // state.instance.isMuted() doesn't work
    },
    isCallActive(state: State) {
      return state.callStatus === CallStatusEnum.ACTIVE
    },
    isCallLoading(state: State) {
      return state.callStatus === CallStatusEnum.LOADING
    },
    isAssistantBusy(state: State) {
      return state.waitingForAssistant || state.waitingForResponse
    },
    isAssistantReady(state: State) {
      return !state.waitingForAssistant
    },
  },
}
