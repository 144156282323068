import Module from '@/modules/module'
import { Traveler } from '@/types'
import { FunctionEnum, TravelerTypeEnum } from '@/enums'
import { App, defineAsyncComponent } from 'vue'
import { Router } from 'vue-router'
import { Store } from 'vuex'
import uniqid from 'uniqid'
import { apiFetchWithTripId } from '@/libs/api'

const title = 'Travelers'

export const getTravelersLabel = (travelers: Array<Traveler>) => {
  const num_adults = travelers.filter((t) => t.traveler_type === TravelerTypeEnum.ADULT).length
  const num_children = travelers.filter((t) => t.traveler_type === TravelerTypeEnum.CHILD).length
  if (num_children) {
    return `${num_adults} adult${num_adults > 1 ? 's' : ''} and ${num_children} child${num_children > 1 ? 'ren' : ''}`
  }
  return `${num_adults} adult${num_adults > 1 ? 's' : ''}`
}

export default ({ name, store, app }: { name: string; router?: Router; store: Store; app: App }) => {
  // create module
  const module = new Module({ name, title, store, app })

  // load saved state
  const defaultTravelers = module.loadPreference('travelers') || [{ traveler_type: TravelerTypeEnum.ADULT }, { traveler_type: TravelerTypeEnum.ADULT }]

  // register event handlers
  module.registerEventHandlers([
    {
      event_name: FunctionEnum.TRAVELERS,
      wordSequences: [
        ['how', 'many', 'people'],
        ['children', 'ages'],
        ['traveling', 'with', 'you'],
        ['travelers', 'joining', 'you'],
        ['others', 'joining', 'you'],
        ['who', 'joining', 'you'],
      ],
      defaultParams: {
        travelers: defaultTravelers,
      },
      shownHandler({ travelers }) {
        return `The user has been shown that ${getTravelersLabel(travelers)} will be traveling. Wait for the user to confirm the travelers before proceeding.`
      },
      confirmHandler: async ({ travelers }) => {
        // send to server
        const trip = await apiFetchWithTripId('/trips', {
          id: uniqid(),
          function: {
            name: FunctionEnum.TRAVELERS,
            arguments: {
              travelers,
            },
          },
        })
        store.dispatch('user/addTrip', trip)

        // save preferences
        module.savePreference('travelers', travelers)

        // system message
        return `The user has confirmed that ${getTravelersLabel(travelers)} will be traveling`
      },
      inferredHandler: ({ travelers }) => {
        return `Can we confirm with the user that ${getTravelersLabel(travelers)} will be traveling?`
      },
      removeHandler: () => {
        module.savePreference('travelers', null)
        return `Please forget about the travelers the user mentioned earlier`
      },
      requestHandler: () => {
        return `The user would like to talk about who will be traveling`
      },
      preferencesHandler: () => {
        const travelers = module.loadPreference('travelers')
        if (travelers) {
          return `Last time ${getTravelersLabel(travelers)} were traveling, so ask the user if the same will be true this time.`
        }
        return ''
      },
      portfolioComponent: defineAsyncComponent(() => import('./components/Portfolio.vue')),
    },
  ])

  // add module store
  module.addModuleStore({
    state: {
      // ...
    },
    actions: {
      // ...
    },
    mutations: {
      // ...
    },
  })
}
