<template>
  <div :class="{ 'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2': absolute }">
    <div class="spinner border-current rounded-full border-l-transparent" :class="[`w-${size}`, `h-${size}`, `border-${stroke}`, easing]" />
    <div class="hidden w-2 h-2 w-3 h-3 w-4 h-4 w-5 h-5 w-6 h-6 w-7 h-7 w-8 h-8 w-12 h-12 w-16 h-16 w-24 h-24 w-32 h-32 border-2 border-4 border-6 border-8" />
  </div>
</template>

<script>
import { duration } from 'dayjs'

export default {
  props: {
    size: {
      type: Number,
      default: 8,
    },
    stroke: {
      type: Number,
      default: 2,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    easing: {
      type: String,
      default: '',
    },
    duration: {
      type: String,
      default: '0.45s',
    },
  },
}
</script>

<style scoped>
.spinner {
  animation: spin v-bind(duration) infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
