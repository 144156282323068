<template>
  <div class="flex pointer-events-none">
    <div class="flex flex-auto items-center justify-center gap-8">
      <transition-group name="slide" appear @before-leave="beforeGroupTransitionLeave">
        <PanelModule
          v-for="module in activePanelModules"
          v-slot="{ breakpoint }"
          :key="module.name"
          :data-module="module.name"
          :title="module.title"
          class="self-stretch flex-1 h-full transition-all duration-500 pointer-events-auto"
          :class="{
            'max-w-md': module.size === 'sm',
            'max-w-xl': module.size === 'md',
            'max-w-4xl': module.size === 'lg',
            'max-w-[85em]': module.size === 'xl',
          }"
          @close="removeActiveModule(module.name)">
          <component
            :is="module.name"
            v-bind="{ ...(module?.params ?? {}), breakpoint, name: module.name }"
            @close="removeActiveModule(module.name)"
            @update="(props) => update(module, props)" />
        </PanelModule>
      </transition-group>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

export default defineComponent({
  methods: {
    ...mapActions(['updateActiveModule']),
    update(module, props) {
      this.updateActiveModule({ name: module.name, ...props })
    },
  },
})
</script>

<style scoped>
.slide-move,
.slide-enter-active {
  transition: all 0.5s var(--ease-in-out-circ);
}

.slide-leave-active {
  transition: all 0.5s var(--ease-in-out-circ);
}

.slide-leave-active {
  position: absolute;
}

.slide-enter-from {
  opacity: 0;
  transform: translateY(2rem);
}

.slide-leave-to {
  opacity: 0;
  transform: scale(0.95);
}
</style>
