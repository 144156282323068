<template>
  <button
    class="inline-flex gap-3 rounded-full font-serif text-lg font-medium leading-tight tracking-tight hover:shadow-[0_6px_12px_0_rgba(0,0,0,0.25)] disabled:pointer-events-none disabled:opacity-30 transition-all duration-[400ms] ease-out-quad"
    :disabled="disabled"
    :class="[{ 'py-3 px-3': icon, 'py-4 px-6': !icon }, `type-${type}`, `layout-${layout}`]">
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      type: 'primary',
      layout: 'default',
    }
  },
  mounted() {
    if (this.secondary) {
      this.type = 'secondary'
    }
    if (this.light) {
      this.type = 'light'
    }
    if (this.icon) {
      this.layout = 'icon'
    }
  },
})
</script>

<style scoped>
.type-primary {
  @apply bg-gradient-to-r from-purple-600 via-purple-700 to-blue-violet-600 text-white bg-[length:200%] bg-right hover:bg-center;
}

.type-secondary {
  @apply bg-gradient-to-r from-slate-400 via-slate-400 to-slate-500 text-white bg-[length:150%] bg-right hover:bg-left;
}

.type-light {
  @apply bg-white text-blue-violet hover:bg-sky-100 hover:text-violet-400;
}

:not(.layout-icon) :deep(svg) {
  @apply w-6 h-6 opacity-75;
}

.layout-icon :deep(svg) {
  @apply w-8 h-8;
}
</style>
