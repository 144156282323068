<script setup lang="ts">
import { ScratchPadItemTypeEnum } from '@/enums'
</script>

<template>
  <Responsive v-slot="{ breakpoint }" :breakpoints="{ narrow: (el) => el.width <= 150 }" class="group/item relative flex items-center">
    <div v-if="item.type === ScratchPadItemTypeEnum.LINK" class="overflow-hidden">
      <a :href="item.data.url" target="_blank" class="block text-blue-violet hover:underline leading-tight" :class="{ 'text-sm': breakpoint.narrow }">
        {{ item.data.title }}
      </a>
      <span class="text-slate-400" :class="{ 'text-sm': !breakpoint.narrow, 'text-xs': breakpoint.narrow }">{{ cleanUrl(item.data.url) }}</span>
    </div>
    <Gallery v-else-if="item.type === ScratchPadItemTypeEnum.IMAGE" v-slot="{ gallery }">
      <Image
        :src="item.data.src"
        :caption="item.data.description"
        class="aspect-square"
        :class="{ 'w-24 rounded-2xl': !breakpoint.narrow, 'w-full rounded-lg': breakpoint.narrow }"
        :gallery="gallery" />
    </Gallery>
    <Video v-else-if="item.type === ScratchPadItemTypeEnum.VIDEO" :can-save="true" :src="item.data.src" class="rounded-xl" />
    <div
      v-else-if="item.type === ScratchPadItemTypeEnum.ATTRACTION"
      class="flex"
      :class="{ 'items-center gap-4': !breakpoint.narrow, 'flex-col gap-2': breakpoint.narrow }">
      <Image
        :src="item.data.image"
        class="shrink-0 aspect-video text-blue-violet cursor-pointer"
        :class="{ 'w-32 rounded-2xl': !breakpoint.narrow, 'w-full rounded-lg': breakpoint.narrow }"
        @click="setOverlay(attractionId)" />
      <div
        class="text-balance leading-none cursor-pointer"
        :class="{ 'text-lg': !breakpoint.narrow, 'text-sm': breakpoint.narrow }"
        @click="setOverlay(attractionId)">
        {{ item.data.title }}
      </div>
      <AttractionOverlay :id="attractionId" :attraction="item.data.original" />
    </div>
    <div
      v-else-if="item.type === ScratchPadItemTypeEnum.ACTIVITY"
      class="flex"
      :class="{ 'items-center gap-4': !breakpoint.narrow, 'flex-col gap-2': breakpoint.narrow }">
      <Image
        :src="item.data.image"
        class="shrink-0 aspect-video text-blue-violet cursor-pointer"
        :class="{ 'w-32 rounded-2xl': !breakpoint.narrow, 'w-full rounded-lg': breakpoint.narrow }"
        @click="setOverlay(activityId)" />
      <div class="flex flex-col gap-1">
        <div
          class="text-balance leading-none cursor-pointer"
          :class="{ 'text-lg': !breakpoint.narrow, 'text-sm line-clamp-3': breakpoint.narrow }"
          @click="setOverlay(activityId)">
          {{ item.data.title }}
        </div>
        <ActivityDuration
          :duration="item.data.original.duration"
          class="text-slate-500"
          :class="{ 'text-sm': !breakpoint.narrow, 'text-xs': breakpoint.narrow }" />
      </div>
      <ActivityOverlay :id="activityId" :activity="item.data.original" />
    </div>
    <a
      v-else-if="item.type === ScratchPadItemTypeEnum.TRIP"
      :href="item.data.url"
      target="_blank"
      class="flex"
      :class="{ 'items-center gap-4': !breakpoint.narrow, 'flex-col gap-2': breakpoint.narrow }">
      <Image
        :src="item.data.image"
        class="shrink-0 aspect-video text-blue-violet cursor-pointer"
        :class="{ 'w-32 rounded-2xl': !breakpoint.narrow, 'w-full rounded-lg': breakpoint.narrow }" />
      <div class="flex flex-col gap-1">
        <div class="text-balance leading-none cursor-pointer" :class="{ 'text-lg': !breakpoint.narrow, 'text-sm line-clamp-3': breakpoint.narrow }">
          {{ item.data.title }}
        </div>
      </div>
    </a>
    <IconX
      class="opacity-0 transition group-hover/item:opacity-100 absolute cursor-pointer"
      :class="{
        'top-1/2 right-0 -translate-y-1/2 text-red-400 w-5': !breakpoint.narrow,
        'top-0 right-0 -translate-y-1/2 translate-x-1/2 bg-red-400 text-white w-6 h-6 p-1 rounded-full': breakpoint.narrow,
      }"
      stroke-width="3"
      @click="$emit('remove')" />
  </Responsive>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import uniqid from 'uniqid'
import { IconX } from '@tabler/icons-vue'

export default defineComponent({
  components: {
    IconX,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['remove'],
  data() {
    return {
      uniqid: uniqid(),
    }
  },
  computed: {
    attractionId() {
      return `attraction-${this.uniqid}-${this.item.id}`
    },
    activityId() {
      return `activity-${this.uniqid}-${this.item.id}`
    },
  },
})
</script>

<style scoped>
.sortable-ghost {
  opacity: 0.25;
}
</style>
