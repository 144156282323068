<script setup lang="ts">
import { TresCanvas, useRenderLoop } from '@tresjs/core'
import { ref, reactive, watch, onMounted } from 'vue'
import { vertexShader, fragmentShader, backingFragmentShader, backingVertexShader } from './shaders'
import { hexToRGB, lerpColor } from './utils/color'
import { gsap } from 'gsap'
import { useMicrophone } from './composables/useMicrophone'
import { useStore } from 'vuex'
import { useEventListener } from '@vueuse/core'

// blob "modes"
// - idle: default state
// - speaking: when the assistant is speaking
// - listening: when the assistant is listening
export type BlobMode = 'listening' | 'speaking' | 'idle'
const props = defineProps<{
  mode: BlobMode
}>()
const blobMode = ref<BlobMode>(props.mode)
const blobVisible = ref(true)
const store = useStore()

// Composables
const { onLoop, pause, resume } = useRenderLoop()

const { isUserTalking, checkAudioLevel, micLevel, resetMic } = useMicrophone()

// Refs

// timer to reset the blob mode after the assistant stops speaking
const speakingTimer = ref<any | null>(null)
// whether the assistant is talking
const aiTalkingRef = ref(false)

// whether the user is talking
const userTalkingRef = ref(false)

// spike intensity of the blob
const spikeIntensity = ref(0)
const backingSpikeIntensity = ref(0)

// 3D Mesh
const mesh = ref()

// backing mesh
// Add a new ref for the backing mesh
const backingMesh = ref()

const scale = ref<{ min: number; max: number }>({ min: 0.6, max: 0.75 })

// Constants
const modeSettings = {
  idle: {
    colorA: '#4A90E2',
    colorB: '#50E3C2',
    colorC: '#B8E986',
    intensity: 0.2,
    rotationSpeed: 0.2,
  },
  speaking: {
    colorA: '#FFD97C',
    colorB: '#f97316',
    colorC: '#FFA649',
    intensity: 0.3,
    rotationSpeed: 0.45,
  },
  // user is talking
  listening: {
    colorA: '#423B9F',
    colorB: '#a855f7',
    colorC: '#5C55B9',
    intensity: 0.3,
    rotationSpeed: 0.45,
  },
}

// the three colors present in the blob
// changes when the mode changes
const colorA = ref(modeSettings.idle.colorA)
const colorB = ref(modeSettings.idle.colorB)
const colorC = ref(modeSettings.idle.colorC)

const lerpFactor = 0.1 // Adjust this to control lerp speed
const rotationLerpFactor = 0.02 // Separate, slower lerp factor for rotation
const intensityLerpFactor = 0.03 // Separate lerp factor for intensity
const spikeIntensityLerpFactor = 0.1 // Separate lerp factor for spike intensity

const lastTime = ref(0)
const accumulatedTime = ref(0)
const fixedDeltaTime = 1 / 60 // Target 60 FPS

// Watch for changes in the mode prop so we can set the mode
// from a parent component
watch(
  () => props.mode,
  (newBlobMode) => {
    blobMode.value = newBlobMode
  },
  { immediate: true }
)

onMounted(() => {
  useEventListener(document, 'visibilitychange', () => {
    if (document.hidden) {
      pause()
      blobVisible.value = false
    } else {
      resume()
      blobVisible.value = true
    }
  })
})

// animate the scale of the blob
// when the scale changes, animate the scale of the blob
// to the new scale + a random value
// to simulate a breathing effect
const animatedScale = reactive({ scale: 0.75 })
watch(scale, ({ max }) => {
  gsap.to(animatedScale, {
    duration: () => Math.random() * 1 + 1,
    scale: Math.random() * 0.1 + max,
    ease: 'sine.inOut',
  })
})

// watch changes to the colors and update the uniforms
watch(colorA, (newValue) => {
  uniforms.u_colorA.value = hexToRGB(newValue)
})

watch(colorB, (newValue) => {
  uniforms.u_colorB.value = hexToRGB(newValue)
})

watch(colorC, (newValue) => {
  uniforms.u_colorC.value = hexToRGB(newValue)
})

// Reactive uniforms
// These are the uniforms that will be passed to the shader
// and will be updated every frame
const uniforms = reactive({
  u_time: { value: 0 },
  u_rotationAngle: { value: 0 },
  u_spikeIntensity: { value: 0 }, // Initialize with 0
  u_intensity: { value: modeSettings.idle.intensity },
  u_rotationSpeed: { value: modeSettings.idle.rotationSpeed },
  u_colorA: { value: hexToRGB(modeSettings.idle.colorA) },
  u_colorB: { value: hexToRGB(modeSettings.idle.colorB) },
  u_colorC: { value: hexToRGB(modeSettings.idle.colorC) },
})

// Target uniforms
// These are the target values for the uniforms
// when the mode changes, lerp the uniforms to these values
const targetUniforms = reactive({
  u_intensity: modeSettings.idle.intensity,
  u_rotationSpeed: modeSettings.idle.rotationSpeed,
  u_colorA: hexToRGB(modeSettings.idle.colorA),
  u_colorB: hexToRGB(modeSettings.idle.colorB),
  u_colorC: hexToRGB(modeSettings.idle.colorC),
})

const backingUniforms = reactive({
  u_time: { value: 0 },
  u_audioLevel: { value: 0 },
  u_colorA: { value: hexToRGB(modeSettings.idle.colorA) },
  u_colorB: { value: hexToRGB(modeSettings.idle.colorB) },
  u_colorC: { value: hexToRGB(modeSettings.idle.colorC) },
  u_opacity: { value: 1 },
})

// Watch for changes in the blob mode
// and update the uniforms and scale accordingly
watch(blobMode, (newBlobMode) => {
  const newSettings = modeSettings[newBlobMode]
  targetUniforms.u_intensity = newSettings.intensity
  targetUniforms.u_rotationSpeed = newSettings.rotationSpeed
  targetUniforms.u_colorA = hexToRGB(newSettings.colorA)
  targetUniforms.u_colorB = hexToRGB(newSettings.colorB)
  targetUniforms.u_colorC = hexToRGB(newSettings.colorC)

  // Update backing colors
  backingUniforms.u_colorA.value = targetUniforms.u_colorA
  backingUniforms.u_colorB.value = targetUniforms.u_colorB
  backingUniforms.u_colorC.value = targetUniforms.u_colorC

  switch (newBlobMode) {
    case 'speaking':
      scale.value = { min: 0.7, max: 0.8 }
      break
    case 'listening':
      scale.value = { min: 0.7, max: 0.8 }
      break
    case 'idle':
    default:
      scale.value = { min: 0.6, max: 0.6 }
      break
  }
})

// Watch for changes in the audio level
// and update the spike intensity
watch(
  () => store.state.assistant.audioLevel,
  (newValue) => {
    spikeIntensity.value = newValue * 0.15
    backingSpikeIntensity.value = newValue
  }
)

// Watch for changes in the mic level (user talking)
// and update the spike intensity
watch(micLevel, (newValue) => {
  if (userTalkingRef.value) {
    spikeIntensity.value = Math.max(newValue / 100, 1) * 0.15
    backingSpikeIntensity.value = newValue
  }
})

// Watch for changes in the isAssistantTalking state
// and update the talking refs accordingly
watch(
  () => store.state.assistant.isAssistantTalking,
  (newValue) => {
    clearTimeout(speakingTimer.value)
    if (newValue) {
      aiTalkingRef.value = true
      userTalkingRef.value = false
    } else {
      userTalkingRef.value = false
      speakingTimer.value = setTimeout(() => {
        aiTalkingRef.value = false
        spikeIntensity.value = 0
        backingSpikeIntensity.value = 0
      }, 1000)
    }
  },
  { immediate: true }
)

// Watch for changes in the user talking state
// and update the blob mode accordingly depending on whether the user is talking or the ai is talking
watch(
  isUserTalking,
  (newUserTalking) => {
    userTalkingRef.value = newUserTalking
    if (!newUserTalking) {
      spikeIntensity.value = 0
      backingSpikeIntensity.value = 0
    }
  },
  { immediate: true }
)

watch([userTalkingRef, aiTalkingRef], ([userTalking, aiTalking]) => {
  let newMode = blobMode.value
  if (userTalking && !aiTalking) {
    newMode = 'listening'
  } else {
    resetMic()
    newMode = aiTalkingRef.value ? 'speaking' : 'idle'
  }
  blobMode.value = newMode
})

// Main render loop
// This is where the magic happens
onLoop(({ elapsed: currentTime }) => {
  if (mesh.value) {
    checkAudioLevel()

    const deltaTime = currentTime - lastTime.value
    lastTime.value = currentTime

    // Accumulate time for fixed time step updates
    accumulatedTime.value += deltaTime

    // Update time-based animations using fixed time step
    while (accumulatedTime.value >= fixedDeltaTime) {
      uniforms.u_time.value += fixedDeltaTime * uniforms.u_rotationSpeed.value
      // Accumulate rotation angle
      uniforms.u_rotationAngle.value += fixedDeltaTime * uniforms.u_rotationSpeed.value
      // Ensure the angle stays within 0-2π range
      uniforms.u_rotationAngle.value %= Math.PI * 2

      backingUniforms.u_time.value += fixedDeltaTime * uniforms.u_rotationSpeed.value

      accumulatedTime.value -= fixedDeltaTime
    }

    // Lerp intensity
    uniforms.u_intensity.value += (targetUniforms.u_intensity - uniforms.u_intensity.value) * intensityLerpFactor * deltaTime * 60

    // Lerp spikeIntensity
    // uniforms.u_spikeIntensity.value = spikeIntensity.value
    uniforms.u_spikeIntensity.value += (spikeIntensity.value - uniforms.u_spikeIntensity.value) * spikeIntensityLerpFactor * deltaTime * 60

    // Lerp rotation speed
    uniforms.u_rotationSpeed.value += (targetUniforms.u_rotationSpeed - uniforms.u_rotationSpeed.value) * rotationLerpFactor * deltaTime * 60

    // Lerp colors
    const colorLerpAmount = lerpFactor * deltaTime * 60
    uniforms.u_colorA.value = lerpColor(uniforms.u_colorA.value, targetUniforms.u_colorA, colorLerpAmount)
    uniforms.u_colorB.value = lerpColor(uniforms.u_colorB.value, targetUniforms.u_colorB, colorLerpAmount)
    uniforms.u_colorC.value = lerpColor(uniforms.u_colorB.value, targetUniforms.u_colorC, colorLerpAmount)

    // Update backing uniforms
    backingUniforms.u_time.value += deltaTime * 0.5 // Slowed down the time progression for more fluid movement
    backingUniforms.u_audioLevel.value = uniforms.u_spikeIntensity.value * 25 // Increased audio reactivity for the backing

    // Lerp the backing opacity based on audio level
    // Lerp the backing opacity based on audio level
    const targetOpacity = 0.25 + uniforms.u_spikeIntensity.value // Adjusted opacity range
    backingUniforms.u_opacity.value += targetOpacity - backingUniforms.u_opacity.value

    // Lerp backing colors
    backingUniforms.u_colorA.value = lerpColor(uniforms.u_colorA.value, targetUniforms.u_colorA, colorLerpAmount)
    backingUniforms.u_colorB.value = lerpColor(uniforms.u_colorA.value, targetUniforms.u_colorA, colorLerpAmount)
    backingUniforms.u_colorC.value = lerpColor(uniforms.u_colorA.value, targetUniforms.u_colorA, colorLerpAmount)
  }
})
</script>

<template>
  <TresCanvas alpha anti-alias :class="{ 'invisible opacity-0': !blobVisible }">
    <TresPerspectiveCamera :position="[0, 0, 8]" />
    <!-- Backing mesh -->
    <TresMesh ref="backingMesh" :scale="0.9" :position="[0, 0, 0.25]">
      <TresIcosahedronGeometry :args="[2, 10]" />
      <TresShaderMaterial :vertex-shader="backingVertexShader" :fragment-shader="backingFragmentShader" :uniforms="backingUniforms" :depth-write="false" />
    </TresMesh>
    <!-- Blob mesh -->
    <TresMesh ref="mesh" :scale="animatedScale.scale" :position="[0, 0, 0]">
      <TresIcosahedronGeometry :args="[2, 20]" />
      <TresShaderMaterial :vertex-shader="vertexShader" :fragment-shader="fragmentShader" :uniforms="uniforms" />
    </TresMesh>
  </TresCanvas>
</template>

<style scoped>
canvas {
  pointer-events: none !important;
}
</style>
