export default {
  methods: {
    getBestImage(variants: any[] = []) {
      if (!variants.length) {
        return ''
      }
      const sortedVariants = variants.sort((a, b) => a.width - b.width)
      return sortedVariants[sortedVariants.length - 1].url
    },
  },
}
