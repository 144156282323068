<script setup lang="ts">
import { ScratchPadItem } from '@/types'
</script>

<template>
  <Responsive v-if="(scratchPadItems as ScratchPadItem[]).length" v-slot="{ breakpoint }" :breakpoints="{ narrow: (el) => el.width <= 640 }">
    <div class="grid gap-y-6 min-h-[50vh]" :class="{ 'grid-cols-5 gap-x-16': !breakpoint.narrow, 'grid-cols-4 gap-x-8': breakpoint.narrow }">
      <div v-if="linkItems.length" class="col-span-2 flex flex-col gap-4">
        <h3 class="relative font-medium text-blue-violet flex items-center gap-2">
          <IconBookmarks class="w-5 h-5 text-blue-violet-300" />
          Links
        </h3>
        <DraggableList item-key="id" tag="div" :list="linkItems" v-bind="dragOptions" class="flex flex-col gap-4" @start="onStartDrag" @end="onEndDrag">
          <template #item="{ element: item }">
            <ScratchPadElement :key="item.id" :item="item" />
          </template>
        </DraggableList>
      </div>
      <div
        v-if="imageItems.length"
        class="flex flex-col gap-4"
        :class="{ 'col-span-3': !breakpoint.narrow, 'col-span-2': breakpoint.narrow, 'col-span-4': breakpoint.narrow && linkItems.length === 0 }">
        <h3 class="relative font-medium text-blue-violet flex items-center gap-2">
          <IconCamera class="w-5 h-5 text-blue-violet-300" />
          Photos
        </h3>
        <DraggableList
          item-key="id"
          tag="div"
          :list="imageItems"
          v-bind="dragOptions"
          class="grid gap-4"
          :class="{ 'grid-cols-3': !breakpoint.narrow, 'grid-cols-2': breakpoint.narrow, 'grid-cols-4': breakpoint.narrow && linkItems.length === 0 }"
          @start="onStartDrag"
          @end="onEndDrag">
          <template #item="{ element: item }">
            <ScratchPadElement :key="item.id" :item="item" />
          </template>
        </DraggableList>
      </div>
      <div v-if="videoItems.length" class="col-span-full flex flex-col gap-4">
        <div v-if="linkItems.length || imageItems.length" class="border-t-2 border-current border-dotted mix-blend-multiply opacity-10" />
        <h3 class="relative font-medium text-blue-violet flex items-center gap-2">
          <IconVideo class="w-5 h-5 text-blue-violet-300" />
          Videos
        </h3>
        <DraggableList
          item-key="id"
          tag="div"
          :list="videoItems"
          v-bind="dragOptions"
          class="grid gap-4"
          :class="{ 'grid-cols-3': !breakpoint.narrow, 'grid-cols-2': breakpoint.narrow }"
          @start="onStartDrag"
          @end="onEndDrag">
          <template #item="{ element: item }">
            <ScratchPadElement :key="item.id" :item="item" />
          </template>
        </DraggableList>
      </div>
      <div v-if="attractionItems.length" class="col-span-full flex flex-col gap-4">
        <div v-if="linkItems.length || imageItems.length || videoItems.length" class="border-t-2 border-current border-dotted mix-blend-multiply opacity-10" />
        <h3 class="relative font-medium text-blue-violet flex items-center gap-2">
          <IconBuildingCarousel class="w-5 h-5 text-blue-violet-300" />
          Attractions
        </h3>
        <DraggableList
          item-key="id"
          tag="div"
          :list="attractionItems"
          v-bind="dragOptions"
          class="relative grid gap-4"
          :class="{ 'grid-cols-3': !breakpoint.narrow, 'grid-cols-2': breakpoint.narrow }"
          @start="onStartDrag"
          @end="onEndDrag">
          <template #item="{ element: item }">
            <ScratchPadElement :key="item.id" :item="item" />
          </template>
        </DraggableList>
      </div>
      <div v-if="activityItems.length" class="col-span-full flex flex-col gap-4">
        <div
          v-if="linkItems.length || imageItems.length || videoItems.length || attractionItems.length"
          class="border-t-2 border-current border-dotted mix-blend-multiply opacity-10" />
        <h3 class="relative font-medium text-blue-violet flex items-center gap-2">
          <IconIceSkating class="w-5 h-5 text-blue-violet-300" />
          Activities
        </h3>
        <DraggableList
          item-key="id"
          tag="div"
          :list="activityItems"
          v-bind="dragOptions"
          class="relative grid gap-4"
          :class="{ 'grid-cols-3': !breakpoint.narrow, 'grid-cols-2': breakpoint.narrow }"
          @start="onStartDrag"
          @end="onEndDrag">
          <template #item="{ element: item }">
            <ScratchPadElement :key="item.id" :item="item" />
          </template>
        </DraggableList>
      </div>
      <div v-if="tripItems.length" class="col-span-full flex flex-col gap-4">
        <div
          v-if="linkItems.length || imageItems.length || videoItems.length || attractionItems.length || activityItems.length"
          class="border-t-2 border-current border-dotted mix-blend-multiply opacity-10" />
        <h3 class="relative font-medium text-blue-violet flex items-center gap-2">
          <IconPlane class="w-5 h-5 text-blue-violet-300" />
          Trips
        </h3>
        <DraggableList
          item-key="id"
          tag="div"
          :list="tripItems"
          v-bind="dragOptions"
          class="relative grid gap-4"
          :class="{ 'grid-cols-3': !breakpoint.narrow, 'grid-cols-2': breakpoint.narrow }"
          @start="onStartDrag"
          @end="onEndDrag">
          <template #item="{ element: item }">
            <ScratchPadElement :key="item.id" :item="item" />
          </template>
        </DraggableList>
      </div>
    </div>
  </Responsive>
  <div v-else class="flex-auto flex flex-col gap-2 items-center justify-center bg-gradient-to-br from-white to-sky-50 rounded-3xl py-32">
    <IconBeach class="w-12 h-12 text-sky-300" stroke-width="1.5" />
    <div class="text-slate-500 font-light text-xl">Save things for later here</div>
    <div class="text-slate-400 flex items-center gap-2">
      Use the
      <PinButton class="inline-flex pointer-events-none" />
      button
    </div>
    <Button class="mt-4" @click="hideSidebar">Go exploring</Button>
  </div>
</template>

<script lang="ts">
import { IconBeach, IconBookmarks, IconBuildingCarousel, IconCamera, IconVideo, IconIceSkating, IconPlane } from '@tabler/icons-vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import DraggableList from 'vuedraggable'
import { ScratchPadItemTypeEnum } from '@/enums'

export default defineComponent({
  components: {
    IconBeach,
    IconCamera,
    IconBookmarks,
    IconVideo,
    IconBuildingCarousel,
    IconIceSkating,
    IconPlane,
    DraggableList,
  },
  props: {
    draggable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('user', ['scratchPadItems']),
    imageItems() {
      return this.scratchPadItems.filter((item) => item.type === ScratchPadItemTypeEnum.IMAGE)
    },
    linkItems() {
      return this.scratchPadItems.filter((item) => item.type === ScratchPadItemTypeEnum.LINK)
    },
    videoItems() {
      return this.scratchPadItems.filter((item) => item.type === ScratchPadItemTypeEnum.VIDEO)
    },
    attractionItems() {
      return this.scratchPadItems.filter((item) => item.type === ScratchPadItemTypeEnum.ATTRACTION)
    },
    activityItems() {
      return this.scratchPadItems.filter((item) => item.type === ScratchPadItemTypeEnum.ACTIVITY)
    },
    tripItems() {
      return this.scratchPadItems.filter((item) => item.type === ScratchPadItemTypeEnum.TRIP)
    },
    dragOptions() {
      return {
        group: { name: 'itinerary', pull: 'clone', put: false },
        disabled: !this.draggable,
        sort: false,
      }
    },
  },
  methods: {
    onStartDrag(event: any) {
      // fix size while dragging
      const el = event.item as HTMLElement
      const { width, height } = window.getComputedStyle(el)
      el.style.width = width
      el.style.height = height
    },
    onEndDrag(event: any) {
      // reset size after dragging
      const el = event.item as HTMLElement
      el.style.width = ''
      el.style.height = ''
    },
  },
})
</script>

<style scoped>
.item-move,
.item-enter-active {
  transition: all 0.5s var(--ease-in-out-circ);
}

.item-leave-active {
  transition: all 0.5s var(--ease-in-out-circ);
}

.item-leave-active {
  position: absolute;
  z-index: 0;
}

.item-enter-from {
  opacity: 0;
}

.item-leave-to {
  opacity: 0;
  transform: scale(0.8);
}
</style>
