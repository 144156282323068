<template>
  <div class="flex justify-between items-center">
    <div class="flex items-center gap-4">
      <IconUser v-if="imageError" class="w-12 h-12 shrink-0 p-3 text-white rounded-full bg-blue-violet" />
      <img v-else :src="identity.picture" class="w-12 h-12 shrink-0 rounded-full bg-slate-200" @error="imageError = true" />
      <div>
        <div class="font-medium">{{ identity.name }}</div>
        <div class="text-sm text-slate-400">{{ identity.email }}</div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { IconUser } from '@tabler/icons-vue'

export default defineComponent({
  components: { IconUser },
  data() {
    return {
      imageError: false,
    }
  },
  computed: {
    ...mapState('auth', ['identity']),
  },
})
</script>
