import currency from 'currency.js'

export default {
  methods: {
    cleanUrl(url: string) {
      const u = new URL(url)
      return u.hostname
    },
    convertToCurrency(value: number, currency: string) {
      if (this.$exchangeRates?.[currency]) {
        return this.$currency(value).multiply(this.$exchangeRates[currency])
      }
      return this.$currency(value)
    },
    formatTripDates(departure_date: string, return_date: string, monthFormat: string = 'MMMM') {
      const depDate = this.$dayjs(departure_date)
      const retDate = this.$dayjs(return_date)
      if (retDate.isSame(depDate, 'month')) {
        return `${depDate.format(`${monthFormat} D`)} to ${retDate.format('D, YYYY')}`
      }
      return `${depDate.format(`${monthFormat} D`)} to ${retDate.format(`${monthFormat} D, YYYY`)}`
    },
  },
}

declare module 'vue' {
  interface ComponentCustomProperties {
    cleanUrl: (url: string) => string
    convertToCurrency: (value: number, currency: string) => currency
  }
}
