<template>
  <div class="shrink-0 text-white flex flex-col justify-end items-center px-4" :class="{ 'basis-60': !breakpoint.short, 'basis-48': breakpoint.short }">
    <transition name="fade">
      <div v-if="photo" class="fixed inset-0 opacity-20 mix-blend-overlay">
        <Image :src="photo" />
      </div>
    </transition>
    <div class="relative z-10 w-full max-w-5xl py-6">
      <div class="flex items-center justify-between">
        <div class="flex flex-col gap-1">
          <h1 class="font-serif text-violet-300" :class="{ 'text-5xl': titleWords < 5, 'text-4xl': titleWords >= 5 }">{{ title }}</h1>
          <h2 v-if="subtitle && subtitle !== title" class="text-xl text-violet-400 font-medium">{{ subtitle }}</h2>
        </div>
        <CloseButton @click="$emit('close')" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    breakpoint: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    photo: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  computed: {
    titleWords() {
      return this.title.split(' ').length
    },
  },
})
</script>
