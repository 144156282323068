import api from '@/services/api'
import merge from 'lodash/merge'
import store from '@/store'
import uniqid from 'uniqid'

export const apiFetch = async (endpoint: string, body: any, method: string = 'post' as 'post' | 'get') => {
  try {
    const abortController = new AbortController()
    const controllerId = uniqid()
    store.commit('addAbortController', { controllerId, abortController })

    // make api call
    const {
      data: { results },
    } = await api[method](
      endpoint,
      {
        message: {
          toolCallList: [{ ...body }],
        },
      },
      {
        signal: abortController.signal,
      }
    )

    // call is done so we can clear the abort controller
    store.commit('clearAbortController', controllerId)

    // extract json from result
    if (results[0]) {
      const { json } = JSON.parse(results[0].result)
      return json
    }

    return null
  } catch (error) {
    store.commit('setApiError', error)
    console.error('Error fetching and parsing API result', error)
    return null
  }
}

export const apiFetchWithTripId = async (endpoint: string, body: any, method: string = 'post' as 'post' | 'get') => {
  // include tripId if there are function arguments
  const toolCall = merge({ ...body }, { function: { arguments: { tripId: store.state.user.currentTripId } } })
  return apiFetch(endpoint, toolCall, method)
}
