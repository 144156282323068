<template>
  <Responsive
    v-slot="{ breakpoint }"
    :breakpoints="{ small: (el) => el.width <= 300 }"
    class="group/card flex flex-col cursor-pointer relative rounded-3xl overflow-hidden transition bg-blue-violet-800 duration-500 hover:shadow-xl backface-hidden">
    <PinButton class="absolute top-2 right-2 z-10" :item="scratchPadItem" />
    <RecommendationTag class="absolute bottom-2 left-2 z-10" :recommendation="recommendation" />
    <div class="p-4 pt-6 flex flex-col flex-auto gap-1 backface-hidden">
      <div class="flex items-center gap-2 pr-8">
        <span class="line-clamp-2 leading-tight" :class="{ 'text-sm': breakpoint.small }">{{ recommendation.title }}</span>
      </div>
      <div v-if="description" class="text-slate-400 leading-tight line-clamp-2" :class="{ 'text-xs': breakpoint.small, 'text-sm': !breakpoint.small }">
        {{ description }}
      </div>
      <RecommendationStarRating :recommendation="recommendation" class="mt-auto pt-2" />
    </div>

    <div class="overflow-hidden" :class="{ 'aspect-[3/2]': !isShortBreakpoint, 'aspect-[2/1]': isShortBreakpoint }">
      <Image
        :src="recommendation.cover.photo.url"
        class="transition duration-500 w-full group-hover/card:scale-[1.05] backface-hidden"
        :class="{ 'aspect-[4/3]': !isShortBreakpoint, 'aspect-[2/1]': isShortBreakpoint }" />
    </div>
  </Responsive>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import recommendationMixins from '@/mixins/recommendation'

export default defineComponent({
  mixins: [recommendationMixins],
  props: {
    isShortBreakpoint: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    description() {
      if (this.recommendation.cover.description && this.recommendation.cover.description !== this.recommendation.title) {
        return this.recommendation.cover.description
      }
      if (this.recommendation?.attraction?.destinationName) {
        return this.recommendation.attraction.destinationName
      }
      return null
    },
  },
})
</script>
