<template>
  <div class="basis-32 shrink-0 flex items-end justify-center px-4">
    <div class="relative z-10 w-full max-w-5xl">
      <div class="flex items-center justify-between">
        <div class="flex flex-col">
          <h1 class="font-serif text-3xl text-violet-300">{{ title }}</h1>
          <h2 v-if="subtitle" class="text-lg text-violet-400 font-medium">{{ subtitle }}</h2>
        </div>
        <CloseButton @click="$emit('close')" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    breakpoint: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
})
</script>
