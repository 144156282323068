<template>
  <Image v-bind="$props" :src="imageSrc" :caption="caption" @dimensions="checkDimensions" @error="tryNextImage" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    src: {
      type: [String, Array<string>],
      required: true,
    },
    caption: {
      type: String,
      default: '',
    },
    minDimension: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      index: 0,
      imageSrc: Array.isArray(this.src) ? this.src[0] : this.src,
    }
  },
  watch: {
    src: {
      immediate: true,
      handler() {
        this.index = 0
        this.imageSrc = Array.isArray(this.src) ? this.src[0] : this.src
      },
    },
  },
  methods: {
    checkDimensions({ width, height }) {
      if (width < this.minDimension || height < this.minDimension) {
        this.tryNextImage()
      }
    },
    tryNextImage() {
      if (Array.isArray(this.src) && this.index < this.src.length - 1) {
        this.imageSrc = this.src[++this.index]
      } else {
        console.log(`Failed to load image`)
        this.imageSrc = ''
      }
    },
  },
})
</script>
