<template>
  <div v-if="duration.fixedDurationInMinutes" class="flex gap-1">
    <span v-if="parseInt(getHours(duration.fixedDurationInMinutes))">{{ getHours(duration.fixedDurationInMinutes) }} hr</span>
    <span v-if="parseInt(getMinutes(duration.fixedDurationInMinutes))">{{ getMinutes(duration.fixedDurationInMinutes) }} min</span>
  </div>
  <div v-else-if="duration.variableDurationFromMinutes && duration.variableDurationToMinutes">
    <span v-if="!parseInt(getMinutes(duration.variableDurationFromMinutes)) && !parseInt(getMinutes(duration.variableDurationToMinutes))" class="flex gap-1">
      <span v-if="parseInt(getHours(duration.variableDurationFromMinutes))">{{ getHours(duration.variableDurationFromMinutes) }}</span>
      <span>to</span>
      <span v-if="parseInt(getHours(duration.variableDurationToMinutes))">{{ getHours(duration.variableDurationToMinutes) }} hours</span>
    </span>
    <span v-else class="flex gap-1">
      <span v-if="parseInt(getHours(duration.variableDurationFromMinutes))">{{ getHours(duration.variableDurationFromMinutes) }} hr</span>
      <span v-if="parseInt(getMinutes(duration.variableDurationFromMinutes))">{{ getMinutes(duration.variableDurationFromMinutes) }} min</span>
      <span>to</span>
      <span v-if="parseInt(getHours(duration.variableDurationToMinutes))">{{ getHours(duration.variableDurationToMinutes) }} hr</span>
      <span v-if="parseInt(getMinutes(duration.variableDurationToMinutes))">{{ getMinutes(duration.variableDurationToMinutes) }} min</span>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    duration: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getHours(minutes) {
      return this.$dayjs.duration(minutes, 'minutes').format('H')
    },
    getMinutes(minutes) {
      return this.$dayjs.duration(minutes, 'minutes').format('mm')
    },
  },
})
</script>
