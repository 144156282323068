<script setup lang="ts">
import { InputTypeEnum, ModuleEventEnum } from '@/enums'
</script>

<template>
  <div
    v-tooltip="{ content: inputMode === InputTypeEnum.TEXT ? 'Switch to voice' : 'Switch to text', distance: 10 }"
    class="group h-10 aspect-[2/1] rounded-full flex relative cursor-pointer"
    :class="{
      'opacity-20 pointer-events-none': !isCallActive,
      'bg-slate-200/20': isDarkBackground,
      'bg-slate-800/10': !isDarkBackground,
    }"
    @click="toggleMode">
    <div
      class="h-full aspect-square rounded-full transition duration-300 relative z-10 group-hover:shadow-lg"
      :class="{
        'bg-amber-400 translate-x-full': inputMode === InputTypeEnum.TEXT,
        'bg-blue-violet': inputMode === InputTypeEnum.VOICE,
        talking: isUserTalking,
      }">
      <Transition name="mode" mode="out-in">
        <IconMicrophone v-if="inputMode === InputTypeEnum.VOICE" class="h-full aspect-square p-2 text-white" stroke-width="1.5" />
        <IconKeyboard v-else class="h-full aspect-square p-2 text-amber-800" stroke-width="1.5" />
      </Transition>
    </div>
    <IconMicrophone
      class="h-full aspect-square absolute left-0 p-2 text-black opacity-20 transition group-hover:text-blue-violet group-hover:opacity-60"
      stroke-width="1.5" />
    <IconKeyboard
      class="h-full aspect-square absolute right-0 p-2 text-black opacity-20 transition group-hover:text-blue-violet group-hover:opacity-60"
      stroke-width="1.5" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import config from '@/config/index.json'
import { IconKeyboard, IconMicrophone } from '@tabler/icons-vue'

export default defineComponent({
  components: {
    IconKeyboard,
    IconMicrophone,
  },
  props: {
    size: {
      type: String,
      required: false,
      default: 'lg',
    },
  },
  data() {
    return {
      config,
      textMessage: '',
    }
  },
  computed: {
    ...mapState('assistant', ['inputMode', 'isUserTalking']),
  },
  watch: {
    inputMode() {
      this.textMessage = ''
      if (this.inputMode === InputTypeEnum.TEXT) {
        // wait for transition
        setTimeout(() => {
          ;(this.$refs?.textInput as HTMLElement)?.focus()
        }, 500)
      }
    },
  },
  methods: {
    toggleMode() {
      this.$emitter.emit(ModuleEventEnum.SWITCH_TO_TEXT_INPUT, { enable: this.inputMode === InputTypeEnum.VOICE })
    },
  },
})
</script>

<style scoped>
.mode-enter-active,
.mode-leave-active {
  transition: all 0.25s var(--ease-out-circ);
}

.mode-enter-from,
.mode-leave-to {
  opacity: 0;
  transform: translateY(5px);
}

.talking:after,
.talking:before {
  @apply absolute inset-0 border-4 border-purple-500 rounded-full;
  animation: ring 1.5s var(--ease-out-circ) infinite;
  content: '';
}
.talking:after {
  animation-delay: 0.75s;
}
@keyframes ring {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
</style>
