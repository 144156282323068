<script lang="ts" setup>
import { ScratchPadItemTypeEnum } from '@/enums'
</script>

<template>
  <div class="group/video relative w-full aspect-video relative overflow-hidden">
    <iframe
      v-if="src"
      :src="src"
      class="w-full h-full"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen />
    <PinButton v-if="canSave" :item="{ type: ScratchPadItemTypeEnum.VIDEO, id: src, data: { src } }" class="absolute z-10 top-2 right-2" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    src: {
      type: String,
      required: true,
    },
    canSave: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
