import { FIRST_MODULE_NAME, SOLO_MODULES } from '@/constants'
import { FunctionEnum, ModuleTypeEnum } from '@/enums'
import { State } from './index'

export default {
  // active modules

  activePanelModules(state: State) {
    return state.activeModules.filter((module) => module.type === ModuleTypeEnum.PANEL)
  },
  activeModalModules(state: State) {
    return state.activeModules.filter((module) => module.type === ModuleTypeEnum.MODAL)
  },
  activeFullScreenModules(state: State) {
    return state.activeModules.filter((module) => module.type === ModuleTypeEnum.FULLSCREEN)
  },

  // first module

  isFirstModule(state: State) {
    return state.numModulesDisplayed === 0
  },
  isFirstModuleActive(state: State) {
    return state.activeModules.map((m) => m.name).includes(FIRST_MODULE_NAME)
  },

  // modules

  isAnyModuleActive(state: State) {
    return state.activeModules.length > 0 || state.informationModules.length > 0 || state.isFtuxModule
  },
  persistentModule(state: State) {
    return state.informationModules.length > 0
  },
  showSuggestedResponses(state: State) {
    // if information modules are active or any solo modules are active, don't show suggested responses
    if (state.informationModules.length > 0 || state.activeModules.find((module) => SOLO_MODULES.includes(module.name))) {
      return false
    }
    return true
  },

  // is an info module showing and is it dark

  isDarkBackground(state: State) {
    if (state.informationModules.length > 0) {
      const lastModule = state.informationModules[state.informationModules.length - 1]
      if (lastModule?.darkBackground) {
        return true
      }
    }
    return false
  },

  // sidebar / portfolio

  portfolio(state: State) {
    return {
      departure_date: (state.portfolioModules?.find((module) => module.name === FunctionEnum.TRAVEL_DATES)?.params as any)?.departure_date ?? '',
      return_date: (state.portfolioModules?.find((module) => module.name === FunctionEnum.TRAVEL_DATES)?.params as any)?.return_date ?? '',
      location: (state.portfolioModules?.find((module) => module.name === FunctionEnum.TRIP_DESTINATION)?.params as any)?.location ?? '',
      lat_long: (state.portfolioModules?.find((module) => module.name === FunctionEnum.TRIP_DESTINATION)?.params as any)?.lat_long ?? [],
      funfacts: (state.portfolioModules?.find((module) => module.name === FunctionEnum.TRIP_DESTINATION)?.params as any)?.funfacts ?? [],
      travelers: (state.portfolioModules?.find((module) => module.name === FunctionEnum.TRAVELERS)?.params as any)?.travelers ?? [],
      accommodations: (state.portfolioModules?.find((module) => module.name === FunctionEnum.ACCOMMODATIONS)?.params as any)?.accommodations ?? [],
    }
  },
}
