<template>
  <Teleport to="body">
    <transition name="overlay" appear @after-enter="onAfterEnter" @after-leave="onAfterLeave">
      <div
        v-show="active"
        :key="key"
        class="fixed w-screen h-screen top-0 left-0 bg-gradient-to-br from-blue-violet/70 via-purple-900/70 to-violet-900/80 text-slate-700"
        :class="{ ' z-[999]': active, 'z-[899]': !active }"
        @click="handleOverlayClick">
        <div class="fixed inset-0 flex items-center justify-center p-8">
          <div
            ref="popup"
            class="popup rounded-5xl z-1 drop-shadow-xl max-h-full relative flex flex-col transition-all duration-500"
            :class="[
              popupBg,
              {
                'bg-white': !popupBg,
                'overflow-hidden': !overflow,
                'w-96 max-w-md': size == 'md',
                'w-full max-w-lg': size == 'lg',
                'w-full max-w-xl': size == 'xl',
                'w-full max-w-2xl': size == '2xl',
                'w-full max-w-3xl': size == '3xl',
                'w-full max-w-4xl': size == '4xl',
                'w-full max-w-5xl': size == '5xl',
                'max-w-sm': size == 'sm',
              },
            ]"
            tabindex="0"
            @click.stop=""
            @keydown.esc="handleEscapeKey">
            <Loading />
            <div v-if="title || showCloseButton" class="w-full p-4 flex justify-between items-center">
              <ModuleTitle :title="title" size="md" :can-close="showCloseButton" class="w-full" @close="closeOverlay" />
            </div>
            <div
              class="flex-auto"
              :class="{ 'overflow-y-auto': !overflow, 'p-8': padding === 'default', 'p-3': padding === 'thin', 'p-0': padding === 'none' }">
              <slot :active="active" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    canClickOutside: {
      type: Boolean,
      default: true,
    },
    overflow: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    popupBg: {
      type: String,
      default: '',
    },
    autoFocus: {
      type: Boolean,
      default: true,
    },
    padding: {
      type: String,
      default: 'default',
    },
  },
  emits: ['close', 'open', 'show', 'shown', 'hide', 'hidden'],
  data() {
    return {
      key: null,
      doNotReset: false,
    }
  },
  computed: {
    ...mapState(['overlay', 'nestedOverlays']),
    active() {
      return this.overlay === this.id || this.nestedOverlays.includes(this.id)
    },
  },
  watch: {
    overlay(n, o) {
      if (!n && o === this.id) {
        this.$emit('close')
      }
      if (n === this.id && o !== this.id) {
        this.$emit('open')
      }
    },
    'nestedOverlays.length'(n, o) {
      if (o < n && o > 0) {
        // coming from nested overlay, don't reset
        this.doNotReset = true
      }
    },
    active(n, o) {
      if (n !== o) {
        if (n) {
          this.key = `${this.id}-active`
          this.$emit('show')
        } else {
          this.$emit('hide')
        }
      }
    },
  },
  methods: {
    closeOverlay() {
      this.setOverlay(null)
    },
    handleOverlayClick() {
      if (this.canClickOutside) {
        this.closeOverlay()
      }
    },
    handleEscapeKey() {
      if (this.canClickOutside) {
        this.closeOverlay()
      }
    },
    onAfterEnter() {
      if (this.active) {
        this.$emit('shown')
      }
    },
    onAfterLeave() {
      if (!this.active) {
        this.$emit('hidden')
      }
    },
  },
})
</script>

<style scoped>
.overlay-enter-active {
  transition: opacity 0.5s var(--ease-out-circ);

  .popup {
    transition: 0.6s var(--ease-out-expo);
  }
}

.overlay-leave-active {
  transition: opacity 0.4s var(--ease-out-circ);

  .popup {
    transition: 0.35s var(--ease-out-expo);
  }
}

.overlay-enter-from {
  opacity: 0;

  .popup {
    @apply translate-y-3/4 md:translate-y-6;
  }
}

.overlay-leave-to {
  opacity: 0;

  .popup {
    @apply translate-y-full md:translate-y-4;
  }
}
</style>
