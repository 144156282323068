import { mapState } from 'vuex'
import { InformationTypeEnum, SidebarWidthEnum } from '@/enums'

const NUM_FEATURED_PHOTOS = 3

export default {
  data() {
    return {
      NUM_FEATURED_PHOTOS,
    }
  },
  computed: {
    ...mapState(['sidebarWidth']),
    hasDescription() {
      return this.description.length > 0
    },
    hasLongDescription() {
      return this.description.split(' ').length > 50
    },
    hasPhotos() {
      return this.photos.length > 0
    },
    prioritizePhotos() {
      return this.priority.includes(InformationTypeEnum.PHOTO)
    },
    hasVideo() {
      return this.video.length > 0
    },
    prioritizeVideo() {
      return this.priority.includes(InformationTypeEnum.VIDEO)
    },
    videoEmbedUrl() {
      if (!this.video) return ''
      let url = new URL(this.video)
      if (this.video.includes('youtube.com')) {
        url = new URL(this.video.replace('watch?v=', 'embed/'))
        // autoplay video when visible
        //url.searchParams.set('autoplay', '1')
      }
      return url.toString()
    },
    hasMap() {
      return this.mapPois.length > 0
    },
    prioritizeMap() {
      return this.priority.includes(InformationTypeEnum.MAP)
    },
    hasAttractions() {
      return this.attractions.length > 0
    },
    prioritizeAttractions() {
      return this.priority.includes(InformationTypeEnum.ATTRACTION)
    },
    hasProducts() {
      return this.products.length > 0
    },
    prioritizeProducts() {
      return this.priority.includes(InformationTypeEnum.PRODUCT)
    },
    hasRecommendations() {
      return this.recommendations.length > 0
    },
    prioritizeRecommendations() {
      return this.priority.includes(InformationTypeEnum.RECOMMENDATION)
    },
    hasRecommendation() {
      return Object.keys(this.recommendation).length > 0
    },
    wideLayout() {
      return !this.isSidebarOpen //this.sidebarWidth === SidebarWidthEnum.SM
    },
    narrowLayout() {
      return this.sidebarWidth === SidebarWidthEnum.LG && this.isSidebarOpen
    },
  },
}
