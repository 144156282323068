<template>
  <div class="flex items-center gap-2">
    <div class="relative">
      <div class="absolute flex gap-0.5 overflow-hidden" :style="{ width: `${(rating / NUM_STARS) * 100}%` }">
        <IconStarFilled v-for="num in NUM_STARS" :key="num" class="text-amber-300 w-5 h-5 shrink-0" />
      </div>
      <div class="flex gap-0.5">
        <IconStar v-for="num in NUM_STARS" :key="num" class="text-slate-200 w-5 h-5 shrink-0" />
      </div>
    </div>
    <div class="text-sm text-slate-400">
      {{ rating.toFixed(1) }}
      <span v-if="reviews > 0">({{ reviews }})</span>
    </div>
  </div>
</template>

<script lang="ts">
import { IconStar, IconStarFilled } from '@tabler/icons-vue'
import { defineComponent } from 'vue'

const NUM_STARS = 5

export default defineComponent({
  components: {
    IconStar,
    IconStarFilled,
  },
  props: {
    rating: {
      type: Number,
      required: true,
    },
    reviews: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      NUM_STARS,
    }
  },
})
</script>
