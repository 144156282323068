<template>
  <div class="relative bg-gradient-to-br from-emerald-300 via-sky-400 to-violet-500 overflow-hidden">
    <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 flex flex-col gap-2 items-center">
      <div class="globe relative">
        <img class="w-24 aspect-square relative z-10" src="@/assets/earth.png" />
        <Spinner absolute class="z-20 text-white" :size="24" />
      </div>
      <div v-if="canSkip" class="flex flex-col items-center gap-1 mix-blend-multiply">
        <div class="font-serif text-center text-2xl text-blue-violet">Gathering recommendations</div>
        <div class="text-sky-200 text-center cursor-pointer hover:text-amber-200 transition" @click="$emit('skip')">Skip and keep planning</div>
      </div>
    </div>
    <div class="absolute z-1 w-full h-full">
      <div
        v-for="(message, index) in formattedMessages"
        :key="index"
        :style="`animation-delay: ${index * stagger}s; animation-duration: ${messages.length * stagger}s`"
        class="message absolute whitespace-nowrap text-center text-black bg-white/90 flex rounded-full py-3 px-4 shadow-xl leading-tight mix-blend-overlay"
        @animationstart="(e) => onAnimationStart(e, index)"
        @animationiteration="(e) => onAnimationStart(e, index)">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const TARGET_DURATION = 20 // seconds

export default defineComponent({
  props: {
    canSkip: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['skip'],
  data() {
    return {
      messages: [
        `We're searching %numTrips% trips`,
        `Real trips from real people`,
        `Travel experts from around the world`,
        `The most amazing trips`,
        `Handpicked just for you`,
        `Based on exactly what you like`,
        `Saving you hours of research`,
        `We're almost there`,
      ],
    }
  },
  computed: {
    stagger() {
      return TARGET_DURATION / this.messages.length
    },
    formattedMessages() {
      const numTrips = Math.round(5000000 * (1 - Math.random() * 0.5)).toLocaleString()
      return this.messages.map((message) => message.replace('%numTrips%', numTrips))
    },
  },
  methods: {
    onAnimationStart(event: AnimationEvent, index: number) {
      ;(event.target as HTMLElement).style.left = `${50 + 35 * (index % 2 ? 1 : -1) * (index / this.messages.length)}%`
    },
  },
})
</script>

<style scoped>
.message {
  animation: bubble 1s infinite var(--ease-in-out-quad) both;
}
.message:first-child {
  @apply bg-orange-300;
}
.message:nth-child(odd) {
  @apply bg-fuchsia-200;
}
.message:nth-child(even) {
  @apply bg-emerald-200;
}
@keyframes bubble {
  0% {
    top: 100%;
    transform: translateX(-50%) scale(0.85);
    opacity: 0;
  }
  30% {
    transform: translateX(-60%) translateY(-30%) scale(1);
    opacity: 1;
  }
  70% {
    transform: translateX(-40%) translateY(-70%) scale(1);
    opacity: 1;
  }
  100% {
    top: 0%;
    transform: translateX(-50%) translateY(-100%) scale(0.85);
    opacity: 0;
  }
}
.globe:after,
.globe:before {
  @apply absolute inset-0 border border-white rounded-full;
  animation: ring 3s var(--ease-out-circ) infinite;
  content: '';
}
.globe:after {
  animation-delay: 1.5s;
}
@keyframes ring {
  0% {
    transform: scale(0.75);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
</style>
