<template>
  <transition name="toast" appear>
    <div v-if="toastMessage" class="fixed w-screen h-screen top-0 left-0 z-[10001] pointer-events-none flex items-center justify-center">
      <div class="message bg-emerald-400 text-white p-6 rounded-3xl drop-shadow-xl min-w-40 flex flex-col items-center justify-center whitespace-nowrap gap-1">
        <IconCircleCheckFilled class="w-12 h-12 mt-1 text-emerald-200" />
        {{ toastMessage }}
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { IconCircleCheckFilled } from '@tabler/icons-vue'

export default defineComponent({
  components: {
    IconCircleCheckFilled,
  },
  computed: {
    ...mapState(['toastMessage']),
  },
})
</script>

<style scoped>
.toast-enter-active {
  transition: opacity 0.5s var(--ease-out-circ);
  perspective: 500px;

  .message {
    transition: 0.6s var(--ease-out-circ);
  }
}

.toast-leave-active {
  transition: opacity 0.65s var(--ease-out-circ);
  perspective: 500px;

  .message {
    transition: 0.45s var(--ease-in-out-circ);
  }
}

.toast-enter-from {
  opacity: 0;

  .message {
    transform: translateY(-3rem) rotateX(30deg);
  }
}

.toast-leave-to {
  opacity: 0;

  .message {
    transform: translateY(3rem) rotateX(-30deg);
  }
}
</style>
