import { IMPORTANT_USER_KEYWORDS } from '@/constants'
import { Searcher } from 'fast-fuzzy'

export const getPositionOfElement = (element: HTMLElement, position: 'center' | 'left' = 'center') => {
  if (!element) return { x: 0, y: 0 }
  const { left, top, width, height } = element.getBoundingClientRect()
  if (position === 'left') {
    return {
      x: left,
      y: top + height / 2,
    }
  }
  return {
    x: left + width / 2,
    y: top + height / 2,
  }
}

export const getCurvedPath = (start: { x: number; y: number }, end: { x: number; y: number }) => {
  return `M ${start.x} ${start.y} Q ${((start.x + end.x) * 2) / 3} ${((start.y + end.y) / 2) * 2}  ${end.x} ${end.y}`
}

export const animateElementAlongPath = (el: HTMLElement, path: string, duration: number, callback: () => void) => {
  el.style.offsetPath = `path("${path}")`

  const animation = el.animate(
    [
      {
        offsetDistance: '0%',
        opacity: 0,
        display: 'block',
      },
      {
        opacity: 1,
      },
      {
        offsetDistance: '100%',
        opacity: 1,
      },
    ],
    {
      duration,
      easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      fill: 'both',
    }
  )

  animation.onfinish = callback
}

export const slugify = (str: string) => {
  return str.trim().toLowerCase().replace(/ /g, '-')
}

export const unslugify = (str: string) => {
  return str.trim().replace(/[-_]/g, ' ')
}

export const getCommaSeparatedList = (list: string[]) => {
  // comma separated list with "and" before the last item
  return list
    .map((item, index) => {
      if (index === list.length - 1 && list.length > 1) {
        return `and ${item}`
      } else {
        return item
      }
    })
    .join(list.length > 2 ? ', ' : ' ')
}

export const getTravelDetailSlug = (category: string, filter: string) => {
  return `${slugify(category)}-${slugify(filter)}`
}

export const wait = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const fuzzySearch = (message: string) => {
  const messageWords = message.split(' ').filter((w) => w.length >= 3)
  const searcher = new Searcher(messageWords, {
    threshold: 0.9,
    returnMatchData: true,
    ignoreCase: true,
    ignoreSymbols: false,
    normalizeWhitespace: true,
  })
  let matches: any[] = []
  for (const word of IMPORTANT_USER_KEYWORDS) {
    const match = searcher.search(word)
    if (match?.length > 0) {
      matches = matches.concat(match)
    }
  }
  return matches
}
