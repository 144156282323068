import axios from 'axios'
import store from '@/store'

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  validateStatus: () => true, // resolve all responses 2xx, 3xx, 4xx, 5xx, etc
})

api.interceptors.request.use(
  (config) => {
    const { token } = store.state.auth
    config.params = { ...config.params } // add extra params here
    config.headers = Object.assign(
      {
        Authorization: `Bearer ${token}`,
      },
      config.headers
    )
    return config
  },
  (error) => {
    return error
  }
)

api.interceptors.response.use(
  (response) => {
    return Promise.resolve(response)
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default api
