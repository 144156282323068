import messagesJson from '@/config/messages.json'
import { getTravelDetailSlug } from '@/libs/utils'
import { FunctionEnum, InputTypeEnum, MessageRoleEnum, ModuleEventEnum, UserOnboardingStepTypeEnum, TravelDetailEnum } from '@/enums'
import confetti from 'canvas-confetti'
import uniqid from 'uniqid'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
  computed: {
    ...mapState('assistant', ['messages', 'isAssistantTalking']),
  },
  mounted() {
    // listen for confirmed events
    this.$emitter.on(ModuleEventEnum.ADD_TO_PORTFOLIO, (payload: { name: string; params: object }) => {
      this.addToPortfolioModules(payload)
      // additional logic handled by confirmHandler in modules
    })

    this.$emitter.on(ModuleEventEnum.REMOVE_FROM_PORTFOLIO, (name: string) => {
      this.removeFromPortfolioModules(name)
      // additional logic handled by removeHandler in modules
    })

    this.$emitter.on(ModuleEventEnum.ADD_TRIP_INFO, (payload) => {
      if (payload.details.length === 0) return

      // highlight new items in UI

      const el = document.getElementById('entity-center')
      const details = payload.details
        .filter((det) => det.detail_type === TravelDetailEnum.TRIP_INFO)
        .filter((det) => {
          return !this.tripInfo[det.category]?.find((d) => d === det.detail)
        })

      this.$nextTick(() => {
        for (const det of details) {
          const slug = getTravelDetailSlug(det.category, det.detail)
          this.$emitter.emit(ModuleEventEnum.ADD_TO_SIDEBAR, {
            el,
            name: `tripInfo/${slug}`,
            message: 'Saved!',
            panel: 'tripInfo',
          })
        }
      })
    })

    this.$emitter.on(ModuleEventEnum.ADD_USER_INFO, (payload) => {
      if (payload.details.length === 0) return

      // highlight new items in UI

      const el = document.getElementById('entity-center')
      const details = payload.details
        .filter((det) => det.detail_type === TravelDetailEnum.USER_INFO)
        .filter((det) => {
          return !this.userInfo[det.category]?.find((d) => d === det.detail)
        })

      this.$nextTick(() => {
        for (const det of details) {
          const slug = getTravelDetailSlug(det.category, det.detail)
          this.$emitter.emit(ModuleEventEnum.ADD_TO_SIDEBAR, {
            el,
            name: `userInfo/${slug}`,
            message: 'Saved!',
            panel: 'userInfo',
          })
        }
      })
    })

    this.$emitter.on(ModuleEventEnum.REMOVE_TRIP_INFO, ({ category, value }: { category: string; value: string }) => {
      this.removeTripPreference({ category, value })
      this.$store.dispatch('assistant/sendMessage', {
        role: MessageRoleEnum.SYSTEM,
        content: `Forget this detail you know about the trip currently being planned in the category "${category}": ${value}.`,
      })
    })

    this.$emitter.on(ModuleEventEnum.REMOVE_USER_INFO, ({ category, value }: { category: string; value: string }) => {
      this.removeUserPreference({ category, value })
      this.$store.dispatch('assistant/sendMessage', {
        role: MessageRoleEnum.SYSTEM,
        content: `Forget this detail you know about the user in the category "${category}": ${value}.`,
      })
    })

    this.$emitter.on(ModuleEventEnum.SET_SIDEBAR_LOCKED, (value: boolean) => {
      this.setSidebarLocked(value)
    })

    // listen for captureTravelDetails
    this.$emitter.on(FunctionEnum.CAPTURE_TRAVEL_DETAILS, async (payload) => {
      // highlight changes in UI

      this.$emitter.emit(ModuleEventEnum.ADD_TRIP_INFO, payload)
      this.$emitter.emit(ModuleEventEnum.ADD_USER_INFO, payload)

      // put in store

      this.handleCaptureTravelDetails(payload)
    })

    // listen for user-triggered events
    this.$emitter.on(ModuleEventEnum.USER_REQUEST, (event: string) => {
      // handled by requestHandler in modules
    })

    // start new trip
    this.$emitter.on(FunctionEnum.NEW_TRIP, () => {
      this.createTrip()
    })

    // user ends call

    this.$emitter.on(ModuleEventEnum.CALL_END, () => {
      this.hideSidebar()
      this.setActiveModules([])
      setTimeout(() => {
        this.$emitter.emit(FunctionEnum.TRIP_SUMMARY, {})
        this.endCall()
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
        })
      }, 500)
    })

    // listen for chat vs text mode

    this.$emitter.on(ModuleEventEnum.SWITCH_TO_TEXT_INPUT, async ({ enable, quietly = false }: { enable: boolean; quietly?: boolean }) => {
      if (enable) {
        this.setInputMode(InputTypeEnum.TEXT)
        this.savePreference('inputMode', InputTypeEnum.TEXT)
        if (!quietly) {
          if (this.isAssistantTalking) {
            this.$emitter.once(ModuleEventEnum.SPEECH_END, async () => {
              await this.sendMessage({ role: MessageRoleEnum.SYSTEM, content: messagesJson.modeSwitch.text })
            })
          } else {
            await this.sendMessage({ role: MessageRoleEnum.SYSTEM, content: messagesJson.modeSwitch.text })
          }
        }
      } else {
        this.setInputMode(InputTypeEnum.VOICE)
        this.savePreference('inputMode', InputTypeEnum.VOICE)
        if (!quietly) {
          if (this.isAssistantTalking) {
            this.$emitter.once(ModuleEventEnum.SPEECH_END, async () => {
              await this.sendMessage({ role: MessageRoleEnum.SYSTEM, content: messagesJson.modeSwitch.voice })
            })
          } else {
            await this.sendMessage({ role: MessageRoleEnum.SYSTEM, content: messagesJson.modeSwitch.voice })
          }
        }
      }
    })

    // load preferences on assistant start

    this.$emitter.on(ModuleEventEnum.ASSISTANT_STARTED, ({ resuming }) => {
      if (this.loadPreference('inputMode')) {
        this.setInputMode(this.loadPreference('inputMode'))
      }
    })

    // listen for assistant messages

    this.$emitter.on(ModuleEventEnum.ADD_ASSISTANT_MESSAGE, async () => {
      let messages = this.messages

      // messages to send (last 10)
      if (messages.length > 10) {
        messages = messages.slice(-10)
      }

      // filter out non-user messages at the beginning of the list
      messages = messages.map((message) => ({ content: message.transcript, role: message.role }))
      while (messages.length > 0 && messages[0].role !== MessageRoleEnum.USER) {
        messages.shift()
      }

      if (!messages.length) return

      // get predictions
      try {
        const { options } = await this.apiFetchWithTripId(`/predictions`, {
          id: uniqid(),
          function: {
            name: FunctionEnum.MESSAGE_PREDICTIONS,
            arguments: {
              messages,
            },
          },
        })
        if (options) {
          if (Array.isArray(options) && options.length) {
            this.$emitter.emit(FunctionEnum.NEXT_MESSAGE_IDEAS, { options })
          }
        }
      } catch (error) {
        console.error('Error getting predictions', error)
      }
    })

    // listen for onboarding complete message
    this.$emitter.on(
      ModuleEventEnum.SEND_ONBOARDING_STEP_COMPLETED_MESSAGE,
      async (payload: { type: UserOnboardingStepTypeEnum; details: string[]; callback?: () => void }) => {
        // get onboarding step results
        const message = {
          id: uniqid(),
          function: {
            name: FunctionEnum.USER_ONBOARDING,
            arguments: {
              type: payload.type,
              details: payload.details,
            },
          },
        }
        console.log('[userOnboarding] making a call to /onboarding', message)
        try {
          const result = await this.apiFetchWithTripId(`/onboarding`, message)

          if (result.step) {
            if (result.step.type === UserOnboardingStepTypeEnum.IMAGES && (!result.step.images || !result.step.image_labels)) {
              result.step.type = UserOnboardingStepTypeEnum.PRIORITIES
            }
            this.$emitter.emit(FunctionEnum.USER_ONBOARDING, { step: result.step })
          } else {
            // if we didn't get a step, we're done
            // send a callback if provided
            if (payload.callback) {
              payload.callback()
            }
          }
        } catch (error) {
          console.error('Error getting onboarding step', error)
        }
      }
    )
  },
  methods: {
    ...mapActions('assistant', ['sendMessage', 'setInputMode', 'endCall']),
    ...mapActions(['setActiveModules', 'setSidebarLocked']),
    ...mapActions('user', ['createTrip']),
  },
})
