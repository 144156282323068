// function names from the AI assistant

export enum FunctionEnum {
  GO_BACK = 'goBack',
  CLOSE_MODULE = 'closeModule',
  CONFIRM_MODULE = 'confirmModule',
  NEXT_MESSAGE_IDEAS = 'nextMessageIdeas',
  TRAVEL_DATES = 'travelDates',
  TRIP_DESTINATION = 'tripDestination',
  TRAVELERS = 'travelers',
  FLIGHTS = 'flights',
  ACCOMMODATIONS = 'accommodations',
  GET_TRIP_INSPIRATION = 'getTripInspiration',
  GET_TRIP_INSPIRATION_DETAIL = 'getTripInspirationDetail',
  TRIP_RECOMMENDATIONS = 'tripRecommendations',
  TRIP_RECOMMENDATION_DETAIL = 'tripRecommendationDetail',
  TRIP_SUMMARY = 'tripSummary',
  INFORMATION = 'information',
  USER_ONBOARDING = 'userOnboarding_v2',
  CAPTURE_TRAVEL_DETAILS = 'captureTravelDetails',
  MESSAGE_PREDICTIONS = 'messagePredictions',
  TRIPS = 'trips',
  LOGIN = 'login',
  NEW_TRIP = 'newTrip',
  SHOW_MORE = 'showMore',
  FILTER_RESULTS = 'filterResults',

  SAMPLE_FUNCTION = 'sampleFunction',
}

export enum AssistantEnum {
  TOOL_MESSAGE = 'toolMessage',
}

export enum InformationTypeEnum {
  TEXT = 'text',
  PHOTO = 'photo',
  VIDEO = 'video',
  MAP = 'map',
  ATTRACTION = 'attraction',
  ACTIVITY = 'activity',
  PRODUCT = 'product',
  RECOMMENDATION = 'recommendation',
}

export enum InformationCategoryEnum {
  DESTINATION = 'destination',
  HOTEL = 'hotel',
  ACTIVITY = 'activity',
  VIDEO = 'video',
  PHOTO = 'photo',
  MAP = 'map',
}

export enum InformationLayoutEnum {
  DEFAULT = 'default',
  PHOTOS = 'photos',
  VIDEO = 'video',
  MAP = 'map',
  RECOMMENDATIONS = 'recommendations',
  RECOMMENDATION = 'recommendation',
}

export enum TravelDetailEnum {
  USER_INFO = 'user_info',
  TRIP_INFO = 'trip_info',
}

export enum InputTypeEnum {
  TEXT = 'text',
  VOICE = 'voice',
}

export enum BooleanEnum {
  TRUE = 'true',
  FALSE = 'false',
}

export enum ModuleTypeEnum {
  PANEL = 'panel',
  MODAL = 'modal',
  FULLSCREEN = 'fullscreen',
}

export enum SidebarWidthEnum {
  SM = 'sm',
  LG = 'lg',
}

export enum SidebarTabEnum {
  DETAILS = 'details',
  SCRATCHPAD = 'scratchPad',
  ITINERARY = 'itinerary',
}

// UI module event names

export enum ModuleEventEnum {
  CONFIRMED = 'confirmed',
  SWITCH_TO_TEXT_INPUT = 'switchToTextInput',
  USER_REQUEST = 'userRequest',
  ADD_TO_SIDEBAR = 'addToSidebar',
  ADD_ASSISTANT_MESSAGE = 'addAssistantMessage',
  ADD_USER_MESSAGE = 'addUserMessage',
  ADDED_TO_SIDEBAR = 'addedToSidebar',
  SIDEBAR_OPENED = 'sidebarOpened',
  SIDEBAR_CLOSED = 'sidebarClosed',
  TRIP_TITLE = 'tripTitle',
  ADD_TO_PORTFOLIO = 'addToPortfolio',
  REMOVE_FROM_PORTFOLIO = 'removeFromPortfolio',
  ONBOARDING_STEP_COMPLETED = 'onboardingStepCompleted',
  SEND_ONBOARDING_STEP_COMPLETED_MESSAGE = 'sendOnboardingStepCompletedMessage',
  ADD_TRIP_INFO = 'addTripInfo',
  REMOVE_TRIP_INFO = 'removeTripInfo',
  ADD_USER_INFO = 'addUserInfo',
  REMOVE_USER_INFO = 'removeUserInfo',
  HANDLE_FIRST_MODULE = 'handleFirstModule',
  CALL_START = 'callStart',
  CALL_END = 'callEnd',
  SPEECH_START = 'speechStart',
  SPEECH_END = 'speechEnd',
  ASSISTANT_MESSAGE_SENT = 'assistantMessageSent',
  FTUX = 'ftux',
  SET_SIDEBAR_LOCKED = 'setSidebarLocked',
  OPEN_SIDEBAR = 'openSidebar',
  ASSISTANT_STARTED = 'assistantStarted',
}

export enum TravelerTypeEnum {
  ADULT = 'adult',
  CHILD = 'child',
}

export enum CallStatusEnum {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  LOADING = 'loading',
}

export enum MessageTypeEnum {
  TRANSCRIPT = 'transcript',
  FUNCTION_CALL = 'function-call',
  FUNCTION_CALL_RESULT = 'function-call-result',
  TOOL_CALLS = 'tool-calls',
  ADD_MESSAGE = 'add-message',
  CONVERSATION_UPDATE = 'conversation-update',
  MODEL_OUTPUT = 'model-output',
}

export enum MessageRoleEnum {
  USER = 'user',
  SYSTEM = 'system',
  ASSISTANT = 'assistant',
}

export enum ConversationRoleEnum {
  USER = 'user',
  TOOL = 'tool',
  ASSISTANT = 'assistant',
}

export enum TranscriptMessageTypeEnum {
  PARTIAL = 'partial',
  FINAL = 'final',
}

export enum UserOnboardingStepTypeEnum {
  PRIORITIES = 'priorities',
  QUESTION = 'question',
  IMAGES = 'images',
}

export enum ScratchPadItemTypeEnum {
  IMAGE = 'image',
  VIDEO = 'video',
  LINK = 'link',
  CONTENT = 'content',
  ATTRACTION = 'attraction',
  ACTIVITY = 'activity',
  TRIP = 'trip',
  UNKNOWN = 'unknown',
}

export enum RecommendationTypeEnum {
  DESTINATION = 'destination',
  ATTRACTION = 'attraction',
  ACTIVITY = 'activity',
  PRODUCT = 'product',
  TRIP = 'trip',
  ITINERARY = 'itinerary',
  RESTAURANT = 'restaurant',
  ACCOMMODATION = 'accommodation',
}
