<script lang="ts" setup>
import { RecommendationTypeEnum } from '@/enums'
</script>

<template>
  <div>
    <StarRating
      v-if="
        recommendation.type === RecommendationTypeEnum.ATTRACTION &&
        recommendation.attraction.reviews &&
        recommendation.attraction.reviews.combinedAverageRating
      "
      :rating="recommendation.attraction.reviews.combinedAverageRating"
      :reviews="recommendation.attraction.reviews.totalReviews" />
    <StarRating
      v-if="recommendation.type === RecommendationTypeEnum.PRODUCT && recommendation.product.reviews && recommendation.product.reviews.combinedAverageRating"
      :rating="recommendation.product.reviews.combinedAverageRating"
      :reviews="recommendation.product.reviews.totalReviews" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    recommendation: {
      type: Object,
      required: true,
    },
  },
})
</script>
