import './assets/main.css'

import { createApp, Plugin } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// plugins

import globals from './plugins/globals'
import api from './plugins/api'
import currency from './plugins/currency'
import dates from './plugins/dates'
import events from './plugins/events'
import weather from './plugins/weather'
import modules from './plugins/modules'
import filters from './plugins/filters'
import rollbar from './plugins/rollbar'

// ui

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import '@asika32764/vue-animate/dist/vue-animate.css'
import TransitionExpand from '@limonische/vue3-transition-expand'
import '@limonische/vue3-transition-expand/dist/style.css'
import { fullscreenImagePlugin } from 'vue-3-fullscreen-image-directive-plugin'
import 'vue-3-fullscreen-image-directive-plugin/style.css' // Don't forget to import the style
import VueDragscroll from 'vue-dragscroll'
import vue3GoogleLogin from 'vue3-google-login'
import { setupCalendar, Calendar, DatePicker } from 'v-calendar'
import 'v-calendar/style.css'

// map styles

import 'leaflet/dist/leaflet.css'
//import 'vue-map-ui/dist/normalize.css'
import 'vue-map-ui/dist/style.css'
import 'vue-map-ui/dist/theme-all.css'

// mixins

import loaderMixin from './mixins/loader'
import uiMixin from './mixins/ui'
import dataMixin from './mixins/data'
import imagesMixin from './mixins/images'
import preferencesMixin from './mixins/preferences'
import utilsMixin from './mixins/utils'
import apiMixin from './mixins/api'

// directives

import disabled from './directives/disabled'

const app = createApp(App)

// load plugins

app.use(router)
app.use(store)
app.use(globals)
app.use(api)
app.use(currency)
app.use(dates)
app.use(events, store)
app.use(weather, store)
app.use(modules)
app.use(filters)
app.use(rollbar)
app.use(FloatingVue)
app.use(TransitionExpand)
app.use(setupCalendar, {})
app.component('VCalendar', Calendar)
app.component('VDatePicker', DatePicker)
//app.use(VueTailwindDatepicker as unknown as Plugin)
app.use(fullscreenImagePlugin as Plugin)
app.use(VueDragscroll)
app.use(vue3GoogleLogin, {
  clientId: import.meta.env.VITE_OAUTH_GOOGLE_CLIENT_ID,
})
// load mixins

app.mixin(loaderMixin)
app.mixin(uiMixin)
app.mixin(dataMixin)
app.mixin(imagesMixin)
app.mixin(preferencesMixin)
app.mixin(utilsMixin)
app.mixin(apiMixin)

// directives

app.directive('disabled', disabled)

// mount app

app.mount('#app')
