<template>
  <transition-group name="group-grid-item" appear @before-leave="beforeGroupTransitionLeave">
    <slot />
  </transition-group>
</template>

<style>
.group-grid-item-move,
.group-grid-item-enter-active,
.group-grid-item-leave-active {
  transition: all 0.5s var(--ease-in-out-cubic);
}

.group-grid-item-enter-active {
  transition-delay: calc(0.05s * var(--index));
}

.group-grid-item-leave-active {
  position: absolute;
}

.group-grid-item-enter-from,
.group-grid-item-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
</style>
