// adds v-disabled directive to elements to disable when value is true

export default (el, binding) => {
  el.style.transition = 'opacity 0.25s'
  if (binding.value) {
    el.style.pointerEvents = 'none'
    el.style.opacity = 0.5
  } else {
    el.style.pointerEvents = 'auto'
    el.style.opacity = 1
  }
}
