<template>
  <select
    :value="modelValue"
    class="border-2 rounded-full border-slate-300 transition"
    :class="{ 'opacity-25 pointer-events-none': disabled }"
    :disabled="disabled"
    @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)">
    <option v-if="placeholder" value="" disabled selected>{{ placeholder }}</option>
    <option v-for="{ label, value } in options" :key="value" :value="value">{{ label }}</option>
  </select>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    options: {
      type: Array<{ label: string; value: any }>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
})
</script>
