import currency from 'currency.js'
import { App, Plugin } from 'vue'
import { DEFAULT_CURRENCY } from '@/constants'
import axios from 'axios'

const getExchangeRates = async (app) => {
  app.config.globalProperties.$exchangeRates = {}
  try {
    /*
    const { data } = await axios.get(`https://v6.exchangerate-api.com/v6/${import.meta.env.VITE_EXCHANGE_RATE_API_KEY}/latest/${DEFAULT_CURRENCY}`)
    if (data.conversion_rates) {
      app.config.globalProperties.$exchangeRates = data.conversion_rates
    }
    */
  } catch (error) {
    console.error(error)
  }
}

export default {
  install(app: App) {
    app.config.globalProperties.$currency = currency
    getExchangeRates(app)
  },
} as Plugin

declare module 'vue' {
  interface ComponentCustomProperties {
    $currency: typeof currency
    $exchangeRates: object
  }
}
