export const travelAssistant: any = {
  name: 'TravelFox',
  model: {
    // provider: 'openai',
    model: 'gpt-4o',
    provider: 'custom-llm',
    metadataSendMode: 'off',
    url: 'https://api.openai.com/v1',
    // model: 'ft:gpt-4o-2024-08-06:travelfox::A0pvF0Jk',
    temperature: 0,
    emotionRecognitionEnabled: true,
    messages: [
      {
        role: 'system',
        content: `You are an expert travel planner with extensive knowledge of destinations worldwide. A user has asked for your help planning the perfect trip. Your goal is to provide concise, personalized recommendations based on the user's preferences, interests, and specific requirements. Offer advice on the best times to visit, transportation options, safety tips, local customs, packing suggestions, and necessary preparations (e.g., visas, vaccinations). Ask questions to better understand their preferences, such as budget, travel dates, interests, and any special requirements (e.g., accessibility needs, dietary restrictions). Tailor your recommendations based on their responses.
  
  Today's date is {{date}}.
  
  ## Primary Mode of Interaction: ##
  Interactions occur mainly through voice audio. Please ensure that your responses are clear and easy to understand when spoken aloud. For example, unless instructed otherwise, responses should only be in concise paragraph format using complete sentences, no headings and no lists.
  
  ## Flexible Conversation Handling ##
  - Adapt to non-linear conversations, allowing users to change details at any point.
  - Gather trip information in any order, tracking known details and identifying missing information.
  - Allow easy modification of previously provided information.
  - Ask for missing details opportunistically based on the conversation flow.
  - Handle and incorporate user interruptions or tangents gracefully.
  - Process multiple intents in a single user message when necessary.
  - Provide a way for users to revisit or modify earlier parts of the conversation.
  - Offer partial recommendations based on incomplete trip details.
  - Suggest alternatives if user preferences can't be met.
  - Support open-ended travel exploration without requiring specific details.
  
  ## Function Instructions: ##
  - tripDestination(): When a user mentions any place name, geographic feature, or location that could be a travel destination, call the tripDestination function. This includes countries, cities, regions, landmarks, or natural features. If the mention is ambiguous or could refer to multiple locations, ask the user to clarify. Always call this function even if you're not certain about the location - use the confidence field to express your level of certainty. Examples of when to call tripDestination:
    - "I'm thinking about going to Paris" (clear destination)
    - "I love the beaches in Thailand" (country as destination)
    - "Maybe somewhere in Europe" (region as destination)
    - "I want to see the Eiffel Tower" (landmark implying destination)
    - "Springfield sounds nice" (ambiguous location)
    - "I live in Palo Alto and plan on traveling to Ottawa"
  - travelDates(): Call this function when a departure or return date has been specified by the user. If the user has mentioned a time period for their trip without specifying exact dates, try to estimate their dates and set the "details_inferred" parameter to string "true". When estimating dates, if no length of stay has been specified, assume the trip will be 7 days. The departure_date and return_date parameters must be later than today's date and the return_date parameter must not be earlier than the departure_date. If the user has not specified a year, select the first date that is later than today's date. Also call travelDates if the user indirectly implies travel dates, such as mentioning a festival or event that occurs at a specific time. For example:
    - if the user sets a departure_date of February 2, and the current date is 2024-07-09, then the date should be 2025-02-02.
    - if the user specifies a travel date of September 2, and the current date is 2024-07-09, then the date should be 2024-09-02.
  - travelers(): If the user has indicated that others will be traveling with them, but wasn't specific, set the "details_inferred" parameter to string "true".
  - tripRecommendations(): Call this function automatically and immediately after the travel dates and number of travelers have been confirmed by the user.
  - goBack(): Users will be shown different modules during the conversation. Call this function when the user indicates they want to navigate back to a previous module or screen.
  - tripTitle(): Call this function when new trip details such as the dates, destination or number of travelers have been mentioned by the user. For example, if the user says they are going to Ottawa in February for a long weekend, a trip title could be "Winter Wonders: A Frosty February Weekend in Ottawa"
  - newTrip(): Call this function when the user has asked to start a new trip.
  - endCall(): Before calling this function, it is mandatory that you confirm with the user that they are done planning.
  - closeModule(): Call this function when the user has asked that a module be closed, hidden or dismissed.
  - showMore(): Call this function when the user has asked for more information, photos, videos, or recommendations.
  - filterResults(): Call this function when the user has asked to filter the results based on their preferences.
  - confirmModule(): Call this function when the user has asked that the details in a module be confirmed, accepted or acknowledged.
  - findAccommodations(): Prior to calling this function, it is mandatory that the travel dates, destination and number of travelers is known. Call this function when the user has asked for hotel recommendations.
  - findFlights(): Prior to calling this function, it is mandatory that the travel dates, destination and number of travelers is known. Call this function when the user has asked for flight recommendations.
  - captureTravelDetails(): Call this function when the user mentions details about their trip or themselves that could be considered when making travel recommendations. For example: explore local culture, traveling likes or dislikes, dietary restrictions, activity preference, accommodation type preference, preferred method of transportation, interests, budget, must see attractions. Specific examples include, but are not limited to:
    - I want to explore local culture
    - I prefer outdoor activities
    - I'd like to visit historical sites
    - Our budget is around $3,000
    - I'm looking for adventurous activities
    - We prefer quieter, less touristy spots
    - I'm flying out from San Francisco
    - We need wheelchair-accessible accommodations
    - I want to experience local cuisine
    - We're planning a road trip
    - We prefer all-inclusive packages
    - I want to visit the top-rated museums
    - We'll be visiting family along the way
    - I prefer eco-friendly accommodations
    - I want to include some luxury experiences
    - We're leaving on a red-eye flight
  - information(): It is mandatory that this function be called when the user asks a question. The following functions should be prioritize over the information function: "tripDestination", "travelers", "travelDates", "tripRecommendations". It is mandatory that the output of the description parameter be markdown. If the user has asked about things to do or attractions, include a map. Examples of when to call the "information" function:
    - "Can you tell me about india?" (text)
    - "Show me photos of Ottawa" (photo)
    - "Where is Tokyo?" (map)
    - "Can I see a video of the Eiffel Tower?" (video)
  - userOnboarding_v2(): It is mandatory that this function be called when the user indicates that they need help planning their trip, they are uncertain of details, what priorities to consider, expresses uncertainty or indecision about their travel plans. Examples include, but are not limited to, phrases like "I need some ideas", "I don't know what important details I should consider", "I don't know where to go", "I don't know what kind of trip I'm looking for", "I'm not sure where to go.", "I don't know what to do", "I'm not sure what to do", "I'm not sure where to go", "Can you help me decide?", "I'm overwhelmed with choices", "I need some suggestions", "I'm confused about my options", "Can you help me plan?", "I need travel ideas", "I'm uncertain about my trip". The information should use what is known about the user to determine the contents of the step. For example:
      - type: "question"
      - question: "What did you have in mind for your trip?"
      - suggested_questions: ["Are you looking for a relaxing or active vacation?", "Did you have a specific destination in mind?", "Who will be you be traveling with?"]
  
  ## Rules: ##
  - Provide information, answer questions, and make recommendations about travel only. Travel related requests such as photos, videos or maps are allowed.
  - Do not fabricate information. It is acceptable to say you don't know an answer.
  - Present dates in a clear format (e.g., January Twenty Fourth) and Do not mention years in dates. Present time in a clear format (e.g. Four Thirty PM) like: 11 pm can be spelled: eleven pee em. Speak dates gently using English words instead of numbers.
  - If the user repeats the same request, it is fine to repeat the same answer.
  
  Always adhere to the rules in the rules above when responding to the user's messages.
  
  ## Task: ##
  1. Flexibly gather trip information, confirming each detail as it's provided. If the user is uncertain about where to go or what to do, use the userOnboarding_v2 function.
  2. Offer trip inspiration based on known details, even if incomplete.
  3. Adapt to the user's conversation style, allowing for changes and exploration throughout the planning process.`,
      },
    ],
    tools: [
      {
        async: true,
        type: 'function',
        function: {
          name: 'goBack',
          description: 'Call this function when the user has indicated that they want to navigate to the previous screen.',
          // strict: true,
        },
      },
      {
        async: true,
        type: 'function',
        function: {
          name: 'findAccommodations',
          description: 'Searches for accommodations (including hotels, motels, etc.) based on user preferences.',
          parameters: {
            type: 'object',
            properties: {
              number_of_rooms: {
                type: 'integer',
                description: 'Total number of rooms for the booking.',
              },
              budget_range: {
                type: 'object',
                properties: {
                  min_price: {
                    type: 'number',
                    description: 'Minimum price per night in USD.',
                  },
                  max_price: {
                    type: 'number',
                    description: 'Maximum price per night in USD.',
                  },
                },
                description: 'Price range per night.',
              },
            },
          },
        },
      },
      {
        async: true,
        type: 'function',
        function: {
          name: 'captureTravelDetails',
          description:
            'Captures details about the user or their planned trip. If the detail is specific to travel dates, destination or number of travelers, use those functions instead.',
          parameters: {
            type: 'object',
            properties: {
              details: {
                type: 'array',
                description: 'An array of captured details',
                items: {
                  type: 'object',
                  properties: {
                    detail_type: {
                      type: 'string',
                      enum: ['user_info', 'trip_info'],
                      description:
                        'Indicates whether the detail is about the user or the trip. A value of "user_info" should be set when the detail is specific to the user. For example, the following preferences should be considered "user_info": accommodation, dietary restrictions, activity.',
                    },
                    category: {
                      type: 'string',
                      enum: [
                        'personal_preference',
                        'budget',
                        'accommodation',
                        'transportation',
                        'activities',
                        'dietary_requirements',
                        'accessibility_needs',
                        'other',
                      ],
                      description: 'The category of the captured detail',
                    },
                    other_category: {
                      type: 'string',
                      description: 'If category is other, create a category',
                    },
                    detail: {
                      type: 'string',
                      description:
                        'A full sentence in the 3rd person of the detail mentioned by the user. Example 1: user: "I have 2 kids aged 6 & 8" detail: "Has 2 kids aged 6 & 8". Example 2 - user: "Going to Paris for 5 days" detail: "Is planning a 5-day trip to Paris"',
                    },
                    confidence: {
                      type: 'number',
                      minimum: 0,
                      maximum: 1,
                      description: 'Confidence level of the captured detail (0 to 1)',
                    },
                  },
                  required: ['detail_type', 'category', 'detail', 'confidence'],
                },
              },
            },
            required: ['details'],
          },
        },
      },
      {
        async: true,
        type: 'function',
        function: {
          name: 'showMore',
          description: 'Show more information, photos, videos, or recommendations.',
        },
      },
      {
        async: true,
        type: 'function',
        function: {
          name: 'filterResults',
          description: 'Filter the results based on the user\'s preferences.',
          parameters: {
            type: 'object',
            properties: {
              filter: {
                type: 'array',
                items: {
                  type: 'string',
                  description: 'The filter to apply to the results from the list of recommendation filters/tags.',
                },
              },
            },
            required: ['filter'],
          },
        },
      },
      {
        async: true,
        type: 'function',
        function: {
          name: 'newTrip',
          description: 'Start a new trip',
        },
      },
      {
        async: true,
        type: 'function',
        function: {
          name: 'closeModule',
          description: 'Name of the UI module that should be closed',
          // strict: true,
          parameters: {
            type: 'object',
            properties: {
              module: {
                type: 'string',
                enum: ['travelers', 'information', 'tripRecommendations', 'tripDestination', 'travelDates'],
              },
            },
            required: ['module'],
          },
        },
      },
      {
        async: true,
        type: 'function',
        function: {
          name: 'confirmModule',
          description: 'Name of the UI module that should be confirmed',
          // strict: true,
          parameters: {
            type: 'object',
            properties: {
              module: {
                type: 'string',
                enum: ['travelers', 'tripDestination', 'travelDates'],
              },
            },
            required: ['module'],
          },
        },
      },
      {
        async: true,
        type: 'function',
        function: {
          name: 'tripTitle',
          description: 'This function should be triggered when trip details have been specified by the user.',
          // strict: true,
          parameters: {
            type: 'object',
            properties: {
              title: {
                type: 'string',
                description: "Creative title for the user's trip based on their travel details.",
              },
            },
            required: ['title'],
          },
        },
      },
      {
        async: true,
        type: 'function',
        function: {
          name: 'endCall',
          description: `Call this function when the user has indicated and confirmed that they are done planning or would like end the call.`,
          // strict: true,
        },
      },
      {
        async: true,
        type: 'function',
        function: {
          name: 'tripRecommendations',
          description: `This function fetches trip recommendations to be presented to the user. It should be triggered immediately after the travel dates and number of travelers have been confirmed.`,
          // strict: true,
          parameters: {
            type: 'object',
            properties: {
              title: {
                type: 'string',
                description: 'A catchy title for the trip recommendations.',
              },
              query: {
                type: 'string',
                description:
                  'Query appropriate for a google search to find high quality trips on the web based on important details about the user and their travel preferences.',
              },
              tripDetails: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    key: {
                      type: 'string',
                      description:
                        'This is the key or detail for which the values have been set. For example, key can be departure_date, return_date, travelers, location, or other trip details the user mentioned.',
                    },
                    value: {
                      type: 'string',
                      description:
                        "This is the value of the detail which the user has finalized. For example, if the key is location, then the value can be Ottawa, Ontario, Canada if that's what the user has selected.",
                    },
                  },
                  required: ['key', 'value'],
                },
              },
            },
            required: ['tripDetails', 'query', 'title'],
          },
        },
      },
      {
        async: false,
        type: 'function',
        server: {
          url: 'https://northamerica-northeast1-atlantean-stone-433113-q0.cloudfunctions.net/api/onboarding',
        },
        function: {
          name: 'userOnboarding_v2',
          description: `It is mandatory that this function be called when the user indicates that they need help planning their trip, they aren't certain of details, what priorities to consider, expresses uncertainty or indecision about their travel plans. Do not reference the results of this function until specifically instructed to do so.`,
          parameters: {
            type: 'object',
            properties: {
              step: {
                type: 'object',
                properties: {
                  type: {
                    type: 'string',
                    enum: ['question'],
                  },
                  question: {
                    type: 'string',
                    description: 'The question to be displayed.',
                  },
                  category: {
                    type: 'string',
                    description: 'The category of the step. For example: destination, activities, travel preferences...',
                  },
                  suggested_questions: {
                    type: 'array',
                    description:
                      "A list of 3 questions for the 'question' type step. The questions should inform the user on the types of information the user can provide.",
                    items: {
                      type: 'string',
                      description: 'A suggested question.',
                    },
                  },
                },
                required: ['type', 'question', 'category', 'suggested_questions'],
              },
            },
            required: ['step'],
          },
        },
      },
      {
        async: true,
        type: 'function',
        function: {
          name: 'travelDates',
          description: `Use this function when the user's departure and return dates have been specified by the user. Ensure that these dates are later than and relative to the current date of {{date}}. The "details_inferred" parameter should be set to string "true" if the dates have been guessed (e.g. the user says they will be traveling in a particular season or month without specifying the exact date)`,
          // strict: true,
          parameters: {
            type: 'object',
            properties: {
              departure_date: {
                type: 'string',
                format: 'date',
                description: `The start date of the trip.`,
              },
              return_date: {
                type: 'string',
                format: 'date',
                description: `The end date of the trip.`,
              },
              details_inferred: {
                type: 'string',
                enum: ['false', 'true'],
                description:
                  'This defines whether the departure_date or return_date have been inferred based on imprecise information from the user.',
              },
            },
            required: ['departure_date', 'return_date', 'details_inferred'],
          },
        },
      },
      {
        async: true,
        type: 'function',
        function: {
          name: 'findFlights',
          description: 'Searches for flights based on user preferences.',
          parameters: {
            type: 'object',
            properties: {
              origin: {
                type: 'string',
                description: 'The origin airport code of the trip.',
              },
              destination: {
                type: 'string',
                description: 'The destination airport code of the trip.',
              },
              cabin_class: {
                type: 'string',
                description: 'The cabin class of the trip.',
                enum: ["first", "business", "premium_economy", "economy"]
              },
              max_connections: {
                type: 'integer',
                description: 'The maximum number of connections for the trip.',
              },
              departure_time: {
                type: 'object',
                properties: {
                  to: {
                    type: 'string',
                    description: 'The earliest time of the departure in 24 hour format. For example, 23:00',
                  },
                  from: {
                    type: 'string',
                    description: 'The lastest time of the departure in 24 hour format. For example, 17:00',
                  },
                },
                required: ['to', 'from'],
              },
              arrival_time: {
                type: 'object',
                properties: {
                  to: {
                    type: 'string',
                    description: 'The earliest time of the arrival in 24 hour format. For example, 13:00',
                  },
                  from: {
                    type: 'string',
                    description: 'The lastest time of the arrival in 24 hour format. For example, 07:00',
                  },
                },
                required: ['to', 'from'],
              },
              budget_range: {
                type: 'object',
                properties: {
                  min_price: {
                    type: 'number',
                    description: 'Minimum price for the trip.',
                  },
                  max_price: {
                    type: 'number',
                    description: 'Maximum price for the trip.',
                  },
                },
                required: ['min_price', 'max_price'],
                description: 'The budget range for the trip.',
              },
            },
          },
        },
      },
      {
        async: true,
        type: 'function',
        function: {
          name: 'tripDestination',
          description: `This function should be called when the user has mentioned the destination of their trip.`,
          // strict: true,
          parameters: {
            type: 'object',
            properties: {
              confidence: {
                type: 'number',
                minimum: 0,
                maximum: 1,
                description: 'Confidence level in the identified location, from 0 to 1.',
              },
              location: {
                type: 'string',
                description: 'The destination of the trip, including city, state/province, and country.',
              },
              lat_long: {
                type: 'array',
                items: {
                  type: 'number',
                },
                description: 'The latitude and longitude of the trip destination as an array of numbers. Example: [45.424721, -75.695000]',
              },
              funfacts: {
                type: 'array',
                items: {
                  type: 'string',
                },
                description: 'List of up to 5 interesting facts about the trip location.',
              },
            },
            required: ['confidence', 'location', 'lat_long'],
          },
        },
      },
      {
        async: true,
        type: 'function',
        function: {
          name: 'information',
          description: `This function should be called when the user asks a question. This includes requests for videos, photos or maps. Do not reference the results of this function until specifically instructed to do so.`,
          // strict: true,
          parameters: {
            type: 'object',
            properties: {
              title: {
                type: 'string',
                description: 'A title for the information provided.',
              },
              subtitle: {
                type: 'string',
                description: 'A subtitle for the information provided.',
              },
              description: {
                type: 'string',
                description: 'Detailed description of the topic. It is mandatory that this content be formatted using markdown.',
              },
              category: {
                type: 'string',
                description: 'The category of the information provided.',
                enum: [
                  'destination',
                  'hotel',
                  'activity',
                  'transportation',
                  'restaurants',
                  'weather',
                  'budget',
                  'documents',
                  'attraction',
                  'culture',
                  'safety',
                  'local customs',
                  'packing tips',
                  'itinerary planning',
                  'other',
                ],
              },
              tags: {
                type: 'array',
                description: 'Relevant tags for the travel information.',
                items: {
                  type: 'string',
                },
              },
              priority: {
                type: 'array',
                items: {
                  type: 'string',
                  enum: ['photo', 'video', 'text', 'map'],
                },
                description: `The types of content that would help answer the user's request in order of priority. Only the appropriate types of content should be included.`,
              },
              questions: {
                type: 'array',
                description: 'A list of suggested followup questions the user can ask to help plan their trip.',
                items: {
                  type: 'string',
                },
              },
              map: {
                type: 'object',
                description: "Map containing POIs. This argument should only be returned if it would be helpful to the user's request.",
                properties: {
                  zoom_level: {
                    type: 'integer',
                    description:
                      'Map zoom level appropriate for the information being requested. The value should be an integer between 1 and 20, where 1 is the entire world, and 20 is a city block',
                  },
                  pois: {
                    type: 'array',
                    items: {
                      type: 'object',
                      properties: {
                        lat_long: {
                          type: 'array',
                          items: {
                            type: 'number',
                          },
                          description:
                            'The latitude and longitude of the point of interest as an array of numbers. Example: [45.424721, -75.695000]',
                        },
                        name: {
                          type: 'string',
                          description: 'The name of the POI.',
                        },
                      },
                      required: ['lat_long', 'name'],
                    },
                  },
                },
                required: ['zoom_level', 'pois'],
              },
            },
            required: ['title', 'category', 'tags', 'description', 'priority', 'questions'],
          },
        },
      },
      {
        async: true,
        type: 'function',
        function: {
          name: 'tripRecommendationDetail',
          description: `This function is triggered when the user indicates that they want to see more information about one of the recommendations returned by the tripRecommendations function.`,
          // strict: true,
          parameters: {
            type: 'object',
            properties: {
              id: {
                type: 'integer',
                description: 'The id of the trip selected by the user.',
              },
            },
            required: ['id'],
          },
        },
      },
      {
        async: true,
        type: 'function',
        function: {
          name: 'travelers',
          description: `This function extracts the traveler information, such as the number of travelers and if any children, their ages. If the user mentioned that they will be traveling with a spouse, husband or wife only, that counts as 2 adults.`,
          // strict: true,
          parameters: {
            type: 'object',
            properties: {
              travelers: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    traveler_type: {
                      type: 'string',
                      enum: ['adult', 'child'],
                      description:
                        'The type of traveler. If a traveler is aged 18 or older, they are an adult, otherwise they are a child.',
                    },
                    age: {
                      type: 'integer',
                      description: 'The age of the traveler.',
                    },
                    relationship: {
                      type: 'string',
                      enum: ['self', 'spouse', 'child', 'parent', 'friend', 'colleague', 'other', 'unknown'],
                      description: 'The relationship of this traveler to the travel planner.',
                    },
                  },
                  required: ['traveler_type', 'relationship'],
                },
              },
              details_inferred: {
                type: 'string',
                enum: ['false', 'true'],
                description:
                  'This defines whether the number of travelers and their type have been inferred based on imprecise information from the user.',
              },
              group_type: {
                type: 'string',
                enum: ['solo', 'couple', 'family', 'friends', 'business', 'mixed'],
                description: 'The primary composition of the travel group.',
              },
            },
            required: ['travelers', 'details_inferred'],
          },
        },
      },
    ],
  },
  transcriber: {
    provider: 'deepgram',
    model: 'nova-2-general',
    language: 'en-US',
    smartFormat: true,
  },
  voice: {
    provider: '11labs',
    voiceId: 'UbehS5uVuO9VGdy0ysUS',
    model: 'eleven_turbo_v2_5',
    inputMinCharacters: 50, // defaults to 30, but higher number leads to better pronunciation, but increased latency.
  },
  silenceTimeoutSeconds: 180,
  maxDurationSeconds: 600,
  endCallFunctionEnabled: false,
  modelOutputInMessagesEnabled: true,
  backgroundDenoisingEnabled: true, // in beta
  clientMessages: [
    'conversation-update',
    'function-call',
    'function-call-result',
    'hang',
    'model-output',
    'speech-update',
    'status-update',
    'transcript',
    'tool-calls',
    'tool-calls-result',
    'user-interrupted',
    'voice-input',
  ],
};
