<script setup lang="ts">
import { TranscriptMessageTypeEnum, MessageRoleEnum } from '@/enums'
</script>

<template>
  <Overlay @shown="shown">
    <ModuleTitle title="Chat History" size="md" class="mb-2 sticky top-0" />
    <div ref="chat" class="scroll-smooth flex flex-col gap-2 min-h-[50vh] max-h-[50vh] overflow-auto">
      <div
        v-for="message in conversation as TranscriptMessage[]"
        :key="message.id"
        class="px-3 py-2 text-sm max-w-[80%] rounded-t-xl leading-tight"
        :class="{
          'self-start text-left bg-sky-100 rounded-br-xl': message.role === MessageRoleEnum.ASSISTANT,
          'self-end text-right bg-blue-violet-400 text-white rounded-bl-xl': message.role === MessageRoleEnum.USER,
        }">
        <div v-if="message.role === MessageRoleEnum.ASSISTANT">
          <div v-if="message.transcriptType === TranscriptMessageTypeEnum.PARTIAL">{{ message.transcript }}</div>
          <div v-else>{{ message.transcript }}</div>
        </div>
        <div v-if="message.role === MessageRoleEnum.USER">
          {{ message.transcript }}
        </div>
      </div>
    </div>
    <ChatTextInput class="mt-2" size="sm" />
  </Overlay>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { TranscriptMessage } from '@/types'
import { MessageTypeEnum } from '@/enums'

export default defineComponent({
  computed: {
    ...mapState('assistant', ['messages', 'activeMessage', 'modelOutput']),
    conversation() {
      return [...this.messages.filter((m) => m.type === MessageTypeEnum.TRANSCRIPT)]
    },
  },
  watch: {
    'messages.length'() {
      this.scrollToBottom()
    },
  },
  methods: {
    shown() {
      this.scrollToBottom()
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatEl = this.$refs.chat as HTMLElement
        chatEl.scrollTop = chatEl.scrollHeight
      })
    },
  },
})
</script>
