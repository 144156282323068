<script lang="ts" setup>
import { SidebarTabEnum } from '@/enums'
</script>

<template>
  <div
    id="sidebar"
    class="absolute p-4 top-0 right-0 bottom-0 transition-all duration-[700ms]"
    :style="[isFullScreen ? `--sidebar-width: 100vw` : '', { width: `var(--sidebar-width)`, transitionDuration: `${SIDEBAR_TRANSITION_DURATION}ms` }]"
    :class="{ 'translate-x-full': !isSidebarOpen }"
    @animationend="onAnimationEnd">
    <div class="relative bg-white shadow-xl h-full rounded-4xl gap-3 flex flex-col">
      <div class="relative rounded-t-4xl flex items-center justify-between px-3 pt-4 bg-gradient-to-b from-slate-50 to-slate-100">
        <nav class="flex items-center gap-1">
          <TabButton :selected="sidebarTab === SidebarTabEnum.DETAILS" @click="setSidebarTab(SidebarTabEnum.DETAILS)">
            <IconGlobe class="w-4 h-4 opacity-50" />
            Details
          </TabButton>
          <TabButton :selected="sidebarTab === SidebarTabEnum.SCRATCHPAD" @click="setSidebarTab(SidebarTabEnum.SCRATCHPAD)">
            <IconPin class="w-4 h-4 opacity-50" />
            Scratchpad
          </TabButton>
          <TabButton :selected="sidebarTab === SidebarTabEnum.ITINERARY" @click="setSidebarTab(SidebarTabEnum.ITINERARY)">
            <IconClipboardCheck class="w-4 h-4 opacity-50" />
            Itinerary
          </TabButton>
        </nav>
        <div class="flex items-center absolute right-3">
          <IconLayoutSidebarRightExpandFilled
            v-if="[SidebarTabEnum.SCRATCHPAD, SidebarTabEnum.ITINERARY].includes(sidebarTab)"
            :class="{ 'rotate-180': isFullScreen }"
            class="w-10 h-6 px-2 text-blue-violet-300 hover:text-blue-violet cursor-pointer transition"
            @click="toggleFullScreen" />
          <IconChevronRight class="w-10 h-6 px-2 text-blue-violet-300 hover:text-blue-violet cursor-pointer transition" @click="hideSidebar" />
        </div>
      </div>
      <div class="overflow-auto flex-auto" :class="{ 'pb-16': config.includeChatHistory, 'pb-4': !config.includeChatHistory }">
        <transition name="fade" mode="out-in">
          <SidebarTripDetails v-if="sidebarTab === SidebarTabEnum.DETAILS" :ref="`Sidebar/tripDetails`" />
          <SidebarScratchPadItinerary
            v-else-if="[SidebarTabEnum.SCRATCHPAD, SidebarTabEnum.ITINERARY].includes(sidebarTab)"
            :columns="isFullScreen"
            :selected="sidebarTab"
            @fullscreen="isFullScreen = true" />
        </transition>
      </div>
      <nav v-if="config.includeChatHistory" class="absolute bottom-3 left-3 right-3 p-3 gap-2 bg-white/90 flex items-center justify-center z-10">
        <MiniButton type="light" @click="setOverlay('chatLog')">Chat history</MiniButton>
      </nav>
    </div>
    <ChatLogOverlay id="chatLog" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { SIDEBAR_TRANSITION_DURATION } from '@/constants'
import { ModuleEventEnum, SidebarWidthEnum } from '@/enums'
import { mapActions, mapState } from 'vuex'
import { IconPin, IconClipboardCheck, IconGlobe, IconChevronRight, IconLayoutSidebarRightExpandFilled } from '@tabler/icons-vue'
import config from '@/config/index.json'

export default defineComponent({
  components: {
    IconPin,
    IconClipboardCheck,
    IconGlobe,
    IconChevronRight,
    IconLayoutSidebarRightExpandFilled,
  },
  data() {
    return {
      SIDEBAR_TRANSITION_DURATION,
      openSection: null as string | null,
      closedSections: [] as string[],
      sidebarWidths: {
        details: SidebarWidthEnum.SM,
        scratchPad: SidebarWidthEnum.LG,
        itinerary: SidebarWidthEnum.LG,
      },
      isFullScreen: false,
      config,
    }
  },
  computed: {
    ...mapState(['sidebarTab']),
  },
  watch: {
    sidebarTab() {
      this.setSidebarWidth(this.sidebarWidths[this.sidebarTab])
    },
    isSidebarOpen() {
      if (!this.isSidebarOpen) {
        this.isFullScreen = false
      }
    },
  },
  mounted() {
    this.$emitter.on(ModuleEventEnum.ADD_TO_SIDEBAR, this.addToSidebar)
    this.$emitter.on(ModuleEventEnum.OPEN_SIDEBAR, this.handleShowSidebar)
    this.setSidebarTab(SidebarTabEnum.DETAILS)
  },
  unmounted() {
    this.$emitter.off(ModuleEventEnum.ADD_TO_SIDEBAR, this.addToSidebar)
    this.$emitter.off(ModuleEventEnum.OPEN_SIDEBAR, this.handleShowSidebar)
  },
  methods: {
    ...mapActions(['setSidebarWidth']),
    addToSidebar() {
      // first portfolio item added to the sidebar, show sidebar
      if (!this.firstItemAddedToSidebar) {
        this.$emitter.once(ModuleEventEnum.ADDED_TO_SIDEBAR, () => {
          this.setFirstItemAddedToSidebar(true)
          if (config.showSidebarOnFirstUse) {
            this.showSidebar()
          }
        })
      }
    },
    handleShowSidebar({ tab, panel }: { tab?: string; panel?: string }) {
      this.showSidebar()
      if (tab && this.sidebarTab !== tab) {
        this.setSidebarTab(tab)
      }
      if (panel && this.openSection !== panel) {
        this.$refs[`Sidebar/tripDetails`].toggleSection(panel, true)
      }
    },
    onAnimationEnd() {
      if (!this.isSidebarOpen) {
        this.$emitter.emit(ModuleEventEnum.SIDEBAR_CLOSED)
      } else {
        this.$emitter.emit(ModuleEventEnum.SIDEBAR_OPENED)
      }
    },
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen
    },
  },
})
</script>
