<script lang="ts" setup>
import { ScratchPadItemTypeEnum } from '@/enums'
</script>

<template>
  <div class="group/card bg-slate-200 relative rounded-3xl aspect-[4/5] overflow-hidden p-3 flex flex-col justify-end transition hover:shadow-xl">
    <PinButton
      class="absolute top-2 right-2 z-10"
      :item="{
        type: ScratchPadItemTypeEnum.ACTIVITY,
        id: activity.productCode,
        data: { title: activity.title, image: coverImage, url: activity.productUrl, original: activity },
      }" />
    <Image :src="coverImage" class="cursor-pointer text-blue-violet !absolute inset-0" @click="setOverlay(id)" />
    <div
      class="cursor-pointer flex flex-col gap-3 bg-white group-hover/card:-translate-y-1 group-hover/card:shadow-xl transition-all duration-[400ms] rounded-2xl relative z-10 p-4"
      @click="setOverlay(id)">
      <h3 class="text-2xl text-blue-violet font-serif leading-none text-balance">{{ activity.title }}</h3>
      <StarRating v-if="activity.reviews" :rating="activity.reviews.combinedAverageRating" :reviews="activity.reviews.totalReviews" />
    </div>
  </div>
  <ActivityOverlay :id="id" :activity="activity" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import viatorMixins from '@/mixins/viator'
import { DEFAULT_CURRENCY, LOCAL_CURRENCY } from '@/constants'
import uniqid from 'uniqid'

export default defineComponent({
  mixins: [viatorMixins],
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      DEFAULT_CURRENCY,
      LOCAL_CURRENCY,
      uniqid: uniqid(),
    }
  },
  computed: {
    id() {
      return `attraction-${this.uniqid}-${this.activity.productCode}`
    },
    coverImage() {
      const image = this.activity.images.find((image) => image.isCover) || this.activity.images[0]
      return this.getBestImage(image?.variants) ?? ''
    },
  },
})
</script>
