<template>
  <Responsive
    v-slot="{ breakpoint }"
    :breakpoints="{
      short: (el) => (paddingTop && paddingBottom ? el.height < 500 : el.height < 650),
      tall: (el) => el.height > 1000,
      large: (el) => el.width > 1400,
    }"
    class="fixed top-0 left-0 bottom-0 bg-gradient-to-br from-amber-50 to-sand z-[1000]"
    tabindex="0"
    :class="[containerClass, { 'pt-24': paddingTop, 'pb-48': paddingBottom, 'sidebar-open-padding-1/2': withSidebar, 'right-0': !withSidebar }]">
    <slot name="background" />
    <div class="relative w-screen h-full">
      <slot :breakpoint="breakpoint" />
    </div>
  </Responsive>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

export default defineComponent({
  props: {
    containerClass: {
      type: String,
      default: '',
    },
    paddingBottom: {
      type: Boolean,
      default: true,
    },
    paddingTop: {
      type: Boolean,
      default: true,
    },
    withSidebar: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.setFullScreenModule(true)
  },
  unmounted() {
    this.setFullScreenModule(false)
  },
  methods: {
    ...mapActions(['setFullScreenModule']),
  },
})
</script>
