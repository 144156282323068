<template>
  <div ref="el">
    <slot :breakpoint="breakpoint" :width="width" :height="height" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ref } from 'vue'
import { useResizeObserver } from '@vueuse/core'

export default defineComponent({
  props: {
    breakpoints: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const el = ref(null)
    const width = ref(0)
    const height = ref(0)

    useResizeObserver(el, (entries) => {
      const entry = entries[0]
      width.value = entry.contentRect.width
      height.value = entry.contentRect.height
    })

    return {
      el,
      width,
      height,
    }
  },
  computed: {
    breakpoint() {
      return Object.fromEntries(Object.entries(this.breakpoints).map(([k, v]) => [k, v({ width: this.width, height: this.height })]))
    },
  },
})
</script>
