<template>
  <transition-group name="group-list-item" appear @before-leave="beforeGroupTransitionLeave">
    <slot />
  </transition-group>
</template>

<style>
.group-list-item-move,
.group-list-item-enter-active,
.group-list-item-leave-active {
  transition: all 0.35s var(--ease-in-out-cubic);
}

.group-list-item-enter-active {
  transition-delay: calc(0.05s * var(--index));
}

.group-list-item-leave-active {
  position: absolute;
}

.group-list-item-enter-from,
.group-list-item-leave-to {
  opacity: 0;
  transform: scale(0.97);
}
</style>
