import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import { App, Plugin } from 'vue'

export default {
  install(app: App) {
    dayjs.extend(relativeTime)
    dayjs.extend(quarterOfYear)
    dayjs.extend(utc)
    dayjs.extend(customParseFormat)
    dayjs.extend(duration)
    app.config.globalProperties.$dayjs = dayjs
  },
} as Plugin

declare module 'vue' {
  interface ComponentCustomProperties {
    $dayjs: typeof dayjs
  }
}
