<template>
  <div v-if="dailyForecast" class="flex items-center">
    <img v-tooltip="dailyForecast.description" :src="dailyForecast.image" class="w-8 h-8 -my-1" />
    <span>
      <span class="font-medium">{{ dailyForecast.temperature_max }}°C</span>
      / {{ dailyForecast.temperature_min }}°C
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import WeatherCodes from '@/data/weathercodes.json'

export default defineComponent({
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(['weatherForecast']),
    dailyForecast() {
      const index = this.weatherForecast?.time?.indexOf(this.date)
      if (index > -1) {
        return {
          ...(WeatherCodes?.[this.weatherForecast.weather_code[index]]?.day ?? {}),
          temperature_max: Math.round(this.weatherForecast.temperature_2m_max[index]),
          temperature_min: Math.round(this.weatherForecast.temperature_2m_min[index]),
        }
      }
      return null
    },
  },
})
</script>
