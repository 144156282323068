import { RecommendationType } from '@/types'
import { PropType } from 'vue'

const DEFAULT_MAP_ZOOM_LEVEL = 10

export default {
  props: {
    breakpoint: {
      type: Object,
      default: () => ({}),
    },
    functionCall: {
      type: Object,
      default: () => ({}),
    },
    priority: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    photos: {
      type: Array as PropType<object[]>,
      default: () => [],
    },
    video: {
      type: String,
      default: '',
    },
    mapLatLong: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    mapPois: {
      type: Array as PropType<object[]>,
      default: () => [],
    },
    mapZoomLevel: {
      type: Number,
      default: DEFAULT_MAP_ZOOM_LEVEL,
    },
    attractions: {
      type: Array as PropType<object[]>,
      default: () => [],
    },
    products: {
      type: Array as PropType<object[]>,
      default: () => [],
    },
    categories: {
      type: Array as PropType<object[]>,
      default: () => [],
    },
    filters: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    selectedCategories: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    selectedFilters: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    recommendation: {
      type: Object as PropType<RecommendationType>,
      default: () => ({}),
    },
    recommendations: {
      type: Array as PropType<RecommendationType[]>,
      default: () => [],
    },
  },
}
