<template>
  <transition name="blur">
    <div
      v-if="suggestions.length > 0"
      data-module="FtuxModule"
      class="absolute w-full h-full flex flex-col items-center justify-center pb-8 transition duration-[700ms]"
      :class="{ 'sidebar-open-translate-1/2': isSidebarOpen }">
      <div class="flex flex-col justify-center items-center gap-10 max-w-xl">
        <transition-group name="suggestion" appear>
          <div v-for="(suggestion, index) in suggestions" :key="suggestion" class="flex flex-wrap">
            <div ref="suggestionEls" class="text-balance text-center text-3xl text-blue-violet leading-tight" v-html="suggestion" />
          </div>
        </transition-group>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import { ModuleEventEnum, MessageRoleEnum, MessageTypeEnum, TranscriptMessageTypeEnum } from '@/enums'

export default defineComponent({
  data() {
    return {
      suggestions: [] as string[],
    }
  },
  computed: {
    ...mapState(['informationModules']),
    ...mapState('assistant', ['instance']),
    numAnyModules() {
      return this.activeModules.length + this.informationModules.length
    },
  },
  watch: {
    numAnyModules(value) {
      if (value > 0) {
        // as soon as a module is activated, hide this
        this.suggestions = []
        this.setFtuxModule(false)
      }
    },
  },
  mounted() {
    this.$emitter.on(ModuleEventEnum.FTUX, this.responseSuggestionsHandler)
  },
  unmounted() {
    this.$emitter.off(ModuleEventEnum.FTUX, this.responseSuggestionsHandler)
  },
  methods: {
    ...mapActions(['setFtuxModule']),
    ...mapActions('assistant', ['addMessage']),
    responseSuggestionsHandler({ introduction, suggestions = [], variables = [] }: { introduction?: string; suggestions: string[]; variables: object[] }) {
      const ftuxCompleted = this.loadPreference('ftuxCompleted')
      if (ftuxCompleted) return
      this.setFtuxModule(true)
      this.suggestions = this.formatSuggestions(suggestions, variables)

      // output message and suggestions
      this.$nextTick(() => {
        let output = introduction ?? ''
        this.$refs.suggestionEls.forEach((el: HTMLElement, index: number) => {
          output += el.innerText
          if (index < this.suggestions.length - 1) {
            output += ', or '
          }
        })
        if (this.instance && output) {
          this.instance.say(output)
          if (introduction) {
            this.addMessage({
              role: MessageRoleEnum.ASSISTANT,
              transcript: introduction,
              type: MessageTypeEnum.TRANSCRIPT,
              transcriptType: TranscriptMessageTypeEnum.FINAL,
            })
          }
        }
      })
      this.savePreference('ftuxCompleted', true)
    },
    formatSuggestions(suggestions: string[], variables: object[]) {
      return suggestions.map((suggestion) => {
        const vars = variables[Math.floor(Math.random() * variables.length)]
        if (!isNaN(vars['when'])) {
          vars['when'] = this.$dayjs().add(vars['when'], 'months').format('MMMM')
        }
        return (
          '<span class="relative z-1 opacity-50 -ml-4">&ldquo;</span>' +
          suggestion
            .replace(/\[([^\]]*?)\]/g, (match, val, offset) => `<span class="variable ${val}" style="--index: ${offset % 5}">${vars?.[val]}</span>`)
            .replace(/\*([^*]*?)\*/g, (match, text, offset) => `<span class="variable" style="--index: ${offset % 5}">${text}</span>`) +
          '<span class="relative z-1 opacity-50">&rdquo;</span>'
        )
      })
    },
  },
})
</script>

<style scoped>
:deep(.variable) {
  animation: pulse 12s infinite backwards;
  animation-delay: calc(2s * var(--index));
  @apply font-medium text-blue-violet-700 rounded-md px-1 -mx-1;
}

@keyframes pulse {
  0% {
    @apply bg-sky-100;
  }
  25% {
    @apply bg-emerald-100;
  }
  50% {
    @apply bg-amber-100;
  }
  75% {
    @apply bg-violet-100;
  }
  100% {
    @apply bg-sky-100;
  }
}

.bubble:nth-child(odd) {
  @apply rounded-l-2xl rounded-r-2xl;

  .tail {
    @apply right-16;

    .shape {
      @apply rotate-45 right-0 origin-bottom-right;
    }
  }
}

.bubble:nth-child(even) {
  @apply rounded-r-2xl rounded-l-2xl;

  .tail {
    @apply left-16;

    .shape {
      @apply -rotate-45 left-0 origin-bottom-left;
    }
  }
}

.blur-enter-active,
.blur-leave-active {
  transition: all 1s ease;
}

.blur-enter-from,
.blur-leave-to {
  @apply opacity-0 blur-xl;
}

.suggestion-enter-active,
.suggestion-leave-active {
  transition: all 3s var(--ease-out-circ);
  transition-delay: calc(0.25s * var(--index));
}

.suggestion-enter-from,
.suggestion-leave-to {
  opacity: 0;
  transform: translateY(50px);
}
</style>
