import { App, Plugin } from 'vue'

// globally register core components

export default {
  install(app: App) {
    const componentFiles = import.meta.glob('../components/**/*.vue', { eager: true })

    Object.entries(componentFiles).forEach(([path, m]: [string, any]) => {
      const componentName =
        path
          ?.split('/')
          ?.pop()
          ?.replace(/\.\w+$/, '') ?? ''
      if (!componentName) return
      app.component(componentName, m.default)
    })
  },
} as Plugin
