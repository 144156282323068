import { App, defineAsyncComponent } from 'vue'
import { Store } from 'vuex'
import { Router } from 'vue-router'
import Module from '@/modules/module'
import { FunctionEnum } from '@/enums'

const size = 'xl'

export default ({ name, store, app }: { name: string; router?: Router; store: Store; app: App }) => {
  // create module
  const module = new Module({ name, size, store, app })

  // register event handlers
  module.registerEventHandlers([
    {
      event_name: FunctionEnum.GET_TRIP_INSPIRATION,
      shownHandler: ({ toolCallId }) => {
        return `The user has been shown the results of the ${FunctionEnum.GET_TRIP_INSPIRATION} tool call with id ${toolCallId}, so you may now 
        reference that content. The user has been shown several trip options in the UI to inspire them. Please give a brief 1-2 sentence summary 
        of the description presented. After that, guide them to review the trips and ask if they need any further assistance.`
      },
      confirmHandler: (params) => {
        // TODO: figure out how to get the selected idea
        return `The user has confirmed that they like one of these ideas`
      },
      inferredHandler: (params) => {
        return `Can we confirm with the user which of these trip ideas they like?`
      },
      removeHandler: () => {
        return `Please forget about the trip idea the user selected earlier`
      },
      requestHandler: () => {
        return `The user would like to talk about some trip ideas`
      },
      portfolioComponent: defineAsyncComponent(() => import('./components/Portfolio.vue')),
    },
  ])

  // add module store
  module.addModuleStore({
    state: {
      // ...
    },
    actions: {
      // ...
    },
    mutations: {
      // ...
    },
  })
}
