<template>
  <component :is="icon" stroke-width="1.5" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { RecommendationTypeEnum } from '@/enums'
import { IconBuildingCarousel, IconIceSkating, IconPlane, IconWorld, IconToolsKitchen2, IconBed } from '@tabler/icons-vue'

export default defineComponent({
  components: {
    IconBuildingCarousel,
    IconIceSkating,
    IconPlane,
    IconWorld,
    IconToolsKitchen2,
    IconBed,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    icon() {
      switch (this.type) {
        case RecommendationTypeEnum.DESTINATION:
          return 'IconWorld'
        case RecommendationTypeEnum.ATTRACTION:
          return 'IconBuildingCarousel'
        case RecommendationTypeEnum.ACTIVITY:
        case RecommendationTypeEnum.PRODUCT:
          return 'IconIceSkating'
        case RecommendationTypeEnum.TRIP:
          return 'IconPlane'
        case RecommendationTypeEnum.RESTAURANT:
          return 'IconToolsKitchen2'
        case RecommendationTypeEnum.ACCOMMODATION:
          return 'IconBed'
      }
      return null
    },
  },
})
</script>
