<template>
  <Responsive
    v-slot="{ breakpoint }"
    class="bg-white p-3 rounded-5xl shadow-xl relative flex flex-col"
    :breakpoints="{
      small: (el) => el.width <= 400,
      medium: (el) => el.width > 400 && el.width <= 700,
      large: (el) => el.width > 700,
      short: (el) => el.height <= 500,
      tall: (el) => el.height > 650,
    }">
    <ModuleTitle v-if="title" :title="title" :can-close="true" @close="close" />
    <Transition name="module" appear>
      <div v-if="visible" class="module flex flex-auto relative">
        <slot :breakpoint="breakpoint" />
      </div>
    </Transition>
  </Responsive>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  data() {
    return {
      visible: false,
    }
  },
  mounted() {
    this.visible = true
  },
  unmounted() {
    this.visible = false
  },
  methods: {
    close() {
      this.visible = false
      this.$emit('close')
    },
  },
})
</script>

<style scoped>
.module-enter-active {
  transition: all 0.6s var(--ease-in-out-circ);
}

.module-leave-active {
  transition: all 0.15s var(--ease-out-circ);
}

.module-enter-from {
  opacity: 0;
  transform: scale(0.97);
}

.module-leave-to {
  opacity: 0;
  transform: scale(0.85);
}
</style>
