<template>
  <a
    class="w-12 shrink-0 aspect-square bg-violet-100 text-blue-violet rounded-full flex items-center justify-center cursor-pointer hover:-rotate-90 transition duration-300 z-10">
    <IconX class="w-6 h-6" />
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { IconX } from '@tabler/icons-vue'

export default defineComponent({
  components: { IconX },
})
</script>
