<script lang="ts" setup>
import { ScratchPadItemTypeEnum } from '@/enums'
</script>

<template>
  <div v-if="!error" class="group/image relative overflow-hidden">
    <Spinner v-if="loading" absolute />
    <img
      v-if="src"
      :src="src"
      :class="{ 'opacity-0': loading, 'opacity-100': !loading, 'pointer-events-none': !gallery, 'cursor-pointer': gallery }"
      :alt="caption"
      class="w-full h-full object-cover transition duration-500"
      :gallery="gallery"
      @click="gallery?.open"
      @load="onLoad"
      @error="onError" />
    <IconZoom
      v-if="!loading && gallery"
      class="absolute z-10 w-12 h-12 text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/3 pointer-events-none opacity-0 group-hover/image:opacity-100 group-hover/image:-translate-y-1/2 transition duration-500 pointer-events-none" />
    <p
      v-if="caption && !loading"
      class="absolute bottom-4 left-4 z-10 py-1 px-3 text-white text-xs bg-black/50 rounded-full transition duration-500 pointer-events-none"
      :class="{ 'opacity-0': loading, 'opacity-0 group-hover/image:opacity-100': !loading }">
      {{ caption }}
    </p>
    <PinButton v-if="canSave" :item="{ type: ScratchPadItemTypeEnum.IMAGE, id: src, data: { src, caption } }" class="absolute z-10 top-2 right-2" />
    <slot />
  </div>
</template>

<script lang="ts">
import { IconZoom } from '@tabler/icons-vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    IconZoom,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    caption: {
      type: String,
      default: '',
    },
    gallery: {
      type: Object,
      default: null,
    },
    canSave: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['load', 'error', 'dimensions'],
  data() {
    return {
      loading: true,
      error: false,
    }
  },
  watch: {
    src() {
      this.loading = true
      this.error = false
    },
  },
  mounted() {
    if (!this.src) {
      this.loading = false
    }
  },
  methods: {
    onLoad(e) {
      this.$emit('dimensions', { width: e.target.naturalWidth, height: e.target.naturalHeight })
      this.loading = false
      this.$emit('load')
    },
    onError() {
      this.loading = false
      this.error = true
      this.$emit('error')
    },
  },
})
</script>
