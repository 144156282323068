<template>
  <button
    class="w-12 h-12 p-2 relative text-blue-violet bg-white rounded-full flex items-center justify-center cursor-pointer transition hover:bg-emerald-400 hover:text-white hover:scale-110"
    @click="showSidebar">
    <IconListCheck stroke-width="1.5" class="w-full h-full" />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { IconListCheck } from '@tabler/icons-vue'
import { ModuleEventEnum } from '@/enums'
import { pulse } from '@asika32764/vue-animate'

export default defineComponent({
  components: { IconListCheck },
  mounted() {
    this.$emitter.on(ModuleEventEnum.ADDED_TO_SIDEBAR, this.addedToSidebar)
  },
  unmounted() {
    this.$emitter.off(ModuleEventEnum.ADDED_TO_SIDEBAR, this.addedToSidebar)
  },
  methods: {
    addedToSidebar() {
      pulse(this.$el as HTMLElement)
    },
  },
})
</script>

<style scoped>
.animate__animated {
  @apply bg-purple-500 text-white duration-[700ms];
}
.animate__animated:after {
  @apply absolute inset-0 border-4 border-purple-500 rounded-full;
  animation: ring 1s var(--ease-out-circ);
  content: '';
}
@keyframes ring {
  0% {
    transform: scale(1.25);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
</style>
