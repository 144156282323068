import { Store } from 'vuex'

export default (store: Store) => {
  const watcher = (state) => {
    return state.activeModules.length + state.informationModules.length === 0
  }
  const callback = async (isClear: Boolean) => {
    if (isClear) {
      store.dispatch('runNextQueuedFunction')
    }
  }
  const unwatch = store.watch(watcher, callback)
}
