<template>
  <div v-if="hasDates" class="h-full">
    <div v-if="layout === 'list'" class="flex flex-col gap-4">
      <div v-for="(dayItems, index) in items" :key="index" class="flex flex-col gap-2 pt-4 first:pt-0">
        <div class="flex items-center gap-2 px-2">
          <div class="font-medium text-lg inline-flex">Day {{ index + 1 }}</div>
          <DailyWeather class="text-sm text-slate-400" :date="$dayjs(portfolio.departure_date).add(index, 'days').format('YYYY-MM-DD')" />
          <div class="ml-auto text-sm text-slate-400 inline-flex">
            {{ $dayjs(portfolio.departure_date).add(index, 'days').format('dddd, MMMM D') }}
          </div>
        </div>
        <div class="relative">
          <div v-if="dayItems.length === 0" class="absolute inset-0 flex items-center justify-center text-blue-violet-200 text-sm">No plans today!</div>
          <DraggableList
            :list="dayItems"
            v-bind="dragOptions"
            class="p-4 rounded-xl flex flex-col gap-2"
            :class="{ 'min-h-24 bg-violet-50': dayItems.length === 0, 'bg-sky-100': dayItems.length > 0 }"
            @change="setItinerary(items)">
            <template #item="{ element: item }">
              <ItineraryElement :item="item" @remove="removeItem(index, item)" />
            </template>
          </DraggableList>
        </div>
      </div>
    </div>
    <div v-if="layout === 'calendar'" class="h-full overflow-auto">
      <div class="h-full flex gap-4">
        <div v-for="(dayItems, index) in items" :key="index" class="basis-32 shrink-0 grow-0 flex flex-col gap-4 overflow-hidden">
          <div class="flex flex-col leading-tight px-2">
            <div class="font-medium text-sm inline-flex">Day {{ index + 1 }}</div>
            <div class="text-xs text-slate-400">
              {{ $dayjs(portfolio.departure_date).add(index, 'days').format('ddd, MMM D') }}
            </div>
            <DailyWeather class="text-sm text-slate-400 mt-1 -ml-2" :date="$dayjs(portfolio.departure_date).add(index, 'days').format('YYYY-MM-DD')" />
          </div>
          <div class="relative rounded-xl flex-auto overflow-auto" :class="{ 'bg-violet-50': dayItems.length === 0, 'bg-sky-100': dayItems.length > 0 }">
            <div v-if="dayItems.length === 0" class="absolute inset-0 flex items-center justify-center text-blue-violet-200 text-sm">No plans today!</div>
            <DraggableList :list="dayItems" v-bind="dragOptions" class="h-full p-4 flex flex-col gap-4" @change="setItinerary(items)">
              <template #item="{ element: item }">
                <ItineraryElement :item="item" @remove="removeItem(index, item)" />
              </template>
            </DraggableList>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="min-h-full flex flex-col items-center justify-center gap-6">
    <IconCalendarMonth class="w-16 h-16 p-3 rounded-full bg-sky-300 text-sky-700" :stroke-width="1" />
    <MiniButton type="bold" @click="chooseDates">Choose travel dates</MiniButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import DraggableList from 'vuedraggable'
import { IconCalendarMonth } from '@tabler/icons-vue'
import { FunctionEnum } from '@/enums'

export default defineComponent({
  components: {
    DraggableList,
    IconCalendarMonth,
  },
  props: {
    layout: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [] as object[][],
    }
  },
  computed: {
    ...mapState('user', ['itinerary']),
    hasDates() {
      return this.portfolio.departure_date.length > 0 && this.portfolio.return_date.length > 0
    },
    numDays() {
      return this.$dayjs(this.portfolio.return_date).diff(this.portfolio.departure_date, 'days') + 1 // inclusive of both dates
    },
    dragOptions() {
      return {
        animation: 250,
        group: 'itinerary',
      }
    },
  },
  watch: {
    numDays: {
      immediate: true,
      handler() {
        if (this.items.filter(Boolean).length) {
          // there are already items in the itinerary
          if (this.numDays > this.items.length) {
            // add empty days
            this.items.push(...Array.from({ length: this.numDays - this.items.length }, () => []))
          } else {
            // remove days
            this.items.splice(this.numDays)
          }
        } else {
          // initialize itinerary
          this.items = Array.from({ length: this.numDays }, () => [])
        }
      },
    },
  },
  mounted() {
    if (this.itinerary.length > 0) {
      this.items = [...this.itinerary]
    }
  },
  methods: {
    ...mapActions('user', ['setItinerary']),
    chooseDates() {
      this.$emitter.emit(FunctionEnum.TRAVEL_DATES, {
        departure_date: this.$dayjs().add(1, 'week').format('YYYY-MM-DD'),
        return_date: this.$dayjs().add(2, 'week').format('YYYY-MM-DD'),
        show_calendar: true,
      })
    },
    removeItem(day: number, item: object) {
      this.items[day] = this.items[day].filter((i) => i !== item)
      this.setItinerary(this.items)
    },
  },
})
</script>
