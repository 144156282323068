<template>
  <div v-if="hasAttractions" class="flex flex-col gap-6">
    <h3 class="relative font-medium text-xl text-violet-300 flex items-center gap-2">
      <IconBuildingCarousel class="w-9 h-9 p-2 rounded-full bg-sky-200 text-sky-500" />
      Top attractions
    </h3>
    <div class="grid grid-cols-3 gap-4">
      <AttractionCard v-for="attraction in attractions" :key="attraction.id" :attraction="attraction" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { IconBuildingCarousel } from '@tabler/icons-vue'
import dataMixins from '../mixins/data'
import propsMixins from '../mixins/props'

export default defineComponent({
  components: { IconBuildingCarousel },
  mixins: [dataMixins, propsMixins],
})
</script>
