import { Comment, Text, Slot } from 'vue'
import { wait } from '@/libs/utils'

export default {
  methods: {
    isSlotEmpty(slot?: Slot, slotProps: object = {}) {
      if (!slot) return true

      return slot(slotProps).some((vnode) => {
        if (vnode.type === Comment) return true

        if (Array.isArray(vnode.children) && !vnode.children.length) return true

        return !(vnode.type !== Text || (typeof vnode.children === 'string' && vnode.children.trim() !== ''))
      })
    },
    beforeGroupTransitionLeave(el: Element) {
      const htmlEl = el as HTMLElement
      // set width/height/position to prevent resizing/moving when leaving
      const { marginLeft, marginTop, width, height } = window.getComputedStyle(htmlEl)
      htmlEl.style.left = `${htmlEl.offsetLeft - parseFloat(marginLeft)}px`
      htmlEl.style.top = `${htmlEl.offsetTop - parseFloat(marginTop)}px`
      htmlEl.style.width = width
      htmlEl.style.height = height
    },
    emphasize(el: HTMLElement) {
      if (!el) return
      el.onanimationend = () => {
        el.classList.remove('emphasize')
      }
      el.classList.add('emphasize')
    },
    spotlight(el: HTMLElement) {
      if (!el) return
      el.onanimationend = () => {
        el.classList.remove('spotlight')
      }
      el.classList.add('spotlight')
    },
    async showToastMessage(message: string, duration: number) {
      this.$store.dispatch('setToastMessage', message)
      await wait(duration)
      this.hideToastMessage()
    },
    hideToastMessage() {
      this.$store.dispatch('setToastMessage', null)
    },
  },
}

declare module 'vue' {
  interface ComponentCustomProperties {
    isSlotEmpty: (slot?: Slot, slotProps?: object) => boolean
    beforeGroupTransitionLeave: (el: Element) => void
    emphasize: (el: HTMLElement) => void
    showLoadingOverlay: (message: string, duration: number) => Promise<void>
    hideLoadingOverlay: () => void
  }
}
