<template>
  <div :style="`--sidebar-width:${sidebarWidthValue}`">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { SIDEBAR_WIDTH } from '@/constants'

export default defineComponent({
  computed: {
    ...mapState(['sidebarWidth']),
    sidebarWidthValue() {
      return SIDEBAR_WIDTH?.[this.sidebarWidth] ?? null
    },
  },
})
</script>
