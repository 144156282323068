import { apiFetch, apiFetchWithTripId } from '@/libs/api'

export default {
  methods: {
    // helper method to send request to API and parse response
    apiFetch: apiFetch.bind(this),
    // helper method to send request to API with tripId and parse response
    apiFetchWithTripId: apiFetchWithTripId.bind(this),
  },
}

declare module 'vue' {
  interface ComponentCustomProperties {
    apiFetch: (endpoint: string, body: any, method?: 'post' | 'get') => any | null
    apiFetchWithTripId: (endpoint: string, body: any, method?: 'post' | 'get') => any | null
  }
}
