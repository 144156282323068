<script setup lang="ts">
import { ModuleEventEnum } from '@/enums'
</script>

<template>
  <div
    v-if="showHint && isCallActive"
    class="bg-violet-50 text-sm leading-tight rounded-xl flex items-center gap-3 py-3 px-3"
    :class="{
      hint: showHint,
      'cursor-pointer': hint.link,
      'pointer-events-none': !hint.link,
      'min-w-[15em]': `${hint.text} ${hint?.link?.text}`.split(' ').length > 5,
    }"
    :position="position"
    @animationend="resumeInterval"
    @click="clickHandler">
    <IconSparkles class="w-6 h-6 shrink-0 text-blue-violet-300" />
    <div>
      <span v-if="hint.text" :class="{ 'inline-flex min-w-[10em]': hint.text.split(' ').length > 5 }">{{ hint.text }}&nbsp;</span>
      <span v-if="hint.link" class="text-blue-violet underline">{{ hint.link.text }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState, mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import { TranscriptMessage } from '@/types'
import { MessageRoleEnum } from '@/enums'
import { IconSparkles } from '@tabler/icons-vue'

const CHECK_INTERVAL = 5000
const HINT_DELAY = 3000

export default defineComponent({
  compontents: {
    IconSparkles,
  },
  props: {
    position: {
      type: String,
      default: 'top',
    },
  },
  data() {
    return {
      hintInterval: 0,
      hintTimeout: 0,
      hint: null as any,
      hintIndex: 0,
      showHint: false,
      hints: [
        {
          // mic is not muted and user hasn't spoken yet
          condition: () =>
            !this.isAssistantTalking && !this.isMuted && (this.messages as TranscriptMessage[]).filter((m) => m.role === MessageRoleEnum.USER).length === 0,
          text: "Just speak to me! I'm listening",
        },
        {
          // mic is muted and user is talking
          condition: () => this.isMuted && this.isUserTalking,
          text: `Sounds like you're talking.`,
          link: {
            text: 'Switch to voice mode?',
            handler: () => {
              this.$emitter.emit(ModuleEventEnum.SWITCH_TO_TEXT_INPUT, { enable: false })
            },
          },
        },
        {
          // input mode is voice
          condition: () => !this.isMuted,
          text: 'Would you rather',
          link: {
            text: 'type than talk?',
            handler: () => {
              this.$emitter.emit(ModuleEventEnum.SWITCH_TO_TEXT_INPUT, { enable: true })
            },
          },
        },
      ],
    }
  },
  computed: {
    ...mapState('assistant', ['isAssistantTalking', 'isUserTalking', 'messages', 'inputMode']),
    ...mapGetters('assistant', ['isMuted']),
    isAnyoneTalking() {
      return this.isAssistantTalking || this.isUserTalking
    },
  },
  mounted() {
    this.hintInterval = window.setInterval(this.tryNextHint, CHECK_INTERVAL)
  },
  beforeUnmount() {
    clearInterval(this.hintInterval)
    clearTimeout(this.hintTimeout)
  },
  methods: {
    resumeInterval() {
      this.showHint = false
      this.hintInterval = window.setInterval(this.tryNextHint, CHECK_INTERVAL)
    },
    tryNextHint() {
      if (this.hintIndex >= this.hints.length) {
        clearInterval(this.hintInterval)
        return
      }

      // try next hint
      this.hint = this.hints[this.hintIndex]
      this.hintIndex++
      if (this.hint.condition()) {
        // show hint after brief delay
        clearInterval(this.hintInterval)
        this.hintTimeout = window.setTimeout(() => {
          this.showHint = true
        }, HINT_DELAY)
      }
    },
    clickHandler() {
      if (this.hint.link && this.hint.link.handler) {
        this.hint.link.handler()
      }
    },
  },
})
</script>

<style scoped>
.hint {
  animation: tooltip 6s var(--ease-in-out-circ) forwards;
}

@keyframes tooltip {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  10% {
    transform: translateY(0%);
    opacity: 1;
  }
  90% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(50%);
    opacity: 0;
  }
}
</style>
