import axios from 'axios'

export default {
  methods: {
    async getStockPhoto({
      query,
      random = true,
      offset = 0,
      orientation = 'landscape',
    }: {
      query: string
      random?: boolean
      offset?: number
      orientation?: 'landscape' | 'portrait' | 'squarish'
    }) {
      if (!query) return null
      const { data } = await axios.get(
        `https://api.unsplash.com/search/photos?query=${query}&orientation=${orientation}&client_id=${import.meta.env.VITE_UNSPLASH_CLIENT_ID}`
      )
      if (data.results.length > 0) {
        const index = random ? Math.floor(Math.random() * data.results.length) : Math.min(offset, data.results.length - 1)
        return data.results[index].urls.regular
      }
      return null
    },
  },
}

declare module 'vue' {
  interface ComponentCustomProperties {
    getStockPhoto: ({
      query,
      random,
      offset,
      orientation,
    }: {
      query: string
      random?: boolean
      offset?: number
      orientation?: 'landscape' | 'portrait' | 'squarish'
    }) => Promise<string | null>
  }
}
