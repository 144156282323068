<template>
  <div class="absolute left-0 top-1/2 -translate-y-1/2 z-[9999] transition duration-500" :class="{ '-translate-x-8': !menuOpen }">
    <transition name="menu" appear>
      <div v-if="menuOpen" class="absolute left-0 top-0 -translate-y-1/2 w-[20rem] h-[80vh] p-4 transition duration-500">
        <div class="relative w-full h-full p-6 rounded-5xl bg-black/10">
          <IconX
            class="content-immediate absolute top-6 right-6 w-12 h-12 p-3 bg-blue-violet text-white rounded-full cursor-pointer hover:-rotate-90 transition duration-300 z-10"
            @click.stop="toggle" />
          <InlineScroller class="content-wait text-slate-400" container-class="flex flex-col" :show-gradient="false">
            <div class="flex flex-col justify-center my-auto" :class="{ 'gap-8': !breakpoint.short, 'gap-4': breakpoint.short }">
              <slot />
            </div>
          </InlineScroller>
        </div>
      </div>
    </transition>
    <transition name="fade" appear>
      <div v-if="!menuOpen" class="absolute left-0 top-0 -translate-y-1/2 w-24 h-24 p-4">
        <div class="w-full h-full p-4 bg-white rounded-full cursor-pointer group hover:shadow-lg transition z-10" @click.stop="toggle">
          <IconSparkles class="w-full h-full text-sky-300 group-hover:scale-[0.8] transition" stroke-width="1.5" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { IconChevronLeft, IconSparkles, IconX } from '@tabler/icons-vue'

export default defineComponent({
  components: {
    IconSparkles,
    IconChevronLeft,
    IconX,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    breakpoint: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['open', 'close'],
  data() {
    return {
      menuOpen: false,
    }
  },
  watch: {
    open: {
      immediate: true,
      handler(value) {
        this.menuOpen = value
      },
    },
    menuOpen(value) {
      if (value) {
        this.$emit('open', value)
      } else {
        this.$emit('close', value)
      }
    },
  },
  methods: {
    toggle() {
      this.menuOpen = !this.menuOpen
    },
  },
})
</script>

<style scoped>
.menu-enter-active {
  transition:
    width 0.45s var(--ease-in-out-circ),
    height 0.45s var(--ease-in-out-circ) 0.1s;
}

.menu-leave-active {
  transition:
    width 0.35s var(--ease-in-out-circ) 0.1s,
    height 0.35s var(--ease-in-out-circ);
}

.menu-enter-from,
.menu-leave-to {
  @apply w-24 h-24 overflow-hidden;
}

.content-wait {
  transition:
    opacity 0.35s ease-in-out 0.4s,
    transform 0.65s ease-in-out !important;
}

.menu-leave-active .content-immediate,
.menu-leave-active .content-wait {
  transition: all 0.1s ease-in-out !important;
}

.menu-enter-from .content-immediate,
.menu-enter-from .content-wait,
.menu-leave-to .content-immediate,
.menu-leave-to .content-wait {
  @apply opacity-0 translate-y-4;
}
</style>
