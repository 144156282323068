<template>
  <div
    class="relative bg-gradient-to-r from-violet-50 to-purple-50 rounded-full flex items-center justify-between"
    :class="{ 'px-4 py-2': size === 'md', 'px-5 py-3': size === 'lg', 'pr-20': canClose && size === 'lg', 'pr-16': canClose && size === 'md' }">
    <span v-if="title" class="font-serif text-blue-violet" :class="{ 'text-xl': size === 'md', 'text-2xl': size === 'lg' }">{{ title }}</span>
    <slot />
    <a
      v-if="canClose"
      class="h-full aspect-square bg-violet-100 rounded-full flex items-center justify-center cursor-pointer absolute top-0 right-0 hover:-rotate-90 transition duration-300 z-10"
      @click="$emit('close')">
      <IconX class="w-6 h-6 text-blue-violet" />
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { IconX } from '@tabler/icons-vue'

export default defineComponent({
  components: { IconX },
  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'lg',
    },
    canClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
})
</script>
