<template>
  <Button icon light class="relative" @click="openScratchpad">
    <IconPin />
    <div v-if="numItems > 0" class="absolute top-0 right-0 font-sans bg-violet-400 text-white text-xs py-0.5 px-1.5 rounded-full -mr-2">
      {{ numItems }}
    </div>
  </Button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { IconPin } from '@tabler/icons-vue'
import { SidebarTabEnum } from '@/enums'

export default defineComponent({
  components: {
    IconPin,
  },
  computed: {
    ...mapGetters('user', ['scratchPadItems']),
    numItems() {
      return this.scratchPadItems.length
    },
  },
  methods: {
    openScratchpad() {
      this.showSidebar({ tab: SidebarTabEnum.SCRATCHPAD })
    },
  },
})
</script>
