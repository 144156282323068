<template>
  <div class="pointer-events-none absolute w-screen h-screen z-[9999]">
    <div v-for="orb in orbs" :key="orb.id" :ref="setElRef" class="orb fixed hidden">
      <div class="ball w-12 h-12 bg-gradient-to-br from-emerald-200 to-violet-400 rounded-full mix-blend-screen blur" />
      <OrbStar
        :style="{ animationDuration: `${animationDuration}ms` }"
        class="star-back absolute -top-12 -left-12 origin-center w-36 h-36 text-violet-300 mix-blend-overlay"
        :stroke-width="1" />
      <OrbStar
        :style="{ animationDuration: `${animationDuration}ms` }"
        class="star-front absolute -top-10 -left-10 origin-center w-32 h-32 text-violet-600 opacity-80 mix-blend-color-dodge"
        :stroke-width="1.5" />
    </div>
    <svg ref="svg" class="hidden fixed w-screen h-screen inset-0">
      <path :d="path" fill="none" stroke="red" />
    </svg>
  </div>
</template>

<script lang="ts">
import { ORB_SHORT_DELAY, ORB_STAGGER_DELAY, SIDEBAR_TRANSITION_DURATION } from '@/constants'
import { animateElementAlongPath, getCurvedPath, getPositionOfElement, wait } from '@/libs/utils'
import { Events, SidebarDetailsPanelType } from '@/types'
import { ModuleEventEnum } from '@/enums'
import uniqid from 'uniqid'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import OrbStar from './OrbStar.vue'

interface OrbAnimation {
  name: keyof Events
  el: HTMLElement
  message: string
  panel?: SidebarDetailsPanelType
}

export default defineComponent({
  components: { OrbStar },
  props: {
    sidebar: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      orbs: [] as any[],
      elRefs: [] as HTMLElement[],
      path: '',
      animationDuration: 1500, // ms
      animationQueue: [] as OrbAnimation[],
      isAnimating: false,
      isSidebarOpenForAnimation: false,
      processQueueTimeout: null as number | null,
      wasSidebarOpen: false,
    }
  },
  computed: {
    ...mapState(['isSidebarOpen', 'sidebarDetailsPanel', 'firstItemAddedToSidebar', 'sidebarDetailsPanelShown']),
  },
  mounted() {
    this.$emitter.on(ModuleEventEnum.ADD_TO_SIDEBAR, this.queueOrbAnimation)
  },
  unmounted() {
    this.$emitter.off(ModuleEventEnum.ADD_TO_SIDEBAR, this.queueOrbAnimation)
  },
  beforeUpdate() {
    this.elRefs = []
  },

  methods: {
    ...mapActions(['setSidebarLocked', 'hideSidebar']),
    setElRef(el) {
      if (el) {
        this.elRefs.push(el)
      }
    },
    getEndElement(name: string, sidebarWillOpen: boolean) {
      // get the panel name from the name param
      const panel = name.split('/')[0]
      const rootRef = this.sidebar?.$refs?.['Sidebar/tripDetails']
      return new Promise((resolve) => {
        if (sidebarWillOpen || this.isSidebarOpen) {
          this.$nextTick(() => {
            const itemRef = rootRef?.$refs?.[`Portfolio/${name}`]
            const itemEl = Array.isArray(itemRef) ? (itemRef?.[0]?.$el as HTMLElement) : (itemRef?.$el as HTMLElement)
            const panelRef = rootRef.$refs?.[`Portfolio/${panel}`]
            const panelEl = panelRef?.$el as HTMLElement
            // fall back to the panel if the item is not found
            // e.g. if the user toggled the sidebar to a different panel
            // or closed the sidebar
            resolve(itemEl || panelEl)
          })
        } else {
          resolve(document.getElementById('sidebarToggleButton') as HTMLElement)
        }
      })
    },
    queueOrbAnimation(params: OrbAnimation) {
      this.animationQueue.push(params)
      // Define the order of panels
      const panelOrder = ['tripDetails', 'tripInfo', 'userInfo']
      // Sort the queue by panel name
      this.animationQueue.sort((a, b) => {
        const panelA = a.panel || ''
        const panelB = b.panel || ''
        return panelOrder.indexOf(panelA) - panelOrder.indexOf(panelB)
      })

      if (this.processQueueTimeout) {
        clearTimeout(this.processQueueTimeout)
      }

      // debounce the queue processing
      // so if many items are added in quick succession,
      // the queue processing is only called once
      this.processQueueTimeout = setTimeout(() => {
        if (!this.isAnimating) {
          this.wasSidebarOpen = this.isSidebarOpen
          this.processQueue()
        }
      }, 500)
    },
    async processQueue() {
      if (this.animationQueue.length === 0) {
        this.isAnimating = false
        this.isSidebarOpenForAnimation = false

        this.setSidebarLocked(false)

        if (!this.wasSidebarOpen) {
          this.hideSidebar()
        }
        return
      }
      this.isAnimating = true
      const currentItem = this.animationQueue.shift()!
      const nextItem = this.animationQueue[0]
      const shouldOpenSidebar =
        (!this.isSidebarOpen && !this.sidebarDetailsPanelShown[currentItem.panel]) ||
        (this.isSidebarOpen && currentItem.panel && this.sidebarDetailsPanel !== currentItem.panel)

      this.setSidebarLocked(true)

      if (shouldOpenSidebar) {
        this.isSidebarOpenForAnimation = true
        this.$emitter.emit(ModuleEventEnum.OPEN_SIDEBAR, { panel: currentItem.panel })
        await wait(SIDEBAR_TRANSITION_DURATION + 1)
      }

      if (!nextItem || (nextItem && nextItem.panel !== currentItem.panel)) {
        // Wait for the full animation duration if the next item is on a different panel
        await this.createOrb(currentItem, this.isSidebarOpenForAnimation || this.isSidebarOpen)
        await wait(ORB_SHORT_DELAY)
      } else {
        // Wait for a short delay if the next item is on the same panel
        this.createOrb(currentItem, this.isSidebarOpenForAnimation || this.isSidebarOpen)
        await wait(ORB_STAGGER_DELAY)
      }

      this.processQueue()
    },
    async createOrb({ name, el, message }: OrbAnimation, isSidebarOpen: boolean) {
      const id = uniqid()
      this.orbs.push({ id, message })
      const start = getPositionOfElement(el)
      if (start.x === 0 && start.y === 0) {
        start.x = window.innerWidth / 1.75
        start.y = window.innerHeight / 2
      }
      const endEl = (await this.getEndElement(name, isSidebarOpen)) as HTMLElement
      let end = getPositionOfElement(endEl)

      if (isSidebarOpen) {
        let elEnd = getPositionOfElement(endEl, 'left')
        if (elEnd?.x !== 0 && elEnd?.y !== 0) {
          end = elEnd
        }
      }

      if (end.x === 0 && end.y === 0) {
        // cancel the animat
        return new Promise<void>((resolve) => {
          resolve()
        })
      }

      const path = getCurvedPath(start, end)
      const orbEl = this.elRefs[this.elRefs.length - 1] as HTMLElement

      return new Promise<void>((resolve) => {
        animateElementAlongPath(orbEl, path, this.animationDuration, async () => {
          this.orbs = this.orbs.filter((orb) => orb.id !== id)
          this.$emitter.emit(ModuleEventEnum.ADDED_TO_SIDEBAR, { name, content: message })
          resolve()
        })
      })
    },
  },
})
</script>

<style scoped>
.star-front {
  animation: frontSpin 1s var(--ease-in-cubic) infinite;
}

@keyframes frontSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.star-back {
  animation: backSpin 1s var(--ease-in-out-cubic) infinite;
}

@keyframes backSpin {
  0% {
    transform: rotate(0deg) scale(0.5);
  }
  50% {
    transform: rotate(180deg) scale(1.5);
  }
  100% {
    transform: rotate(540deg) scale(1);
  }
}
</style>
