import { App } from 'vue'
import { Store } from 'vuex'
import { Router } from 'vue-router'
import Module from '@/modules/module'

const size = 'lg'

export default ({ name, store, app }: { name: string; router?: Router; store: Store; app: App }) => {
  // create module
  const module = new Module({ name, size, store, app })

  // add module store
  module.addModuleStore({
    state: {
      // ...
    },
    actions: {
      // ...
    },
    mutations: {
      // ...
    },
  })
}
