<template>
  <div v-if="hasProducts" class="flex flex-col gap-6">
    <h3 class="relative font-medium text-xl text-violet-300 flex items-center gap-2">
      <IconIceSkating class="w-9 h-9 p-2 rounded-full bg-amber-200 text-amber-500" />
      Activities
    </h3>
    <div class="grid grid-cols-3 gap-4">
      <ActivityCard v-for="product in products" :key="product.productCode" :activity="product" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { IconIceSkating } from '@tabler/icons-vue'
import dataMixins from '../mixins/data'
import propsMixins from '../mixins/props'

export default defineComponent({
  components: { IconIceSkating },
  mixins: [dataMixins, propsMixins],
})
</script>
