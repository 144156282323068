import Module from '@/modules/module'
import { State } from '@/store'
import { UserOnboardingConfirmation } from '@/types'
import { FunctionEnum, MessageRoleEnum, ModuleEventEnum, ModuleTypeEnum, UserOnboardingStepTypeEnum, AssistantEnum } from '@/enums'
import { App } from 'vue'
import { Router } from 'vue-router'
import { Store } from 'vuex'
import { getCommaSeparatedList, unslugify } from '@/libs/utils'

export default ({ name, store, app }: { name: string; router?: Router; store: Store; app: App }) => {
  // create module
  const module = new Module({ name, store, app, type: ModuleTypeEnum.FULLSCREEN })

  const sanitizeResponse = (response: string[]) => {
    return getCommaSeparatedList(response.map((r) => unslugify(r)))
  }

  const confirmHandler = (params: UserOnboardingConfirmation) => {
    const sanitizedResponse: string = sanitizeResponse(params.response)

    switch (params.step.type) {
      case UserOnboardingStepTypeEnum.PRIORITIES:
        return `The user has indicated that they would like to prioritize ${sanitizedResponse} on their trip.`
      case UserOnboardingStepTypeEnum.IMAGES:
        return `The user has responded to the question: "${params.step.question}" with the following answer: "${sanitizedResponse}".`
      case UserOnboardingStepTypeEnum.QUESTION:
      default: {
        return `The user has responded to the question: "${params.step.question}" with the following answer: "${sanitizedResponse}".`
      }
    }
  }

  const breakdownPreferencesObject = (details: { [key: string]: string[] }) => {
    return Object.entries(details)
      .map(([key, value]) => {
        return `for the category "${key}", the user "${getCommaSeparatedList(value)}"`
      })
      .join(', ')
  }

  // this is a generic function that can be used to get the user details for the onboarding step completed message
  // we might want to use this elsewhere, as it could be used to provide the AI context for some other things.
  const getUserDetails = ({ state }: { state: State }) => {
    const travelers = module.loadPreference('travelers') || store.portfolio?.travelers
    const departure_date = store.portfolio?.departure_date
    const return_date = store.portfolio?.return_date
    const destination = store.portfolio?.location

    const result = {
      portfolio_summary: state.portfolioSummary,
      user_info: breakdownPreferencesObject(store.getters['user/userInfo']),
      trip_info: breakdownPreferencesObject(store.getters['user/tripInfo']),
      travelers,
      destination,
      departure_date,
      return_date,
    }

    // remove empty string, empty arrays, and undefined values
    return Object.entries(result).reduce((acc, [key, value]) => {
      if (value && value.length > 0 && value !== 'undefined') {
        acc[key] = value
      }
      return acc
    }, {})
  }

  // register event handlers
  module.registerEventHandlers([
    {
      event_name: FunctionEnum.USER_ONBOARDING,
      shownHandler: ({ toolCallId }) => {
        // toolCallId is only present on first step
        if (toolCallId) {
          // wait for the tool message event to be emitted
          // and then send the system message
          return new Promise((resolve) => {
            const eventHandler = (message) => {
              if (message.tool_call_id === toolCallId) {
                app.config.globalProperties.$emitter.off(AssistantEnum.TOOL_MESSAGE, eventHandler)
                resolve(
                  `The user has been shown the results of the ${FunctionEnum.USER_ONBOARDING} tool call with id ${toolCallId}, so you may now reference that content.`
                )
              }
            }
            app.config.globalProperties.$emitter.on(AssistantEnum.TOOL_MESSAGE, eventHandler)
          })
        }
        return ''
      },
      confirmHandler,
      removeHandler: ({ category, filter }) => {
        return `The user is no longer interested in ${category} - ${filter}`
      },
    },
  ])

  // add module store
  module.addModuleStore({
    state: {
      // ...
    },
    actions: {
      // gets called when the module is loaded
      async onModuleLoaded() {
        // listen for onboarding step completion
        app.config.globalProperties.$emitter.on(ModuleEventEnum.ONBOARDING_STEP_COMPLETED, async ({ name: event_name, params }) => {
          console.log('[userOnboarding] step completed', params)

          const onboardingParams = params as UserOnboardingConfirmation
          store.dispatch('incrementOnboardingStepsCompleted')

          if (event_name === FunctionEnum.USER_ONBOARDING) {
            // get message
            const message = await confirmHandler(onboardingParams)

            if (message) {
              const currentOnboardingStep = store.state.onboardingStepsCompleted
              let nextStepType = null as UserOnboardingStepTypeEnum | null
              let systemMessage = ''

              console.log('[userOnboarding] current step #', currentOnboardingStep)

              // check if the user selected "other" in the images step
              const selectedOther =
                onboardingParams?.step?.type === UserOnboardingStepTypeEnum.IMAGES &&
                onboardingParams?.response
                  ?.filter(Boolean)
                  .map((r) => r.toLowerCase())
                  .includes('other')

              // determine the next step type
              if (currentOnboardingStep === 1) {
                nextStepType = UserOnboardingStepTypeEnum.PRIORITIES
              } else if (currentOnboardingStep === 2) {
                nextStepType = UserOnboardingStepTypeEnum.IMAGES
              }

              // last step
              if (onboardingParams?.step?.type === UserOnboardingStepTypeEnum.IMAGES) {
                if (selectedOther) {
                  // ask for more details
                  systemMessage = `The user selected "other" as their answer to ${onboardingParams.step.question}. Prompt the user to provide more information before proceeding.`
                }
                // done
                store.dispatch('removeActiveModule', name)
              }

              const userDetails = getUserDetails(store)
              const messageDetails = {
                onboarding_step_completed_message: message,
                ...userDetails,
              }

              store.dispatch('assistant/sendMessage', {
                role: MessageRoleEnum.SYSTEM,
                content: `${message}\n\nSince we are onboarding, do not follow up with the user right now. The ${FunctionEnum.USER_ONBOARDING} function should not be triggered.`,
              })

              // send the event to trigger the function call for the next step
              app.config.globalProperties.$emitter.emit(ModuleEventEnum.SEND_ONBOARDING_STEP_COMPLETED_MESSAGE, {
                type: nextStepType as UserOnboardingStepTypeEnum,
                details: messageDetails,
                callback: systemMessage
                  ? () => {
                      store.dispatch('assistant/sendMessage', {
                        role: MessageRoleEnum.SYSTEM,
                        content: systemMessage,
                      })
                    }
                  : undefined,
              })
            }
          }
        })
      },
    },
    mutations: {
      // ...
    },
  })
}
