export default {
  async reset({ commit, dispatch }) {
    // reset UI
    commit('setFtuxModule', false)
    commit('setActiveModules', [])
    commit('setInformationModules', [])
    commit('setPortfolioModules', [])
    commit('setPortfolioSummary', '')
    commit('setCustomPortfolioSummary', '')
    commit('setSidebar', false)
    commit('setWeatherForecast', null)
    commit('clearFunctionQueue')
    commit('abortAllAbortControllers')

    // reset assistant

    commit('assistant/clearMessages')
    commit('assistant/setModelOutput', [])
    commit('assistant/clearQueuedModelOutput')
    commit('assistant/setWaitingForAssistant', false)

    await dispatch('assistant/restart')
  },

  // modules

  createModule({ commit }, value) {
    commit('createModule', value)
  },
  updateModule({ commit }, value) {
    commit('updateModule', value)
  },

  // active modules

  setActiveModules({ commit }, value) {
    commit('setActiveModules', value)
  },
  async addActiveModule({ commit, dispatch, state }, value) {
    commit('addActiveModule', value)
    const module = state.modules.find((m) => m.name === value.name)
    if (module && !module.accessed) {
      await dispatch(`module/${value.name}/onModuleAccessed`)
      dispatch('updateModule', { name: value.name, accessed: true }) // mark as accessed
    }
  },
  updateActiveModule({ commit }, value) {
    commit('updateActiveModule', value)
  },
  removeActiveModule({ commit }, value) {
    commit('removeActiveModule', value)
  },
  removeAllOtherActiveModules({ commit }, value) {
    commit('removeAllOtherActiveModules', value)
  },

  // function queue

  addToFunctionQueue({ commit }, value) {
    commit('addToFunctionQueue', value)
  },
  queueFunction({ commit }, value) {
    console.log('addToFunctionQueue', value)
    commit('addToFunctionQueue', value)
  },
  runNextQueuedFunction({ commit }) {
    commit('runNextQueuedFunction')
  },
  clearFunctionQueue({ commit }) {
    commit('clearFunctionQueue')
  },

  // abort controllers

  addAbortController({ commit }, value) {
    commit('addAbortController', value)
  },
  clearAbortController({ commit }, value) {
    commit('clearAbortController', value)
  },
  abortAllAbortControllers({ commit }) {
    commit('abortAllAbortControllers')
  },

  // api error

  setApiError({ commit }, value) {
    commit('setApiError', value)
  },

  // portfolio

  setPortfolioSummary({ commit, dispatch }, value) {
    commit('setPortfolioSummary', value)
    dispatch('user/updateTrip', { title: value }, { root: true })
  },
  setCustomPortfolioSummary({ commit, dispatch }, value) {
    commit('setCustomPortfolioSummary', value)
    dispatch('user/updateTrip', { title: value }, { root: true })
  },
  incrementOnboardingStepsCompleted({ commit }) {
    commit('incrementOnboardingStepsCompleted')
  },
  setPortfolioModules({ commit }, value) {
    commit('setPortfolioModules', value)
  },
  addToPortfolioModules({ commit }, value) {
    commit('addToPortfolioModules', value)
  },
  removeFromPortfolioModules({ commit }, value) {
    commit('removeFromPortfolioModules', value)
  },

  // ui

  toggleSidebar({ commit, state }) {
    commit('setSidebar', !state.isSidebarOpen)
  },
  showSidebar({ commit }, value) {
    commit('setSidebar', true)
    if (value?.tab) {
      commit('setSidebarTab', value.tab)
    }
    if (value?.panel) {
      commit('setSidebarDetailsPanel', value.panel)
      commit('setSidebarDetailsPanelShown', value.panel)
    }
  },
  hideSidebar({ commit }) {
    commit('setSidebar', false)
  },
  setSidebar({ commit }, value) {
    commit('setSidebar', value)
    if (value?.panel) {
      commit('setSidebarDetailsPanel', value.panel)
    }
  },
  setSidebarTab({ commit }, value) {
    commit('setSidebarTab', value)
  },
  setSidebarDetailsPanel({ commit }, value) {
    commit('setSidebarDetailsPanel', value)
  },
  setSidebarLocked({ commit }, value) {
    commit('setSidebarLocked', value)
  },
  setFirstItemAddedToSidebar({ commit }, value) {
    commit('setFirstItemAddedToSidebar', value)
  },
  setSidebarDetailsPanelShown({ commit }, value) {
    commit('setSidebarDetailsPanelShown', value)
  },
  setSidebarWidth({ commit }, value) {
    commit('setSidebarWidth', value)
  },
  setOverlay({ state, commit }, value) {
    if (typeof value === 'object' && value) {
      const { id, data } = value
      commit('setOverlay', id)
      commit('setOverlayData', { ...state.overlayData, [id]: data || null })
    } else {
      commit('setOverlay', value)
      commit('setOverlayData', { ...state.overlayData, [value]: null })
    }
  },
  setToastMessage({ commit }, value) {
    commit('setToastMessage', value)
  },
  setTakeoverBackground({ commit }, value) {
    commit('setTakeoverBackground', value)
  },
  setFullScreenModule({ commit }, value) {
    commit('setFullScreenModule', value)
  },
  setFtuxModule({ commit }, value) {
    commit('setFtuxModule', value)
  },
  setInformationModules({ commit }, value) {
    commit('setInformationModules', value)
  },
  addToInformationModules({ commit }, value) {
    commit('addToInformationModules', value)
  },
  updateInformationModule({ commit }, value) {
    commit('updateInformationModule', value)
  },
  removeFromInformationModules({ commit }, value) {
    commit('removeFromInformationModules', value)
  },

  // weather

  setWeatherForecast({ commit }, value) {
    commit('setWeatherForecast', value)
  },
}
