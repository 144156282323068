<template>
  <div class="relative" :class="className">
    <div ref="toast" class="fixed opacity-0 translate-x-0 max-h-[100px] pointer-events-none z-[3]">
      <div
        class="py-1 px-3 text-white shadow-xl rounded-full text-sm bg-green-500 fixed origin-bottom-left"
        :class="{ '-translate-x-[175%]': !isSidebarOpen, '-translate-x-full': isSidebarOpen }">
        {{ toastContent }}
      </div>
    </div>
    <div v-if="!isSlotEmpty($slots.menu)" class="highlight-hover group cursor-default">
      <span v-if="label" class="text-slate-400 text-sm block sentence">{{ label }}</span>
      <slot />
      <IconPencil class="absolute z-0 right-2 top-1/2 -translate-y-1/2 pointer-events-none w-5 h-5 text-blue-violet-200" />
      <div
        class="absolute z-10 right-2 top-1/2 -translate-y-1/2 flex items-center gap-2 opacity-0 pointer-events-none transition group-hover:opacity-100 group-hover:pointer-events-auto">
        <slot name="menu" :modify="modify" :forget="forget" />
      </div>
    </div>
    <div v-else class="highlight-hover group cursor-default" :class="{ button: button, 'no-highlight': highlight !== true }">
      <span v-if="label" class="text-slate-400 text-sm block sentence">{{ label }}</span>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Events } from '@/types'
import { ModuleEventEnum } from '@/enums'
import { IconPencil } from '@tabler/icons-vue'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  components: {
    IconPencil,
  },
  props: {
    className: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    event: {
      type: String as PropType<keyof Events>,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    highlight: {
      type: Boolean,
      required: false,
      default: false,
    },
    button: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['modify', 'forget', 'added'],
  data() {
    return {
      toastContent: 'Saved!',
    }
  },
  mounted() {
    this.$emitter.on(ModuleEventEnum.ADDED_TO_SIDEBAR, this.addedToSidebar)
  },
  unmounted() {
    this.$emitter.off(ModuleEventEnum.ADDED_TO_SIDEBAR, this.addedToSidebar)
  },
  methods: {
    modify(payload: { event: keyof Events; params: object }) {
      this.$emit('modify', payload)
    },
    forget(event: keyof Events) {
      this.$emit('forget', event)
    },
    addedToSidebar({ name, content }: { name?: keyof Events; content?: string }) {
      if (name === this.event || name === this.name) {
        this.$el.querySelector('.highlight-hover').classList.add('highlighted')
        if (content) {
          this.toastContent = content
        }
        const animation = (this.$refs.toast as HTMLElement).animate(
          [
            {
              opacity: 0,
              transform: 'translateX(0)',
            },
            {
              opacity: 1,
              transform: 'translateX(-0.25rem)',
              offset: 0.25,
            },
            {
              opacity: 1,
              transform: 'translateX(-1.5rem)',
              offset: 0.99,
            },
            {
              opacity: 0,
              transform: 'translateX(-1.75rem)',
            },
          ],
          {
            duration: 1500,
            easing: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
            fill: 'both',
          }
        )

        animation.onfinish = () => {
          this.$el.querySelector('.highlight-hover').classList.remove('highlighted')
        }
      }
    },
  },
})
</script>
