<template>
  <transition name="fade">
    <div v-if="loading" class="fixed inset-0 bg-opacity-50">
      <Spinner class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" />
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
})
</script>
