<template>
  <div>
    <slot :gallery="gallery" />
    <Teleport to="body">
      <transition name="fade">
        <div
          v-if="selectedImage"
          class="fixed flex flex-col items-center gap-6 top-0 left-0 w-screen h-screen z-[99999] bg-gradient-to-br from-blue-violet-800/90 to-black/90 p-12"
          @click.stop="close">
          <IconX
            class="absolute top-2 right-2 w-12 h-12 text-white cursor-pointer transition duration-300 hover:rotate-90"
            stroke-width="1"
            @click.stop="close" />
          <IconChevronRight
            v-if="images.length > 1"
            stroke-width="1"
            class="absolute top-1/2 -right-5 -translate-y-1/2 w-24 h-24 p-5 text-white cursor-pointer transition duration-300 hover:translate-x-1"
            @click.stop="nextImage" />
          <IconChevronLeft
            v-if="images.length > 1"
            stroke-width="1"
            class="absolute top-1/2 -left-5 -translate-y-1/2 w-24 h-24 p-5 text-white cursor-pointer transition duration-300 hover:-translate-x-1"
            @click.stop="previousImage" />

          <transition name="fade" mode="out-in">
            <Spinner v-if="loading" class="text-white" absolute />
          </transition>

          <div class="flex-auto overflow-hidden w-full">
            <img
              :src="selectedImage.src"
              class="w-full h-full object-contain transition duration-500"
              :class="{
                'opacity-0 scale-[0.98]': loading,
              }"
              @load="onLoad" />
          </div>
          <p class="text-white transition duration-500" :class="{ 'opacity-0 -translate-y-1': loading }">{{ caption }}</p>

          <div v-if="images.length > 1" class="flex items-center gap-4">
            <img
              v-for="(image, index) in images"
              :key="index"
              :src="image.src"
              class="w-16 h-12 rounded object-cover cursor-pointer transition"
              :class="{ 'opacity-100': selectedIndex === index, 'opacity-30 hover:opacity-50': selectedIndex !== index }"
              @click.stop="selectedIndex = index" />
          </div>
        </div>
      </transition>
    </Teleport>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { IconChevronLeft, IconChevronRight, IconX } from '@tabler/icons-vue'

export default defineComponent({
  components: {
    IconX,
    IconChevronLeft,
    IconChevronRight,
  },
  data() {
    return {
      images: [] as { src: string; caption: string }[],
      el: null as HTMLElement | null,
      selectedIndex: null as number | null,
      loading: false,
      caption: '',
      observer: null as MutationObserver | null,
      gallery: {
        open: (e) => {
          this.selectedIndex = this.images.findIndex((img) => img.src === e.target.src)
        },
      },
    }
  },
  computed: {
    selectedImage() {
      return this.selectedIndex !== null ? this.images[this.selectedIndex] : null
    },
  },
  watch: {
    selectedIndex() {
      this.loading = true
    },
    selectedImage() {
      if (this.selectedImage) {
        this.addKeyboardEventListeners()
      } else {
        this.removeKeyboardEventListeners()
      }
    },
  },
  mounted() {
    this.el = this.$el as HTMLElement
    this.observer = new MutationObserver((records) => {
      for (const record of records) {
        if (record.type === 'childList') {
          this.updateImageList()
        }
      }
    })
    this.observer.observe(this.$el, { childList: true })
    this.updateImageList()
  },
  beforeUnmount() {
    this.observer?.disconnect()
    this.removeKeyboardEventListeners()
  },
  methods: {
    updateImageList() {
      if (!this.el) return []

      const els = this.el.querySelectorAll('img[gallery]') as NodeListOf<HTMLImageElement>
      this.images = Array.from(els).map((el) => ({
        src: el.src,
        caption: el.alt,
      }))
    },
    previousImage() {
      if (this.selectedIndex === null) return
      this.selectedIndex = (this.selectedIndex - 1 + this.images.length) % this.images.length
    },
    nextImage() {
      if (this.selectedIndex === null) return
      this.selectedIndex = (this.selectedIndex + 1) % this.images.length
    },
    onLoad() {
      this.loading = false
      this.caption = this.selectedImage?.caption || ''
    },
    addKeyboardEventListeners() {
      window.addEventListener('keydown', this.onKeyDown)
    },
    removeKeyboardEventListeners() {
      window.removeEventListener('keydown', this.onKeyDown)
    },
    onKeyDown(e: KeyboardEvent) {
      console.log('keyDown', e)
      e.stopPropagation()
      e.preventDefault()
      if (e.key === 'ArrowLeft') {
        this.previousImage()
      } else if (e.key === 'ArrowRight') {
        this.nextImage()
      } else if (e.key === 'Escape') {
        this.close()
      }
      return false
    },
    close() {
      this.selectedIndex = null
    },
  },
})
</script>
