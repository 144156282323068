import preferenceMethods from '@/libs/preferences'

// helper methods to save/load user preferences
// preferences are ephemeral and saved to local storage

export default {
  methods: {
    ...preferenceMethods,
  },
}
