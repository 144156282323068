<template>
  <Button v-bind="$props" class="relative overflow-hidden" @click="onClick">
    <slot />
    <div v-if="countdown && !disabled" class="timer absolute top-0 right-0 w-full h-full bg-white mix-blend-overlay opacity-80" />
  </Button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    countdown: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    return {
      timeout: 0,
    }
  },
  computed: {
    countdownInMs() {
      return `${this.countdown * 1000}ms`
    },
  },
  watch: {
    countdown: {
      immediate: true,
      handler(value) {
        window.clearTimeout(this.timeout)
        if (this.disabled) {
          return
        }
        if (value > 0) {
          this.timeout = window.setTimeout(() => {
            this.$emit('click')
          }, value * 1000)
        }
      },
    },
  },
  methods: {
    onClick() {
      window.clearTimeout(this.timeout)
      this.$emit('click')
    },
  },
})
</script>

<style scoped>
.timer {
  animation: timer 1s linear forwards;
  animation-duration: v-bind(countdownInMs);
}

@keyframes timer {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
</style>
