<template>
  <div class="relative">
    <component :is="`Shape${shape}`" class="absolute inset-0 z-1" />
    <div class="absolute flex items-center justify-center inset-0 z-[2]">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Shape1 from '@/assets/blobs/1.svg'
import Shape2 from '@/assets/blobs/2.svg'

export default defineComponent({
  components: { Shape1: Shape1 as any, Shape2: Shape2 as any },
  props: {
    shape: {
      type: Number,
      default: 1,
    },
  },
})
</script>
