<template>
  <div v-if="meta.length">
    <div class="bg-white shadow-xl py-8 px-8 -mx-8 rounded-4xl flex flex-wrap gap-x-8 gap-y-6">
      <div
        v-for="(item, index) in meta"
        :key="index"
        class="flex-1 flex gap-4 text-balance max-w-72"
        :class="[`meta meta-${(item.type || 'default').toLowerCase()}`, { 'min-w-48': meta.length > 3 }]">
        <component :is="getMetaIcon(item.type)" class="icon w-12 h-12 p-2.5 rounded-full shrink-0" stroke-width="1.5" />
        <div class="flex flex-col gap-1">
          <p class="text-blue-violet font-medium uppercase leading-tight">{{ item.title }}</p>
          <p class="text-sm leading-tight text-slate-500">{{ item.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  IconSparkles,
  IconHelpHexagon,
  IconManFilled,
  IconClock,
  IconCalendarEvent,
  IconThumbUp,
  IconArrowLeft,
  IconCash,
  IconSun,
  IconMapPin,
  IconBuildingCircus,
  IconMountain,
  IconLanguage,
  IconPlane,
} from '@tabler/icons-vue'
const LONG_DESCRIPTION_NUM_WORDS = 20

export default defineComponent({
  components: {
    IconSparkles,
    IconHelpHexagon,
    IconManFilled,
    IconClock,
    IconCalendarEvent,
    IconThumbUp,
    IconCash,
    IconArrowLeft,
    IconSun,
    IconMapPin,
    IconBuildingCircus,
    IconMountain,
    IconLanguage,
    IconPlane,
  },
  props: {
    recommendation: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    meta() {
      return (this.recommendation?.details?.meta ?? []).slice(0, 8)
    },
    hasLongMetaDescriptions() {
      return this.meta.some((item) => item.description.split(' ').length > LONG_DESCRIPTION_NUM_WORDS)
    },
  },
  methods: {
    getMetaIcon(type: string) {
      switch (type.toLowerCase()) {
        case 'travelers':
        case 'population':
          return IconManFilled
        case 'duration':
          return IconClock
        case 'dates':
        case 'when':
        case 'events':
        case 'event_types':
        case 'besttime':
          return IconCalendarEvent
        case 'verified':
          return IconThumbUp
        case 'budget':
        case 'currency':
          return IconCash
        case 'weather':
        case 'temperature':
          return IconSun
        case 'location':
        case 'locations':
          return IconMapPin
        case 'attractions':
          return IconBuildingCircus
        case 'adventure':
          return IconMountain
        case 'language':
          return IconLanguage
        case 'airport':
          return IconPlane
        default:
          return IconSparkles
      }
    },
  },
})
</script>

<style scoped>
.meta .icon {
  @apply bg-slate-300 text-slate-600;
}
.meta-travelers .icon,
.meta-population .icon {
  @apply bg-orange-300 text-orange-600;
}
.meta-duration .icon,
.meta-language .icon {
  @apply bg-violet-300 text-violet-600;
}
.meta-dates .icon,
.meta-when .icon,
.meta-events .icon,
.meta-event_types .icon,
.meta-besttime .icon {
  @apply bg-sky-300 text-sky-600;
}
.meta-verified .icon,
.meta-weather .icon,
.meta-temperature .icon {
  @apply bg-amber-300 text-amber-600;
}
.meta-budget .icon,
.meta-currency .icon {
  @apply bg-emerald-300 text-emerald-600;
}
.meta-location .icon,
.meta-locations .icon {
  @apply bg-blue-violet-300 text-blue-violet-600;
}
.meta-attractions .icon {
  @apply bg-pink-300 text-pink-600;
}
.meta-airport .icon {
  @apply bg-pink-300 text-pink-600;
}
</style>
